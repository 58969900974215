import { SurgeLog } from '@common/api/pricingAlgorithm/types';
import { Modal, Group, Button, Flex, Stack, Text } from '@mantine/core';
import { SurgeProtectionDecayChart } from '@pages/Client/PricingStrategy/SurgeProtection/components/SurgeProtectionDecayChart';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { Icon } from '@common/components/foundations/icons';
import { usePriceDrawerStore } from '@common/store/priceDrawer';

type Props = {
  opened: boolean;
  onClose: () => void;
  surgeEvent: SurgeLog | undefined;
  selectedRoomId: number | undefined;
};

export const PriceDecayChartModal: React.FC<Props> = ({
  opened,
  onClose,
  surgeEvent,
  selectedRoomId
}) => {
  const { t } = useTranslation();
  const { pricingSettings } = usePricingSettings();
  const { hotelDetails } = useHotelDetails();
  const { setDrawerState } = usePriceDrawerStore();

  if (!selectedRoomId) return null;

  const basePrice = pricingSettings?.default?.[selectedRoomId]?.avg_price ?? 0;

  const decayStep = surgeEvent?.configurations?.decay_step?.[selectedRoomId] ?? 0;
  const initialFixPrice = surgeEvent?.configurations?.fix_price?.[selectedRoomId] ?? 0;
  const decaySpeed = Math.round((initialFixPrice - basePrice) / decayStep);

  const handleGoToPriceSettings = () => {
    setDrawerState(false);
    onClose();
  };

  return (
    <Modal
      centered
      size="xl"
      opened={opened}
      onClose={onClose}
      title={
        <Stack gap={2}>
          {t('Protection Price Decay over {{durationInDays}} days', {
            durationInDays: decaySpeed
          })}
          <Text size="xs">{t('Decay will finish early if Recommended Price is reached')}</Text>
        </Stack>
      }
    >
      <Group wrap="nowrap" align="flex-start">
        <SurgeProtectionDecayChart
          initialFixPrice={initialFixPrice}
          durationInDays={decaySpeed}
          decayStep={decayStep}
          surgeEventDate={surgeEvent?.date}
          surgeEventTimestamp={surgeEvent?.timestamp}
          isSurgeEventContext
        />
      </Group>
      <Flex justify="flex-end">
        <Button
          mt="xs"
          variant="subtle"
          rightSection={<Icon.ArrowLongRight />}
          component={Link}
          onClick={handleGoToPriceSettings}
          to={`/client/${hotelDetails?.id}/surge-event/price-settings`}
        >
          {t('Go to Protection Price Settings')}
        </Button>
      </Flex>
    </Modal>
  );
};
