import { API_DATE_FORMAT } from '@common/constants/date';
import { Box, Tooltip, Text, Flex, Group } from '@mantine/core';

import dayjs from 'dayjs';
import '@mantine/dates/styles.css';
import { useTranslation } from 'react-i18next';

interface DayProps {
  day: Date;
  availableRooms: number;
}

export const Day = ({ day, availableRooms }: DayProps) => {
  const { t } = useTranslation();

  return (
    <Tooltip label={availableRooms + t(' rooms available')} withArrow>
      <Flex align="center" justify="center" w="100%" h="100%" component="span">
        {day.getDate()}
      </Flex>
    </Tooltip>
  );
};
