import {
  overridePriceMemberShipPricing,
  removeOverridePriceMemberShipPricing
} from '@common/api/hotel';
import { OverridePricePayload } from '@common/api/hotel/types';
import { useNotificationsStore } from '@common/store/notifications';
import { MembershipQueryKeys } from '@common/types/query-keys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export const useOverridePriceSubscription = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (data: OverridePricePayload) => {
      return overridePriceMemberShipPricing(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [MembershipQueryKeys.MEMBERSHIP_PRICING] });
      addNotification('success', 'Updated price successfully');
    },
    onError: (error: AxiosError) => {
      const responseData = (error.response?.data as { price?: string[] }) || {};
      responseData.price = responseData.price || [];
      if (responseData.price.length > 0) {
        addNotification('fail', responseData.price.join(', '));
      } else {
        addNotification('fail', 'Failed to update price');
      }
    }
  });

  return {
    overridePrice: mutate,
    isLoading
  };
};

export const useRemoveOverridePriceSubscription = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();

  const {
    mutate,
    isPending: isLoading,
    isSuccess: isSuccessRemoveOverride
  } = useMutation({
    mutationFn: removeOverridePriceMemberShipPricing,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [MembershipQueryKeys.MEMBERSHIP_PRICING] });
      addNotification('success', 'Remove override price successfully');
    },
    onError: () => {
      addNotification('fail', 'Failed to remove override price');
    }
  });

  return {
    removeOverridePrice: mutate,
    isLoading,
    isSuccessRemoveOverride
  };
};
