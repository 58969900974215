import {
  ParamsRatePlan,
  ParamsRatePlanSchema,
  UpdateRatePlans
} from '@common/api/integration/ratePlansSchema';

import { Icon } from '@common/components/foundations/icons';
import { Table } from '@common/components/molecules/Table';
import { pmsHasRoomTypesColumn, pmsHasUpdateRate } from '@common/constants/pmsList';
import { useModal } from '@common/hooks/useModal';
import { useUserRole } from '@common/hooks/useUserRole';
import { useViewStore } from '@common/store/view';
import { filterEmptyValues } from '@common/utils/filterEmptyValues';
import { zodResolver } from '@hookform/resolvers/zod';
import { ActionIcon, Button, Center, Flex, rem, Text, Tooltip } from '@mantine/core';
import { AddNewRoomRateModal } from '@pages/Client/Account/Integrations/components/room-rates/AddNewRoomRateModal';
import { DeleteRoomRatesModal } from '@pages/Client/Account/Integrations/components/room-rates/DeleteRoomRatesModal';
import { UpdateRoomRateModal } from '@pages/Client/Account/Integrations/components/room-rates/UpdateRoomRateModal';
import UploadRoomModal from '@pages/Client/Account/Integrations/components/UploadRoomModal';
import { usePmsRoomRate } from '@pages/Client/Account/Integrations/hooks/usePmsRoomRate';
import { usePmsRoomType } from '@pages/Client/Account/Integrations/hooks/usePmsRoomType';
import { useCreateRoomRatesStore } from '@pages/Client/Account/Integrations/store/createRoomRate';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { PmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { SortingState, Updater } from '@tanstack/react-table';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Params } from 'react-router-dom';

export const RoomRatesTable = () => {
  const { hotelDetails } = useHotelDetails();
  const { t } = useTranslation();
  const { isCreateLoading } = useCreateRoomRatesStore();
  const { pmsRoomTypeList } = usePmsRoomType({
    ordering: undefined,
    page: 1
  });
  const { isRpgAdmin } = useUserRole();
  const { isAdmin } = useViewStore();

  const { isOpen: addOpen, openModal: addClick, closeModal: addClose } = useModal();
  const { isOpen: editOpen, openModal: editClick, closeModal: editClose } = useModal();
  const { isOpen: deleteOpen, openModal: deleteClick, closeModal: deleteClose } = useModal();
  const { isOpen: uploadOpen, openModal: uploadClick, closeModal: uploadClose } = useModal();

  const [selectedRoomRate, setSelectedRoomRate] = useState<UpdateRatePlans>({
    id: 0,
    name: '',
    rate_id: '',
    description: '',
    checkin: '',
    checkout: '',
    room_type_ids: [],
    other_rate: '',
    extra: ''
  });

  const [sorting, setSorting] = useState([{ id: 'id', desc: false }]);

  const defaultValues = {
    page: 1,
    ordering: undefined
  };

  const { watch, reset } = useForm<ParamsRatePlan>({
    defaultValues,
    resolver: zodResolver(ParamsRatePlanSchema)
  });

  const onResetAndSet = useCallback(
    (field: keyof Params, value: Params[typeof field]) => {
      reset(
        filterEmptyValues({
          ...defaultValues,
          [field]: value
        })
      );
    },
    [reset, filterEmptyValues, defaultValues]
  );

  const { pmsRoomRateList, isLoading } = usePmsRoomRate(
    filterEmptyValues({
      ordering: watch('ordering'),
      page: watch('page')
    })
  );

  const roomApartmentSpaceName = hotelDetails?.room_apartment_space_name;
  const pmsProviderId = hotelDetails?.pms_provider ? hotelDetails?.pms_provider : 0;

  const planNameColumnName = t('Name');

  useEffect(() => {
    if (deleteOpen) {
      editClose();
    }
  }, [deleteOpen]);

  const RoomTypeCell = ({ row }: { row: any }) => {
    const pmsRooms = row?.row?.original?.room_type_ids;
    const existingRoomTypesArray =
      pmsRoomTypeList?.data
        ?.filter((item) => pmsRooms.includes(item.id))
        .map((item) => item.name) || [];

    const existingRoomTypes = existingRoomTypesArray.join(', ');

    // Calculate average word length per room type name
    const totalWords = existingRoomTypesArray.reduce(
      (acc, name) => acc + name.split(' ').length,
      0
    );
    const avgWordsPerName =
      existingRoomTypesArray.length > 0 ? totalWords / existingRoomTypesArray.length : 0;

    // Calculate tooltip width based on room type names
    let tooltipMaw = rem(300);
    if (existingRoomTypesArray.length > 20 && avgWordsPerName >= 3) {
      tooltipMaw = rem(600);
    }
    if (existingRoomTypesArray.length > 30 || avgWordsPerName > 5) {
      tooltipMaw = rem(700);
    }

    return (
      <Tooltip label={existingRoomTypes} maw={tooltipMaw} multiline p="sm" position="bottom">
        <Text maw={rem(400)} size="sm" truncate>
          {existingRoomTypes}
        </Text>
      </Tooltip>
    );
  };

  const commonRoomTypeColumn = (header: string, accessorKey: string) => ({
    header,
    accessorKey,
    cell: (row: any) => <RoomTypeCell row={row} />,
    meta: { showOnMobile: true }
  });

  const deleteColumn = {
    header: 'Actions',
    accessorKey: 'id',
    cell: (row: any) => (
      <Center w="100%">
        <ActionIcon
          onClick={() => {
            setSelectedRoomRate(row.row.original);
            deleteClick();
          }}
        >
          <Icon.Delete className="text-copyTextGrey" />
        </ActionIcon>
      </Center>
    ),
    meta: { showOnMobile: true }
  };

  const siteMinderNewColumn = [
    {
      header: 'Rate Plan',
      accessorKey: 'name',
      cell: (row: any) => <Text size="sm">{(row?.getValue() as string) || '-'}</Text>,
      meta: { showOnMobile: true }
    },
    {
      header: 'Rate Plan Code',
      accessorKey: 'extra',
      cell: (row: any) => <Text size="sm">{(row?.getValue() as string) || '-'}</Text>,
      meta: { showOnMobile: true }
    }
  ];

  const guestLineAdditionalColumns = [
    commonRoomTypeColumn(`${roomApartmentSpaceName} Code`, 'room_type_ids'),
    {
      header: t('Rate Flex Table'),
      accessorKey: 'other_rate',
      cell: (row: any) => <Text size="sm">{(row?.getValue() as string) || '-'}</Text>,
      meta: { showOnMobile: true }
    }
  ];

  const dEdgeAdditionalColumns = [
    commonRoomTypeColumn(`${roomApartmentSpaceName} Types`, 'room_rates')
  ];

  const roomTypesAdditionalColumn = [
    commonRoomTypeColumn(`${roomApartmentSpaceName} Types`, 'room_rate')
  ];

  const columns = [
    {
      header: 'Rate Plan Code',
      accessorKey: 'rate_id',
      cell: (row: any) => <Text size="sm">{row?.getValue() as string}</Text>,
      meta: { showOnMobile: true }
    },
    ...(pmsProviderId === PmsProvider.GUESTLINE ? guestLineAdditionalColumns : []),
    {
      header: `${planNameColumnName}`,
      accessorKey: 'name',
      cell: (row: any) => <Text size="sm">{row?.getValue() as string}</Text>,
      meta: { showOnMobile: true }
    },
    ...(pmsHasRoomTypesColumn.includes(pmsProviderId) ? roomTypesAdditionalColumn : []),
    ...(pmsProviderId === PmsProvider.D_EDGE ? dEdgeAdditionalColumns : []),
    {
      header: 'Description',
      accessorKey: 'description',
      cell: (row: any) => <Text size="sm">{(row?.getValue() as string) || '-'}</Text>,
      meta: { showOnMobile: true }
    },
    ...(pmsProviderId === PmsProvider.SITEMINDER_NEW ? [] : [deleteColumn])
  ];

  const tableColumn = pmsProviderId === PmsProvider.SITEMINDER_NEW ? siteMinderNewColumn : columns;

  const ROWS_PER_PAGE = 10;

  const orderingLookup: Record<string, string> = {
    name: 'name',
    rate_id: 'rate_id',
    other_rate: 'other_rate',
    room_type_ids: 'room_type_ids'
  };

  const handleSortingChange = (updater: Updater<SortingState>) => {
    console.log('updater', updater);
    const newSorting = typeof updater === 'function' ? updater(sorting) : updater;
    const columnIndex = sorting.findIndex((s) => s.id === newSorting[0].id);

    if (columnIndex >= 0) {
      newSorting[0].desc = !sorting[columnIndex].desc;
    }

    const ordering = newSorting[0].desc
      ? `-${orderingLookup[newSorting[0].id]}`
      : orderingLookup[newSorting[0].id];

    onResetAndSet('ordering', ordering);
    setSorting(newSorting);
  };

  return (
    <div className="mt-8 max-w-4xl">
      <Table
        isFetching={isLoading}
        onClickRow={(row: any) => {
          if (pmsHasUpdateRate.includes(pmsProviderId)) {
            setSelectedRoomRate({
              ...row?.row.original,
              room_type_ids: pmsRoomTypeList.data
                ?.filter((item) => row?.row?.original?.room_type_ids?.includes(item.id))
                .map((item) => item.id.toString())
            });
          } else {
            setSelectedRoomRate(row?.row.original);
          }
          editClick();
        }}
        headerComponent={
          <Text size="lg" c="dark">
            {t('Rate Plans')}
          </Text>
        }
        actionComponents={
          ![64].includes(pmsProviderId) ? (
            <Flex gap="xs">
              <Button loading={isCreateLoading} onClick={() => addClick()}>
                {t('Add Rate Plan')}
              </Button>
              {isRpgAdmin && isAdmin ? (
                <ActionIcon variant="filled" aria-label="Settings" onClick={() => uploadClick()}>
                  <Icon.Publish className="h-5 w-5" />
                </ActionIcon>
              ) : null}
            </Flex>
          ) : null
        }
        skeletonCount={10}
        columns={tableColumn}
        data={pmsRoomRateList ? pmsRoomRateList.data : []}
        isNext={!!pmsRoomRateList?.metadata?.next}
        rowsPerPage={ROWS_PER_PAGE}
        manualSorting
        onSortingChange={handleSortingChange}
        sorting={sorting}
        isHover
        isRowClickable
      />
      <AddNewRoomRateModal isOpen={addOpen} onClose={addClose} />
      <UpdateRoomRateModal
        selectedRoomRate={selectedRoomRate}
        isOpen={editOpen}
        onClose={() => {
          editClose();
          setSelectedRoomRate({
            id: 0,
            name: '',
            rate_id: '',
            description: '',
            checkin: '',
            checkout: '',
            room_type_ids: [],
            other_rate: '',
            extra: ''
          });
        }}
      />
      <DeleteRoomRatesModal
        selectedRoomRate={selectedRoomRate}
        isOpen={deleteOpen}
        onClose={deleteClose}
      />
      <UploadRoomModal isOpen={uploadOpen} onClose={uploadClose} />
    </div>
  );
};
