import { Colors } from '@common/components/foundations/colorVariants';
import { useTailwindColor } from '@common/hooks/useTailwindColors';
import clsx from 'clsx';

interface DataIconProps {
  icon: JSX.Element;
  color?: Colors;
  bgColor?: Colors;
  className?: string;
}
export const DataIcon = ({
  icon,
  color = 'white',
  bgColor = 'indigo',
  className
}: DataIconProps) => {
  const indigo = useTailwindColor('indigo');
  const darkGreen = useTailwindColor('darkGreen');
  const uiRed = useTailwindColor('uiRed');
  const darkGrey = useTailwindColor('darkGrey');
  const orange = useTailwindColor('orange');
  const uiGReen = useTailwindColor('uiGreen');

  const getColor = (color: Colors) => {
    if (color === 'darkGreen') {
      return darkGreen;
    }
    if (color === 'indigo') {
      return indigo;
    }
    if (color === 'uiRed') {
      return uiRed;
    }
    if (color === 'darkGrey') {
      return darkGrey;
    }
    if (color === 'orange') {
      return orange;
    }
    if (color === 'uiGreen') {
      return uiGReen;
    }
    return 'white';
  };
  return (
    <span
      className={clsx(
        'inline-flex h-8 w-8 items-center justify-center rounded-full p-2',
        className
      )}
      style={{ color: getColor(color), backgroundColor: getColor(bgColor) }}
    >
      {icon}
    </span>
  );
};
