import React from 'react';
import {
  Table,
  Text,
  Flex,
  Group,
  Tooltip,
  Center,
  useMantineTheme,
  Skeleton
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Icon } from '@common/components/foundations/icons';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import PriceHover from '@pages/Client/PricingStrategy/GroupDisplacement/components/QuotationResult/PriceHover';
import RoomsHover from '@pages/Client/PricingStrategy/GroupDisplacement/components/QuotationResult/RoomsHover';
import { Quotation } from '@common/api/pricingAlgorithm/types';

interface ResultsTableProps {
  data?: Quotation;
  isLoading?: boolean;
}
function getStatusIcon(code: 'OK' | 'WARNING' | 'ERROR') {
  const icon = {
    OK: <Icon.CheckList className="text-darkGreen w-4 h-4" />,
    WARNING: <Icon.WarningOutline className="text-orange w-4 h-4" />,
    ERROR: <Icon.Clear className="text-error w-4 h-4" />
  };
  return icon[code] || null;
}

const ResultsTable: React.FC<ResultsTableProps> = ({ data, isLoading }) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();

  return (
    <Table c="gray.6" borderColor="gray.1" mb="lg">
      <Table.Thead>
        <Table.Tr>
          <Table.Th style={{ verticalAlign: 'top' }}>
            <Text size="xs" c="gray.6" fw={500}>
              {t('Stay Date')}
            </Text>
          </Table.Th>
          <Table.Th style={{ verticalAlign: 'top' }}>
            <Text size="xs" c="gray.6" fw={500}>
              {t('Rooms Required')}
            </Text>
          </Table.Th>
          <Table.Th style={{ verticalAlign: 'top' }}>
            <Text size="xs" c="gray.6" fw={500}>
              {t('Rooms Available')}
            </Text>
          </Table.Th>
          <Table.Th style={{ verticalAlign: 'top' }}>
            <Text size="xs" c="gray.6" fw={500}>
              {t('Standard Price')}
              <br />
              {t('(Per Room)')}
            </Text>
          </Table.Th>
          <Table.Th style={{ verticalAlign: 'top' }}>
            <Flex align="center" justify="flex-end">
              <Text size="xs" c="gray.6" fw={500}>
                {t('Displaced Revenue')}
              </Text>
              <Icon.Spark color={theme.colors.indigo[3]} className="h-4 w-4" />
            </Flex>
          </Table.Th>
          <Table.Th style={{ verticalAlign: 'top' }}>
            <Text size="xs" c="gray.6" fw={500}>
              {t('Recommended Group Price')}
            </Text>
          </Table.Th>
          <Table.Th style={{ verticalAlign: 'top' }}>
            <Text size="xs" c="gray.6" fw={500}>
              {t('Group Profit')}
            </Text>
          </Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {isLoading ? (
          <Table.Tr>
            <Table.Td>
              <Skeleton height={20} />
            </Table.Td>
            <Table.Td>
              <Skeleton height={20} />
            </Table.Td>
            <Table.Td>
              <Skeleton height={20} />
            </Table.Td>
            <Table.Td>
              <Skeleton height={20} />
            </Table.Td>
            <Table.Td>
              <Skeleton height={20} />
            </Table.Td>
            <Table.Td>
              <Skeleton height={20} />
            </Table.Td>
            <Table.Td>
              <Skeleton height={20} />
            </Table.Td>
          </Table.Tr>
        ) : (
          Object.entries(data?.results?.data || {}).map(([date, dayData]) => (
            <Table.Tr key={date}>
              <Table.Td miw="110">
                <Text size="sm" c="gray.6">
                  {date}
                </Text>
              </Table.Td>
              <Table.Td align="center">
                <Text size="sm" c="gray.6">
                  {dayData.group_rooms}
                </Text>
              </Table.Td>
              <Table.Td align="center">
                <Tooltip label={<RoomsHover dayData={dayData} />} position="bottom">
                  <Flex justify="center" gap="xs">
                    <Group gap={0}>
                      <Text size="sm" c="gray.6">
                        {dayData.inventory_remaining}/{dayData.number_of_rooms}
                      </Text>
                    </Group>
                  </Flex>
                </Tooltip>
              </Table.Td>
              <Table.Td align="right" miw="120">
                <Tooltip
                  label={<PriceHover dayData={dayData} baseRateOffset={data?.base_rate_offset} />}
                  position="bottom"
                >
                  <span>
                    <Text size="sm" c="gray.6">
                      {CurrencyFormatter.format(dayData.base_rate * dayData.group_rooms)} (
                      {CurrencyFormatter.format(dayData.base_rate)})
                    </Text>
                  </span>
                </Tooltip>
              </Table.Td>
              <Table.Td align="right">
                <Text size="sm" c="gray.6">
                  {CurrencyFormatter.format(dayData.displaced_revenue)}
                </Text>
              </Table.Td>
              <Table.Td align="right">
                <Text size="sm" c="gray.6">
                  {CurrencyFormatter.format(dayData.recommended_group_price)}
                </Text>
              </Table.Td>
              <Table.Td align="right" miw="100">
                <Text size="sm" fw="bold" c={dayData.group_profit > 0 ? 'green.8' : 'red.5'}>
                  {CurrencyFormatter.format(dayData.group_profit)}
                </Text>
              </Table.Td>
              <Table.Td>
                {dayData.status.code === 'OK' ? (
                  <Center>{getStatusIcon(dayData.status.code)}</Center>
                ) : (
                  <Tooltip label={dayData.status.message} position="left-end">
                    <Center>{getStatusIcon(dayData.status.code)}</Center>
                  </Tooltip>
                )}
              </Table.Td>
            </Table.Tr>
          ))
        )}
      </Table.Tbody>
    </Table>
  );
};

export default ResultsTable;
