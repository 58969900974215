import { Button } from '@common/components/atoms/Button';
import { Input } from '@common/components/atoms/Input';
import { Typography } from '@common/components/foundations/Typography';
import { Header } from '@common/components/molecules/Header/Header';
import { Page } from '@common/components/organisms/Page';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { useUpdatePricingSettings } from '@pages/Client/hooks/useUpdatePricingSettings';
import { useDocumentTitle } from '@mantine/hooks';
import { Divider } from '@mantine/core';

const isWithinRange = (val: number | undefined) => val === undefined || (val >= -50 && val <= 50);
const validationMessage = 'Please enter a value between -50% and 50%.';
const validationEmptyMessage = 'Please enter a value';

const adjustmentErrorMap: z.ZodErrorMap = (error) => {
  switch (error.code) {
    case 'invalid_type':
      return { message: validationEmptyMessage };

    default:
      return { message: validationEmptyMessage };
  }
};

const schema = z.object({
  four_days_to_seven_days: z
    .number({ errorMap: adjustmentErrorMap })
    .optional()
    .refine(isWithinRange, {
      message: validationMessage
    }),
  four_weeks_to_six_weeks: z
    .number({ errorMap: adjustmentErrorMap })
    .optional()
    .refine(isWithinRange, {
      message: validationMessage
    }),
  last_day: z.number({ errorMap: adjustmentErrorMap }).optional().refine(isWithinRange, {
    message: validationMessage
  }),
  one_half_months_to_three_months: z
    .number({ errorMap: adjustmentErrorMap })
    .optional()
    .refine(isWithinRange, {
      message: validationMessage
    }),
  one_week_to_two_weeks: z
    .number({ errorMap: adjustmentErrorMap })
    .optional()
    .refine(isWithinRange, {
      message: validationMessage
    }),
  two_days_to_three_days: z
    .number({ errorMap: adjustmentErrorMap })
    .optional()
    .refine(isWithinRange, {
      message: validationMessage
    }),
  two_weeks_to_four_weeks: z
    .number({ errorMap: adjustmentErrorMap })
    .optional()
    .refine(isWithinRange, {
      message: validationMessage
    }),
  three_months_to_six_months: z
    .number({ errorMap: adjustmentErrorMap })
    .optional()
    .refine(isWithinRange, {
      message: validationMessage
    }),
  six_months_plus: z.number({ errorMap: adjustmentErrorMap }).optional().refine(isWithinRange, {
    message: validationMessage
  })
});

type dataKey =
  | 'four_days_to_seven_days'
  | 'four_weeks_to_six_weeks'
  | 'last_day'
  | 'one_half_months_to_three_months'
  | 'one_week_to_two_weeks'
  | 'two_days_to_three_days'
  | 'two_weeks_to_four_weeks'
  | 'three_months_to_six_months'
  | 'six_months_plus';

export const LeadTime = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Lead Time Adjustments'));

  const { pricingSettingsQuery } = useRoomPrices();
  const { data: pricingSettings } = pricingSettingsQuery;
  const {
    isLoading: isSavePricingLoading,
    savePricingSettings,
    isSuccess: isSaveSuccess
  } = useUpdatePricingSettings();
  const [isSuccessRef, setIsSuccessRef] = useState<{ current: boolean }>({ current: false });

  const { control, setValue, handleSubmit } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  const valuesData: Record<string, number | null> = pricingSettings
    ? pricingSettings?.adjustment.lead_time
    : {};

  useEffect(() => {
    if (pricingSettings) {
      Object.entries(valuesData).forEach(([key, value]) => {
        setValue(key as dataKey, value as number);
      });
    }
  }, [pricingSettings?.adjustment.lead_time, setValue]);

  const leadTimeLabels = {
    last_day: t(`Last day`),
    two_days_to_three_days: `${t('2-3 days')}`,
    four_days_to_seven_days: `${t('4-7 days')}`,
    one_week_to_two_weeks: `${t('1-2 weeks')}`,
    two_weeks_to_four_weeks: `${t('2-4 weeks')}`,
    four_weeks_to_six_weeks: `${t('4-6 weeks')}`,
    one_half_months_to_three_months: `${t('1.5-3 Months')}`,
    three_months_to_six_months: `${t('3 Months')} +`,
    six_months_plus: `${t('6 Months')} +`
  };

  const onSubmit = async (data: z.infer<typeof schema>) => {
    const { data: latestPricingSettings } = await pricingSettingsQuery.refetch();
    const settings = JSON.stringify({
      ...latestPricingSettings,
      adjustment: {
        weekday: latestPricingSettings?.adjustment.weekday,
        lead_time: data,
        monthly: latestPricingSettings?.adjustment.monthly
      }
    });
    await savePricingSettings(settings);
  };

  useEffect(() => {
    setIsSuccessRef({ current: isSaveSuccess });
    if (isSaveSuccess) {
      setTimeout(() => {
        setIsSuccessRef({ current: false });
      }, 2000);
    }
  }, [isSaveSuccess]);

  return (
    <Page
      header={
        <Header
          title={`${t('Lead Time Adjustments')}`}
          description={`${t(
            'These are rolling adjustments that are determined by lead time to check-in. For example, you may want to reduce prices additionally 2-4 weeks before guest arrival to get more bookings in that window. These settings directly influence the RoomPriceGenie price suggestions.'
          )}`}
          actions={
            <Button
              disabled={isSavePricingLoading}
              isSuccess={isSuccessRef.current}
              isLoading={isSavePricingLoading}
              intent="primary"
              size="large"
              onClick={handleSubmit(onSubmit)}
            >
              {t('Save')}
            </Button>
          }
        />
      }
    >
      <Divider />
      <div className="my-4 grid grid-cols-1 items-center gap-4 md:grid-cols-12 md:gap-10">
        <div className="col-span-1 flex flex-col gap-4">
          <Typography variant="paragraph-2" element="p" className="font-light" color="darkGrey">
            {t('Adjust')}
            <br />
            {t('Price by')}
          </Typography>
        </div>
        <div className="col-span-11">
          <div className="grid grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-9">
            {Object.entries(leadTimeLabels)
              .reverse()
              .map(([key, label]) => (
                <div key={key} className="w-full">
                  <Controller
                    name={key.toString() as dataKey}
                    control={control}
                    render={({ field: { onChange, name, value }, fieldState: { error } }) => (
                      <Input
                        name={name}
                        type="number"
                        value={value as number}
                        label={label}
                        placeholder={label}
                        onChange={(e) => {
                          const parsedValue = parseInt(e.target.value);
                          if (isNaN(parsedValue)) {
                            onChange(null);
                          } else {
                            onChange(parsedValue);
                          }
                        }}
                        showClearButton={false}
                        error={!!error}
                        trailingAddon={<>%</>}
                        helperMessage={
                          error && (
                            <div className="flex items-center gap-2 text-error">
                              <Typography element="span" color="error" variant="meta-2">
                                {error.message}
                              </Typography>
                            </div>
                          )
                        }
                      />
                    )}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
      <Divider />
    </Page>
  );
};
