import { QuotationRequest } from '@common/api/pricingAlgorithm/types';
import { API_DATE_FORMAT } from '@common/constants/date';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  Input,
  InputLabel,
  NumberInput,
  Paper,
  rem,
  Stack,
  Text,
  TextInput,
  Tooltip,
  useMantineTheme
} from '@mantine/core';

import { ModalFooter } from '@common/mantine/components/modal-footer';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { DatePicker } from '@mantine/dates';
import '@mantine/dates/styles.css';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { Day } from '@pages/Client/PricingStrategy/GroupDisplacement/components/CalculateQuotation/Day';
import {
  QuotationInputForm,
  QuotationInputSchema
} from '@pages/Client/PricingStrategy/GroupDisplacement/schema/quotation';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash-es';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mantine/hooks';
import { Icon } from '@common/components/foundations/icons';
import { useEventTracking } from '@common/hooks/useEventTracking';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';

interface CalculateQuotationViewProps {
  initialData: QuotationRequest | null;
  onSubmitData: (data: QuotationRequest) => void;
}

const CalculateQuotationView = ({ onSubmitData, initialData }: CalculateQuotationViewProps) => {
  const { t } = useTranslation();
  const { roomPrices } = useRoomPrices();
  const { hotelDetails } = useHotelDetails();
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints?.md})`);
  const { trackEvent } = useEventTracking();
  const { pricingSettings } = usePricingSettings();

  const selectedRoom = pricingSettings?.rooms?.reference?.id;
  const refRoomPrice = selectedRoom
    ? pricingSettings?.default?.[selectedRoom]?.avg_price
    : undefined;

  const initialState = {
    group_name: '',
    group_rooms: [{ value: 1 }],
    date_range: {
      start_date: '',
      end_date: ''
    },
    ancillary_per_group: 0,
    ancillary_per_room: 0,
    variable_cost_per_room: 0,
    derivation_from_ref_room: 0,
    has_custom_number_of_rooms: false,
    number_of_rooms_input: 0,
    length_of_stay: 0,
    run_forecast: false
  };

  const oneYearFromNow = dayjs().add(1, 'year').format(API_DATE_FORMAT);

  const lastPriceDate = Object.keys(roomPrices?.prices?.data || {}).find((date) => {
    const dayData = roomPrices?.prices?.data?.[dayjs(date).add(1, 'day').format(API_DATE_FORMAT)];
    return dayData?.reason === 'No availability found' && dayData?.error;
  });

  const finalLastPriceDate = lastPriceDate
    ? dayjs(lastPriceDate).isAfter(oneYearFromNow)
      ? oneYearFromNow
      : lastPriceDate
    : oneYearFromNow;

  const hotelCapacity = Math.max(
    ...Object.values(roomPrices?.prices?.data || {}).map((day: any) => {
      const numberOfRooms = day?.property?.number_of_rooms || 0;
      return Array.isArray(numberOfRooms) ? Math.max(...numberOfRooms) : numberOfRooms;
    })
  );

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors }
  } = useForm<QuotationInputForm>({
    resolver: zodResolver(QuotationInputSchema(hotelCapacity, refRoomPrice ?? 0)),
    defaultValues: {
      group_name: initialData?.group_name || '',
      group_rooms: initialData?.group_rooms.map((value: any) => ({
        value: value || 0
      })) || [{ value: 0 }],
      date_range: {
        start_date: initialData?.start_date,
        end_date: initialData?.start_date
          ? dayjs(initialData.start_date)
              .add(initialData.length_of_stay, 'day')
              .format(API_DATE_FORMAT)
          : ''
      },
      base_rate_offset: initialData?.base_rate_offset || 0,
      has_custom_number_of_rooms: initialData?.has_custom_number_of_rooms || false,
      length_of_stay: initialData?.length_of_stay || 0,
      number_of_rooms_input: initialData?.group_rooms?.[0] || undefined
    }
  });

  const { fields } = useFieldArray({
    name: 'group_rooms',
    control
  });

  const dateRange = watch('date_range');
  const hasCustomNrOfRooms = watch('has_custom_number_of_rooms');
  const lengthOfStay = watch('length_of_stay') || 0;
  const nrOfRoomsInput = watch('number_of_rooms_input') || 0;

  const onSubmit = (data: QuotationInputForm) => {
    const apiPayload = {
      ...data,
      start_date: data.date_range.start_date,
      group_rooms: hasCustomNrOfRooms
        ? data.group_rooms.map((room) => room.value)
        : Array.from({ length: data.length_of_stay || 1 }, () => nrOfRoomsInput ?? 1),
      base_rate_offset: data.base_rate_offset,
      has_custom_number_of_rooms: hasCustomNrOfRooms,
      length_of_stay: data.length_of_stay || 0
    };
    reset(initialState);
    onSubmitData(apiPayload);
    trackEvent('QuoteCalculated');
  };

  const handleDateRangeChange = (startDate: string, endDate: string) => {
    const days = dayjs(endDate).diff(dayjs(startDate), 'day');
    setValue('length_of_stay', days);
    setValue(
      'group_rooms',
      Array.from({ length: days }, (_, index) => {
        const date = dayjs(startDate).add(index, 'day').format(API_DATE_FORMAT);
        return {
          date,
          value: nrOfRoomsInput || 1
        };
      })
    );
  };

  return (
    <Box p="0">
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <Stack gap="sm" px="lg" pb="lg">
          <Controller
            name="group_name"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                withAsterisk
                label={t('Group Name')}
                placeholder="Enter group name"
                error={errors.group_name?.message}
                variant="filled"
              />
            )}
          />
          <Flex gap={'sm'} direction={isMobile ? 'column' : 'row'}>
            <Controller
              name="number_of_rooms_input"
              control={control}
              render={({ field }) => (
                <NumberInput
                  min={0}
                  {...field}
                  withAsterisk
                  label={t('Number of Rooms')}
                  placeholder={String(t('Enter number of rooms'))}
                  hideControls
                  style={{ flex: 1 }}
                  error={errors.number_of_rooms_input?.message}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  variant="filled"
                  allowDecimal={false}
                />
              )}
            />
            <Controller
              name="base_rate_offset"
              control={control}
              render={({ field }) => (
                <NumberInput
                  {...field}
                  leftSection={<Text size="sm">{CurrencyFormatter.currencySymbol()}</Text>}
                  rightSection={
                    <Tooltip
                      multiline
                      w={rem(350)}
                      label={
                        <Text size="xs" p="xs" c="white">
                          {t(
                            'By default, we price the base room at the base rate. If you’d like to add extras, such as breakfast, you can use the Base Rate Offset. The amount entered (positive or negative) will be applied to each room night. For example, if you enter 20 EUR and book 10 rooms for 10 nights, an additional 20 * 10 * 10 = 2,000 EUR will be added to the minimum group price.'
                          )}
                        </Text>
                      }
                      withArrow
                    >
                      <button>
                        <Icon.Help className="h-5 w-5 fill-grey" />
                      </button>
                    </Tooltip>
                  }
                  label={t('Base Rate Offset')}
                  placeholder="Enter Base Rate Offset"
                  hideControls
                  style={{ flex: 1 }}
                  error={errors.base_rate_offset?.message}
                  variant="filled"
                  allowDecimal={false}
                />
              )}
            />
          </Flex>

          <Stack gap={1} align="center">
            <InputLabel style={{ alignSelf: 'flex-start' }}>
              Duration of Stay
              <span style={{ color: 'red', marginLeft: '0.375rem' }}>*</span>
            </InputLabel>
            <Box style={{ border: '1px solid lightGrey', borderRadius: 10 }} w="100%" p="xs">
              <Flex w="100%" style={{ justifyContent: 'center' }}>
                <Controller
                  name="date_range"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      minDate={new Date()}
                      maxDate={finalLastPriceDate ? new Date(finalLastPriceDate) : undefined}
                      firstDayOfWeek={hotelDetails?.starts_monday ? 1 : 0}
                      type="range"
                      value={[
                        field.value.start_date ? new Date(field.value.start_date) : null,
                        field.value.end_date ? new Date(field.value.end_date) : null
                      ]}
                      onChange={(range) => {
                        const startDate = range[0] ? dayjs(range[0]).format('YYYY-MM-DD') : '';
                        const endDate = range[1] ? dayjs(range[1]).format('YYYY-MM-DD') : '';
                        field.onChange({
                          start_date: startDate,
                          end_date: endDate
                        });
                        handleDateRangeChange(startDate, endDate);
                        trackEvent('QuoteDateRangeChanged');
                      }}
                      getDayProps={(date) => {
                        const formattedDay = dayjs(date).format(API_DATE_FORMAT);
                        const availableRooms =
                          roomPrices?.prices?.data?.[formattedDay]?.property?.inventory_remaining ||
                          0;

                        const requestedRooms = nrOfRoomsInput;

                        const isAvailable = availableRooms >= requestedRooms;
                        const lowAvailability = availableRooms <= requestedRooms + 2 && isAvailable;

                        return {
                          bg: requestedRooms
                            ? isAvailable
                              ? lowAvailability
                                ? 'yellow.1'
                                : 'green.1'
                              : 'red.1'
                            : undefined
                        };
                      }}
                      numberOfColumns={isMobile ? 1 : 2}
                      maxLevel="month"
                      renderDay={(day) => {
                        const formattedDay = dayjs(day).format(API_DATE_FORMAT);
                        const availableRooms =
                          roomPrices?.prices?.data?.[formattedDay]?.property?.inventory_remaining ||
                          0;

                        return <Day day={day} availableRooms={availableRooms} />;
                      }}
                      c="gray.8"
                    />
                  )}
                />
              </Flex>

              {errors.date_range?.start_date?.message && (
                <Input.Error style={{ alignSelf: 'flex-start' }}>
                  {errors.date_range.start_date.message}
                </Input.Error>
              )}
              {errors.date_range?.end_date?.message && (
                <Input.Error style={{ alignSelf: 'flex-start' }}>
                  {errors.date_range.end_date.message}
                </Input.Error>
              )}
              {lengthOfStay >= 1 && (
                <Text size="sm" pt="md" style={{ textAlign: 'center' }} c="violet">
                  {lengthOfStay + '-' + t('night stay ')}
                </Text>
              )}
            </Box>
          </Stack>
          <Controller
            name="has_custom_number_of_rooms"
            control={control}
            render={({ field }) => (
              <Checkbox
                label={t('Customise Number of Rooms by Date')}
                checked={field.value}
                disabled={!nrOfRoomsInput}
                onChange={(event) => {
                  field.onChange(event.currentTarget.checked);
                }}
              />
            )}
          />
          {hasCustomNrOfRooms && (
            <Grid columns={isMobile ? 4 : 7} gutter="xs">
              {fields.map((field, index) => {
                const startDate = dateRange.start_date;
                const dateLabel = startDate
                  ? dayjs(startDate as string)
                      .add(index, 'day')
                      .format('YYYY-MM-DD')
                  : `Night ${index + 1}`;
                return (
                  <Grid.Col span={1} key={field.id}>
                    <Box>
                      <Controller
                        name={`group_rooms.${index}.value`}
                        control={control}
                        render={({ field }) => (
                          <NumberInput
                            label={
                              <Text size="sm" style={{ textAlign: 'center' }}>
                                {dateLabel}
                              </Text>
                            }
                            hideControls
                            value={field.value || nrOfRoomsInput || 1}
                            error={errors.group_rooms?.[index]?.value?.message}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />
                    </Box>
                  </Grid.Col>
                );
              })}
            </Grid>
          )}
        </Stack>
        <ModalFooter>
          <Button type="button" variant="transparent" onClick={() => reset(initialState)}>
            Reset
          </Button>
          <Button type="submit" disabled={!isEmpty(errors) || nrOfRoomsInput > hotelCapacity}>
            Calculate
          </Button>
        </ModalFooter>
      </form>
    </Box>
  );
};

export default CalculateQuotationView;
