import { useAuthStore } from '@common/store/auth';
import { useEffect, useState } from 'react';

type UseRoleReturn = {
  isStaff: boolean;
  isPartner: boolean;
  isRpgAdmin: boolean;
};

const TYPE_USERS_ROLE = {
  RPG_ADMIN: null,
  RPG_PARTNER: 2,
  RPG_SUPPORT: 3,
  RPG_SALES: 1
};

export const useUserRole = (): UseRoleReturn => {
  const { user } = useAuthStore();

  const [isStaff, setIsStaff] = useState(false);
  const [isPartner, setIsPartner] = useState(false);
  const [isRpgAdmin, setIsRpgAdmin] = useState(false);

  useEffect(() => {
    if (user) {
      setIsStaff(!!user.is_staff);
      setIsPartner(user.sub_account_type === TYPE_USERS_ROLE.RPG_PARTNER);
      setIsRpgAdmin(
        user.is_staff &&
          (user?.sub_account_type === TYPE_USERS_ROLE.RPG_SALES ||
            user.sub_account_type === TYPE_USERS_ROLE.RPG_SUPPORT ||
            user.sub_account_type === TYPE_USERS_ROLE.RPG_ADMIN)
      );
    } else {
      setIsStaff(false);
      setIsPartner(false);
      setIsRpgAdmin(false);
    }
  }, [user]);

  return {
    isStaff,
    isPartner,
    isRpgAdmin
  };
};
