import { createRoomTypesSchema } from '@common/api/integration/roomTypesSchema';
import { ModalFooter } from '@common/mantine/components/modal-footer';
import { useHotelStore } from '@common/store/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Modal, NumberInput, SimpleGrid, Stack, TextInput } from '@mantine/core';
import { useCreatePmsRoomType } from '@pages/Client/Account/Integrations/hooks/usePmsRoomType';
import { useCreateRoomTypeStore } from '@pages/Client/Account/Integrations/store/createRoomType';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { PmsProvider, usePmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onClick?: () => void;
};

export const AddNewRoomTypeModal = ({ isOpen, onClose }: ModalProps) => {
  const { t } = useTranslation();
  const { hotelDetails } = useHotelDetails();
  const { hotelAuthToken } = useHotelStore();
  const { setCreateLoading } = useCreateRoomTypeStore();
  const { hideMinMaxOccupancyOnRoomTypeTable } = usePmsProvider();
  const { createRoomType, isLoading } = useCreatePmsRoomType();
  const roomApartmentSpaceName = hotelDetails?.room_apartment_space_name;
  const pmsProviderId = hotelDetails?.pms_provider ? hotelDetails?.pms_provider : 0;
  const schema = createRoomTypesSchema(pmsProviderId);
  const asteriskSymbol = pmsProviderId === PmsProvider.CASABLANCA;

  const roomName = t(`${roomApartmentSpaceName} Type Code`);

  const { handleSubmit, control, reset } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  useEffect(() => {
    if (isLoading) {
      setCreateLoading(true);
    } else {
      setCreateLoading(false);
    }
  }, [isLoading]);

  const onSubmit = async (data: any) => {
    const payload = {
      ...data,
      token: hotelAuthToken
    };
    await createRoomType(payload);
    reset();
    onClose();
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  return (
    <Modal
      opened={isOpen}
      onClose={handleClose}
      title={t(`Add ${hotelDetails?.room_apartment_space_name} Type`)}
      size="auto"
      centered
      styles={{
        body: {
          padding: '0'
        }
      }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack px="md" pb="lg" gap="md">
          <SimpleGrid cols={2}>
            <Controller
              name="room_id"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextInput
                  placeholder={t(`Please enter ${roomName}`) as string}
                  error={error?.message}
                  variant="filled"
                  label={t(`${roomName}`)}
                  value={value}
                  onChange={onChange}
                  withAsterisk
                />
              )}
            />
            <Controller
              name="name"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextInput
                  placeholder={`${t('Please enter name')}`}
                  error={error?.message}
                  variant="filled"
                  label={t(`${hotelDetails?.room_apartment_space_name} Type Name`)}
                  value={value}
                  onChange={onChange}
                  withAsterisk
                />
              )}
            />
          </SimpleGrid>
          {hideMinMaxOccupancyOnRoomTypeTable() ? null : (
            <SimpleGrid cols={2}>
              <Controller
                name="min_occupancy"
                control={control}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <NumberInput
                    placeholder={`${t('Please enter min occupancy')}`}
                    error={error?.message}
                    variant="filled"
                    label={t('Min Occupancy')}
                    value={value}
                    onChange={onChange}
                    withAsterisk={asteriskSymbol}
                    hideControls
                  />
                )}
              />
              <Controller
                name="max_occupancy"
                control={control}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <NumberInput
                    placeholder={`${t('Please enter max occupancy')}`}
                    error={error?.message}
                    variant="filled"
                    label={t('Max Occupancy')}
                    value={value}
                    onChange={onChange}
                    withAsterisk={asteriskSymbol}
                    hideControls
                  />
                )}
              />
            </SimpleGrid>
          )}
          {[PmsProvider.GUESTLINE, PmsProvider.PROTEL].includes(pmsProviderId) ? (
            <Controller
              name="default_occupancy"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <NumberInput
                  placeholder={`${t('Please enter default occupancy')}`}
                  error={error?.message}
                  variant="filled"
                  label={t('Default Occupancy')}
                  value={value}
                  onChange={onChange}
                  hideControls
                />
              )}
            />
          ) : null}
        </Stack>
        <ModalFooter>
          <Button onClick={handleClose} variant="subtle">
            {t('Cancel')}
          </Button>
          <Button type="submit" loading={isLoading}>
            {t('Save')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
