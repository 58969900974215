import { RadioGroup } from '@common/components/atoms/RadioGroup';
import { Typography } from '@common/components/foundations/Typography';
import { DatePicker } from '@common/components/molecules/DatePicker';
import { useViewStore } from '@common/store/view';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Divider, Flex, Group, Skeleton } from '@mantine/core';
import { DemoHotelSwitcher } from '@pages/Client/Account/Subscription/components/DemoHotelSwitcher';
import {
  useAddFreeTrial,
  useSetHotelState
} from '@pages/Client/Account/Subscription/hooks/usePayment';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { HotelState } from '@pages/Client/hooks/useSubscription';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

const schema = z.object({
  state: z.number(),
  free_trial_end: z.date().optional()
});

const PARTNER_SUBSCRIBE_OPTIONS = [
  {
    label: 'Subscribed via another property',
    value: HotelState.SUBSCRIBED_PROPERTY
  },
  {
    label: 'Subscribe to Partner',
    value: HotelState.SUBSCRIBED_PARTNER
  },
  {
    label: 'Unsubscribe ',
    value: HotelState.CANCELED_PARTNER
  },
  {
    label: 'Free Trial',
    value: HotelState.FREE_TRIAL
  }
];

export const PartnerSubscription = () => {
  const { t } = useTranslation();
  const {
    hotelDetails: hotel,
    query: { refetch, isLoading }
  } = useHotelDetails();
  const { setHotelState, isLoading: isPartnerStateLoading } = useSetHotelState();
  const { mutate: addFreeTrialTime, isLoading: isAddFreetrialLoading } = useAddFreeTrial(true);
  const [showDatePicker, setShowDatePicker] = useState(hotel?.state === HotelState.FREE_TRIAL);

  const { view } = useViewStore();
  const isAdmin = view === 'admin';
  const isDemoHotel = hotel?.state === HotelState.DEMO;

  const { control, handleSubmit, watch } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      free_trial_end: dayjs(hotel?.free_trial_end).toDate()
    }
  });

  const newState = watch('state');
  const isStateChanged = newState !== hotel?.state;
  const currentFreeTrialEndDate = hotel?.free_trial_end
    ? dayjs(hotel.free_trial_end).toDate()
    : null;

  const freeTrialEnd = watch('free_trial_end');

  const isFreeTrialEndChanged =
    freeTrialEnd && currentFreeTrialEndDate
      ? freeTrialEnd.getTime() !== currentFreeTrialEndDate.getTime()
      : freeTrialEnd !== currentFreeTrialEndDate;

  useEffect(() => {
    if (hotel && !isLoading) {
      setShowDatePicker(hotel.state === HotelState.FREE_TRIAL);
    }
  }, [hotel, isLoading]);

  const onSubmit = async (data: z.infer<typeof schema>) => {
    const { state, free_trial_end } = data;

    if (isStateChanged) {
      await setHotelState({ state });
    }

    if (state === HotelState.FREE_TRIAL && isFreeTrialEndChanged) {
      await addFreeTrialTime({
        free_trial_end: dayjs(free_trial_end).tz().format('YYYY-MM-DD')
      });
    }

    if (isStateChanged || isFreeTrialEndChanged) {
      refetch();
    }
  };

  return (
    <div className={'my-2 rounded-sm border bg-white pb-1'}>
      <div className="p-6 py-10">
        {hotel?.state === HotelState.SUBSCRIBED_PROPERTY ||
        hotel?.state === HotelState.SUBSCRIBED_PARTNER ? (
          <div className="mb-4 flex flex-col gap-4">
            <Typography element="h5" variant="h5" color="darkGrey" className="text-h1-bold">
              {t('You are subscribed.')}
            </Typography>
            <Typography>
              {t('If you have any questions, please contact your representative ') + ' '}
              {hotel?.reseller ? hotel.reseller.name : ''}.
            </Typography>
          </div>
        ) : null}

        {hotel?.is_locked &&
        hotel?.state !== HotelState.SUBSCRIBED_PROPERTY &&
        hotel?.state !== HotelState.SUBSCRIBED_PARTNER &&
        hotel?.state !== HotelState.CANCELED_PARTNER &&
        hotel?.state !== HotelState.DEMO ? (
          <div className="mb-4 flex flex-col gap-4">
            <Typography element="h5" variant="h5" color="darkGrey" className="text-h1-bold">
              {hotel?.state == HotelState.SUSPENDED || hotel?.state == HotelState.NOT_ACTIVE
                ? 'Oops!'
                : t('All Good Things Come to an End...')}
            </Typography>
            <div className="flex max-w-3xl flex-col gap-3">
              <Typography color="uiRed">
                {hotel?.state == HotelState.SUSPENDED || hotel?.state == HotelState.NOT_ACTIVE
                  ? t(
                      'It seems like there is a problem with your credit card. We tried 3 times but unfortunately it didn`t work. Please update your details if they are incorrect. And please call us if you are having any issues.'
                    )
                  : t(
                      "But this one doesn't have to. Just pop in your credit card details to continue to benefit from our great prices and your increased revenue. And if you choose the monthly payment option, you will have the flexibility to be able to cancel with maximum one month's notice. We're quietly confident that you won't want to though."
                    )}
              </Typography>
              <Typography>
                {hotel?.state == HotelState.SUSPENDED || hotel?.state == HotelState.NOT_ACTIVE
                  ? ' '
                  : t(
                      ' If not, best of luck in the future. And let us know if we can ever be of service'
                    )}
              </Typography>
            </div>
          </div>
        ) : null}
        {hotel?.state === HotelState.CANCELED_PARTNER ? (
          <div className="mb-4 flex flex-col gap-4">
            <Typography element="h5" variant="h5" color="darkGrey" className="text-h1-bold">
              {t('Your Subscription Has Expired')}
            </Typography>
            <Typography color="uiRed">
              {t(
                `We're sorry, but your subscription has expired. Please contact your representative`
              )}
              {hotel?.reseller ? ' at ' + hotel.reseller.name : ''}
              {t(` to renew your subscription.`)}
            </Typography>
          </div>
        ) : null}

        <Flex align="center" justify="space-between" gap="md">
          {isAdmin ? (
            <>
              <Group>
                <DemoHotelSwitcher />
                <Divider ml={'lg'} orientation="vertical" />
                <Skeleton visible={isLoading}>
                  <div>
                    <Controller
                      name="state"
                      control={control}
                      defaultValue={hotel?.state}
                      render={({ field }) => (
                        <RadioGroup
                          optionsWrapperClassName="flex gap-6"
                          defaultSelectedOption={field.value}
                          value={field.value}
                          onChange={(value) => {
                            field.onChange(value);
                            setShowDatePicker(value === HotelState.FREE_TRIAL);
                          }}
                          options={PARTNER_SUBSCRIBE_OPTIONS}
                        />
                      )}
                    />
                  </div>
                </Skeleton>
              </Group>
              <Flex align="center" justify="flex-end" style={{ flexGrow: 1 }}>
                {isDemoHotel ||
                  (showDatePicker ? (
                    <Controller
                      control={control}
                      name="free_trial_end"
                      render={({ field: { value, onChange } }) => (
                        <Flex direction="column">
                          <DatePicker
                            disabledDays={{ before: dayjs().subtract(1, 'day').toDate() }}
                            ISOWeek={hotel?.starts_monday}
                            selectedDate={value || dayjs().add(7, 'day').toDate()}
                            onDateChange={(date) => {
                              onChange(date);
                            }}
                            timezone={hotel?.timezone}
                            closeOnSelect
                          />
                        </Flex>
                      )}
                    />
                  ) : null)}
              </Flex>
              <Button
                size="large"
                disabled={
                  !isStateChanged && !(newState === HotelState.FREE_TRIAL && isFreeTrialEndChanged)
                }
                onClick={handleSubmit(onSubmit)}
                loading={isAddFreetrialLoading || isPartnerStateLoading}
              >
                {t('Save')}
              </Button>
            </>
          ) : null}
        </Flex>
      </div>
    </div>
  );
};
