import { start } from 'repl';
import { number, z } from 'zod';

export const quotationsListSchema = z.object({
  page: z.number(),
  ordering: z.string(),
  status: z.string().optional(),
  search: z.string().optional(),
  name: z.string().optional(),
  length_of_stay: z.number().optional(),
  average_price: z.number().optional(),
  number_of_rooms: z.number().optional(),
  total_quote: z.number().optional(),
  start_date: z.string().optional()
});

export const appliedFiltersSchema = z.object({
  status: z.string().optional(),
  search: z.string().optional(),
  name: z.string().optional(),
  length_of_stay: z.number().optional(),
  average_price: z.number().optional(),
  number_of_rooms: z.number().optional(),
  total_quote: z.number().optional(),
  start_date: z.string().optional()
});

export type QuotationsListSchema = z.infer<typeof quotationsListSchema>;
export type AppliedFiltersSchema = z.infer<typeof appliedFiltersSchema>;

export const quotationOrderingLookup: Record<string, string> = {
  id: 'id',
  name: 'name',
  status: 'status',
  last_upload: 'last_updated_at',
  search: 'search',
  length_of_stay: 'length_of_stay',
  number_of_rooms: 'number_of_rooms',
  average_price: 'average_price',
  total_quote: 'total_quote',
  last_updated_at: 'last_updated_at',
  start_date: 'start_date'
};
