import { Header } from '@common/components/molecules/Header/Header';
import { Page } from '@common/components/organisms/Page';
import { PricingScheduleLogs } from '@pages/Client/Logs/PricingSchedule/components/PricingScheduleLogs';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useEnablePricing } from '@pages/Client/hooks/usePricingEnable';
import { Switch } from '@mantine/core';

export const PricingSchedule = () => {
  const { enablePricing, isLoading: isEnablePricingLoading } = useEnablePricing();
  const {
    query: { isLoading },
    hotelDetails
  } = useHotelDetails();

  const isPricingEnabled = hotelDetails?.is_pricing_turn_on;

  return (
    <Page
      header={
        <Header
          title="Pricing Logs"
          actions={
            <Switch
              label={`${isEnablePricingLoading ? 'Updating...' : 'Pricing'}`}
              disabled={isLoading}
              checked={isPricingEnabled}
              onChange={() => enablePricing(!isPricingEnabled)}
            />
          }
        />
      }
    >
      <div>
        <PricingScheduleLogs />
      </div>
    </Page>
  );
};
