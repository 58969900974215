import {
  editQuotation,
  getDetailQuotation,
  getGroupQuotations,
  groupDisplacementCalculation,
  saveQuotation
} from '@common/api/pricingAlgorithm';
import { QuotationsListPagination } from '@pages/Client/PricingStrategy/GroupDisplacement/types';
import {
  EditQuotationPayload,
  Quotation,
  QuotationRequest
} from '@common/api/pricingAlgorithm/types';
import { useQuotationsListStore } from '@common/store/group-displacement-list-store';
import { useNotificationsStore } from '@common/store/notifications';
import { GroupDisplacementKeys } from '@common/types/query-keys';
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const useCalculateNewQuotation = () => {
  const { addNotification } = useNotificationsStore();
  const {
    mutate,
    isPending: isLoading,
    isError,
    data
  } = useMutation({
    mutationFn: (data: QuotationRequest) => {
      return groupDisplacementCalculation(data);
    },
    onError: (error: any) => {
      if (error.response.data.non_field_errors) {
        addNotification('fail', error.response.data.non_field_errors.join(','));
      }
    }
  });

  return {
    calculateGroupDisplacement: mutate,
    isLoading,
    isError,
    result: data?.data
  };
};

export function useQuotationsList() {
  const { params } = useQuotationsListStore();
  return useQuery({
    queryKey: [GroupDisplacementKeys.GET_GROUP_QUOTATIONS, params],
    queryFn: async () => {
      const response = await getGroupQuotations(params);
      return response as unknown as QuotationsListPagination;
    },
    placeholderData: keepPreviousData
  });
}

export const useSaveQuotation = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();
  const {
    mutate,
    isPending: isLoading,
    isError
  } = useMutation({
    mutationFn: (data: Quotation) => {
      return saveQuotation(data);
    },
    onError: (error: any) => {
      if (error.response.data.status) {
        addNotification('fail', error.response.data.status.join(','));
      } else {
        addNotification('fail', 'Failed to save quotation');
      }
    },
    onSuccess: () => {
      addNotification('success', 'Quotation saved successfully');
      queryClient.invalidateQueries({
        queryKey: [GroupDisplacementKeys.GET_GROUP_QUOTATIONS]
      });
    }
  });

  return {
    saveQuotation: mutate,
    isLoading,
    isError
  };
};

export const useGetDetailResult = (id: number | string | null) => {
  const query = useQuery({
    queryKey: [GroupDisplacementKeys.GET_DETAIL_QUOTATIONS, id],
    queryFn: () => getDetailQuotation(id),
    enabled: !!id
  });

  return {
    query,
    detailResult: query.data?.data,
    isLoading: query.isFetching
  };
};

export const useUpdateQuotation = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (payload: EditQuotationPayload & { id: number }) =>
      editQuotation(payload, payload.id),
    onSuccess: (data) => {
      addNotification('success', 'Quotation updated successfully');
      queryClient.invalidateQueries({
        queryKey: [GroupDisplacementKeys.GET_GROUP_QUOTATIONS]
      });
      queryClient.invalidateQueries({
        queryKey: [GroupDisplacementKeys.GET_DETAIL_QUOTATIONS, data.data?.id]
      });
    },
    onError: () => {
      addNotification('fail', 'Failed to Update Quotation');
    }
  });

  return {
    updateQuotation: mutate,
    isLoading
  };
};
