import { Modal } from '@common/components/molecules/Modal';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { useAuthStore } from '@common/store/auth';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

type DataParamsType = {
  [key: string]: { params: string; title: string }[];
};

export const WalkthroughsModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { user } = useAuthStore();

  const DATA_PARAMS: DataParamsType = {
    en: [
      {
        params: '?appcue=0fe5daa7-5be3-43f0-8990-f59c356d8311',
        title: t('0: How to navigate through our app')
      },
      {
        params: '?appcue=97f4aa0e-f5de-4f04-8653-f7cb18f89e4e',
        title: t('1: Get an overview of your price calendar')
      },
      {
        params: '?appcue=088b5ba3-707c-44be-ac0b-8062b0d42e2a',
        title: t('2: Dive deeper into daily prices & adjust prices')
      },
      {
        params: '?appcue=60a47d34-2348-4f7c-9f75-c3f8f7b42c00',
        title: t('3: Check your booking performance')
      },
      {
        params: '?appcue=ed998f5d-8619-44ac-b7de-dd2b737df783',
        title: t('4: Edit room setup & fine-tune your pricing strategy')
      }
    ],
    de: [
      {
        params: '?appcue=977af74a-dcdb-48b8-a8c7-f76ba11d8697',
        title: t('0: How to navigate through our app')
      },
      {
        params: '?appcue=9c20dd9d-0ed6-4041-b31f-bce251c6f75c',
        title: t('1: Get an overview of your price calendar')
      },
      {
        params: '?appcue=c2dbc177-a88d-47e1-ba5d-30e004dc0708',
        title: t('2: Dive deeper into daily prices & adjust prices')
      },
      {
        params: '?appcue=75255fdc-48da-4f5e-9b06-624713f72b2b',
        title: t('3: Check your booking performance')
      },
      {
        params: '?appcue=74ed9dd5-f4d0-4b1c-949c-29f7edf8195e',
        title: t('4: Edit room setup & fine-tune your pricing strategy')
      }
    ],
    es: [
      {
        params: '?appcue=3b920eb8-dd7b-41db-819b-74a71f005260',
        title: t('0: How to navigate through our app')
      },
      {
        params: '?appcue=d0fd64c0-32b5-4852-94b1-edc7b02093b3',
        title: t('1: Get an overview of your price calendar')
      },
      {
        params: '?appcue=8370c9ac-0443-442a-b5a6-bf228f3bc033',
        title: t('2: Dive deeper into daily prices & adjust prices')
      },
      {
        params: '?appcue=8c15a3ea-6520-4359-a137-6c485a6a777b',
        title: t('3: Check your booking performance')
      },
      {
        params: '?appcue=040627f0-7648-4007-940a-ac4b3ad3ba8c',
        title: t('4: Edit room setup & fine-tune your pricing strategy')
      }
    ],
    fr: [
      {
        params: '?appcue=1a606ef6-adb1-4360-8ecb-042ce2a77eee',
        title: t('0: How to navigate through our app')
      },
      {
        params: '?appcue=26d1c3b3-ec45-40b2-978c-ca6bf5f053e9',
        title: t('1: Get an overview of your price calendar')
      },
      {
        params: '?appcue=75e37a18-9fab-44df-8d5a-e6144c4f9a38',
        title: t('2: Dive deeper into daily prices & adjust prices')
      },
      {
        params: '?appcue=ea7fd7ac-ab25-4f68-a07f-08fa08d275cd',
        title: t('3: Check your booking performance')
      },
      {
        params: '?appcue=672f9b6e-bcb4-4280-a715-5d720f79df32',
        title: t('4: Edit room setup & fine-tune your pricing strategy')
      }
    ]
  };

  const DATA_PARAMS_WALK = DATA_PARAMS[user?.language?.split('-')[0]?.toLowerCase() ?? 'en'];

  return (
    <Modal
      open={isOpen}
      showFooter={false}
      overFlowHidden
      okText="Delete"
      onClose={onClose}
      size={'xl'}
      continueIntent="danger"
      children={
        <>
          <div className="flex  flex-col items-start gap-3">
            <div className="my-3">
              <div className=" rounded-full bg-indigo p-3">
                <Icon.Navigate color="white" />
              </div>
            </div>
            <Typography className="mb-1 font-semibold" element="h6" color="darkGrey" variant="h6">
              {t('In-App Walkthroughs') + ' '}
            </Typography>
            <div className="flex w-full flex-col gap-4">
              {DATA_PARAMS_WALK.map((item, index) => (
                <Link
                  key={index}
                  className="w-full cursor-pointer rounded-md px-2 py-3  hover:bg-indigo-reduced hover:bg-opacity-20"
                  to={`/client/${id}/calendar/pricing` + item.params}
                  target="_blank"
                >
                  {item.title}
                </Link>
              ))}
            </div>
          </div>
        </>
      }
    />
  );
};
