import { Input } from '@common/components/atoms/Input';
import { Controller, useFormContext } from 'react-hook-form';
import { schema } from '@pages/Client/PricingStrategy/RoomSetup/common/formSchema';
import { z } from 'zod';
import { useHotelPmsDataMap } from '@pages/Client/PricingStrategy/RoomSetup/hooks/useHotelPmsDataMap';
import { useEffect } from 'react';
import { InputHelperMessage } from '@common/components/atoms/InputHelperMessage';
import { Icon } from '@common/components/foundations/icons';
import { useTranslation } from 'react-i18next';
import { SimpleGrid } from '@mantine/core';

export const OccupancyPricing: React.FC = () => {
  const { t } = useTranslation();
  const {
    control,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors, isSubmitting }
  } = useFormContext<z.infer<typeof schema>>();
  const { hotelPmsDataMap } = useHotelPmsDataMap();
  const defaultOccupancy = watch('occupancyPricing.defaultOccupancy');

  const minOccupancy = hotelPmsDataMap?.mapped_data.find(
    (item) => +item.room_id === watch('pmsMapping.roomInPms')
  )?.min_occupancy;
  const maxOccupancy = hotelPmsDataMap?.mapped_data.find(
    (item) => +item.room_id === watch('pmsMapping.roomInPms')
  )?.max_occupancy;

  useEffect(() => {
    setValue('occupancyPricing.defaultOccupancy', maxOccupancy);
    setValue('occupancyPricing.minOccupancy', minOccupancy);
    setValue('occupancyPricing.maxOccupancy', maxOccupancy);
  }, [maxOccupancy, minOccupancy]);

  useEffect(() => {
    if (defaultOccupancy === undefined || minOccupancy === undefined || maxOccupancy === undefined)
      return;
    if (defaultOccupancy < minOccupancy || defaultOccupancy > maxOccupancy) {
      setError('occupancyPricing.defaultOccupancy', {
        type: 'manual',
        message: `Default Occupancy must be between ${minOccupancy} and ${maxOccupancy}`
      });
    } else {
      clearErrors('occupancyPricing.defaultOccupancy');
    }
  }, [defaultOccupancy, minOccupancy, maxOccupancy, setError, clearErrors]);

  return (
    <SimpleGrid cols={3}>
      <Controller
        control={control}
        name="occupancyPricing.defaultOccupancy"
        render={({ field: { value, onChange, name }, fieldState: { invalid } }) => (
          <Input
            showClearButton={false}
            disabled={isSubmitting}
            type="number"
            label={`${t('Default Occupancy')}`}
            placeholder={`${t('Default occupancy')}`}
            background="grey"
            name={name}
            value={value}
            onChange={onChange}
            error={invalid}
            helperMessage={
              errors?.occupancyPricing?.defaultOccupancy && (
                <InputHelperMessage
                  message={errors?.occupancyPricing?.defaultOccupancy?.message}
                  icon={<Icon.WarningOutline className="h-4 w-4" />}
                />
              )
            }
          />
        )}
      />
      <Controller
        control={control}
        name="occupancyPricing.minOccupancy"
        render={({ field: { value, onChange, name }, fieldState: { invalid } }) => (
          <Input
            showClearButton={false}
            disabled={isSubmitting}
            type="number"
            label={`${t('Min Occupancy')}`}
            placeholder={`${t('Min occupancy')}`}
            background="grey"
            name={name}
            value={value}
            onChange={onChange}
            helperMessage={
              errors?.occupancyPricing?.minOccupancy && (
                <InputHelperMessage
                  message={errors?.occupancyPricing?.minOccupancy?.message}
                  icon={<Icon.WarningOutline className="h-4 w-4" />}
                />
              )
            }
            error={invalid}
          />
        )}
      />
      <Controller
        control={control}
        name="occupancyPricing.maxOccupancy"
        render={({ field: { value, onChange, name }, fieldState: { invalid } }) => (
          <Input
            showClearButton={false}
            disabled={isSubmitting}
            type="number"
            label={`${t('Max Occupancy')}`}
            placeholder={`${t('Max occupancy')}`}
            background="grey"
            name={name}
            value={value}
            onChange={onChange}
            helperMessage={
              errors?.occupancyPricing?.maxOccupancy && (
                <InputHelperMessage
                  message={errors?.occupancyPricing?.maxOccupancy?.message}
                  icon={<Icon.WarningOutline className="h-4 w-4" />}
                />
              )
            }
            error={invalid}
          />
        )}
      />
    </SimpleGrid>
  );
};
