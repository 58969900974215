import { find, flatMap, toPairs } from 'lodash-es';

const trackedEvents = {
  calendar: {
    NoteAdded: { event: 'note_add', icon: '📝' },
    NoteRemoved: { event: 'note_remove', icon: '🗑️' },
    WeekViewOpenedFromTab: { event: 'week_view_from_tab_open', icon: '🗓️' },
    WeekViewOpenedFromWeekNumber: { event: 'week_view_from_week_number_open', icon: '📅' },
    QuickMonthNavigatorOpened: { event: 'quick_month_navigator_open', icon: '🗓️' },
    AdjustmentSelected: { event: 'adjustment_select', icon: '👀' },
    RoomTypeSelected: { event: 'room_type_select', icon: '🏨' },
    ComparePriceToSelected: { event: 'compare_price_to_select', icon: '⚖️' },
    ComparePickupToSelected: { event: 'compare_pickup_to_select', icon: '🚚' },
    AutoUploadPricesEnabled: { event: 'auto_upload_prices_enable', icon: '📤' }
  },
  account: {
    Login: { event: 'login', icon: '🔑' },
    Logout: { event: 'logout', icon: '🚪' },
    ProfileUpdated: { event: 'profile_update', icon: '👤' }
  },
  pricing: {
    AllRoomTypesOpened: { event: 'all_room_types_open', icon: '🏨' },
    RateEvolutionChartOpened: { event: 'rate_evolution_chart_open', icon: '📈' },
    BulkEditOpened: { event: 'bulk_edit_open', icon: '📝' },
    BulkEditDragAndDrop: { event: 'bulk_edit_drag_and_drop', icon: '❏' },
    BulkEditApplied: { event: 'bulk_edit_apply', icon: '📝' },
    BulkRemoveOpened: { event: 'bulk_remove_open', icon: '␡' },
    BulkRemoveApplied: { event: 'bulk_remove_apply', icon: '␡' }
  },
  minStay: {
    MinStayAutomationApplied: { event: 'min_stay_automation_apply', icon: '📝' },
    MinStayAutomationRemoved: { event: 'min_stay_automation_remove', icon: '🗑️' },
    OrphanGapCorrectionApplied: { event: 'orphan_gap_correction_apply', icon: '📝' },
    MinStayMultipleRates: { event: 'min_stay_multiple_rates', icon: '📝' }
  },
  groupDisplacement: {
    CalculateNewQuotationOpened: { event: 'calculate_new_quotation_open', icon: '🗓️' },
    QuoteCalculated: { event: 'quote_calculated', icon: '🔢' },
    QuoteSaved: { event: 'quote_saved', icon: '📩' },
    QuoteUpdated: { event: 'quotation_updated', icon: '📝' },
    QuoteDateRangeChanged: { event: 'quotation_date_range_changed', icon: '📅' },
    FiltersApplied: { event: 'filters_applied', icon: '🔍' },
    PriceOverwriteApplied: { event: 'price_overwrite_applied', icon: '💰' }
  }
};

// Utility type to extract nested event keys
type ExtractNestedEventKeys<T> = {
  [K in keyof T]: T[K] extends Record<string, any> ? keyof T[K] : never;
}[keyof T];

// Using the utility type to extract event names
export type EventNames = ExtractNestedEventKeys<typeof trackedEvents>;

export const getEventInfo = (eventName: EventNames) => {
  // Adjust the flatMap operation to include channel in the data structure it produces
  const eventEntry = find(
    flatMap(toPairs(trackedEvents), ([channel, channelData]) =>
      toPairs(channelData).map(([key, value]) => ({
        key,
        channel,
        ...value // Spread the event details
      }))
    ),
    { key: eventName }
  );

  if (!eventEntry) {
    throw new Error(`Event ${eventName} not found`);
  }

  return eventEntry;
};
