import {
  QuotationInputSchema,
  EditQuotationResultSchema,
  QuotationStatusOption
} from '@pages/Client/PricingStrategy/GroupDisplacement/schema/quotation';
import { z } from 'zod';

export interface RunPricingFetchInventoryStatus {
  run_pricing: boolean;
  fetch_inventory: boolean;
}

export interface GetInventoryOrRunPriceRequest {
  start_date: string;
  end_date: string;
  get_pms_data?: boolean;
  command: string;
  return_full_dates?: boolean;
  pricing_type?: PricingOptionValue;
}

export interface RunPricingAndFetchInventoryStatusResponse {
  fetch_inventory: boolean;
  run_pricing: boolean;
}

export enum PricingOptionValue {
  ThreeMonths = 3,
  SixMonths = 4,
  OneYear = 5,
  OneHalfYear = 6
}

export interface UploadPriceRequest {
  /** E.g: 2023-04-20 15:00:23 */
  client_time: string;
  client_timezone: string;
  command: 'upload_price_to_pms';
  disable_threshold: boolean; // false
  end_date: string; //'2023-04-21'
  start_date: string; //'2023-04-20',
  room_id?: string;
  specific_date?: string;
}

export interface UpdatePricePmsRequest {
  date: string;
  room?: string;
  price?: string;
}

export interface DownloadPriceRequest {
  command: string;
  room_id: number;
  start_date: string;
  end_date: string;
  format_type: number;
}

export interface CreateAlgoPrograms {
  hotel: number | undefined;
  hour: string | number;
  minute: string | number;
  name: string | number;
  timezone: string | undefined;
  type: string | number;
  token: string | null;
}

export interface PricingHistory {
  date: string;
  name: string;
  pricing_history_id: string;
  token: string;
}
interface SegmentData {
  [key: string]: {
    segment_tags: string[];
    segments_settings_corrections: boolean;
    segments_price_estimate: boolean;
    segments_pickupboost: boolean;
    name: string;
  };
}

export interface ResetPickupboostpriceSegmentationEffect {
  new_segmentation_data: SegmentData;
}

export interface ResetPickupBoostPrice {
  token: string | null | undefined;
  start_date: string;
  end_date: string;
}
export interface ResetPickupBoostPriceAndOccupancy {
  token: string | null | undefined;
  start_date: string;
  end_date: string;
  return_full_dates: boolean;
}

interface TaskDataAlgoProgramExecute {
  id: number;
  task_url: string;
  program_id: number;
  program_name: string;
  date: string;
  task: string;
  status: number;
  created: string;
  is_update_to_pms: boolean;
  update_result: any;
  update_status: any;
  pricing_history_id: string;
  dates_count: number;
  requests_count: number;
  upload_price_requests_count: number;
  total_price_count: number;
  above_threshold_dates_count: number;
  total_above_threshold_price_count: number;
  hotel: number;
}

export interface AlgoProgramExecuteLogsPagination {
  count: number;
  current: number;
  next: string;
  // previous?: any;
  results: TaskDataAlgoProgramExecute[];
}

interface PricingSchedule {
  id: number;
  hotel: number;
  name: string;
  hour: number;
  type: number;
  hotel_info: PricingScheduleHotelInfo;
}

interface PricingScheduleHotelInfo {
  id: number;
  name: string;
  state: number;
  is_client_access_disabled: boolean;
  address: string;
  lat: string;
  lng: string;
}

export interface PricingScheduleResponse {
  count: number;
  current: number;
  next: number | null;
  previous: number | null;
  results: PricingSchedule[];
}

export interface DiagnosticsPayload {
  command: string;
  start_date: string;
  end_date: string;
}

export interface HealthReportResult {
  [key: string]: {
    pickup_model_enabled: boolean;
    hubspot_ticket_id: number | string;
    has_max_price_issue: boolean;
    default_max_price: number;
    threshold: number;
    dates_count: number;
    dates_when_max_price_is_too_low: Record<string, unknown>;
    base_price: number;
    overall_price_health: OverallPriceHealth;
    monthly_low_price_high_occupancy: string[];
    monthly_high_price_low_occupancy: unknown[];
    monthly_health: MonthlyHealth[];
    day_of_the_week_low_price_high_occupancy: string[];
    day_of_the_week_high_price_low_occupancy: unknown[];
    day_of_the_week_health: DayOfWeekHealth[];
    room_types_with_low_occupancy: unknown[];
    room_types_with_high_occupancy: string[];
    derivation_room_type_health: DerivationRoomTypeHealth[];
  };
}

interface OverallPriceHealth {
  label: string;
  too_low_count?: number;
  too_high_count?: number;
}

export interface HealthReportResponse {
  count: number;
  current: number;
  next: string | null;
  previous: string | null;
  results: HealthReportResult;
}

interface DayOfWeekHealth {
  id: number;
  name: string;
  price_health: HealthInfo;
  avg_price: number;
  avg_pickup_boost_price: number;
  occupancy_health: HealthInfo;
  rooms_sold: number;
  expected_rooms_sold: number;
  health: HealthStatus;
  max_price_too_low: PriceDataMax;
}

export interface HealthInfo {
  score: number;
  label: string;
}

export interface HealthStatus {
  code: number;
  label: string;
}

interface PriceDetailsMin {
  min_price: number;
  suggested_price_without_restriction: number;
}

export interface PriceDataMin {
  [date: string]: PriceDetailsMin;
}
interface PriceDetailsMax {
  max_price: number;
  suggested_price_without_restriction: number;
}

export interface PriceDataMax {
  [date: string]: PriceDetailsMax;
}

interface MonthlyHealth {
  year: number;
  month: number;
  price_health: HealthInfo;
  avg_price: number;
  avg_pickup_boost_price: number;
  occupancy_health: HealthInfo;
  rooms_sold: number;
  expected_rooms_sold: number;
  health: HealthStatus;
  max_price_too_low: PriceDataMax;
}

interface DerivationRoomTypeHealth {
  id: string;
  name: string;
  is_reference_room: boolean;
  base_price: number;
  rooms_sold_health: HealthInfo;
  expected_sum_room_sold: number;
  sum_rooms_sold: number;
  number_of_rooms: number;
  room_type_selling_too_few: boolean;
  room_type_selling_too_many: boolean;
}

export interface SurgeLogResponse {
  count: number;
  current: number;
  next: string | null;
  previous: string | null;
  results: SurgeLog[];
}

export interface SurgeLog {
  id: number;
  date: string;
  reservation_count: number;
  timestamp: string;
  reservation_id: number;
  active: boolean;
  expiry_datetime: string;
  configurations: SurgeLogConfigurations;
}

interface SurgeLogConfigurations {
  decay_step: Record<number, number>;
  fix_price: Record<number, number>;
}

export interface DefaultConfigSurge {
  days_ahead: number;
  duration_in_days: number;
  fix_price: number;
  threshold: number;
  user_recipients: number[];
  auto_price_protection?: boolean;
  decay_step: number;
}

export interface DefaultConfigSurgeResponse {
  data: DefaultConfigSurge;
}

export interface SimulateSurgeEventPayload {
  events: SimulateSurgeEventItem[];
}

interface SimulateSurgeEventItem {
  reservation_count: number;
  date: string;
}

// This Type use for group displacement feature
export type QuotationInputForm = z.infer<ReturnType<typeof QuotationInputSchema>>;
export type QuotationResultForm = z.infer<typeof EditQuotationResultSchema>;
export type QuotationInputPayload = Omit<QuotationInputForm, 'number_of_rooms'> & {
  number_of_rooms: number[];
};

interface InventoryItem {
  available: number;
  total: number;
}

interface Inventory {
  [key: string]: InventoryItem;
}

export interface PriceInfo {
  original_price: number;
  base_price: number;
  average_price: number;
  weighted_average_price: number;
  weighted_average_price_with_pubp: number;
  suggested_price: number;
  suggested_price_without_restriction: number;
}

export interface DayDetails {
  group_rooms: number;
  cost_of_group: number;
  displaced_revenue: number;
  recommended_group_price: number;
  base_rate: number;
  occupancy_level: number;
  inventory_remaining: number;
  number_of_rooms: number;
  group_profit: number;
  all_rooms_inventories: Record<string, number>;
  inventory: Inventory;
  price_info: PriceInfo;
  status: {
    code: 'OK' | 'WARNING' | 'ERROR';
    message: string;
  };
}

interface QuotationDetails {
  [date: string]: DayDetails;
}

export interface QuotationRequest {
  group_name: string;
  start_date: string;
  length_of_stay: number;
  group_rooms: (number | null)[];
  base_rate_offset?: number;
  segment_proportions?: number[];
  adjustment_factor?: number;
  run_forecast?: boolean;
  notes?: string;
  has_custom_number_of_rooms?: boolean;
  discount_share_in_percentage?: number;
}

interface Results {
  data: QuotationDetails;
  average_price_per_room_per_night: number;
  recommended_group_price: number;
  total_base_rate: number;
  discount_vs_base_rate: number;
  discount_vs_base_rate_in_percentage: number;
  group_profit: number;
  displaced_revenue: number;
}

export interface Quotation {
  id: number;
  hotel_id: number;
  group_name: string;
  created_at: string; // ISO Date string
  updated_at: string; // ISO Date string
  author: string;
  start_date: string; // ISO Date string
  length_of_stay: number;
  base_rate_offset: number;
  discount_share_in_percentage: number;
  group_rooms: number[];
  min_price: number;
  reference_room_name: string;
  reference_room_id: number;
  excess_profit: number;
  segment_proportions: number[]; // Array of proportions
  adjustment_factor: number;
  median_lead_time: number;
  variable_cost_per_room?: number;
  total_rooms: number;
  results: Results;
  status: QuotationStatusOption | null;
  notes: string;
  total_quote: number;
  average_price: number;
}

export interface EditQuotationPayload {
  notes?: string;
  status?: QuotationStatusOption | null;
  recommended_group_price?: number;
  average_price_per_room_per_night?: number;
  group_profit?: number;
  number_of_rooms?: number;
  length_of_stay?: number;
  total_quote?: number;
  average_price?: number;
}

export interface GroupQuotation {
  id: number;
  author: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
  };
  created_at: string;
  last_updated_at: string;
  name: string;
  number_of_rooms: number;
  start_date: string;
  length_of_stay: number;
  total_quote: number;
  average_price: number;
  status: QuotationStatusOption;
  s3_path: string;
}

export interface CleanPriceCachePayload {
  date: string;
}
