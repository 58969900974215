import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Area,
  ReferenceLine,
  ReferenceArea
} from 'recharts';
import dayjs from 'dayjs';
import { Card } from '@common/components/molecules/Card';
import { Typography } from '@common/components/foundations/Typography';
import { useTailwindColor } from '@common/hooks/useTailwindColors';
import clsx from 'clsx';
import { formattedCurrency } from '@pages/Client/PriceChart/utils/formattedCurrency';
import { groupBy } from 'lodash-es';
import { Icon } from '@common/components/foundations/icons';
import { Feature, useFeaturesStore } from '@common/store/features';
import { useTranslation } from 'react-i18next';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { ScrollArea, ScrollBar } from '@common/components/molecules/ScrollArea';
import { useDeepCompareMemo } from '@common/hooks/useDeepCompareMemo';
import { API_DATE_FORMAT } from '@common/constants/date';
import { usePriceDrawerStore } from '@common/store/priceDrawer';
import { useSurgePrice } from '@pages/Client/Calendar/hooks/useSurgePrice';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { cn } from '@common/utils/cn';
import { useSurgeEvents } from '@pages/Client/PricingStrategy/SurgeProtection/hooks/useSurgeProtection';
import { useDate } from '@common/hooks/useDate';
import { ChartUserflowIds } from '@common/types/userflow-ids';

type DataKey =
  | 'priceRecommendation'
  | 'priceInPms'
  | 'basePrice'
  | 'closedPeriod'
  | 'minPrice'
  | 'maxPrice'
  | 'surgeEvent';

interface Props {
  isLoading: boolean;
  priceRecommendation: { date: string; value: number }[];
  priceInPms: { date: string; value: number }[];
  basePrice: number;
  closedPeriod: { date: string; value: boolean }[];
  occupancy: { date: string; value: string }[];
  isMonthly: boolean;
  currentMonth?: string;
  minPrice: { date: string; value: number }[];
  maxPrice: { date: string; value: number }[];
  surgeEvent: { date: string; value: boolean }[];
}

interface DataPoint {
  startDate: string;
  endDate: string;
}

export const Chart: React.FC<Props> = ({
  isLoading,
  priceRecommendation,
  priceInPms,
  closedPeriod,
  basePrice,
  occupancy,
  isMonthly,
  currentMonth,
  minPrice,
  maxPrice,
  surgeEvent
}) => {
  const { t } = useTranslation();
  const { features } = useFeaturesStore();
  const { hotelDetails } = useHotelDetails();
  const { selectedHotelRoomId } = useHotelRoomsList();
  const { getNowInHotelTimezone } = useDate();
  const limitDate = dayjs().add(365, 'day').startOf('day');
  const { getSurgePriceForDay } = useSurgePrice();
  const { getSurgeEventByDay } = useSurgeEvents();
  const channelManagerOrPms = hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS';
  const indigo = useTailwindColor('indigo');
  const orange = useTailwindColor('orange');
  const uiGreen = useTailwindColor('uiGreen');
  const gold = useTailwindColor('gold');
  const darkGrey = useTailwindColor('darkGrey');
  const grey = useTailwindColor('grey');
  const mediumGrey = useTailwindColor('mediumGrey');
  const fontFamily = "'Inter var', sans-serif";
  const fontSize = '12px';
  const fontWeight = 400;
  const lastLabelDisplayedRef = useRef('');
  const [customLegendPayload, setCustomLegendPayload] = useState<any>([]);

  const baseCustomLegendPayload = [
    {
      dataKey: 'priceRecommendation',
      value: t('Recommended/Fix Price'),
      color: indigo
    },
    {
      dataKey: 'priceInPms',
      value: t(`Price in ${channelManagerOrPms}`),
      color: mediumGrey
    },
    {
      dataKey: 'basePrice',
      value: t('Base Price'),
      basePriceValue: basePrice,
      color: darkGrey
    },
    {
      dataKey: 'closedPeriod',
      value: t('Closed Period'),
      color: gold
    },
    {
      dataKey: 'surgeEvent',
      value: t('Surge Event'),
      color: uiGreen
    }
  ];

  const baseCustomLegendPayloadMinMax = [
    {
      dataKey: ['minPrice', 'maxPrice'],
      value: t(`Max/Min Price`),
      color: orange
    }
  ];

  useEffect(() => {
    if (minPrice.length > 0 && maxPrice.length > 0) {
      setCustomLegendPayload([...baseCustomLegendPayload, ...baseCustomLegendPayloadMinMax]);
    } else {
      setCustomLegendPayload(baseCustomLegendPayload);
    }
  }, [minPrice, maxPrice]);

  const { setDrawerState, setViewingDate } = usePriceDrawerStore();

  const handleOpenPriceDrawer = (date: string) => {
    const formattedDate = new Date(dayjs(date).format(API_DATE_FORMAT));
    setDrawerState(true);
    setViewingDate(formattedDate);
  };

  const [seriesVisibility, setSeriesVisibility] = useState<Record<DataKey, boolean>>({
    priceInPms: true,
    priceRecommendation: true,
    basePrice: true,
    minPrice: true,
    maxPrice: true,
    closedPeriod: true,
    surgeEvent: true
  });

  const data = useDeepCompareMemo(() => {
    const uniqueDatesSet = new Set();
    const closedPeriodMap = new Map(closedPeriod.map((item) => [item.date, item.value]));
    const surgeEventMap = new Map(surgeEvent.map((item) => [item.date, item.value]));
    const priceRecommendationMap = seriesVisibility.priceRecommendation
      ? new Map(priceRecommendation.map((item) => [item.date, item.value]))
      : new Map();
    const priceInPmsMap = seriesVisibility.priceInPms
      ? new Map(priceInPms.map((item) => [item.date, item.value]))
      : new Map();
    const minPriceMap = seriesVisibility.minPrice
      ? new Map(minPrice.map((item) => [item.date, item.value]))
      : new Map();
    const maxPriceMap = seriesVisibility.maxPrice
      ? new Map(maxPrice.map((item) => [item.date, item.value]))
      : new Map();

    const allDatesRaw = [
      ...priceRecommendation,
      ...priceInPms,
      ...closedPeriod,
      ...minPrice,
      ...surgeEvent
    ]
      .map(({ date }) => ({ raw: date, formattedDate: dayjs(date).format('YYYY-MM') }))
      .filter(({ raw }) => {
        if (uniqueDatesSet.has(raw)) return false;
        uniqueDatesSet.add(raw);
        const day = dayjs(raw);
        const now = getNowInHotelTimezone();
        let dateCondition = day.isSameOrAfter(now, 'day');
        if (!features?.includes(Feature.EighteenMonthsPricing)) {
          dateCondition = dateCondition && day.isBefore(limitDate);
        }

        return dateCondition;
      });

    const processData = ({ raw, formattedDate }: { raw: string; formattedDate: string }) => ({
      date: raw,
      priceRecommendation: priceRecommendationMap.get(raw),
      minPrice: minPriceMap.get(raw),
      maxPrice: maxPriceMap.get(raw),
      priceInPms: priceInPmsMap.get(raw),
      basePrice,
      closedPeriod: closedPeriodMap.get(raw),
      surgeEvent: surgeEventMap.get(raw),
      occupancy: formattedDate === raw ? occupancy.find((d) => d.date === raw)?.value : undefined
    });

    if (isMonthly) {
      const groupedByMonth = groupBy(allDatesRaw, 'formattedDate');
      const groupedDates = Object.entries(groupedByMonth).reduce(
        (acc: { [key: string]: any }, [month, dates]) => {
          acc[month] = dates.map(processData);
          return acc;
        },
        {}
      );

      if (currentMonth) return groupedDates[currentMonth];
      // If currentMonth is not provided, return all the dates
      return Object.values(groupedByMonth).flat().map(processData);
    } else {
      return allDatesRaw.map(processData);
    }
  }, [
    currentMonth,
    priceRecommendation,
    priceInPms,
    basePrice,
    closedPeriod,
    occupancy,
    isMonthly,
    features,
    seriesVisibility,
    minPrice,
    maxPrice,
    surgeEvent
  ]);

  const groupConsecutiveClosedPeriods = (
    data: Array<{ date: string; closedPeriod: boolean }>
  ): DataPoint[] => {
    if (!data) return [];
    const closedPeriods: DataPoint[] = [];
    let periodStart: string | null = null;

    data.forEach((entry, index) => {
      if (entry.closedPeriod) {
        if (periodStart === null) periodStart = entry.date;
      } else if (periodStart !== null) {
        closedPeriods.push({ startDate: periodStart, endDate: data[index - 1].date });
        periodStart = null;
      }
    });

    // If the last period continues until the end
    if (periodStart !== null)
      closedPeriods.push({ startDate: periodStart, endDate: data[data.length - 1].date });

    return closedPeriods;
  };

  const groupConsecutiveSurgeEvents = (
    data: Array<{ date: string; surgeEvent: boolean }>
  ): DataPoint[] => {
    if (!data) return [];
    const surgeEvents: DataPoint[] = [];
    let periodStart: string | null = null;

    data.forEach((entry, index) => {
      if (entry.surgeEvent) {
        if (periodStart === null) periodStart = entry.date;
      } else if (periodStart !== null) {
        surgeEvents.push({ startDate: periodStart, endDate: data[index - 1].date });
        periodStart = null;
      }
    });

    // If the last period continues until the end
    if (periodStart !== null)
      surgeEvents.push({ startDate: periodStart, endDate: data[data.length - 1].date });

    return surgeEvents;
  };

  const closedPeriods = useMemo(() => groupConsecutiveClosedPeriods(data), [data]);
  const surgeEvents = useMemo(() => groupConsecutiveSurgeEvents(data), [data]);

  const dataMax = Math.floor(
    Math.max(
      ...(data || []).map(
        (item: { priceRecommendation: number; priceInPms: number }) =>
          Math.max(item.priceRecommendation, item.priceInPms) * 1.1
      )
    )
  ); // 10% padding
  const dataMin = Math.floor(
    Math.min(
      ...(data || []).map(
        (item: { priceRecommendation: number; priceInPms: number }) =>
          Math.min(item.priceRecommendation, item.priceInPms) * 0.9
      )
    )
  ); // 10% padding

  const CustomLegend: React.FC<any> = (props) => {
    const { payload } = props;

    // Add onClick handler to toggle series visibility
    const handleLegendClick = (dataKey: DataKey | DataKey[]) => {
      if (Array.isArray(dataKey)) {
        dataKey.forEach((key) => {
          setSeriesVisibility((prev) => ({ ...prev, [key]: !prev[key] }));
        });
      } else {
        setSeriesVisibility((prev) => ({ ...prev, [dataKey]: !prev[dataKey] }));
      }
    };

    return (
      <ul className="mb-8 flex list-none justify-center gap-8">
        {payload?.map(
          (
            entry: { dataKey: DataKey; value: string; color: string; basePriceValue?: number },
            index: number
          ) => {
            if (entry.dataKey === 'closedPeriod' && closedPeriods.length === 0) return null;
            if (entry.dataKey === 'surgeEvent' && surgeEvents.length === 0) return null;

            return (
              <li
                key={`item-${index}`}
                className="flex cursor-pointer items-center"
                onClick={() => handleLegendClick(entry.dataKey)}
              >
                {isLoading ? (
                  <Icon.LoadingCircle
                    className="mr-1 h-4 w-4"
                    style={{
                      color: entry.color
                    }}
                  />
                ) : (
                  <div
                    className={cn(
                      'mr-1 rounded-full',
                      entry.dataKey === 'surgeEvent' ? 'h-5 w-5' : ' h-3 w-3'
                    )}
                    style={{
                      backgroundColor: entry.color,
                      opacity: Array.isArray(entry.dataKey)
                        ? (entry.dataKey as DataKey[]).every((key) => seriesVisibility[key])
                          ? 1
                          : 0.3
                        : seriesVisibility[entry.dataKey]
                          ? 1
                          : 0.3
                    }}
                  >
                    {entry.dataKey === 'surgeEvent' ? (
                      <Icon.Ripple className="h-5 w-5 text-white" />
                    ) : null}
                  </div>
                )}
                <Typography
                  color="copyTextGrey"
                  variant="meta-2"
                  className={clsx(
                    Array.isArray(entry.dataKey)
                      ? (entry.dataKey as DataKey[]).every((key) => seriesVisibility[key])
                        ? 'opacity-100'
                        : 'opacity-30'
                      : seriesVisibility[entry.dataKey]
                        ? 'opacity-100'
                        : 'opacity-30'
                  )}
                >
                  {isLoading
                    ? t('Loading...')
                    : entry.value +
                      (entry.basePriceValue ? ` (${formattedCurrency(entry.basePriceValue)})` : '')}
                </Typography>
              </li>
            );
          }
        )}
      </ul>
    );
  };

  const CustomTooltip: React.FC<any> = (props) => {
    const { active, payload } = props;
    if (!active || !payload || !payload[0]) return null;
    const dataPoint = payload[0].payload;
    const { isSurgePrice } = getSurgePriceForDay(dataPoint.date, selectedHotelRoomId);
    const isSurgeEvent = getSurgeEventByDay(dataPoint.date)?.active;
    return (
      <Card backgroundColor="white">
        <Typography variant="meta-1" className="font-semibold" color="darkGrey">
          <div className="flex items-center gap-1">
            {isSurgeEvent ? <Icon.Ripple className="h-6 w-6 text-uiGreen" /> : null}
            {dayjs(dataPoint.date).format('ddd, DD MMM YYYY')}
          </div>
        </Typography>
        <div className="flex items-center justify-between gap-x-3">
          <div className="flex items-center gap-2">
            {isSurgePrice ? (
              <Icon.Lock className="h-2 w-2" />
            ) : (
              <div className="h-2 w-2 rounded-full bg-indigo" />
            )}
            <Typography
              variant="meta-2"
              color="darkGrey"
              className={cn(isSurgePrice ? 'text-meta-2-semibold' : null)}
            >
              {isSurgePrice ? t('Protection Price') : t('Recommended/Fix Price')}
            </Typography>
          </div>
          <Typography variant="meta-2" className="text-meta-2-medium" color="darkGrey">
            <b>{formattedCurrency(Math.round(dataPoint.priceRecommendation))}</b>
          </Typography>
        </div>
        <div className="flex items-center justify-between gap-x-3">
          <div className="flex items-center gap-2">
            <div className="h-2 w-2 rounded-full bg-mediumGrey" />
            <Typography variant="meta-2" color="darkGrey">
              {t(`Price in ${channelManagerOrPms}`)}
            </Typography>
          </div>
          <Typography variant="meta-2" className="text-meta-2-medium" color="darkGrey">
            <b>{formattedCurrency(dataPoint.priceInPms)}</b>
          </Typography>
        </div>
        <div className="flex items-center justify-between gap-x-3">
          <div className="flex items-center gap-2">
            <div className="h-2 w-2 rounded-full bg-orange" />
            <Typography variant="meta-2" color="darkGrey">
              {t(`Maximum Price`)}
            </Typography>
          </div>
          <Typography variant="meta-2" className="text-meta-2-medium" color="darkGrey">
            <b>{formattedCurrency(dataPoint.maxPrice)}</b>
          </Typography>
        </div>
        <div className="flex items-center justify-between gap-x-3">
          <div className="flex items-center gap-2">
            <div className="h-2 w-2 rounded-full bg-orange" />
            <Typography variant="meta-2" color="darkGrey">
              {t(`Minimum Price`)}
            </Typography>
          </div>
          <Typography variant="meta-2" className="text-meta-2-medium" color="darkGrey">
            <b>{formattedCurrency(dataPoint.minPrice)}</b>
          </Typography>
        </div>
        {dataPoint.occupancy ? (
          <div className="flex items-center justify-between gap-x-3">
            <div className="flex items-center gap-2">
              <div className="h-2 w-2 rounded-full bg-white" />
              <Typography variant="meta-2" color="darkGrey">
                {t('Occupancy')}
              </Typography>
            </div>

            <Typography variant="meta-2" className="text-meta-2-medium" color="darkGrey">
              <b>{Math.floor(dataPoint.occupancy) + '%'}</b>
            </Typography>
          </div>
        ) : null}
        <div className="mt-2 text-xs italic text-copyTextGrey">
          {t('Click the chart for more details.')}
        </div>
      </Card>
    );
  };

  // Leaving it here in case we want the base price label back
  // const CustomLabel: React.FC<any> = ({ viewBox, visibility }) => {
  //   const { x, y } = viewBox;
  //   const label = `Base Price: ${formattedCurrency(basePrice)}`;
  //   const BBox = {
  //     width: label.length * parseInt(fontSize) * 0.525,
  //     height: parseInt(fontSize) * 1.5
  //   };
  //   const borderRadius = 5; // Adjust this as needed

  //   return (
  //     <g>
  //       {visibility ? (
  //         <>
  //           <rect
  //             x={x * 1.1 + 5}
  //             y={y - BBox.height / 2}
  //             width={BBox.width}
  //             height={BBox.height}
  //             fill={darkGrey}
  //             rx={borderRadius}
  //             ry={borderRadius}
  //           />
  //           <text
  //             x={x * 1.1 + 10}
  //             y={y}
  //             fill="white"
  //             textAnchor="start"
  //             dominantBaseline="central"
  //             fontSize={fontSize}
  //             fontFamily={fontFamily}
  //             fontWeight={fontWeight}>
  //             {label}
  //           </text>
  //         </>
  //       ) : null}
  //     </g>
  //   );
  // };

  const xAxisTickFormatter = (
    value: any,
    isMonthly: boolean,
    lastLabelDisplayedRef: React.MutableRefObject<string | undefined>
  ) => {
    const currentLabel = isMonthly
      ? dayjs(value).format('MMM DD')
      : dayjs(value).format('MMM YYYY');
    if (currentLabel === lastLabelDisplayedRef.current) return '';
    lastLabelDisplayedRef.current = currentLabel;
    return currentLabel;
  };

  const CustomTick: React.FC<any> = ({ x, y, payload }) => {
    const { isToday, isWeekend } = useDate();
    const date = new Date(payload.value);

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={10}
          dy={0}
          textAnchor="end"
          fill={grey}
          fontFamily={fontFamily}
          fontSize={fontSize}
          fontWeight={isToday(date) ? 'bold' : fontWeight}
          textDecoration={isWeekend(date) ? 'underline' : 'none'}
          transform="rotate(-45 0 10)"
        >
          {dayjs(payload.value).format('MMM DD')}
        </text>
      </g>
    );
  };

  return (
    <div
      className="relative h-full w-full"
      data-userflow-id={ChartUserflowIds.PRICE_CHART_CONTAINER}
    >
      <Card backgroundColor="white">
        <ScrollArea>
          <ResponsiveContainer minWidth={800} width="100%" height={500}>
            <ComposedChart
              data={data}
              className="cursor-pointer"
              margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
              onClick={(data: any, event: any) => {
                if (['path', 'svg'].includes(event?.target?.tagName))
                  handleOpenPriceDrawer(data?.activeLabel);
              }}
            >
              <defs>
                <linearGradient id="totalGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor={indigo} stopOpacity={0.5} />
                  <stop offset="100%" stopColor={indigo} stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid stroke={mediumGrey} strokeDasharray="2" vertical={false} />
              <XAxis
                key={currentMonth}
                axisLine={false}
                tick={isMonthly ? <CustomTick /> : { fill: grey }}
                tickLine={false}
                fontFamily={fontFamily}
                fontSize={fontSize}
                fontWeight={fontWeight}
                dataKey="date"
                tickFormatter={(value) =>
                  xAxisTickFormatter(value, isMonthly, lastLabelDisplayedRef)
                }
                interval={isMonthly ? 0 : 'preserveEnd'}
                angle={-45}
                textAnchor={'end'}
                height={isMonthly ? 40 : 60}
              />
              <YAxis
                domain={[dataMin, dataMax]}
                axisLine={false}
                width={100}
                tick={{ fill: grey }}
                tickLine={false}
                fontFamily={fontFamily}
                fontSize={fontSize}
                fontWeight={fontWeight}
                tickFormatter={(value) => formattedCurrency(value)}
              />
              <Tooltip content={(props) => <CustomTooltip {...props} />} />
              <Legend
                verticalAlign="top"
                content={(props) => <CustomLegend {...props} payload={customLegendPayload} />}
              />

              <Area
                visibility={seriesVisibility.minPrice ? 'visible' : 'hidden'}
                dataKey="minPrice"
                name={t('Min Price') as string}
                stroke={orange}
                strokeWidth={1}
                fill="url(#totalGradient)"
                fillOpacity={0}
                strokeDasharray="4 4"
              />

              <Area
                visibility={seriesVisibility.maxPrice ? 'visible' : 'hidden'}
                dataKey="maxPrice"
                name={t('Max Price') as string}
                stroke={orange}
                strokeWidth={1}
                fill="url(#totalGradient)"
                fillOpacity={0}
                strokeDasharray="4 4"
              />

              {seriesVisibility.closedPeriod &&
                closedPeriods.map((period, index) => {
                  if (period.startDate === period.endDate) {
                    return (
                      <ReferenceLine
                        name={t('Closed Period') as string}
                        visibility={seriesVisibility.closedPeriod ? 'visible' : 'hidden'}
                        key={`closedPeriod-referenceLine-${index}`}
                        x={period.startDate}
                        stroke={gold}
                        strokeWidth={isMonthly ? 20 : 5}
                        strokeOpacity={0.4}
                      />
                    );
                  }

                  return (
                    <ReferenceArea
                      name={t('Closed Period') as string}
                      visibility={seriesVisibility.closedPeriod ? 'visible' : 'hidden'}
                      key={`closedPeriod-referenceArea-${index}`}
                      x1={period.startDate}
                      x2={period.endDate}
                      fill={gold}
                      stroke={gold}
                      strokeWidth={0}
                      fillOpacity={0.4}
                    />
                  );
                })}

              {seriesVisibility.surgeEvent &&
                surgeEvents.map((period, index) => {
                  if (period.startDate === period.endDate) {
                    return (
                      <ReferenceLine
                        name={t('Surge Event') as string}
                        visibility={seriesVisibility.surgeEvent ? 'visible' : 'hidden'}
                        key={`surgeEvent-referenceLine-${index}`}
                        x={period.startDate}
                        stroke={uiGreen}
                        strokeWidth={isMonthly ? 20 : 5}
                        strokeOpacity={0.4}
                      />
                    );
                  }

                  return (
                    <ReferenceArea
                      name={t('Surge Event') as string}
                      visibility={seriesVisibility.surgeEvent ? 'visible' : 'hidden'}
                      key={`surgeEvent-referenceArea-${index}`}
                      x1={period.startDate}
                      x2={period.endDate}
                      fill={uiGreen}
                      stroke={uiGreen}
                      strokeWidth={0}
                      fillOpacity={0.4}
                    />
                  );
                })}

              {seriesVisibility.priceInPms && (
                <Line
                  isAnimationActive={false}
                  visibility={seriesVisibility.priceInPms ? 'visible' : 'hidden'}
                  dataKey="priceInPms"
                  name={t(`Price in ${channelManagerOrPms}`) as string}
                  stroke={mediumGrey}
                  fill={mediumGrey}
                  strokeWidth={2}
                  dot={false}
                  activeDot={{
                    stroke: mediumGrey,
                    strokeWidth: 4,
                    className: 'cursor-pointer',
                    onClick: (_, data: any) => handleOpenPriceDrawer(data.payload.date)
                  }}
                />
              )}

              {seriesVisibility.priceRecommendation && (
                <Area
                  visibility={seriesVisibility.priceRecommendation ? 'visible' : 'hidden'}
                  dataKey="priceRecommendation"
                  name={t('Recommended/Fix Price') as string}
                  stroke={indigo}
                  strokeWidth={2}
                  fill="url(#totalGradient)"
                  fillOpacity={0.2}
                  activeDot={{
                    stroke: indigo,
                    strokeWidth: 4,
                    className: 'cursor-pointer',
                    elevation: 4,
                    onClick: (_, data: any) => handleOpenPriceDrawer(data.payload.date)
                  }}
                />
              )}

              {seriesVisibility.basePrice && (
                <ReferenceLine
                  name={t('Base Price') as string}
                  visibility={seriesVisibility.basePrice ? 'visible' : 'hidden'}
                  y={basePrice}
                  stroke={darkGrey}
                  strokeWidth={2}
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      </Card>
    </div>
  );
};
