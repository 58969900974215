import { Badge } from '@mantine/core';
import { Genie, RoomPriceGenieText } from '@common/components/foundations/Logo';

export const FullscreenLoading = () => {
  return (
    <div className="bg-black fixed left-0 top-0 z-10 flex h-screen w-screen items-center justify-center bg-opacity-50">
      <div className="flex items-center gap-x-4" title="RoomPriceGenie">
        <div className="flex h-auto w-10 animate-rpg-bounce flex-col items-center gap-2 duration-700 xl:w-20">
          <Badge color={'yellow.6'}>Admin</Badge>
          <Genie className="fill-indigo" />
        </div>
        <div className="h:16 w-16 xl:h-28 xl:w-28">
          <RoomPriceGenieText className="fill-indigo" />
        </div>
      </div>
    </div>
  );
};
