export const yieldingTagColors: { [key: string]: string } = {
  // Old Color
  '#00BFFF': 'yellow.6',
  '#FFA500': 'orange.6',
  '#0000CD': 'indigo.6',
  '#32CD32': 'red.6',
  '#FF69B4': 'gray.5',
  '#006400': 'green.9',
  // New Color,
  '#ECBA59': 'yellow.6',
  '#FE764B': 'orange.6',
  '#5B48EE': 'indigo.6',
  '#D62057': 'red.6',
  '#949494': 'gray.6',
  '#06815B': 'green.9'
};
