import { PmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { z } from 'zod';

const createRoomSchema = z.object({
  room_id: z.string().optional(),
  name: z.string().optional(),
  property_code: z.string().optional(),
  min_occupancy: z.number().optional(),
  max_occupancy: z.number().optional(),
  default_occupancy: z.number().optional(),
  max_adult: z.number().optional(),
  number_of_rooms: z.number().optional(),
  children_allowed: z.boolean().optional(),
  extra: z.string().optional()
});

const resultSchema = z.object({
  id: z.number(),
  room_id: z.string(),
  name: z.string(),
  property_code: z.string(),
  min_occupancy: z.number(),
  max_occupancy: z.number(),
  default_occupancy: z.number(),
  max_adult: z.number(),
  number_of_rooms: z.number(),
  children_allowed: z.boolean(),
  extra: z.string()
});

const MetadataSchema = z.object({
  count: z.number(),
  current: z.number(),
  next: z.string().url().optional()
});

const responseSchema = z.object({
  metadata: MetadataSchema,
  data: z.array(resultSchema)
});

export function createRoomTypesSchema(pmsProviderId: number) {
  const occupancySchema = z
    .union([
      z.string({
        invalid_type_error: 'Please enter a number'
      }),
      z.number({
        invalid_type_error: 'Please enter a number'
      })
    ])
    .refine((val) => Number(val) > 0, {
      message: 'Value should be greater than 0'
    });

  const schema = createRoomSchema.extend({
    name: z.string().trim().min(1, { message: 'Required' }),
    room_id: z.string().trim().min(1, { message: 'Required' }),
    max_occupancy:
      pmsProviderId === PmsProvider.CASABLANCA ? occupancySchema : occupancySchema.optional(),
    min_occupancy:
      pmsProviderId === PmsProvider.CASABLANCA ? occupancySchema : occupancySchema.optional(),
    default_occupancy:
      pmsProviderId === PmsProvider.GUESTLINE ? occupancySchema : occupancySchema.optional()
  });

  return schema.refine(
    (data) => {
      if (data.min_occupancy && data.max_occupancy) {
        return Number(data.min_occupancy) <= Number(data.max_occupancy);
      }
      return true;
    },
    {
      message: 'Cannot higher than max_occupancy',
      path: ['min_occupancy']
    }
  );
}

const ErrorResponseSchema = z.object({
  response: z.object({
    data: z.object({
      errors: z.array(
        z.object({
          non_field_errors: z.array(z.string()).optional()
        })
      )
    })
  })
});

export const ParamsRoomTypeSchema = z.object({
  page: z.number().optional(),
  ordering: z.string().nullable().optional()
});

export type RoomTypesResponse = z.infer<typeof responseSchema>;
export type CreateRoomTypes = z.infer<typeof createRoomSchema>;
export type UpdateRoomTypes = Partial<CreateRoomTypes> & { id?: number };
export type ParamsRoomType = z.infer<typeof ParamsRoomTypeSchema>;

export type RoomTypeErrorResponse = z.infer<typeof ErrorResponseSchema>;
