import { Checkbox } from '@mantine/core';
import { Input } from '@common/components/atoms/Input';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { Controller, useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { InputHelperMessage } from '@common/components/atoms/InputHelperMessage';
import { Icon } from '@common/components/foundations/icons';
import { useTranslation } from 'react-i18next';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useViewStore } from '@common/store/view';
import { useRoomSetupStore } from '@pages/Client/PricingStrategy/RoomSetup/store/roomSetup';
import { editReferenceRoomSchema } from '@pages/Client/PricingStrategy/RoomSetup/common/formSchema';
import { usePmsProvider } from '~/Client/hooks/usePmsProvider';

export const PricingSetupEditReferenceRoom: React.FC = () => {
  const {
    control,
    formState: { isSubmitting },
    watch
  } = useFormContext<z.infer<typeof editReferenceRoomSchema>>();
  const { isApartment } = useRoomSetupStore();
  const { hotelDetails } = useHotelDetails();
  const { t } = useTranslation();
  const { view } = useViewStore();
  const { hasAdditionalPricePerPerson } = usePmsProvider();

  const isAdjustInflation = watch('hotel.automatic_inflation_increase');

  const basePrice = watch('pricingSetup.basePrice');
  const currentRoundingValue = watch('pricingSetup.roundPriceSetting');

  const getRoundingOptions = (basePrice: number) => {
    // Standard options that are always available
    const standardOptions = [
      { label: t('No Rounding'), value: null },
      { label: t('Round to nearest {{value}}', { value: '5' }), value: 5 },
      { label: t('Round to nearest {{value}}', { value: '9' }), value: 9 },
      { label: t('Round to nearest {{value}}', { value: '10' }), value: 10 },
      { label: t('Round to nearest {{value}}', { value: '20' }), value: 20 },
      { label: t('Round to nearest {{value}}', { value: '50' }), value: 50 },
      { label: t('Round to nearest {{value}}', { value: '100' }), value: 100 }
    ];

    // If there's no base price, return standard options
    if (!basePrice) return standardOptions;

    const maxRounding = Math.ceil(basePrice * 0.1); // 10% of base price
    const currentMagnitude = currentRoundingValue
      ? Math.floor(Math.log10(currentRoundingValue))
      : 0;
    const targetMagnitude = Math.floor(Math.log10(maxRounding));

    // Use the higher of current or target magnitude to ensure we include all relevant options
    const highestMagnitude = Math.max(currentMagnitude, targetMagnitude);

    // Generate powers of 10 starting from 1000 (10^3)
    const powerOptions = [];
    for (let i = 3; i <= highestMagnitude; i++) {
      const value = Math.pow(10, i);
      // Include the option if it's either within maxRounding OR if it's less than current value
      if (value <= maxRounding || (currentRoundingValue && value <= currentRoundingValue)) {
        powerOptions.push({
          label: t('Round to nearest {{value}}', { value: value.toLocaleString() }),
          value
        });
      }
    }

    return [...standardOptions, ...powerOptions];
  };

  return (
    <>
      <div className="flex w-full flex-col gap-y-6 md:w-2/3">
        <Controller
          control={control}
          name="pricingSetup.basePrice"
          render={({ field: { value, onChange, name }, fieldState: { error, invalid } }) => (
            <Input
              showClearButton={false}
              disabled={isSubmitting}
              leadingAddon={CurrencyFormatter.currencySymbol()}
              type="number"
              label={`${t('Base Price')}*`}
              placeholder="Please enter the base price"
              background="grey"
              name={name}
              value={value}
              onChange={onChange}
              error={invalid}
              trailingAddon={
                <TooltipProvider delayDuration={75}>
                  <Tooltip>
                    <TooltipTrigger type="button">
                      <Icon.Help className="h-5 w-5 fill-grey" />
                    </TooltipTrigger>
                    <TooltipContent side="bottom" className="max-w-sm text-left">
                      {t(
                        `The Base Price is the most important number in your system. You can think of it as the average price for this ${hotelDetails?.room_apartment_space_name.toLowerCase()} and rate over the whole year. It’s not your ADR but more an average BAR.  In general, if you move it up by $50 then all your prices will go up by an average of around $50. Please be very careful changing it. You can read more in the Help Center.`
                      )}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              }
              helperMessage={
                invalid ? (
                  <InputHelperMessage
                    icon={<Icon.WarningOutline className="h-3 w-3 fill-uiRed" />}
                    message={error?.message}
                  />
                ) : null
              }
            />
          )}
        />

        <Controller
          control={control}
          name="pricingSetup.defaultMinPrice"
          render={({ field: { value, onChange, name }, fieldState: { error, invalid } }) => (
            <Input
              showClearButton={false}
              disabled={isSubmitting}
              leadingAddon={CurrencyFormatter.currencySymbol()}
              type="number"
              label={`${t('Default Minimum Price')}*`}
              placeholder={`${t('Please enter the default minimum price')}`}
              background="grey"
              name={name}
              value={value}
              onChange={onChange}
              error={invalid}
              trailingAddon={
                <TooltipProvider delayDuration={75}>
                  <Tooltip>
                    <TooltipTrigger type="button">
                      <Icon.Help className="h-5 w-5 fill-grey" />
                    </TooltipTrigger>
                    <TooltipContent side="bottom" className="max-w-sm text-left">
                      {t(
                        `The Default Minimum Price is the price that we will not sell this ${hotelDetails?.room_apartment_space_name.toLowerCase()} below. For example, if you set a minimum of $99 and we will never set this ${hotelDetails?.room_apartment_space_name.toLowerCase()} and rate below $99 unless you specifically over-ride it. To over-ride the Default Minimum, you can either use ‘Fix Price’ to fix the price below. Or you can change the minimum (up or down) on an individual day basis, using the Adjustment View. Find out more about minimum and maximum prices in our Help Center.`
                      )}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              }
              helperMessage={
                invalid ? (
                  <InputHelperMessage
                    icon={<Icon.WarningOutline className="h-3 w-3 fill-uiRed" />}
                    message={error?.message}
                  />
                ) : null
              }
            />
          )}
        />
        <Controller
          control={control}
          name="pricingSetup.defaultMaxPrice"
          render={({ field: { value, onChange, name }, fieldState: { invalid, error } }) => (
            <Input
              showClearButton={false}
              disabled={isSubmitting}
              leadingAddon={CurrencyFormatter.currencySymbol()}
              type="number"
              label={`${t('Default Maximum Price')}*`}
              background="grey"
              placeholder={`${t('Please enter the default maximum price')}`}
              name={name}
              value={value}
              onChange={onChange}
              error={invalid}
              trailingAddon={
                <TooltipProvider delayDuration={75}>
                  <Tooltip>
                    <TooltipTrigger type="button">
                      <Icon.Help className="h-5 w-5 fill-grey" />
                    </TooltipTrigger>
                    <TooltipContent side="bottom" className="max-w-sm text-left">
                      {t(
                        `The Default Maximum Price is the price that we will not sell this ${hotelDetails?.room_apartment_space_name.toLowerCase()} above. For example, if you set a maximum of $299 and we will never set this ${hotelDetails?.room_apartment_space_name.toLowerCase()} and rate above $299 unless you specifically over-ride it. To over-ride the Default Maximum, you can either use ‘Fix Price’ to fix the price higher. Or you can change the maximum (up or down) on an individual day basis, using the Adjustment View. Find out more about minimum and maximum prices in our Help Center.`
                      )}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              }
              helperMessage={
                invalid ? (
                  <InputHelperMessage
                    icon={<Icon.WarningOutline className="h-3 w-3 fill-uiRed" />}
                    message={error?.message}
                  />
                ) : null
              }
            />
          )}
        />

        <Controller
          control={control}
          name="pricingSetup.variableCost"
          render={({ field: { value, onChange, name }, fieldState: { error, invalid } }) => (
            <Input
              showClearButton={false}
              disabled={isSubmitting}
              leadingAddon={CurrencyFormatter.currencySymbol()}
              type="number"
              label={`${t('Variable Cost')}*`}
              placeholder={`${t('Please enter variable cost')}`}
              background="grey"
              name={name}
              value={value}
              onChange={onChange}
              error={invalid}
              trailingAddon={
                <TooltipProvider delayDuration={75}>
                  <Tooltip>
                    <TooltipTrigger type="button">
                      <Icon.Help className="h-5 w-5 fill-grey" />
                    </TooltipTrigger>
                    <TooltipContent side="bottom" className="max-w-sm text-left">
                      {t(
                        `The Variable Costs are the extra costs associated with having a guest in the ${hotelDetails?.room_apartment_space_name.toLowerCase()} rather than having an empty ${hotelDetails?.room_apartment_space_name.toLowerCase()}. These should take into account what it costs you to clean or maintain a ${hotelDetails?.room_apartment_space_name.toLowerCase()}, the cost of breakfast, utilities etc.  They should also include any OTA commissions you are paying. When optimising your revenue, we want to make sure that these costs are taken into account and you make enough profit on each ${hotelDetails?.room_apartment_space_name.toLowerCase()} sold. For most properties, we recommend setting this at least at 50% of the Minimum Price. If your prices are going too low for your preference, you can raise it to up-to 80% of your Minimum price.`
                      )}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              }
              helperMessage={
                invalid ? (
                  <InputHelperMessage
                    icon={<Icon.WarningOutline className="h-3 w-3 fill-uiRed" />}
                    message={error?.message}
                  />
                ) : null
              }
            />
          )}
        />
        {hasAdditionalPricePerPerson() ? (
          <div className="flex w-full flex-col gap-y-6 ">
            <Controller
              control={control}
              name="occupancyPricing.additionalAdultPrice"
              render={({ field: { value, onChange, name }, fieldState: { error, invalid } }) => (
                <Input
                  showClearButton={false}
                  background="grey"
                  leadingAddon={CurrencyFormatter.currencySymbol()}
                  label={(t('Additional Price Per Person') as string) + '*'}
                  name={name}
                  value={value}
                  onChange={onChange}
                  error={invalid}
                  helperMessage={
                    invalid ? (
                      <InputHelperMessage
                        icon={<Icon.WarningOutline className="h-3 w-3 fill-uiRed" />}
                        message={error?.message}
                      />
                    ) : null
                  }
                />
              )}
            />
          </div>
        ) : null}

        <Controller
          control={control}
          name="pricingSetup.roundPriceSetting"
          render={({ field: { value, onChange, name }, fieldState: { error, invalid } }) => (
            <SelectDropdown
              fullWidth
              hint={`${t(
                `Round Prices (applied to all ${isApartment ? 'apartments' : 'rooms'})`
              )}*`}
              background="grey"
              name={name}
              value={value}
              options={getRoundingOptions(basePrice || 0)}
              onChange={onChange}
              error={invalid}
              helperMessage={invalid ? error?.message : undefined}
            />
          )}
        />
      </div>

      <Controller
        control={control}
        name="pricingSetup.lockDerivationWhenBasePriceChanges"
        render={({ field: { value, onChange, name } }) => (
          <div className="flex items-center gap-1.5">
            <Checkbox
              label={t(
                `Lock Derivation When Changing Base Price of Reference ${
                  isApartment ? 'Apartment' : 'Room'
                }`
              )}
              id={name}
              name={name}
              checked={value}
              onChange={onChange}
            />
          </div>
        )}
      />

      <Controller
        control={control}
        name="hotel.automatic_inflation_increase"
        render={({ field: { value, onChange, name } }) => (
          <Checkbox
            label={t(`Adjust base price on a monthly basis to account for inflation`)}
            id={name}
            name={name}
            checked={value}
            onChange={onChange}
          />
        )}
      />

      {isAdjustInflation ? (
        <div className="md:w-2/3">
          <Controller
            control={control}
            name="hotel.yearly_percent_increase"
            render={({ field: { value, onChange, name }, fieldState: { error, invalid } }) => (
              <Input
                showClearButton={false}
                disabled={isSubmitting}
                type="number"
                label={`${t('Yearly inflation')}*`}
                placeholder="Please enter the yearly inflation"
                background="grey"
                name={name}
                value={value === 0 ? '0' : value}
                onChange={onChange}
                error={invalid}
                trailingAddon={<>%</>}
                helperMessage={
                  invalid ? (
                    <InputHelperMessage
                      icon={<Icon.WarningOutline className="h-3 w-3 fill-uiRed" />}
                      message={error?.message}
                    />
                  ) : null
                }
              />
            )}
          />
        </div>
      ) : null}
      {view === 'admin' ? (
        <Controller
          control={control}
          name="pricingSetup.dontCountUploads"
          render={({ field: { value, onChange, name } }) => (
            <div className="flex items-center gap-1.5">
              <Checkbox
                label={t(`Don't count uploads`)}
                id={name}
                name={name}
                checked={value}
                onChange={onChange}
              />
            </div>
          )}
        />
      ) : null}
    </>
  );
};
