import { ErrorResponse } from '@common/api/auth';
import { setAuthToken } from '@common/api/axiosInstance';
import { getHotelOutboundLogs, getHotelOutboundLogsBlob } from '@common/api/hotel';
import { HotelOutboundApiLogsPagination, DownloadOutboundApiLogs } from '@common/api/hotel/types';
import { useHotelStore } from '@common/store/auth';
import { useNotificationsStore } from '@common/store/notifications';
import { HotelQueryKeys } from '@common/types/query-keys';
import { useGetHasMetadataStore } from '@pages/Client/Logs/OutboundApiLog/store/download';
import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';
import { isPlainObject } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';

type Params = {
  page: number;
  search?: string;
  request_type?: number;
  date?: string;
  ordering?: string;
  is_success?: string;
};

export const useOutboundApiLogs = ({
  page = 1,
  search,
  request_type,
  date,
  ordering,
  is_success
}: Params) => {
  const { addNotification } = useNotificationsStore();

  const query = useQuery<HotelOutboundApiLogsPagination, ErrorResponse>({
    queryKey: [
      HotelQueryKeys.GET_HOTEL_OUTBOUND_API_LOGS,
      page,
      search,
      request_type,
      date,
      ordering,
      is_success
    ],
    queryFn: () => {
      return getHotelOutboundLogs(page, search, request_type, date, ordering, is_success);
    },
    placeholderData: keepPreviousData
  });

  useEffect(() => {
    if (query.isError) {
      addNotification('fail', 'Failed to get hotel outbound API logs');
    }
  }, [query.isError]);

  const hotelOutboundApiLogs = useMemo(() => {
    return query.data;
  }, [query.data]);

  const refetchLogs = async () => {
    await query.refetch();
    addNotification('success', 'Outbound API logs have been updated');
  };

  useEffect(() => {
    query.refetch();
  }, [ordering]);

  const isRefetchLoading = query.isLoading || query.isFetching;

  return { hotelOutboundApiLogs, query, refetchLogs, isRefetchLoading };
};

export const useDownloadOutboundApiLogs = () => {
  const { addNotification } = useNotificationsStore();
  const { isHasMetadata, isAllLogs } = useGetHasMetadataStore();
  const { hotelAuthToken } = useHotelStore();
  const [additionalFileName, setAdditionalFileName] = useState({
    name: '',
    type: ''
  });

  const downloadOutboundApiLogs = (response: any, contentType: string) => {
    let blobData;
    let fileExtension;

    if (contentType.includes('application/json')) {
      blobData = [JSON.stringify(response)];
      fileExtension = 'json';
    } else if (contentType.includes('text/plain')) {
      // Use Lodash to check if the response is a plain object
      if (isPlainObject(response)) {
        // If it's an object, stringify it
        blobData = [JSON.stringify(response)];
      } else {
        // Otherwise, treat it as plain text
        blobData = [response];
      }
      fileExtension = 'txt';
    } else if (contentType.includes('application/xml') || contentType.includes('text/xml')) {
      blobData = [response];
      fileExtension = 'xml';
    } else if (contentType.includes('text/html')) {
      blobData = [response];
      fileExtension = 'html';
    } else {
      return;
    }

    const metaDataFileName = isHasMetadata ? '-metadata' : '';
    const allLogsFileName = isAllLogs ? '-all' : '';
    const blob = new Blob(blobData, { type: contentType });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${additionalFileName.name}-${
      additionalFileName.type
    }${`${metaDataFileName}`}${`${allLogsFileName}`}.${fileExtension}`;
    link.click();
    URL.revokeObjectURL(url);
  };

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (data: DownloadOutboundApiLogs) => {
      setAdditionalFileName({
        name: data.unique_log_id,
        type: data.request_type === 1 ? 'request' : 'response'
      });
      setAuthToken(hotelAuthToken);
      return getHotelOutboundLogsBlob(data);
    },
    onSuccess: (response) => {
      const contentType = response?.headers['content-type'];
      downloadOutboundApiLogs(response.data, contentType as string);
    },
    onError: () => {
      addNotification('fail', 'Failed to download outbound API logs');
    }
  });

  return {
    getOutboundApiLogs: mutate,
    isLoading
  };
};
