import { Dayjs } from 'dayjs';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { useTranslation } from 'react-i18next';
import { Typography } from '@common/components/foundations/Typography';
import { useFeaturesStore } from '@common/store/features';
import {
  useRecommendedMinStay,
  useRecommendedPrice
} from '@pages/Client/Calendar/components/Tables/hooks';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { usePmsProvider } from '@pages/Client/hooks/usePmsProvider';

export const UploadPriceConfirmationModal = ({
  currentViewingDate
}: {
  currentViewingDate: Dayjs;
}) => {
  const { t } = useTranslation();
  const { hasMinStayFeature } = useFeaturesStore();
  const { hotelDetails } = useHotelDetails();
  const { displayHotelRooms } = useHotelRoomsList();
  const { recommendedPriceData } = useRecommendedPrice(currentViewingDate);
  const { recommendedMinStayData } = useRecommendedMinStay(currentViewingDate);
  const { hasRoomTypeOnly, pmsMinStayConfig } = usePmsProvider();

  const channelManagerOrPms = hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS';
  const remainingSpaceClass = hasMinStayFeature() ? 'w-1/4' : 'w-1/2';

  return (
    <>
      <h1 className="mt-3 text-h6 font-medium">{t(`Upload Prices to ${channelManagerOrPms}`)}</h1>
      <div className="flex flex-col py-6">
        <div className="mb-1 flex items-center justify-between space-x-4 border-b-2 border-appBackground py-3">
          <div className="w-1/2 text-left font-medium">
            <Typography variant="nav" color="grey">
              {t(`${hotelDetails?.room_apartment_space_name}`)}
            </Typography>
          </div>
          <div className={remainingSpaceClass + ' text-center font-medium'}>
            <Typography variant="nav" color="grey">
              {t('Price')}
            </Typography>
          </div>
          {hasMinStayFeature() ? (
            <div className="w-1/4 text-center font-medium">
              <Typography variant="nav" color="grey">
                {t('Min Stay')}
              </Typography>
            </div>
          ) : null}
        </div>

        {displayHotelRooms
          .filter((room) => hasRoomTypeOnly || room.pms_rate)
          .map((room) => (
            <div
              key={room.id}
              className="flex items-center justify-between space-x-4 border-b-2 border-appBackground py-3"
            >
              <div className="w-1/2">
                <Typography variant="paragraph-2" color="darkGrey">
                  {room.name}
                </Typography>
              </div>
              <div className={remainingSpaceClass + ' text-center'}>
                <Typography variant="paragraph-2" color="darkGrey" className="font-medium">
                  {CurrencyFormatter.format(recommendedPriceData?.[room.id].price)}
                </Typography>
              </div>
              {hasMinStayFeature() ? (
                <div className="w-1/4 text-center">
                  <Typography variant="paragraph-2" color="darkGrey" className="font-medium">
                    {recommendedMinStayData?.[room.id] === pmsMinStayConfig?.min_stay_remove_value
                      ? t('Removes Min Stay')
                      : recommendedMinStayData?.[room.id] === undefined
                        ? '-'
                        : recommendedMinStayData?.[room.id]}
                  </Typography>
                </div>
              ) : null}
            </div>
          ))}
      </div>
    </>
  );
};
