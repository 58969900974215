import { axiosInstance, setAuthToken } from '@common/api/axiosInstance';
import { portalMembershipPricing } from '@common/api/hotel';
import { CheckoutSubscription } from '@common/api/hotel/types';
import { Icon } from '@common/components/foundations/icons';
import { useModal } from '@common/hooks/useModal';
import { useUserRole } from '@common/hooks/useUserRole';
import { useHotelStore } from '@common/store/auth';
import { Feature, useFeaturesStore } from '@common/store/features';
import { useViewStore } from '@common/store/view';
import { useWarnings } from '@common/store/warnings';
import { MembershipQueryKeys } from '@common/types/query-keys';
import { Flex, Button, SimpleGrid, Group } from '@mantine/core';
import { useDocumentTitle } from '@mantine/hooks';
import CardSubscription from '@pages/Client/Account/Subscription/components/CardSubscription';
import { ChargebeeAccountInformation } from '@pages/Client/Account/Subscription/components/ChargebeeAccountInformation';
import { DemoHotelSwitcher } from '@pages/Client/Account/Subscription/components/DemoHotelSwitcher';
import { DownGradeModal } from '@pages/Client/Account/Subscription/components/DownGradeModal';
import { FreeTrialForm } from '@pages/Client/Account/Subscription/components/FreeTrialForm';
import { PartnerSubscription } from '@pages/Client/Account/Subscription/components/PartnerSubscription';
import { useChargebee } from '@pages/Client/Account/Subscription/hooks/useChargebee';
import { useMemberShipPricing } from '@pages/Client/Account/Subscription/hooks/usePayment';
import { usePaymentStore } from '@pages/Client/Account/Subscription/store/subscription';
import { PricingItem } from '@pages/Client/Account/Subscription/types/subscription';
import { getMemberShipKey } from '@pages/Client/Account/Subscription/utils/subscription';
import { useHSTicket } from '@pages/Client/hooks/useHSTicket';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { usePmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { HotelPlan, HotelState, SubscriptionType } from '@pages/Client/hooks/useSubscription';
import { useQueryClient } from '@tanstack/react-query';
import { isNil } from 'lodash-es';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function Subscription() {
  const { t } = useTranslation();
  useDocumentTitle(t('Subscription'));
  const queryClient = useQueryClient();
  const { membershipPricing, isLoading, subscriptionPriceData } = useMemberShipPricing();
  const { isMonthly: isPricingMonthly } = usePaymentStore();
  const {
    hotelDetails: hotel,
    query: { refetch: hotelDetailsRefetch }
  } = useHotelDetails();
  const { isRpgAdmin, isPartner } = useUserRole();
  const { view } = useViewStore();
  const { hotelAuthToken } = useHotelStore();
  const { features } = useFeaturesStore();
  const { hasDashboardEnabled } = usePmsProvider();
  const { createWarning } = useWarnings();
  const { createHSTicket } = useHSTicket();

  const {
    isOpen: downGradeOpen,
    openModal: downGradeClick,
    closeModal: downGradeClose
  } = useModal();

  const [planName, setPlanName] = useState<number>();

  const cbInstance = useChargebee();

  const pricing = isLoading ? {} : membershipPricing ? membershipPricing : {};

  const pricingList = Object.keys(pricing).map((key) => {
    return {
      id: key,
      type: parseInt(key),
      current: hotel?.plan,
      isOverrideMonthly:
        subscriptionPriceData?.override_price?.plan === parseInt(key) &&
        subscriptionPriceData?.override_price?.subscription_type === 1,
      isOverrideYearly:
        subscriptionPriceData?.override_price?.plan === parseInt(key) &&
        subscriptionPriceData?.override_price?.subscription_type === 2,
      isOverridePrice: subscriptionPriceData?.override_price?.plan === parseInt(key),
      isProliteMonthly: pricing ? pricing[key].monthly.active : false,
      isProliteYearly: pricing ? pricing[key].yearly.active : false,
      currentPlan: hotel?.subscription_type,
      isSubscribed: hotel?.plan === parseInt(key),
      yearlyPrice: pricing ? pricing[key].yearly.price : 0,
      yearlyMonthPrice: pricing ? pricing[key].yearly.price_per_month : 0,
      monthlyPrice: pricing ? pricing[key].monthly.price : 0,
      currency: pricing ? pricing[key].currency.symbol : ''
    };
  });

  if (features?.includes(Feature.SmallBusiness)) {
    const index5 = pricingList.findIndex((plan) => plan.id === '5');
    if (index5 !== -1) {
      const plan5 = pricingList.splice(index5, 1)[0];
      pricingList.unshift(plan5);
    }

    const index2 = pricingList.findIndex((plan) => plan.id === '2');
    if (index2 !== -1) {
      const plan2 = pricingList.splice(index2, 1)[0];
      pricingList.push(plan2);
    }
  }
  if (hotel?.plan === 6) {
    const index3 = pricingList.findIndex((plan) => plan.id === '3');
    const index6 = pricingList.findIndex((plan) => plan.id === '6');
    if (index3 !== -1 && index6 !== -1) {
      // Swap positions of 'id: 3' and 'id: 6'
      const temp = pricingList[index3];
      pricingList[index3] = pricingList[index6];
      pricingList[index6] = temp;
    }
  }

  const handlePortal = async () => {
    if (hotel?.subscription_id) {
      cbInstance?.setPortalSession(() => {
        return new Promise((resolve, _reject) => {
          resolve(portalMembershipPricing(hotelAuthToken as string));
        });
      });
    }
    const cbPortal = cbInstance?.createChargebeePortal();
    cbPortal?.open({
      close() {}
    });
  };

  // Checkout Payment
  const handleCheckout = async (checkout: CheckoutSubscription) => {
    cbInstance?.openCheckout({
      hostedPage: () => {
        setAuthToken(hotelAuthToken);
        return axiosInstance.post('/hotel/payment/checkout/', checkout).then((res) => res.data);
      },
      loaded: function () {},
      error: function () {},
      close: () => {
        hotelDetailsRefetch();
        queryClient.invalidateQueries({ queryKey: [MembershipQueryKeys.MEMBERSHIP_PRICING] });
        // window.location.reload();
      },
      success: async (hostedPageId: any) => {
        setAuthToken(hotelAuthToken);
        try {
          await axiosInstance.post('/hotel/chargebee/link/', {
            hosted_page_token: hostedPageId,
            subscription_plan: checkout.subscription_plan
          });
        } catch (error) {
          return error;
        }
        hotelDetailsRefetch();
        queryClient.invalidateQueries({ queryKey: [MembershipQueryKeys.MEMBERSHIP_PRICING] });
      },

      step(step: any) {
        if (step === 'thankyou_screen') {
          return;
        }
      }
    });
  };

  const handleSubscribeClick = (item: PricingItem) => {
    if (item.type === HotelPlan.PROFESSIONAL) {
      createHSTicket({ ticket_type: 'upsell_request' });

      if (!hasDashboardEnabled()) {
        return createWarning({
          message: t(
            'Upgrading to Professional is an excellent decision for your property. The professional plan includes more features, more control and a lot more time with our revenue managers to perfect your pricing. A RoomPriceGenie team member will be in touch shortly to get you started.'
          ),
          type: 'success',
          showIgnoreLabel: false,
          dismissLabel: t('OK') as string,
          customHeaderTitle: t('Upgrade to Professional') as string
        });
      }
    }

    setPlanName(item.type);
    const clickCheckout = () => {
      handleCheckout({
        is_yearly: isPricingMonthly ? 0 : 1,
        subscription_plan: parseInt(item.id)
      });
    };
    if (
      item.isSubscribed &&
      hotel?.subscription_type === SubscriptionType.MONTHLY &&
      isPricingMonthly
    ) {
      handlePortal();
    } else if (
      item.isSubscribed &&
      hotel?.subscription_type === SubscriptionType.YEARLY &&
      !isPricingMonthly
    ) {
      handlePortal();
    } else if (parseInt(item.id) < (hotel?.plan ?? 0)) {
      if (hotel?.plan === HotelPlan.SMALLBUSINESS) {
        return;
      } else {
        downGradeClick();
      }
    } else {
      if (
        hotel?.plan === HotelPlan.PROFESSIONAL &&
        hotel?.subscription_type === SubscriptionType.YEARLY
      ) {
        return;
      } else {
        clickCheckout();
      }
    }
  };

  return (
    <Flex direction="column" gap="md">
      {features?.includes(Feature.PartnerSubscriptionPage) ? <PartnerSubscription /> : null}
      <div>
        <ChargebeeAccountInformation />
      </div>

      {features?.includes(Feature.PartnerSubscriptionPage) ? null : (
        <>
          {view === 'admin' && (
            <div className="my-2 pb-1">
              <Group justify="space-between">
                <DemoHotelSwitcher />
                {!isNil(hotel?.state) &&
                [HotelState.FREE_TRIAL, HotelState.FREE_TRIAL_EXPIRED].includes(
                  hotel?.state as HotelState
                ) &&
                (isPartner || isRpgAdmin) ? (
                  <FreeTrialForm />
                ) : null}
              </Group>
            </div>
          )}
          <SimpleGrid
            cols={{
              base: 1,
              sm: 1,
              md: 3
            }}
            className="mb-4 gap-6"
          >
            {pricingList
              ?.slice(
                isPricingMonthly ? 0 : features?.includes(Feature.SmallBusiness) ? 1 : 0,
                isPricingMonthly && features?.includes(Feature.SmallBusiness) ? 3 : 3
              )
              .map((item) => {
                return (
                  <div key={item.id}>
                    <CardSubscription
                      isMonthly={isPricingMonthly}
                      currencySymbol={item.currency}
                      currentSubscriptionType={hotel?.plan}
                      current={item.current}
                      isSubscribed={item.isSubscribed}
                      isProliteMonthly={item.isProliteMonthly}
                      isProliteYearly={item.isProliteYearly}
                      type={item.type}
                      price={isPricingMonthly ? item.monthlyPrice : item.yearlyPrice}
                      monthPrice={item.yearlyMonthPrice}
                      isLoading={isLoading}
                      onclick={() => handleSubscribeClick(item as PricingItem)}
                      isOverrideMonthly={item.isOverrideMonthly}
                      isOverrideYearly={item.isOverrideYearly}
                      isOverridePrice={
                        isPricingMonthly &&
                        subscriptionPriceData?.override_price?.subscription_type ===
                          SubscriptionType.MONTHLY
                          ? true
                          : !isPricingMonthly &&
                              subscriptionPriceData?.override_price?.subscription_type ===
                                SubscriptionType.YEARLY
                            ? true
                            : false
                      }
                    />
                  </div>
                );
              })}
          </SimpleGrid>
          <a href="https://roompricegenie.com/pricing/" target="_blank" rel="noreferrer">
            <Button
              variant="transparent"
              size="lg"
              fullWidth
              rightSection={<Icon.ArrowLongRight />}
              c="dark.9"
              fw={500}
              fz="md"
            >
              {t('See How Our Plans Compare')}
            </Button>
          </a>
        </>
      )}

      <DownGradeModal
        isOpen={downGradeOpen}
        onClose={downGradeClose}
        plan={getMemberShipKey(planName ?? 0)}
      />
    </Flex>
  );
}
