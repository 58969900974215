import { ROOMS_SETUP_TABS as Tabs } from '@pages/Client/PricingStrategy/RoomSetup/types';
import { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';

interface HandleTabErrorsParams {
  methods: UseFormReturn<any>;
  setActiveTab: (tab: Tabs) => void;
}

export const useHandleTabErrors = ({ methods, setActiveTab }: HandleTabErrorsParams) => {
  const findTabWithError = useCallback((errors: any): Tabs | null => {
    if (errors.pmsMapping) return Tabs.PMSMapping;
    if (errors.pricingSetup) return Tabs.PricingSetup;
    if (errors.occupancyPricing) return Tabs.OccupancyPricing;
    if (errors.derivedRates) return Tabs.DerivedRates;
    return null;
  }, []);

  const validateAndHandleErrors = async () => {
    if (!methods) return false;

    const isValid = await methods.trigger();

    await new Promise((resolve) => setTimeout(resolve, 0));

    const errors = methods.formState.errors;
    const errorTab = findTabWithError(errors);

    if (!isValid && errorTab !== null) {
      setActiveTab(errorTab);
      return false;
    }

    return true;
  };

  return { validateAndHandleErrors };
};
