import { z } from 'zod';

// This Type use for group displacement feature
export type QuotationInputForm = z.infer<ReturnType<typeof QuotationInputSchema>>;

export const QuotationInputSchema = (hotelCapacity: number, referenceRoomPrice: number) =>
  z.object({
    group_name: z
      .string()
      .nonempty('Group name is required')
      .max(100, 'Group name must be at most 100 characters long'),
    group_rooms: z.array(
      z.object({
        value: z
          .union([z.number().int(), z.string().regex(/^\d+$/, 'Number of rooms is required')])
          .transform((value) => Number(value))
          .refine((value) => value > 0, 'Number of rooms must be a positive integer')
          .refine(
            (value) => value <= hotelCapacity,
            `Number of rooms cannot exceed ${hotelCapacity}`
          )
      })
    ),
    date_range: z.object({
      start_date: z
        .string()
        .nonempty('Start date is required')
        .refine((date) => !isNaN(Date.parse(date)), 'Start date must be a valid date'),
      end_date: z
        .string()
        .nonempty('End date is required')
        .refine(
          (date) => date === '' || !isNaN(Date.parse(date)),
          'End date must be a valid date or empty'
        )
    }),
    run_forecast: z.boolean().optional().default(false),
    has_custom_number_of_rooms: z.boolean().optional().default(false),
    base_rate_offset: z
      .union([
        z.number().int(),
        z.string().regex(/^[+-]?\d+$/, 'Base rate offset must be a positive or negative integer')
      ])
      .refine(
        (value) =>
          -(70 % referenceRoomPrice) < Number(value) && Number(value) < 10 * referenceRoomPrice,
        `Base rate offset must be between ${-(70 % referenceRoomPrice)} and ${10 * referenceRoomPrice}`
      )
      .transform((value) => Number(value))
      .optional(),
    length_of_stay: z
      .union([z.number().int(), z.string().regex(/^-?\d+$/, 'Must be a number')])
      .transform((value) => Number(value))
      .optional(),
    number_of_rooms_input: z
      .union([z.number().int(), z.string().regex(/^\d+$/, 'Number of rooms is required')])
      .transform((value) => Number(value))
      .refine((value) => value > 0, 'Number of rooms must be a positive integer')
      .refine((value) => value <= hotelCapacity, `Number of rooms cannot exceed ${hotelCapacity}`)
  });

export const EditQuotationResultSchema = z.object({
  notes: z.string().optional(),
  total_quote: z.string().optional()
});

export const QuotationStatusOptionSchema = z.enum(['APPROVED', 'REJECTED', 'IN_REVIEW']);

export type QuotationStatusOption = z.infer<typeof QuotationStatusOptionSchema>;

export const quotationStatusOptions: Record<
  QuotationStatusOption,
  { value: string; label: string; color: string }
> = {
  APPROVED: { value: 'APPROVED', label: 'Approved', color: 'green.9' },
  REJECTED: { value: 'REJECTED', label: 'Rejected', color: 'red.5' },
  IN_REVIEW: { value: 'IN_REVIEW', label: 'In Review', color: 'gray.5' }
};
