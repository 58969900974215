import { useCurrencyList } from '@common/hooks/useSystem';

export enum ProtelConn {
  ON_PREM = 1,
  P_AIR = 2
}
export const useMasterList = () => {
  const { currencyList } = useCurrencyList();

  const otherSiteMinderRegions = [
    { label: 'Americas', value: 1 },
    { label: 'ANZ Pacific', value: 2 },
    { label: 'Asia', value: 3 },
    { label: 'Europe', value: 4 },
    { label: 'RDX', value: 6 },
    { label: 'RDX EMEA', value: 7 }
  ];

  const masterList = {
    siteminderRegionList: otherSiteMinderRegions,
    regionList: otherSiteMinderRegions,
    integration: [
      { label: 'One-Way', value: 1 },
      { label: 'Two-Way', value: 2 }
    ],
    protelConn: [
      { label: 'onPrem', value: ProtelConn.ON_PREM },
      { label: 'pAir', value: ProtelConn.P_AIR }
    ],
    publisherList: [],
    pricingType: [
      { label: 'Occupancy-based Pricing', value: 'occupancy price' },
      { label: 'Room-based Pricing', value: 'fixed price' }
    ],
    regionListRms: [
      { label: 'Southern Hemisphere', value: 1 },
      { label: 'Northern Hemisphere', value: 2 }
    ],
    regionListStayntouch: [
      { label: 'North America', value: 1 },
      { label: 'Europe', value: 2 },
      { label: 'United States', value: 3 }
    ],
    semperList: [
      { label: 'Occupancy Based', value: 'occupancy_based' },
      { label: 'Unit Based', value: 'unit_based' }
    ],
    verticalBookingList: [
      { label: 'Occupancy Based', value: 'occupancy_based' },
      { label: 'Unit Based', value: 'unit_based' }
    ],
    sabeeappList: [
      { label: 'Occupancy Based', value: 'occupancy_based' },
      { label: 'Unit Based', value: 'unit_based' }
    ],
    versionList: [
      { label: 'Newer versions', value: 1 },
      { label: 'Version 21.5.XX or older', value: 2 }
    ],

    guestLineRegions: [
      { label: 'Europe', value: 1 },
      { label: 'Asia', value: 2 }
    ],
    newbookRegions: [
      { label: 'OTHER', value: '0' },
      { label: 'AUSTRALIA', value: 1 },
      { label: 'ASIA PACIFIC', value: 2 },
      { label: 'EUROPE', value: 3 },
      { label: 'UNITED STATES', value: 4 }
    ],
    propertyList: [],
    evPropertyList: [],
    hlsPropertyList: [],
    sabeePropertyList: [],
    multiPropertyList: [],
    availability: [
      { label: 'Definite Available', value: '1' },
      { label: 'Tentative Available', value: '2' }
    ],
    operaAvailability: [
      { label: 'Definite Available', value: 'normal' },
      { label: 'Tentative Available', value: 'tentative' }
    ],
    currency: currencyList?.map((currency) => ({
      label: `${currency.name} (${currency.symbol})`,
      value: currency.id
    }))
  };

  return masterList;
};
