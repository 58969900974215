import { ModalFooter } from '@common/mantine/components/modal-footer';
import { Button, Center, Flex, Modal, Paper, Stack, Text, rem } from '@mantine/core';
import { Dropzone, FileWithPath } from '@mantine/dropzone';
import { Controller, useForm } from 'react-hook-form';
import { useImportRoom } from '@pages/Client/Account/Integrations/hooks/useImportRoom';
import { IconFileSpreadsheet } from '@tabler/icons-react';
import { useEffect } from 'react';

interface UploadRoomTypeModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const UploadRoomModal = ({ isOpen, onClose }: UploadRoomTypeModalProps) => {
  const { uploadFile, isSuccess } = useImportRoom();

  const { control, handleSubmit, watch, reset } = useForm({
    defaultValues: { roomType: [] as FileWithPath[] }
  });

  const roomType = watch('roomType');

  const onSubmit = (data: { roomType: FileWithPath[] }) => {
    if (data.roomType.length === 0) return;

    data.roomType.forEach((file) => {
      uploadFile({
        url: '/hotel/pms/upload-room-rate-csv/',
        file: file,
        fieldName: 'file'
      });
    });

    onClose();
    reset();
  };

  useEffect(() => {
    if (isSuccess) reset();
  }, [isSuccess, reset]);

  const handleClose = () => {
    onClose();
    reset();
  };

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      size="md"
      centered
      title="Upload Room Types and Rate Plans"
      withCloseButton
      styles={{ body: { padding: 0 } }}
    >
      <Paper
        radius={12}
        withBorder
        m="md"
        style={{ borderStyle: 'dashed', borderWidth: 1 }}
        bg="gray.0"
      >
        <Stack>
          <Controller
            control={control}
            name="roomType"
            render={({ field: { onChange } }) => (
              <Dropzone onDrop={onChange} maxSize={5 * 1024 ** 2} accept={['text/csv']}>
                <Stack
                  justify="center"
                  align="center"
                  mih={rem(200)}
                  className="hover:cursor-pointer"
                >
                  <Dropzone.Idle>
                    <IconFileSpreadsheet
                      style={{
                        width: rem(52),
                        height: rem(52),
                        color: 'var(--mantine-color-dark)'
                      }}
                      stroke={1}
                    />
                  </Dropzone.Idle>
                  <Center>
                    <Stack>
                      <Text c="dark" ta="center">
                        Choose a CSV file or drag and drop it here
                      </Text>
                    </Stack>
                  </Center>
                </Stack>
              </Dropzone>
            )}
          />

          {roomType.map((file) => (
            <Paper
              key={file.name}
              py="md"
              px="md"
              style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
            >
              <Flex gap={4} justify="center" align="center">
                <IconFileSpreadsheet
                  style={{
                    width: rem(28),
                    height: rem(28),
                    color: 'var(--mantine-color-indigo-6)'
                  }}
                  stroke={1.5}
                />
                <Text c="indigo" fw="bolder">
                  {file.name}
                </Text>
              </Flex>
            </Paper>
          ))}
        </Stack>
      </Paper>

      <ModalFooter>
        <Button type="button" variant="transparent" color="gray.8" onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleSubmit(onSubmit)}>Save</Button>
      </ModalFooter>
    </Modal>
  );
};

export default UploadRoomModal;
