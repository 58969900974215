import { Button } from '@common/components/atoms/Button';
import { Typography } from '@common/components/foundations/Typography';
import { Header } from '@common/components/molecules/Header/Header';
import { Table } from '@common/components/molecules/Table';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import { Page } from '@common/components/organisms/Page';
import { useModal } from '@common/hooks/useModal';
import { browserTimezone } from '@common/utils/browserTimezone';
import { DownloadJson } from '@common/utils/downloadJson';
import { useDocumentTitle } from '@mantine/hooks';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { ModalLogs } from '@pages/Client/Logs/SettingsLog/components/ModalLogs';
import { UsersLogDownloadHeader } from '@pages/Client/Logs/SettingsLog/components/UsersLogDownloadHeader';
import {
  useInventoryPrice,
  useSettingsLog
} from '@pages/Client/Logs/SettingsLog/hooks/useSettingsLog';
import { useTransformLog } from '@pages/Client/Logs/SettingsLog/hooks/useTransformLog';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import dayjs from 'dayjs';
import { useState } from 'react';

export const SettingsLogs = () => {
  useDocumentTitle('Settings Log');
  const { settingsLog, isLoading } = useSettingsLog();
  const { isOpen, openModal, closeModal } = useModal();
  const [objectLog, setObjectLog] = useState({} as any);
  const { inventoryPrice, isLoading: isInventoryLoading } = useInventoryPrice();
  const { hotelDetails } = useHotelDetails();
  const { transformLog } = useTransformLog();

  const { cachePriceQuery, pricingSettingsQuery } = useRoomPrices();
  const { data: cachePrice, isLoading: isCachePriceLoading } = cachePriceQuery;
  const { data: pricingSettings, isLoading: isPricingSettingsLoading } = pricingSettingsQuery;

  const columns = [
    {
      header: 'Date Modified',
      accessorKey: 'date_time',
      cell: (row: any) => {
        return (
          <div className="flex items-center">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <div className="flex flex-col">
                    <Typography element="span" variant="paragraph-3" color="copyTextGrey">
                      {dayjs(row?.getValue()).utc().format('YYYY-MM-DD HH:mm:ss [UTC]')}
                    </Typography>
                  </div>
                </TooltipTrigger>
                <TooltipContent side="bottom" sideOffset={10} className="w-full">
                  <table className="table-auto">
                    <tbody>
                      <tr>
                        <td className="pr-2">
                          <Typography element="span" variant="paragraph-3" color="white">
                            Hotel Time:
                          </Typography>
                        </td>
                        <td>
                          <Typography element="span" variant="paragraph-3" color="white">
                            {dayjs(row?.getValue())
                              .tz(hotelDetails?.timezone)
                              .format('YYYY-MM-DD HH:mm:ss')}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td className="pr-2">
                          <Typography element="span" variant="paragraph-3" color="white">
                            Browser Time:
                          </Typography>
                        </td>
                        <td>
                          <Typography element="span" variant="paragraph-3" color="white">
                            {dayjs(row?.getValue())
                              .tz(browserTimezone)
                              .format('YYYY-MM-DD HH:mm:ss')}
                          </Typography>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Email',
      accessorKey: 'email',
      cell: (row: any) => {
        return (
          <div className="flex items-center">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'User Name',
      accessorKey: 'username',
      cell: (row: any) => {
        return (
          <div className="flex items-center">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Change Log',
      accessorKey: 'data',
      cell: (row: any) => {
        const data = JSON.parse(row?.getValue());
        const transformedData = transformLog(data);

        return (
          <Button
            intent="text"
            className="flex items-center hover:cursor-pointer"
            onClick={() => {
              setObjectLog(transformedData);
              openModal();
            }}
          >
            <pre>{JSON.stringify(transformedData).slice(0, 50)}</pre>
          </Button>
        );
      },
      meta: {
        showOnMobile: true
      }
    }
  ];

  const handleDownloadInventory = () => {
    DownloadJson(inventoryPrice, 'inventory-price', hotelDetails?.id);
  };

  const handleDownloadSettings = () => {
    DownloadJson(pricingSettings, 'pricing-settings', hotelDetails?.id);
  };

  const handleDownloadPrice = () => {
    DownloadJson(cachePrice?.data, 'cache-price', hotelDetails?.id);
  };

  return (
    <Page
      header={
        <Header
          title="Settings Log"
          actions={
            <UsersLogDownloadHeader
              onDownloadPrice={handleDownloadPrice}
              downloadPriceDisabled={isCachePriceLoading && !hotelDetails}
              onDownloadSettings={handleDownloadSettings}
              downloadSettingsDisabled={isPricingSettingsLoading && !hotelDetails}
              onDownloadInventory={handleDownloadInventory}
              downloadInventoryDisabled={isInventoryLoading && !hotelDetails}
            />
          }
        />
      }
    >
      <Table
        skeletonCount={5}
        isFetching={isLoading}
        columns={columns}
        data={
          !isLoading
            ? settingsLog.sort((a: any, b: any) => {
                const dateA = dayjs(a.date_time, 'YYYY-MM-DD HH:mm:ss');
                const dateB = dayjs(b.date_time, 'YYYY-MM-DD HH:mm:ss');
                return dateB.diff(dateA); // Sort in descending order
              })
            : []
        }
        isHover
      />
      <ModalLogs isOpen={isOpen} onClose={closeModal} Logs={objectLog} />
    </Page>
  );
};
