import { CreateRatePlans, getRatePlansSchema } from '@common/api/integration/ratePlansSchema';
import { ModalFooter } from '@common/mantine/components/modal-footer';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Checkbox,
  Group,
  Modal,
  MultiSelect,
  MultiSelectProps,
  SimpleGrid,
  Stack,
  TextInput
} from '@mantine/core';
import { useCreatePmsRoomRate } from '@pages/Client/Account/Integrations/hooks/usePmsRoomRate';
import { usePmsRoomType } from '@pages/Client/Account/Integrations/hooks/usePmsRoomType';
import { useCreateRoomRatesStore } from '@pages/Client/Account/Integrations/store/createRoomRate';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { PmsProvider, usePmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { ChangeEvent, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const AddNewRoomRateModal = ({ isOpen, onClose }: ModalProps) => {
  const { t } = useTranslation();
  const { hotelDetails } = useHotelDetails();
  const { setCreateLoading } = useCreateRoomRatesStore();
  const { ROOMS_OPTIONS } = usePmsRoomType({
    ordering: undefined,
    page: 1
  });
  const pmsOrChannelManager = hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS';
  const { createRoomRate, isLoading } = useCreatePmsRoomRate();
  const { hasIndependentRates } = usePmsProvider();

  const roomRateSchema = getRatePlansSchema(hasIndependentRates(), 'create');

  const { handleSubmit, control, reset, setValue, watch } = useForm<z.infer<typeof roomRateSchema>>(
    {
      resolver: zodResolver(roomRateSchema),
      defaultValues: {
        name: '',
        rate_id: '',
        description: '',
        room_type_ids: [],
        other_rate: ''
      }
    }
  );

  const pmsProviderId = hotelDetails?.pms_provider ? hotelDetails?.pms_provider : 0;

  useEffect(() => {
    if (isLoading) {
      setCreateLoading(true);
    } else {
      setCreateLoading(false);
    }
  }, [isLoading]);

  const onSubmit = async (payload: CreateRatePlans) => {
    await createRoomRate(payload);
    reset();
    onClose();
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  const renderMultiSelectOption: MultiSelectProps['renderOption'] = ({ option, checked }) => (
    <Group gap="sm" wrap="nowrap">
      <Checkbox
        disabled={option.disabled}
        checked={checked}
        onChange={() => {}}
        aria-hidden
        tabIndex={-1}
        style={{ pointerEvents: 'none' }}
      />
      <span>{option.label}</span>
    </Group>
  );

  return (
    <Modal
      opened={isOpen}
      onClose={handleClose}
      title={t('Add Rate Plan')}
      size="lg"
      centered
      styles={{
        body: {
          padding: '0'
        }
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack px="md" pb="lg" gap="md">
          <SimpleGrid cols={2}>
            <Controller
              name="rate_id"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextInput
                  placeholder={`${t('Please enter Rate Plan Code')}`}
                  error={error?.message}
                  variant="filled"
                  label={t('Rate Plan Name')}
                  value={value}
                  onChange={onChange}
                  withAsterisk
                  required
                />
              )}
            />
            <Controller
              name="name"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextInput
                  placeholder={`${t('Please enter Rate Plan Name')}`}
                  error={error?.message}
                  variant="filled"
                  label={t('Rate Plan Name')}
                  value={value}
                  onChange={onChange}
                  withAsterisk
                  required
                />
              )}
            />
          </SimpleGrid>
          <SimpleGrid cols={2}>
            <Stack>
              <Controller
                name="room_type_ids"
                control={control}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <MultiSelect
                    label={t(`${pmsOrChannelManager} Room Type`)}
                    renderOption={renderMultiSelectOption}
                    placeholder={`${t(`Select ${pmsOrChannelManager} Room`)}`}
                    variant="filled"
                    disabled={hasIndependentRates()}
                    value={value?.map((val) => val.toString())}
                    onChange={(selectedStringValues) => {
                      const numericValues = selectedStringValues.map((str) => parseInt(str, 10));
                      onChange(numericValues);
                    }}
                    data={ROOMS_OPTIONS ?? []}
                    error={error?.message}
                    required={!hasIndependentRates()}
                    withAsterisk={!hasIndependentRates()}
                  />
                )}
              />
              <Checkbox
                label={t('Select All')}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  console.log(e.target.checked);
                  const allRoomTypes = ROOMS_OPTIONS?.map((roomType) => roomType.value.toString());
                  setValue('room_type_ids', e.target.checked ? allRoomTypes : []);
                }}
                checked={ROOMS_OPTIONS?.length === watch('room_type_ids')?.length}
              />
            </Stack>
            <Controller
              name="description"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextInput
                  placeholder={`${t('Please enter Description')}`}
                  error={error?.message}
                  variant="filled"
                  label={t('Description')}
                  value={value || ''}
                  onChange={onChange}
                />
              )}
            />
          </SimpleGrid>
          {pmsProviderId === PmsProvider.GUESTLINE ? (
            <Controller
              name="other_rate"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextInput
                  placeholder={`${t('Please enter Rate Flex Table')}`}
                  error={error?.message}
                  variant="filled"
                  label={t('Rate Flex Table')}
                  value={value || ''}
                  onChange={onChange}
                  withAsterisk
                  required
                />
              )}
            />
          ) : null}
        </Stack>
        <ModalFooter>
          <Button onClick={handleClose} variant="subtle">
            {t('Cancel')}
          </Button>
          <Button type="submit" loading={isLoading}>
            {t('Save')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
