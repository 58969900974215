import { useMemberShipPricing } from '@pages/Client/Account/Subscription/hooks/usePayment';
import { useGetAccountHotel } from '@pages/Client/hooks/useGetAccountHotel';

export enum HotelState {
  FREE_TRIAL = 0,
  SUBSCRIBED = 1,
  CANCELED = 2,
  FREE_TRIAL_EXPIRED = 3,
  SUSPENDED = 4,
  NOT_ACTIVE = 5,
  SUBSCRIBED_PARTNER = 6,
  CANCELED_PARTNER = 7,
  DEMO = 8,
  PAUSED = 9,
  SANDBOX = 10,
  NON_RENEWING = 11,
  SUBSCRIBED_PROPERTY = 12
}

export enum HotelPlan {
  DEFAULT_SUBSCRIPTION = 0,
  STARTER = 2,
  ADVANCED = 3,
  PROFESSIONAL = 4,
  SMALLBUSINESS = 5,
  PRO_LITE = 6
}

export enum SubscriptionType {
  MONTHLY = 1,
  YEARLY = 2
}

const UpgradeEnabled = new Set<HotelState>([
  HotelState.SUBSCRIBED,
  HotelState.SUBSCRIBED_PARTNER,
  HotelState.DEMO
]);

const SubscribedStates = new Set<HotelState>([
  HotelState.SUBSCRIBED,
  HotelState.SUBSCRIBED_PARTNER
]);

export const useSubscription = () => {
  const { data } = useGetAccountHotel();
  const { membershipPricing, isLoading } = useMemberShipPricing();

  const pricing = isLoading ? {} : membershipPricing ? membershipPricing : {};
  const hotelState = data?.hotel?.state;
  const hotelPlan = data?.hotel?.plan;
  const subscriptionType = data?.hotel?.subscription_type;
  const isFreeTrial = hotelState === HotelState.FREE_TRIAL;
  const isSubscribedPartner = hotelState === HotelState.SUBSCRIBED_PARTNER;
  const starterPricing = pricing[HotelPlan.STARTER];
  const advancedPricing = pricing[HotelPlan.ADVANCED];
  const professionalPricing = pricing[HotelPlan.PROFESSIONAL];

  let subscriptionPriceFreeTrial;

  if (isFreeTrial) {
    // More advanced logic will follow later,
    // advanced pricing is the base for the moment for free trial users
    subscriptionPriceFreeTrial = advancedPricing?.yearly?.price_per_month;
  }
  return {
    pricing,
    starterPricing,
    advancedPricing,
    professionalPricing,
    isMonthlySubscription: subscriptionType === SubscriptionType.MONTHLY,
    isFreeTrial,
    isSubscribedPartner,
    subscriptionPriceFreeTrial,
    hasUpgradeEnabled: () => {
      return UpgradeEnabled.has(hotelState as HotelState) && hotelPlan === HotelPlan.ADVANCED;
    },
    isSubscribed: () => {
      return SubscribedStates.has(hotelState as HotelState);
    }
  };
};
