import { create } from 'zustand';

// Should be mapped correctly with Backend
export enum Feature {
  DisableClientAccess = 19,
  PartnerSubscriptionPage = 10,
  SettingsCorrection = 5,
  BarLevels = 13,
  PickupBoostPrice = 22,
  AirBNB = 26,
  DashboardOnly = 31,
  ClosedRoomDetection = 29,
  StarterUpload = 24,
  SmallBusiness = 28,
  AdvancedUpload = 33,
  BulkEdits = 1,
  IndividualMinMax = 6,
  LeadTimeAdjustment = 7,
  OccupancyStrategy = 12,
  LimitedDashboard = 20,
  MonthlyGroupBookings = 21,
  UploadAllRuns = 34,
  YieldTag = 9,
  EighteenMonthsPricing = 23,
  ProfessionalDashboard = 25,
  Segmentation = 32,
  ProBulkEdits = 35,
  PickupCalendar = 30,
  LimitedMinimumStayRestrictions = 41,
  MinimumStayRestrictions = 36,
  SurgeProtectionPro = 40,
  SurgeProtection = 38,
  LimitedMarketInsights = 201,
  ProMarketInsights = 202,
  ReservationPipelineV2 = 203,
  PriceEvolution = 204,
  NoMarketData = 42
}

// Production RPG Users that have elevated access to specific features
export const AdvancedRMUsers = new Set([
  365, 633, 633, 1876, 1876, 2235, 2583, 2767, 2767, 2816, 11320, 13036, 22045, 30691, 41086, 1983,
  41086
]);

const ProFeatures = new Set<Feature>([
  Feature.UploadAllRuns,
  Feature.YieldTag,
  Feature.EighteenMonthsPricing,
  Feature.ProfessionalDashboard,
  Feature.PickupCalendar,
  Feature.Segmentation,
  Feature.ProBulkEdits,
  Feature.PriceEvolution,
  Feature.SurgeProtectionPro
]);

const SmallBusinessFeatures = new Set<Feature>([Feature.SmallBusiness]);

const StarterFeatures = new Set<Feature>([Feature.StarterUpload]);

const StarterUploadFeatures = new Set<Feature>([Feature.StarterUpload]);
const AdvancedUploadFeatures = new Set<Feature>([Feature.AdvancedUpload]);
const ProUploadFeatures = new Set<Feature>([Feature.UploadAllRuns]);

const AdvancedFeatures = new Set<Feature>([
  Feature.AdvancedUpload,
  Feature.BulkEdits,
  Feature.IndividualMinMax,
  Feature.LeadTimeAdjustment,
  Feature.OccupancyStrategy,
  Feature.LimitedDashboard,
  Feature.MonthlyGroupBookings
]);

const DashboardFeatures = new Set<Feature>([
  Feature.DashboardOnly,
  Feature.LimitedDashboard,
  Feature.ProfessionalDashboard
]);

const MarketInsightsFeatures = new Set<Feature>([
  Feature.ProMarketInsights,
  Feature.LimitedMarketInsights
]);

const ReportingFeatures = new Set<Feature>([
  Feature.ProfessionalDashboard,
  Feature.DashboardOnly,
  Feature.LimitedDashboard,
  Feature.ProMarketInsights,
  Feature.LimitedMarketInsights
]);

const UploadFeatures = new Set<Feature>([
  Feature.StarterUpload,
  Feature.AdvancedUpload,
  Feature.UploadAllRuns
]);

export const AdminAccessFeatures = new Set<Feature>([
  Feature.DashboardOnly,
  Feature.LimitedDashboard,
  Feature.ProfessionalDashboard,
  Feature.OccupancyStrategy,
  Feature.DisableClientAccess,
  Feature.PickupCalendar
]);

export const MinStayFeatures = new Set<Feature>([
  Feature.MinimumStayRestrictions,
  Feature.LimitedMinimumStayRestrictions
]);

type FeaturesStore = {
  isInitialised: boolean;
  features: (number | Feature)[] | undefined;
  setFeatures: (value: FeaturesStore['features']) => void;
  hasProFeature: () => boolean;
  hasAdvancedFeature: () => boolean;
  showLimitedDashboard: () => boolean;
  hasDashboardFeature: () => boolean;
  hasMarketInsightsFeature: () => boolean;
  hasReportingFeature: () => boolean;
  hasUploadFeature: () => boolean;
  hasSmallBusinessFeature: () => boolean;
  hasStarterFeature: () => boolean;
  hasStarterUploadFeature: () => boolean;
  hasAdvancedUploadFeature: () => boolean;
  hasProUploadFeature: () => boolean;
  hasMinStayFeature: () => boolean;
};

export const useFeaturesStore = create<FeaturesStore>((set, get) => ({
  isInitialised: false,
  features: [],
  setFeatures: (features) => set({ features, isInitialised: true }),
  hasUploadFeature: () => {
    const { features } = get();
    return features?.some((feature) => UploadFeatures.has(feature as Feature)) || false;
  },
  hasProFeature: () => {
    const { features } = get();
    return features?.some((feature) => ProFeatures.has(feature as Feature)) || false;
  },
  hasAdvancedFeature: () => {
    const { features } = get();
    return features?.some((feature) => AdvancedFeatures.has(feature as Feature)) || false;
  },
  hasSmallBusinessFeature: () => {
    const { features } = get();
    return features?.some((feature) => SmallBusinessFeatures.has(feature as Feature)) || false;
  },
  hasStarterFeature: () => {
    const { features } = get();
    return features?.some((feature) => StarterFeatures.has(feature as Feature)) || false;
  },
  showLimitedDashboard: () => {
    const { features } = get();
    return (
      (features?.includes(Feature.LimitedDashboard) &&
        !features?.includes(Feature.ProfessionalDashboard)) ||
      false
    );
  },
  hasDashboardFeature: () => {
    const { features } = get();
    return features?.some((feature) => DashboardFeatures.has(feature as Feature)) || false;
  },
  hasMarketInsightsFeature: () => {
    const { features } = get();
    return features?.some((feature) => MarketInsightsFeatures.has(feature as Feature)) || false;
  },
  hasReportingFeature: () => {
    const { features } = get();
    return features?.some((feature) => ReportingFeatures.has(feature as Feature)) || false;
  },
  hasStarterUploadFeature: () => {
    const { features } = get();
    return features?.some((feature) => StarterUploadFeatures.has(feature as Feature)) || false;
  },
  hasAdvancedUploadFeature: () => {
    const { features } = get();
    return features?.some((feature) => AdvancedUploadFeatures.has(feature as Feature)) || false;
  },
  hasProUploadFeature: () => {
    const { features } = get();
    return features?.some((feature) => ProUploadFeatures.has(feature as Feature)) || false;
  },
  hasMinStayFeature: () => {
    const { features } = get();
    return features?.some((feature) => MinStayFeatures.has(feature as Feature)) || false;
  }
}));
