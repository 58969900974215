import React from 'react';
import { PopoverClose } from '@radix-ui/react-popover';
import { Button } from '@common/components/atoms/Button';
import { Icon } from '@common/components/foundations/icons';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@common/components/molecules/Popoverv2/Popover';
import { Typography } from '@common/components/foundations/Typography';
import { ScrollArea, ScrollBar } from '@common/components/molecules/ScrollArea';
import { truncate } from 'lodash-es';
import { TooltipIcon } from '@common/components/molecules/TooltipIcon';

interface Option<TValue = unknown> {
  value: TValue;
  label: string;
  disabled?: boolean;
  renderAdjacent?: () => React.ReactElement | null;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

interface DropdownProps<TValue = unknown> {
  label?: string;
  selectedOption?: Option<TValue>;
  options: Option<TValue>[];
  onOptionClick: (option: Option<TValue>) => void;
  disableSelector?: (option: Option<TValue>) => boolean;
  minWidth?: number | string;
  labelTooltipContent?: string | React.ReactNode;
  disabled?: boolean;
  userflowId?: string;
}

const OPTIONS_THRESHOLD_FOR_SCROLL = 11;

export const Dropdown = <T,>({
  label,
  selectedOption,
  options,
  onOptionClick,
  disableSelector,
  minWidth,
  labelTooltipContent,
  disabled,
  userflowId
}: DropdownProps<T>) => {
  // Disables options menu when only one option;
  // aiming to keep logic here to prevent repeating conditions across pages.
  const isSingleOptionMode = options.length === 1;

  const renderOptions = () => (
    <div className="flex flex-col">
      {options.map((option) => (
        <PopoverClose
          type="button"
          key={`${option.value}`}
          className="rounded-md px-2 py-1.5 text-left text-meta-1 capitalize text-darkGrey hover:bg-mediumGrey-reduced hover:text-darkGrey disabled:cursor-not-allowed disabled:opacity-40"
          onClick={() => {
            onOptionClick(option);
          }}
          disabled={(option.disabled || disableSelector?.(option)) ?? false}
          onMouseEnter={option?.onMouseEnter}
          onMouseLeave={option?.onMouseLeave}
        >
          <div className="flex flex-row items-center">
            {truncate(option?.label, {
              length: 40
            })}
            {option?.renderAdjacent?.()}
          </div>
        </PopoverClose>
      ))}
    </div>
  );

  return (
    <div className="flex flex-col">
      {label ? (
        <div className="flex items-center">
          <Typography variant="meta-2" className="ml-4 leading-6">
            {label}
          </Typography>
          {labelTooltipContent ? (
            <TooltipIcon iconClassName="ml-1" content={labelTooltipContent} />
          ) : null}
        </div>
      ) : null}
      <Popover>
        <PopoverTrigger asChild>
          <Button
            data-userflow-id={userflowId}
            intent="text"
            disabled={disabled}
            fullWidth
            className="bg-white pl-4 pr-3 capitalize leading-4"
            title={selectedOption?.label}
            style={{ minWidth }}
          >
            <span className="truncate">{selectedOption?.label}</span>
            {!isSingleOptionMode ? <Icon.ChevronDown /> : <div className="h-6" />}
          </Button>
        </PopoverTrigger>

        {!isSingleOptionMode ? (
          <PopoverContent className="relative h-full w-full max-w-sm bg-white p-1" align="start">
            {options.length > OPTIONS_THRESHOLD_FOR_SCROLL ? (
              <>
                <ScrollArea className="h-96 w-80">
                  {renderOptions()}
                  <ScrollBar orientation="vertical" />
                </ScrollArea>
                <div className="pointer-events-none absolute bottom-0 left-0 right-0 h-4 rounded-md bg-gradient-to-b from-transparent to-white" />
              </>
            ) : (
              <div className="min-w-[180px] max-w-xs">{renderOptions()}</div>
            )}
          </PopoverContent>
        ) : null}
      </Popover>
    </div>
  );
};
