import { Group, Button, Stack, Modal, Flex, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { DatePicker, DatesProvider } from '@mantine/dates';
import { useState } from 'react';
import { useCleanPriceCache } from '@pages/Client/Calendar/hooks/useClearPriceCache';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import dayjs from 'dayjs';

export const ClearPriceCacheModal = ({ opened, close }: { opened: boolean; close: () => void }) => {
  const { cleanPriceCache } = useCleanPriceCache();

  const { t } = useTranslation();
  const { hotelDetails } = useHotelDetails();

  const [confirmDelete, setConfirmDelete] = useState(false);

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const handleChange = (date: Date | null) => {
    setSelectedDate(date);

    setConfirmDelete(false);
  };

  const handleDelete = () => {
    setConfirmDelete(false);
    if (!selectedDate) return;
    cleanPriceCache({ date: dayjs(selectedDate).format('YYYY-MM-DD') });
    setSelectedDate(null);
    close();
  };
  const twoWeeksFromNow = dayjs().subtract(14, 'days').toDate();
  const now = dayjs();

  return (
    <Modal.Root opened={opened} onClose={close}>
      <Modal.Overlay />
      <Modal.Content>
        <Stack p="md">
          <Text size="sm">{t('Please select the first date for pricing')}</Text>
          <Flex justify="center" pb="sm">
            <DatesProvider settings={{ timezone: hotelDetails?.timezone }}>
              <DatePicker
                variant="filled"
                minDate={twoWeeksFromNow}
                maxDate={now.toDate()}
                value={selectedDate}
                onChange={handleChange}
              />
            </DatesProvider>
          </Flex>
          {confirmDelete ? (
            <>
              <Text size="sm" c="red">
                {selectedDate
                  ? t(
                      `All pricing data before ${selectedDate.toLocaleDateString()} will be removed. Are you sure?`
                    )
                  : null}
              </Text>
              <Group justify="flex-end">
                <Button variant="transparent" onClick={() => setConfirmDelete(false)}>
                  Cancel
                </Button>
                <Button color="red" onClick={handleDelete}>
                  Delete
                </Button>
              </Group>
            </>
          ) : (
            <Flex justify="flex-end">
              <Button onClick={() => setConfirmDelete(true)} disabled={!selectedDate}>
                {t('Clear historical price data')}
              </Button>
            </Flex>
          )}
        </Stack>
      </Modal.Content>
    </Modal.Root>
  );
};
