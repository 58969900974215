import { Button } from '@common/components/atoms/Button';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Card } from '@common/components/molecules/Card';
import { PusherEventNames } from '@common/constants/pusher';
import { useModal } from '@common/hooks/useModal';
import { useNotificationsStore } from '@common/store/notifications';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePusherStore } from '@common/store/pusher';
import { useFutureDaily } from '@pages/Client/PricingStrategy/MarketIntelligence/hooks/useFutureDaily';
import { useMarketCode } from '@pages/Client/PricingStrategy/MarketIntelligence/hooks/useMarketCode';
import { AirbnbChart } from '@pages/Client/PricingStrategy/MarketIntelligence/pages/Airbnb/components/AirbnbChart';
import { ManualSearchModal } from '@pages/Client/PricingStrategy/MarketIntelligence/pages/Airbnb/components/ManualSearchModal';
import { useManualSearchAirdnaStore } from '@pages/Client/PricingStrategy/MarketIntelligence/pages/Airbnb/store/manualSearch';
import { SegmentedControl } from '@mantine/core';
import { YearlyMonthly } from '@common/types';
import { Skeleton } from '@mantine/core';
import { useBanners } from '@common/store/banner';
import { useViewStore } from '@common/store/view';
import { DownloadJson } from '@common/utils/downloadJson';

interface DataItem {
  date: string;
  value: number;
}

export const Airbnb = () => {
  const { isLoading, futureDaily, refetch: refetchFutureDaily } = useFutureDaily();
  const { id } = useParams();
  const [view, setView] = useState<string>(YearlyMonthly.Yearly);
  const [currentMonthIndex, setCurrentMonthIndex] = useState(0);
  const { getMarketCode, isLoading: isGetMarketCodeLoading } = useMarketCode();
  const { isOpen: manualOpen, openModal: manualClick, closeModal: manualClose } = useModal();
  const { channel, channelBind, channelUnbind } = usePusherStore();
  const { addNotification } = useNotificationsStore();
  const { isManualSearchLoading, isSuccess } = useManualSearchAirdnaStore();
  const { createBanner, removeBanner } = useBanners();
  const { view: authView } = useViewStore();

  const dataFutureDaily = futureDaily?.full_air_dna_data?.airdna_data
    ? futureDaily?.full_air_dna_data?.airdna_data
    : {};

  const data: DataItem[] = Object.entries(dataFutureDaily)?.map(([date, details]) => ({
    date: date,
    value: details.airdna_new_price_for_compset
  }));

  const groupedData: Record<string, DataItem[]> = data?.reduce(
    (result, item) => {
      const dateParts = dayjs(item.date).format('MMM YYYY').split(' ');
      const month = `${dateParts[0]} ${dateParts[1]}`; // Include the year in the month name
      if (!result[month]) {
        result[month] = [];
      }
      result[month].push(item);
      return result;
    },
    {} as Record<string, DataItem[]>
  );

  const months: string[] = Object.keys(groupedData);

  const handlePrevMonth = (): void => {
    if (currentMonthIndex > 0) {
      setCurrentMonthIndex(currentMonthIndex - 1);
    }
  };

  const handleNextMonth = (): void => {
    if (currentMonthIndex < months.length - 1) {
      setCurrentMonthIndex(currentMonthIndex + 1);
    }
  };

  const filteredData = useMemo(() => {
    return groupedData[months[currentMonthIndex]]?.map((item) => ({
      ...item,
      date: item.date
    }));
  }, [groupedData, currentMonthIndex]);

  // PusherIntegration
  const airbnbPusher = useCallback(() => {
    channelBind(PusherEventNames.CompetitorMessage, (data: any) => {
      if (data.success) {
        const message = data.message ?? `Success to fetch AirDNA Data`;
        addNotification('success', message);
        refetchFutureDaily();
      }
    });
  }, [channel]);

  useEffect(() => {
    channel && airbnbPusher();
    return () => {
      channelUnbind(PusherEventNames.CompetitorMessage);
    };
  }, [channel]);

  useEffect(() => {
    const totalRooms = futureDaily?.todays_total_rooms;
    if (totalRooms > 0 && totalRooms < 20 && !isLoading && authView === 'admin') {
      createBanner({
        id: 'issue-announcement',
        description: (
          <div>
            {`The Airbnb Market you selected has only ${totalRooms} rooms. We are hightly recommending to choose another market as 20 rooms are seen as minimum, > 50 rooms as recommended for good pricing.`}
          </div>
        ),
        type: 'warning',
        showAction: false
      });
      return () => removeBanner('issue-announcement');
    }
  }, [futureDaily?.todays_total_rooms, authView]);

  return (
    <Card backgroundColor="white" className="shadow-none">
      <div>
        <div className="my-2 flex items-center justify-between">
          <div className="flex gap-4">
            <div>
              <SegmentedControl
                onChange={(value) => {
                  setView(value);
                  setCurrentMonthIndex(0);
                }}
                data={[
                  { label: 'Monthly', value: YearlyMonthly.Monthly },
                  { label: 'Yearly', value: YearlyMonthly.Yearly }
                ]}
                value={view}
              />
            </div>
            {view === YearlyMonthly.Monthly ? (
              <div className="flex items-center gap-x-1 text-grey">
                <div className="mr-2 w-[80px]">
                  <Typography element="span" variant="meta-1" className="mx-2  text-meta-1-medium">
                    {months[currentMonthIndex]}
                  </Typography>
                </div>
                <Button icon onClick={handlePrevMonth} disabled={currentMonthIndex === 0}>
                  <Icon.ChevronLeft />
                </Button>
                <Button
                  intent="text"
                  onClick={() => setCurrentMonthIndex(0)}
                  className="hidden whitespace-nowrap md:block"
                >
                  <Typography element="span" variant="meta-1">
                    Current month
                  </Typography>
                </Button>
                <Button
                  icon
                  onClick={handleNextMonth}
                  disabled={currentMonthIndex === months.length - 1}
                >
                  <Icon.ChevronRight />
                </Button>
              </div>
            ) : null}
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex items-center justify-end gap-3">
              <Button
                intent="text"
                onClick={() => {
                  DownloadJson(dataFutureDaily, 'airbnb-data', parseInt(id ? id : '0'));
                }}
              >
                <Icon.Download className="h-4 w-4" />
                Download Log
              </Button>
              <Button
                intent="outline"
                onClick={manualClick}
                isLoading={isManualSearchLoading}
                isSuccess={isSuccess}
              >
                Manual Search
              </Button>
              <Button
                intent="primary"
                onClick={() => getMarketCode()}
                isLoading={isGetMarketCodeLoading}
              >
                Find Nearest City/Region
              </Button>
            </div>
            <Skeleton visible={isLoading}>
              <div className="flex items-center justify-end gap-3">
                <Typography>
                  Airbnb City or Region:{' '}
                  <span className="font-semibold text-darkGrey">{futureDaily?.city_name}</span>
                </Typography>
                <Typography>
                  Totals Rooms:{' '}
                  <span className="font-semibold text-darkGrey">
                    {futureDaily?.todays_total_rooms || '-'}
                  </span>
                </Typography>
              </div>
            </Skeleton>
          </div>
        </div>
      </div>
      <AirbnbChart
        data={view === YearlyMonthly.Monthly ? filteredData : data}
        isMonthly={view === YearlyMonthly.Monthly}
      />
      <ManualSearchModal
        isOpen={manualOpen}
        onClose={manualClose}
        onSaveSuccess={() => refetchFutureDaily()}
      />
    </Card>
  );
};
