import { useQuotationsListStore } from '@common/store/group-displacement-list-store';
import { TextInput, ActionIcon } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { IconX } from '@tabler/icons-react';
import { isEmpty } from 'lodash-es';
import { useState, useEffect } from 'react';

export function QuotationsListSearch() {
  const paramKey = 'search';
  const { params, setParams, setFilters } = useQuotationsListStore();
  const [value, setValue] = useState(params.search || '');
  const [debounced] = useDebouncedValue(value, 1000);

  useEffect(() => {
    if (params.search !== value) setValue(params.search || '');
  }, [params.search]);

  useEffect(() => {
    setFilters({ [paramKey]: isEmpty(debounced) ? undefined : debounced });
    setParams({ [paramKey]: isEmpty(debounced) ? undefined : debounced });
  }, [debounced]);

  return (
    <TextInput
      placeholder="Search"
      value={value}
      variant="filled"
      onChange={(event) => setValue(event.currentTarget.value)}
      rightSection={
        isEmpty(value) ? null : (
          <ActionIcon size="xs" p={2} onClick={() => setValue('')}>
            <IconX />
          </ActionIcon>
        )
      }
    />
  );
}
