import { RootRedirect } from '@common/routes/RootRedirect';
import { RouteObject } from 'react-router-dom';
import { AuthLayout } from '@pages/Auth/AuthLayout';
import OTPPage from '@pages/Auth/OTP';
import ResetPasswordPage from '@pages/Auth/ResetPassword';
import SetNewPassword from '@pages/Auth/SetNewPassword';
import SSOPage from '@pages/Auth/SSO';
import { Apaleo as SSOApaleo } from '@pages/Auth/SSO/Apaleo';
import LoginPage from '@pages/Auth/Login';
import InviteUserPage from '@pages/Auth/InviteUser';
import { Init as SSOInit } from '@pages/Auth/SSO/Init';
import { Connect as SSOConnect } from '@pages/Auth/SSO/Connect';
import { Properties as SSOProperties } from '@pages/Auth/SSO/Properties';
import { RegisterConfirmation as SSORegisterConfirmation } from '@pages/Auth/SSO/RegisterConfirmation';
import AcademySignup from '@pages/Auth/AcademySignup';

export const authRoutes: RouteObject[] = [
  {
    path: '/',
    element: <RootRedirect />
  },
  { path: '/academy-signup', element: <AcademySignup /> },
  {
    path: '/',
    element: <AuthLayout />,
    children: [
      { path: '/login', element: <LoginPage /> },
      { path: '/otp', element: <OTPPage /> },
      { path: '/get-new-password', element: <ResetPasswordPage /> },
      { path: '/reset-password/:token', element: <SetNewPassword /> },
      { path: '/sso', element: <SSOPage /> },
      { path: '/account/verify-invited-user', element: <InviteUserPage /> },
      { path: '/sso/init/:pms/:code', element: <SSOInit /> },
      { path: '/sso/connect/:id/:pms/:code', element: <SSOConnect /> },
      { path: '/sso/properties', element: <SSOProperties /> },
      { path: '/sso/register', element: <SSORegisterConfirmation /> }
    ]
  },
  { path: '/sso/apaleo', element: <SSOApaleo /> }
];
