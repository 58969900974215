import { Header } from '@common/components/molecules/Header/Header';
import { Page } from '@common/components/organisms/Page';
import { Flags } from '@common/constants/flags';
import { ActionIcon, Group, rem, Stack, Text, Tooltip } from '@mantine/core';
import { useDocumentTitle } from '@mantine/hooks';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { t } from 'i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import QuotationsTable from '@pages/Client/PricingStrategy/GroupDisplacement/components/QuotationsTable/QuotationsTable';
import { Icon } from '@common/components/foundations/icons';

export const GroupDisplacement = () => {
  useDocumentTitle('Group Displacement');
  const { hotelDetails } = useHotelDetails();
  const flags = useFlags();
  const navigate = useNavigate();

  useEffect(() => {
    if (hotelDetails && !(flags[Flags.ForeCastingV2] && flags[Flags.GroupDisplacementRollout]))
      navigate(`/client/${hotelDetails.id}/calendar`);
  }, [flags, hotelDetails]);

  return (
    <Page
      header={
        <Header
          title="Group Displacement"
          isBeta={true}
          titleAdditionalInfo={
            <Tooltip
              label={
                <>
                  <Text size="sm" pb="sm" c="gray.2">
                    {t(`
RoomPriceGenie uses historical pricing and occupancy trends to calculate a recommended group price. You can also override our price and see the impact on your projected revenue.`)}
                  </Text>
                  <Text size="sm" pb="sm" c="gray.2">
                    {t(
                      `We do not send these values to your PMS or channel manager. If you want to offer a group the price you see you will need to communicate with them directly`
                    )}
                  </Text>
                </>
              }
              maw={rem(600)}
              py="sm"
              px="xs"
              multiline
              position="bottom"
            >
              <ActionIcon>
                <Icon.Help className="h-4 w-4 fill-grey" />
              </ActionIcon>
            </Tooltip>
          }
        />
      }
    >
      <Group gap="sm" w={rem(900)}>
        <Text size="sm" pb="sm">
          {t(
            `Group displacement helps you determine the best price to offer for group reservations maximising your revenue. `
          )}
        </Text>
      </Group>
      <QuotationsTable />
    </Page>
  );
};
