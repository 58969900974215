import { useQuotationsListStore } from '@common/store/group-displacement-list-store';
import { Flex, Pill } from '@mantine/core';
import { isEqual, isNil, map, camelCase, isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Text } from '@mantine/core';

export function ActiveFiltersList() {
  const { t } = useTranslation();
  const { filters, setFilters, defaultFilters, setParams } = useQuotationsListStore();
  const hasChanges = !isEqual(JSON.stringify(filters), JSON.stringify(defaultFilters));

  function handleRemove(paramKey: string) {
    setFilters({ [paramKey]: undefined });
    setParams({ [paramKey]: undefined });
  }

  return hasChanges ? (
    <>
      <Text>
        {t('Filters')}
        <span className="sr-only">, active</span>
      </Text>
      <Flex gap="md" align="center" visibleFrom="md">
        {map(filters, (value, key) =>
          isNil(value) ? null : (
            <Pill withRemoveButton key={key} onRemove={() => handleRemove(key)}>
              {key.charAt(0).toUpperCase() + key.replace(/_/g, ' ').slice(1)} = {value}
            </Pill>
          )
        )}
      </Flex>
    </>
  ) : null;
}
