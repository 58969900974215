import { DayDetails } from '@common/api/pricingAlgorithm/types';
import { Table } from '@mantine/core';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { useTranslation } from 'react-i18next';

interface RoomInventory {
  dayData: DayDetails;
}

const RoomsHover = ({ dayData }: RoomInventory) => {
  const { t } = useTranslation();
  const { hotelRooms } = useHotelRoomsList();

  // Map room names with inventory data
  const mappedData = hotelRooms?.map((room) => {
    const inventoryData = dayData?.inventory?.[room.id];
    return {
      name: room.name,
      available: inventoryData?.available || 0,
      total: inventoryData?.total || 0
    };
  });
  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th c="white">{t('Room')}</Table.Th>
          <Table.Th c="white">{t('Rooms Available')}</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {mappedData.map((item) => (
          <Table.Tr key={item.name}>
            <Table.Td>{item.name}</Table.Td>
            <Table.Td ta="right" fw={500}>
              {item.available}/{item.total}
            </Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  );
};

export default RoomsHover;
