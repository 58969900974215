import { useEffect } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { truncate } from 'lodash-es';
import { useDocumentTitle } from '@mantine/hooks';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { usePriceChartStore } from '@pages/Client/PriceChart/store/priceChart';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { Skeleton } from '@mantine/core';
import { Typography } from '@common/components/foundations/Typography';
import { Chart } from '@pages/Client/PriceChart/components/Chart';
import { extractPriceByRoomIdAndKey } from '@pages/Client/PriceChart/utils/extractPriceByRoomIdAndKey';
import { extractRoomIdsByType } from '@pages/Client/PriceChart/utils/extractRoomIdsByType';
import { InputDataType } from '@common/api/roomPrices/types';
import { useSurgePrice } from '@pages/Client/Calendar/hooks/useSurgePrice';
import { useSurgeEvents } from '@pages/Client/PricingStrategy/SurgeProtection/hooks/useSurgeProtection';
import { Feature, useFeaturesStore } from '@common/store/features';
import { Dropdown } from '@common/components/molecules/Dropdown/Dropdown';
import { ChartUserflowIds } from '@common/types/userflow-ids';

export const PriceChart = () => {
  const { t } = useTranslation();
  const { view, liliusInstance } = usePriceChartStore();
  const { features } = useFeaturesStore();
  const { getSurgePriceForDay } = useSurgePrice();
  const { getSurgeEventByDay, surgeEvents } = useSurgeEvents();
  useDocumentTitle(t('Price Chart'));

  const isSurgeEvents =
    surgeEvents?.results.some((surgeEvent) => surgeEvent.active) &&
    features?.includes(Feature.SurgeProtection);

  // Hooks, store usage
  const { cachePriceQuery, pricingSettings } = useRoomPrices();
  const { data: cachePriceData, isLoading: isCachePriceLoading } = cachePriceQuery;

  const {
    hotelDetails,
    query: { isLoading: isLoadingHotel }
  } = useHotelDetails();

  const {
    sortedHotelRooms,
    selectedHotelRoom,
    hotelRooms,
    setSelectedHotelRoomId,
    query: { isFetching: isLoadingSortedHotelRooms }
  } = useHotelRoomsList();

  const roomTypeOptions = sortedHotelRooms.map((room) => ({
    label: `${truncate(room.name, { length: 30 })} (${
      room.is_reference_room ? t('Reference') : t('Derived')
    })`,
    value: room.id.toString()
  }));

  // Set default selected hotel room effect
  useEffect(() => {
    if (!selectedHotelRoom && hotelRooms) {
      if (import.meta.env.DEV) {
        console.log('Setting default selected hotel room...');
      }

      const refRoom = hotelRooms.find((room) => room.is_reference_room);
      setSelectedHotelRoomId(refRoom?.id);
    }
  }, [hotelRooms]);

  if (!selectedHotelRoom) return null;

  const roomData = extractRoomIdsByType(pricingSettings?.rooms as InputDataType);

  const minPrice = extractPriceByRoomIdAndKey(
    cachePriceData?.data.prices.data,
    pricingSettings?.dates,
    selectedHotelRoom?.id,
    'min_price',
    pricingSettings?.default?.[selectedHotelRoom?.id]?.min_price || 0,
    pricingSettings?.features || [],
    roomData
  );

  const maxPrice = extractPriceByRoomIdAndKey(
    cachePriceData?.data.prices.data,
    pricingSettings?.dates,
    selectedHotelRoom?.id,
    'max_price',
    pricingSettings?.default?.[selectedHotelRoom?.id]?.max_price || 0,
    pricingSettings?.features || [],
    roomData
  );

  return (
    <div className="grid grid-cols-1 gap-6">
      <div className="flex items-end justify-between">
        <div className="flex items-end">
          <Typography element="h3" variant="h6" className="font-medium" color="darkGrey">
            {t('Price Chart')}
          </Typography>
        </div>

        <div className="flex items-end justify-end gap-1">
          <Skeleton visible={isLoadingSortedHotelRooms || isLoadingHotel}>
            <Dropdown
              label={t(`${hotelDetails?.room_apartment_space_name} Type`) as string}
              selectedOption={roomTypeOptions.find(
                (option) => option.value === selectedHotelRoom?.id.toString()
              )}
              options={
                pricingSettings?.hotel.all_room_pricing ? roomTypeOptions : [roomTypeOptions[0]]
              }
              onOptionClick={(option) => setSelectedHotelRoomId(Number(option.value))}
              userflowId={ChartUserflowIds.ROOM_TYPE_SELECT}
            />
          </Skeleton>
        </div>
      </div>

      <Chart
        isLoading={isCachePriceLoading}
        priceRecommendation={
          cachePriceData?.data?.prices?.data
            ? Object.entries(cachePriceData?.data.prices.data).map(([key, value]) => {
                const { surgePrice, isSurgePrice } = getSurgePriceForDay(
                  key,
                  selectedHotelRoom?.id
                );
                const isSurgeEvent = getSurgeEventByDay(dayjs(key).toDate())?.active ?? false;
                return {
                  date: key,
                  value:
                    isSurgePrice && isSurgeEvent && surgePrice
                      ? surgePrice
                      : value[selectedHotelRoom?.id]?.price
                };
              })
            : []
        }
        priceInPms={
          cachePriceData?.data?.prices?.data
            ? Object.entries(cachePriceData?.data.prices.data).map(([key, value]) => {
                return {
                  date: key,
                  value: value[selectedHotelRoom?.id]?.original_price
                };
              })
            : []
        }
        basePrice={Math.round(pricingSettings?.default?.[selectedHotelRoom?.id]?.avg_price || 0)}
        closedPeriod={
          cachePriceData?.data?.prices?.data
            ? Object.entries(cachePriceData?.data.prices.data).map(([key, value]) => ({
                date: key,
                value: value.property?.closed_rooms === pricingSettings?.hotel.number_of_rooms
              }))
            : []
        }
        surgeEvent={
          isSurgeEvents
            ? surgeEvents?.results.map((surgeEvent) => {
                return {
                  date: surgeEvent.date,
                  value: surgeEvent.active
                };
              }) ?? []
            : []
        }
        occupancy={
          cachePriceData?.data?.prices?.latest_actual_occupancy
            ? Object.entries(cachePriceData?.data.prices.latest_actual_occupancy).map(
                ([key, value]) => {
                  return {
                    date: key,
                    value: value
                  };
                }
              )
            : []
        }
        isMonthly={view === 'monthly'}
        currentMonth={dayjs(liliusInstance?.viewing).tz().format('YYYY-MM')}
        minPrice={minPrice}
        maxPrice={maxPrice}
      />
    </div>
  );
};
