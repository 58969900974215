import { Controller, useForm } from 'react-hook-form';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash-es';
import { API_DATE_FORMAT } from '@common/constants/date';
import { RevenueBudget } from '@common/api/hotel/types';
import { PerformanceFilter, useDashboardPageStore } from '@pages/Client/Dashboard/store/dashboard';
import { usePerformanceDashboardReservationKPI } from '@pages/Client/Dashboard/hooks/usePerformanceDashboardReservationKPI';
import { usePerformanceFilter } from '@pages/Client/Dashboard/hooks/usePerformanceFilter';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';
import { useUpdateForecastingCache } from '@pages/Client/Dashboard/hooks/useUpdateForecastingCache';
import { useRunPricingFetchInventoryStatus } from '@pages/Client/Logs/SettingsLog/hooks/useSettingsLog';
import { savePricingSettings } from '@common/api/hotel';
import { useNotificationsStore } from '@common/store/notifications';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { KPIData } from '@common/api/dashboard/types';
import { Modal } from '@common/components/molecules/Modal';
import { Typography } from '@common/components/foundations/Typography';
import { Button } from '@common/components/atoms/Button';
import { Icon } from '@common/components/foundations/icons';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { formattedCurrency } from '@pages/Client/Dashboard/utils/formattedCurrency';
import { LineTable } from '@common/components/molecules/LineTable/LineTable';
import { PricingQueryKeys } from '@common/types/query-keys';
import { useBudget } from '@pages/Client/Dashboard/hooks/useBudget';
import { useResourceCenter } from '@common/hooks/useResourceCenter';
import { SetBudgetInput } from '@pages/Client/Dashboard/components/SetBudgetInput';

type PerformanceFilterKey =
  | 'grossValuesExclExtraServices'
  | 'grossValuesInclExtraServices'
  | 'netValuesExclExtraServices'
  | 'netValuesInclExtraServices';

const revenueBudget = {
  grossValuesExclExtraServices: 'revenue_budget_gross_excl_services',
  grossValuesInclExtraServices: 'revenue_budget_gross_incl_services',
  netValuesExclExtraServices: 'revenue_budget_net_excl_services',
  netValuesInclExtraServices: 'revenue_budget_net_incl_services'
};

const allMonths = (year: string) => {
  const months = [];
  for (let i = 0; i < 12; i++) {
    months.push(dayjs().year(parseInt(year)).tz().month(i).format(API_DATE_FORMAT));
  }
  return months;
};

type Props = {
  open: boolean;
  onClose: () => void;
};

export type BudgetValues = Record<string, Record<string, Record<keyof RevenueBudget, number>>>;

export const SetBudgetForm: React.FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { liliusInstance, performanceFilter, setPerformanceFilter } = useDashboardPageStore();
  const {
    performanceDashboardReservationKPI: { getAllYears, getKPITable },
    query: {
      refetch: refetchPerformanceDashboardReservationKPI,
      isSuccess: isPerformanceDashboardReservationKPISuccess,
      isLoading: isPerformanceDashboardReservationKPILoading
    }
  } = usePerformanceDashboardReservationKPI();
  const {
    pricingSettings,
    pricingSettingsQuery: {
      refetch: refetchPricingSettings,
      isSuccess: isPricingSettingsSuccess,
      isLoading: isPricingSettingsLoading
    }
  } = usePricingSettings();
  const {
    query: { refetch: refetchBudget }
  } = useBudget();
  const { performanceOptions } = usePerformanceFilter({ isSetBudgetForm: true });
  const hasUpdatedCache = useRef(false);
  const { updateForecastingCache } = useUpdateForecastingCache();
  const { refetch: runPricingFetchInventoryStatus, isSuccess } =
    useRunPricingFetchInventoryStatus();

  useResourceCenter({ shouldHideResourceCenter: open });

  if (isSuccess && !hasUpdatedCache.current) {
    updateForecastingCache();
    hasUpdatedCache.current = true;
  }

  const { mutateAsync, isPending: isLoading } = useMutation({
    mutationKey: [PricingQueryKeys.UPDATE_BUDGET_VALUES],
    mutationFn: savePricingSettings,
    onSuccess: async () => {
      await refetchPricingSettings();
      await runPricingFetchInventoryStatus();
      await refetchBudget();
      onClose();
      addNotification('success', t('Budget updated successfully'));
    },
    onError: (error) => {
      console.log(error);
      addNotification('fail', t('Budget could not be updated'));
    }
  });
  const { addNotification } = useNotificationsStore();
  const { hotelDetails } = useHotelDetails();

  const revenueBudgetValues = (): BudgetValues => {
    const allYears = getAllYears();
    const yearsWithAllMonths = allYears.reduce((acc, year) => {
      const monthsInYear = allMonths(year.toString()).reduce(
        (monthsAcc, month) => {
          const monthFormatted = dayjs(month).tz().format('YYYY-MM');

          const monthRevenueBudget = Object.values(revenueBudget).reduce(
            (revenueBudgetAcc, key): Record<keyof RevenueBudget, number> => {
              const newRevenueBudgetAcc = {
                ...revenueBudgetAcc
              };
              newRevenueBudgetAcc[key as keyof RevenueBudget] =
                pricingSettings?.revenue_budget?.[key as keyof RevenueBudget]?.[monthFormatted] ||
                0;
              return newRevenueBudgetAcc;
            },
            {} as Record<keyof RevenueBudget, number>
          );
          monthsAcc[monthFormatted] = monthRevenueBudget;
          return monthsAcc;
        },
        {} as Record<string, Record<keyof RevenueBudget, number>>
      );

      acc[year] = monthsInYear;
      return acc;
    }, {} as BudgetValues);

    return yearsWithAllMonths;
  };

  const [defaultValues, setDefaultValues] = useState(revenueBudgetValues);

  useEffect(() => {
    if (!open) return;

    refetchPerformanceDashboardReservationKPI();
    refetchPricingSettings();

    if (!isPerformanceDashboardReservationKPILoading && !isPricingSettingsLoading) {
      const allQueriesSuccessful =
        isPerformanceDashboardReservationKPISuccess && isPricingSettingsSuccess;

      allQueriesSuccessful
        ? resetFormAndUpdateDefaultValues(revenueBudgetValues())
        : addNotification('fail', t('Something went wrong.'));
    }
  }, [
    open,
    isPerformanceDashboardReservationKPISuccess,
    isPricingSettingsSuccess,
    isPerformanceDashboardReservationKPILoading,
    isPricingSettingsLoading
  ]);

  const { control, reset, watch, formState, getValues, setValue, trigger, setError, clearErrors } =
    useForm<BudgetValues>({
      values: defaultValues,
      resetOptions: {
        keepDirty: true,
        keepDirtyValues: true
      }
    });

  const { dirtyFields, errors } = formState;

  const resetFormAndUpdateDefaultValues = (newDefaultValues: BudgetValues) => {
    setDefaultValues(newDefaultValues);
    reset(newDefaultValues);
  };

  const budgetValues = watch();

  useEffect(() => {
    if (performanceFilter) {
      const updatedValues: BudgetValues = {};

      Object.entries(defaultValues).forEach(([year, months]) => {
        Object.entries(months).forEach(([month, budget]) => {
          const budgetKey = revenueBudget[
            performanceFilter as PerformanceFilterKey
          ] as keyof RevenueBudget;

          if (!updatedValues[year]) {
            updatedValues[year] = {};
          }
          if (!updatedValues[year][month]) {
            updatedValues[year][month] = {} as Record<keyof RevenueBudget, number>;
          }

          // Check if the value is not in dirtyFields
          if (!dirtyFields[year]?.[month]?.[budgetKey]) {
            updatedValues[year][month][budgetKey] = budget[budgetKey];
          }
        });
      });

      // Update values without resetting
      Object.entries(updatedValues).forEach(([year, months]) => {
        Object.entries(months).forEach(([month, budget]) => {
          Object.entries(budget).forEach(([budgetKey, value]) => {
            const field = `${year}.${month}.${budgetKey}`;
            setValue(field, value as any, {
              shouldDirty: true,
              shouldValidate: false
            });
          });
        });
      });

      // Manually trigger validation for all fields after updating
      trigger();
    }
  }, [performanceFilter]);

  const allYears = getAllYears();
  const minYear = allYears[0];

  const viewingDate = liliusInstance?.viewing;
  const viewingYear = viewingDate?.getFullYear();

  const toCurrentYear = () => {
    liliusInstance?.viewYear(new Date().getFullYear());
  };

  const toNextYear = () => {
    liliusInstance?.viewNextYear();
  };

  const toPreviousYear = () => {
    liliusInstance?.viewPreviousYear();
  };

  const prevButtonDisabled = viewingYear ? viewingYear <= minYear : false;

  const transformBudgetValues = (budget: BudgetValues) => {
    return Object.values(revenueBudget).reduce(
      (acc, key) => {
        const transformedBudgets: { [key: string]: number } = {};

        // Iterate over each year in the budget object
        Object.keys(budget).forEach((year) => {
          const yearBudget = budget[year];
          Object.entries(yearBudget).forEach(([monthFormatted, monthBudget]) => {
            transformedBudgets[monthFormatted] = Number(
              monthBudget[key as keyof typeof monthBudget]
            );
          });
        });

        return {
          ...acc,
          [key]: transformedBudgets
        };
      },
      {} as { [key: string]: { [key: string]: number } }
    );
  };

  const handleClose = async () => {
    resetFormAndUpdateDefaultValues(defaultValues);
    return onClose();
  };

  const handleOK = async () => {
    const transformedBudgetValues = transformBudgetValues(budgetValues);

    const { data: latestPricingSettings } = await refetchPricingSettings();

    const updatedSettings = {
      ...latestPricingSettings,
      revenue_budget: {
        ...latestPricingSettings?.revenue_budget,
        ...transformedBudgetValues
      }
    };
    await mutateAsync(JSON.stringify(updatedSettings));
  };

  const otbRevenue: Record<PerformanceFilter, keyof KPIData<string>> = {
    grossValuesExclExtraServices: 'REVENUE',
    grossValuesInclExtraServices: 'REVENUE_INCL_SERVICES',
    netValuesExclExtraServices: 'REVENUE_NET',
    netValuesInclExtraServices: 'REVENUE_NET_INCL_SERVICES'
  };

  useEffect(() => {
    // If there is no available performance option for set budget form,
    // set the first option as performance filter
    if (open && !performanceOptions.find((option) => performanceFilter === option.value)) {
      setPerformanceFilter(performanceOptions[0].value);
    }
  }, [open, performanceFilter]);

  return (
    <Modal
      open={open}
      size="full"
      onClose={handleClose}
      cancelText={t('Cancel') as string}
      okText={t('Save') as string}
      disableOkBtn={!isEmpty(errors)}
      onClick={handleOK}
      isLoading={
        isLoading || (!isPerformanceDashboardReservationKPISuccess && !isPricingSettingsSuccess)
      }
      disableCloseOnOk={true}
    >
      <div className="mt-1 flex flex-col justify-between gap-y-4 px-4 md:flex-col md:px-8">
        <div className="flex">
          <div className="flex flex-col gap-y-1">
            <Typography variant="h5" className="text-meta-1 font-medium" color="darkGrey">
              {t('Set Budget for')} {hotelDetails?.name}
            </Typography>
          </div>
        </div>
        <div className="flex flex-col flex-wrap gap-4 md:flex-row md:items-center">
          <div className="flex flex-1 items-center gap-x-4">
            <div
              role="button"
              className={clsx('min-w-[40px] cursor-pointer md:min-w-[60px]')}
              onClick={toCurrentYear}
            >
              <Typography variant="h5" className="text-meta-1 font-medium" color="darkGrey">
                {dayjs(viewingDate).tz().format('YYYY')}
              </Typography>
            </div>
            <div className="flex gap-x-1 text-grey">
              <Button icon onClick={toPreviousYear} disabled={prevButtonDisabled}>
                <Icon.ChevronLeft />
              </Button>
              <Button
                intent="text"
                onClick={toCurrentYear}
                className="hidden whitespace-nowrap md:block"
              >
                <Typography element="span" variant="meta-1">
                  {t('Current Year')}
                </Typography>
              </Button>
              <Button icon onClick={toNextYear}>
                <Icon.ChevronRight />
              </Button>
            </div>
            <div className="flex-1" />
          </div>
          <div className="w-fit">
            <SelectDropdown
              name="performanceFilter"
              fullWidth
              value={performanceOptions.find((option) => performanceFilter === option.value)?.value}
              onChange={(option) => {
                setPerformanceFilter(option);
              }}
              options={performanceOptions.map((option) => ({
                label: t(option.label),
                value: option.value
              }))}
            />
          </div>
        </div>
        <div className="flex flex-wrap gap-2">
          <div className="flex items-center gap-1">
            <Typography variant="paragraph-1" className="font-medium">
              {formattedCurrency(
                getKPITable('yearly', 'total', dayjs(viewingDate).tz().format('YYYY'))?.[
                  otbRevenue[performanceFilter]
                ] ?? 0
              )}
            </Typography>
            <Typography variant="paragraph-2" className="font-light">
              {t('OTB Revenue')}
            </Typography>
          </div>
          <div className="flex items-center gap-1">
            <Typography variant="paragraph-1" className="font-medium">
              {formattedCurrency(
                Math.round(
                  allMonths(dayjs(viewingDate).tz().format(API_DATE_FORMAT)).reduce((acc, curr) => {
                    const budgetValue = getValues(
                      `${dayjs(viewingDate).tz().format('YYYY')}.${dayjs(curr)
                        .tz()
                        .format('YYYY-MM')}.${
                        revenueBudget[performanceFilter as PerformanceFilterKey]
                      }`
                    );

                    return acc + parseInt(budgetValue);
                  }, 0)
                )
              )}
            </Typography>
            <Typography variant="paragraph-2" className="font-light">
              {t('Budget')}
            </Typography>
          </div>
          <div className="flex items-center gap-1">
            <Typography variant="paragraph-1" className="font-medium">
              {formattedCurrency(
                Math.round(
                  allMonths(dayjs(viewingDate).tz().format(API_DATE_FORMAT)).reduce((acc, curr) => {
                    const budgetValue = getValues(
                      `${dayjs(viewingDate).tz().format('YYYY')}.${dayjs(curr)
                        .tz()
                        .format('YYYY-MM')}.${
                        revenueBudget[performanceFilter as PerformanceFilterKey]
                      }`
                    );
                    return acc + parseFloat(budgetValue);
                  }, 0) / allMonths(dayjs(viewingDate).tz().format(API_DATE_FORMAT)).length
                )
              )}
            </Typography>
            <Typography variant="paragraph-2" className="font-light">
              {t('Average Budget')}
            </Typography>
          </div>
        </div>

        <LineTable>
          <thead>
            <tr>
              <th scope="col">{t('Month')}</th>
              <th scope="col">{t('Days')}</th>
              <th scope="col">{t('OTB Revenue')}</th>
              <th scope="col">{t('Last Year Revenue')}</th>
              <th scope="col">{t('Revenue Budget')}</th>
              <th scope="col">{t('ADR Budget')}</th>
            </tr>
          </thead>
          <tbody>
            {allMonths(dayjs(viewingDate).format(API_DATE_FORMAT)).map((month) => {
              const monthFormatted = dayjs(month).tz().format('YYYY-MM');
              const monthFormattedMonth = dayjs(month).tz().format('MM');
              const daysInMonth = dayjs(month).tz().daysInMonth();
              const previousYearMonth = dayjs(month).tz().subtract(1, 'year').format('YYYY-MM');

              const budgetValueQueryString = `${dayjs(viewingDate)
                .tz()
                .format('YYYY')}.${monthFormatted}.${
                revenueBudget[performanceFilter as PerformanceFilterKey]
              }`;

              const otbRevenueCellValue = Math.round(
                getKPITable('monthly', 'total', monthFormatted)?.[otbRevenue[performanceFilter]] ??
                  0
              );
              const lyRevenueCellValue = Math.round(
                getKPITable('monthly', 'total', previousYearMonth)?.[
                  otbRevenue[performanceFilter]
                ] ?? 0
              );
              const budgetValue = watch(budgetValueQueryString) as unknown as number;

              const adrBudgetCellValue = Math.round(
                budgetValue /
                  ((pricingSettings?.hotel.number_of_rooms || 1) *
                    ((pricingSettings?.monthly_target_occupancy || [])[
                      Number(monthFormattedMonth) - 1
                    ] / 100 || 0) *
                    daysInMonth)
              );

              return (
                <tr key={month}>
                  <td>{dayjs(month).tz().format('MMM YYYY')}</td>
                  <td>{daysInMonth}</td>
                  <td>{formattedCurrency(otbRevenueCellValue)}</td>
                  <td>{formattedCurrency(lyRevenueCellValue)}</td>
                  <td className="w-48">
                    <Controller
                      name={budgetValueQueryString}
                      control={control}
                      render={({ field: { onChange }, fieldState: { isDirty, error } }) => {
                        return (
                          <SetBudgetInput
                            fieldKey={budgetValueQueryString}
                            value={budgetValue}
                            onChange={onChange}
                            isDirty={isDirty}
                            disabled={isEmpty(allYears)}
                            error={error}
                            setError={setError}
                            clearErrors={clearErrors}
                          />
                        );
                      }}
                    />
                  </td>
                  <td>{formattedCurrency(adrBudgetCellValue)}</td>
                </tr>
              );
            })}
          </tbody>
        </LineTable>
      </div>
    </Modal>
  );
};
