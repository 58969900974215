import { getRatePlansSchema, UpdateRatePlans } from '@common/api/integration/ratePlansSchema';
import { ModalFooter } from '@common/mantine/components/modal-footer';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Checkbox,
  Group,
  Modal,
  MultiSelect,
  MultiSelectProps,
  Select,
  SimpleGrid,
  Stack,
  TextInput
} from '@mantine/core';
import { useUpdatePmsRoomRate } from '@pages/Client/Account/Integrations/hooks/usePmsRoomRate';
import { usePmsRoomType } from '@pages/Client/Account/Integrations/hooks/usePmsRoomType';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { PmsProvider, usePmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { ChangeEvent, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedRoomRate: UpdateRatePlans;
};

export const UpdateRoomRateModal = ({ isOpen, onClose, selectedRoomRate }: ModalProps) => {
  const { t } = useTranslation();
  const { hotelDetails } = useHotelDetails();
  const { ROOMS_OPTIONS } = usePmsRoomType({
    ordering: undefined,
    page: 1
  });
  const pmsOrChannelManager = hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS';
  const { updateRoomRate, isLoading } = useUpdatePmsRoomRate();
  const { hasIndependentRates } = usePmsProvider();

  const roomRateSchema = getRatePlansSchema(hasIndependentRates(), 'update');

  const { handleSubmit, control, setValue, reset, watch } = useForm<z.infer<typeof roomRateSchema>>(
    {
      resolver: zodResolver(roomRateSchema)
    }
  );

  const pmsProviderId = hotelDetails?.pms_provider ? hotelDetails?.pms_provider : 0;

  useEffect(() => {
    if (!selectedRoomRate.id) return;
    setValue('name', selectedRoomRate.name || '');
    setValue('rate_id', selectedRoomRate.rate_id || '');
    setValue('description', selectedRoomRate.description);
    if (selectedRoomRate.room_type_ids?.length) {
      setValue(
        'room_type_ids',
        selectedRoomRate?.room_type_ids?.map((item) => item.toString())
      );
    }
    setValue('other_rate', selectedRoomRate.other_rate);
    setValue('extra', selectedRoomRate.extra);
  }, [selectedRoomRate]);

  const onSubmit = async (payload: UpdateRatePlans) => {
    await updateRoomRate({
      ...payload,
      id: selectedRoomRate.id
    });
    reset();
    onClose();
  };

  const handleClose = () => {
    reset();
    setValue('room_type_ids', []);
    onClose();
  };
  const renderMultiSelectOption: MultiSelectProps['renderOption'] = ({ option, checked }) => (
    <Group gap="sm" wrap="nowrap">
      <Checkbox
        disabled={option.disabled}
        checked={checked}
        onChange={() => {}}
        aria-hidden
        tabIndex={-1}
        style={{ pointerEvents: 'none' }}
      />
      <span>{option.label}</span>
    </Group>
  );

  return (
    <Modal
      opened={isOpen}
      onClose={handleClose}
      title={`${t('Edit')} ${selectedRoomRate?.name ? `${selectedRoomRate.name}` : ''}`}
      size={pmsProviderId === PmsProvider.SITEMINDER_NEW ? 'auto' : 'lg'}
      centered
      styles={{
        body: {
          padding: '0'
        }
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack px="md" pb="lg" gap="md">
          {pmsProviderId === PmsProvider.SITEMINDER_NEW ? (
            <Controller
              name="extra"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextInput
                  placeholder={`${t('Please enter Rate Plan Code')}`}
                  error={error?.message}
                  variant="filled"
                  label={t('Rate Plan Code')}
                  value={value || ''}
                  onChange={onChange}
                  withAsterisk
                  required
                />
              )}
            />
          ) : (
            <>
              <SimpleGrid cols={2}>
                <Controller
                  name="rate_id"
                  control={control}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <TextInput
                      placeholder={`${t('Please enter Rate Plan Code')}`}
                      error={error?.message}
                      variant="filled"
                      label={t('Rate Plan Code')}
                      value={value}
                      onChange={onChange}
                      withAsterisk
                      required
                    />
                  )}
                />
                <Controller
                  name="name"
                  control={control}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <TextInput
                      placeholder={`${t('Please enter Rate Plan Name')}`}
                      error={error?.message}
                      variant="filled"
                      label={t('Rate Plan Name')}
                      value={value}
                      onChange={onChange}
                      withAsterisk
                      required
                    />
                  )}
                />
              </SimpleGrid>
              <SimpleGrid cols={2}>
                <Controller
                  name="room_type_ids"
                  control={control}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <>
                      {hasIndependentRates() ? ( // Single select when hasIndependentRates is true
                        <Select
                          label={t(`${pmsOrChannelManager} Room Type`)}
                          placeholder={`${t(`Select ${pmsOrChannelManager} Room`)}`}
                          variant="filled"
                          disabled={false}
                          value={value ? value[0]?.toString() : null}
                          onChange={(selectedValue) => {
                            if (!selectedValue) return;
                            onChange([parseInt(selectedValue, 10)]);
                          }}
                          data={ROOMS_OPTIONS ?? []}
                          error={error?.message}
                          w="100%"
                          required
                          withAsterisk
                        />
                      ) : (
                        // Multi select when hasIndependentRates is false
                        <Stack>
                          <MultiSelect
                            label={t(`${pmsOrChannelManager} Room Type`)}
                            renderOption={renderMultiSelectOption}
                            placeholder={`${t(`Select ${pmsOrChannelManager} Room`)}`}
                            variant="filled"
                            value={value?.map((val) => val.toString())}
                            onChange={(selectedStringValues) => {
                              const numericValues = selectedStringValues.map((str) =>
                                parseInt(str, 10)
                              );
                              onChange(numericValues);
                            }}
                            data={ROOMS_OPTIONS ?? []}
                            error={error?.message}
                            w="100%"
                            required={!hasIndependentRates()}
                            withAsterisk={!hasIndependentRates()}
                          />
                          <Checkbox
                            label={t('Select All')}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              console.log(e.target.checked);
                              const allRoomTypes = ROOMS_OPTIONS?.map((roomType) =>
                                roomType.value.toString()
                              );
                              setValue('room_type_ids', e.target.checked ? allRoomTypes : []);
                            }}
                            checked={ROOMS_OPTIONS?.length === watch('room_type_ids')?.length}
                          />
                        </Stack>
                      )}
                    </>
                  )}
                />

                <Controller
                  name="description"
                  control={control}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <TextInput
                      placeholder={`${t('Please enter Description')}`}
                      error={error?.message}
                      variant="filled"
                      label={t('Description')}
                      value={value || ''}
                      onChange={onChange}
                    />
                  )}
                />
              </SimpleGrid>
            </>
          )}
          {pmsProviderId === PmsProvider.GUESTLINE ? (
            <Controller
              name="other_rate"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextInput
                  placeholder={`${t('Please enter Rate Flex Table')}`}
                  error={error?.message}
                  variant="filled"
                  label={t('Rate Flex Table')}
                  value={value || ''}
                  onChange={onChange}
                  withAsterisk
                  required
                />
              )}
            />
          ) : null}
        </Stack>
        <ModalFooter>
          <Button onClick={handleClose} variant="subtle">
            {t('Cancel')}
          </Button>
          <Button type="submit" loading={isLoading}>
            {t('Save')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
