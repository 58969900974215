export function UserGroup(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill={props.fill || 'currentColor'}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="924 578 200 200"
      {...props}
    >
      <g>
        <path d="M984.585 638.942c0 13.999-9.609 25.348-21.462 25.348s-21.459-11.349-21.459-25.348 9.607-25.346 21.459-25.346 21.462 11.348 21.462 25.346zM987.585 683.641c1.55-.945 3.265-1.561 5.041-1.855-3.606-5.088-6.161-10.546-7.637-17.078-.404-2.387-3.672-2.667-6.102-.687-4.546 3.706-9.849 6.186-15.765 6.186-6.03 0-11.577-2.399-16.024-6.414-1.419-1.282-3.51-1.476-5.143-.479-8.443 5.158-14.834 13.344-17.622 23.067-.749 2.605-.223 5.42 1.411 7.588 1.636 2.166 4.192 3.443 6.906 3.443h38.668c7.279 0 12.742-4.662 18.917-8.431z" />
        <path d="M1063.416 638.942c0 13.999 9.608 25.348 21.461 25.348s21.46-11.349 21.46-25.348-9.606-25.346-21.46-25.346-21.461 11.348-21.461 25.346zM1060.415 683.641c-1.55-.945-3.266-1.561-5.041-1.855 3.606-5.088 6.161-10.546 7.637-17.078.405-2.387 3.673-2.667 6.103-.687 4.546 3.706 9.848 6.186 15.764 6.186 6.029 0 11.577-2.399 16.025-6.414 1.419-1.282 3.509-1.476 5.142-.479 8.444 5.158 14.836 13.344 17.622 23.067.748 2.605.223 5.42-1.41 7.588-1.637 2.166-4.192 3.443-6.905 3.443h-38.67c-7.279 0-12.741-4.662-18.917-8.431z" />
        <path d="M1082.475 725.451c-4.198-14.654-13.72-27.045-26.326-34.992-2.487-1.566-5.715-1.313-7.921.631-6.766 5.959-15.138 9.506-24.228 9.506-9.269 0-17.791-3.686-24.626-9.855-2.182-1.971-5.393-2.268-7.902-.734-12.977 7.924-22.799 20.504-27.082 35.445-1.151 4.008-.344 8.328 2.166 11.662 2.516 3.33 6.443 5.291 10.615 5.291h92.523c4.173 0 8.103-1.955 10.618-5.291 2.51-3.334 3.313-7.65 2.162-11.662zM1056.981 652.547c0 21.513-14.766 38.955-32.981 38.955s-32.979-17.442-32.979-38.955 14.765-38.951 32.979-38.951 32.981 17.437 32.981 38.951z" />
      </g>
    </svg>
  );
}
