import { Flags } from '@common/constants/flags';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const useAppFlags = () => {
  const flags = useFlags();
  return {
    isDerivedRatesV2Enabled: flags[Flags.DerivedRatesV2],
    isAnalyticsCompareToEnabled: flags[Flags.AnalyticsCompareTo],
    is12MonthsBudgetBannerEnabled: flags[Flags.Show12MonthsBudgetBanner],
    isHideOverridePriceSubscriptionPage: flags[Flags.HideOverridePriceSubscription]
  };
};
