import React, { useEffect, useMemo, useState } from 'react';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  CartesianGrid,
  LineChart
} from 'recharts';
import clsx from 'clsx';
import { useQuery } from '@tanstack/react-query';
import { Icon } from '@common/components/foundations/icons';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash-es';
import { useTailwindColor } from '@common/hooks/useTailwindColors';
import { Typography } from '@common/components/foundations/Typography';
import { Card } from '@common/components/molecules/Card';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { calculateAveragePrice } from '@common/api/hotel';
import { useHotelStore } from '@common/store/auth';
import { useRoomSetupStore } from '@pages/Client/PricingStrategy/RoomSetup/store/roomSetup';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useWarnings } from '@common/store/warnings';

type DataKey = 'priceInPms';

interface Props {
  roomId: number | undefined;
  variableCost?: number;
}

export const CurrentAverageChart: React.FC<Props> = ({ roomId, variableCost }) => {
  const { hotelAuthToken } = useHotelStore();
  const navigate = useNavigate();
  const { createWarning } = useWarnings();
  const { t } = useTranslation();
  const { hotelDetails } = useHotelDetails();
  const channelManagerOrPms = hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS';
  const {
    data: priceInPmsData,
    isFetching,
    isSuccess
  } = useQuery({
    queryKey: [`calculate_average_price_${roomId}`],
    queryFn: () => calculateAveragePrice({ roomId, token: hotelAuthToken }),
    enabled: !!roomId && !!hotelAuthToken
  });

  if (isSuccess) {
    if (!priceInPmsData?.is_final_data && priceInPmsData?.success) {
      createWarning({
        message: 'No inventory data available. Please run pricing.',
        ignore: false,
        ignoreLabel: 'Cancel',
        dismissLabel: 'Go to Calendar'
      }).then(() => {
        navigate(`/client/${hotelDetails?.id}/calendar`);
      });
    }
  }

  const { setNewBasePrice, setNewMinPrice, setNewMaxPrice } = useRoomSetupStore();

  useEffect(() => {
    if (!roomId || !priceInPmsData) return;
    const newBasePrice = get(priceInPmsData, `data.average_price[${roomId}].avg_price`, 0);
    setNewBasePrice(newBasePrice);
    setNewMinPrice(Math.round(newBasePrice * 0.7));
    setNewMaxPrice(Math.round(newBasePrice * 2));
  }, [priceInPmsData, roomId]);

  const indigo = useTailwindColor('indigo');
  const grey = useTailwindColor('grey');
  const mediumGrey = useTailwindColor('mediumGrey');
  const fontFamily = "'Inter var', sans-serif";
  const fontSize = '12px';
  const fontWeight = 400;

  const [seriesVisibility, setSeriesVisibility] = useState<Record<DataKey, boolean>>({
    priceInPms: true
  });

  const data = useMemo(() => {
    if (!roomId) return [];
    return Object.entries(priceInPmsData?.data?.daily_price[roomId] || {})?.map(([key, value]) => ({
      date: key,
      priceInPms: value
    }));
  }, [priceInPmsData]);

  const dataMax = Math.floor(Math.max(...data.map((item) => item.priceInPms)) * 1.025); // 2.5% padding
  const dataMin = Math.floor(Math.min(...data.map((item) => item.priceInPms)) * 0.975); // 2.5% padding

  const CustomLegend: React.FC<any> = (props) => {
    const { payload } = props;

    // Add onClick handler to toggle series visibility
    const handleLegendClick = (dataKey: DataKey) => {
      setSeriesVisibility((prev) => ({ ...prev, [dataKey]: !prev[dataKey] }));
    };

    return (
      <ul className="mb-8 flex list-none justify-center">
        {payload?.map(
          (entry: { dataKey: DataKey; value: string; color: string }, index: number) => (
            <li
              key={`item-${index}`}
              className="mr-2 flex cursor-pointer items-center"
              onClick={() => handleLegendClick(entry.dataKey)}
            >
              {isFetching ? (
                <Icon.LoadingCircle className="mr-1 h-4 w-4" />
              ) : (
                <div
                  className="mr-1 h-3 w-3 rounded-full"
                  style={{
                    backgroundColor: entry.color,
                    opacity: seriesVisibility[entry.dataKey] ? 1 : 0.3
                  }}
                />
              )}

              <Typography
                color="copyTextGrey"
                variant="meta-2"
                className={clsx(seriesVisibility[entry.dataKey] ? 'opacity-100' : 'opacity-30')}
              >
                {isFetching ? 'Loading...' : entry.value}
              </Typography>
            </li>
          )
        )}
      </ul>
    );
  };

  const CustomTooltip: React.FC<any> = (props) => {
    const { active, payload } = props;
    if (!active || !payload || !payload[0]) return null;
    const dataPoint = payload[0].payload;
    return (
      <Card backgroundColor="white">
        <Typography variant="meta-2" className="text-meta-2-semibold" color="grey">
          {dayjs(dataPoint.date).tz().format('DD MMM YYYY')}
        </Typography>
        <div className="flex items-center justify-between gap-x-3">
          <Typography variant="meta-2" color="darkGrey">
            {t(`Price in ${channelManagerOrPms}`)}
          </Typography>
          <Typography variant="meta-2" className="text-meta-2-medium" color="darkGrey">
            {CurrencyFormatter.format(dataPoint.priceInPms)}
          </Typography>
        </div>
      </Card>
    );
  };

  if (!roomId) return null;

  const newBasePrice = get(priceInPmsData, `data.average_price[${roomId}].avg_price`, 0);

  return (
    <div className="mt-1 flex flex-col justify-between gap-y-4 px-4 md:flex-col md:px-8">
      <div className="flex">
        <div className="flex flex-col gap-y-1">
          <Typography variant="h5" className="text-meta-1 font-medium" color="darkGrey">
            {t('Base Price Year Chart')}
          </Typography>
          <div className="flex flex-wrap gap-4">
            <Typography>
              {t('New Base Price')}: {CurrencyFormatter.format(newBasePrice)}
            </Typography>
            <Typography>
              {t('Default Min')}: {CurrencyFormatter.format(Math.round(newBasePrice * 0.7))}
            </Typography>

            <Typography>
              {t('Default Max')}: {CurrencyFormatter.format(Math.round(newBasePrice * 2))}
            </Typography>

            {variableCost ? (
              <Typography>
                {t('Variable Cost')}: {CurrencyFormatter.format(variableCost)}
              </Typography>
            ) : null}
          </div>
        </div>
      </div>
      <div className="relative h-full w-full">
        <ResponsiveContainer width="100%" height={600}>
          <LineChart
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0
            }}
          >
            <defs>
              <linearGradient id="totalGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={indigo} stopOpacity={0.5} />
                <stop offset="100%" stopColor={indigo} stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid stroke={mediumGrey} strokeDasharray="2" vertical={false} />
            <XAxis
              axisLine={false}
              height={80}
              tick={{ fill: grey, stroke: 'none' }}
              tickLine={false}
              fontFamily={fontFamily}
              fontSize={fontSize}
              fontWeight={fontWeight}
              dataKey="date"
              tickFormatter={(value) => dayjs(value).format('DD/MM/YYYY')}
              interval="preserveStart"
              angle={-45}
              textAnchor="end"
            />
            <YAxis
              domain={[dataMin, dataMax]}
              axisLine={false}
              width={100}
              tick={{ fill: grey, stroke: 'none' }}
              tickLine={false}
              fontFamily={fontFamily}
              fontSize={fontSize}
              fontWeight={fontWeight}
              tickFormatter={(value) => CurrencyFormatter.format(value as number)}
            />
            <Tooltip content={(props) => <CustomTooltip {...props} />} />
            <Legend verticalAlign="top" content={(props) => <CustomLegend {...props} />} />
            <Line
              visibility={seriesVisibility.priceInPms ? 'visible' : 'hidden'}
              dataKey="priceInPms"
              name={`${t(`Price in ${channelManagerOrPms}`)}`}
              stroke={indigo}
              fill={indigo}
              dot={false}
              isAnimationActive={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
