export const PriceTag = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.845 8.04033L7.56896 0.764331C7.50646 0.701814 7.42169 0.666683 7.33329 0.666664H0.999959C0.911554 0.666664 0.826769 0.701783 0.764257 0.764295C0.701745 0.826807 0.666626 0.911592 0.666626 0.999997V7.33333C0.666645 7.42173 0.701776 7.5065 0.764293 7.569L8.04029 14.845C8.35311 15.1571 8.77693 15.3323 9.21879 15.3323C9.66065 15.3323 10.0845 15.1571 10.3973 14.845L14.845 10.3973C15.1575 10.0848 15.3331 9.66085 15.3331 9.21883C15.3331 8.77681 15.1575 8.35289 14.845 8.04033ZM4.66663 6C4.40292 6 4.14513 5.9218 3.92587 5.77529C3.7066 5.62878 3.5357 5.42054 3.43479 5.17691C3.33387 4.93327 3.30747 4.66518 3.35891 4.40654C3.41036 4.1479 3.53735 3.91032 3.72382 3.72385C3.91029 3.53738 4.14786 3.4104 4.40651 3.35895C4.66515 3.3075 4.93324 3.33391 5.17687 3.43482C5.42051 3.53574 5.62874 3.70664 5.77525 3.9259C5.92176 4.14517 5.99996 4.40296 5.99996 4.66666C5.99996 5.02029 5.85948 5.35942 5.60944 5.60947C5.35939 5.85952 5.02025 6 4.66663 6ZM9.23563 11.2357C9.17312 11.2982 9.08835 11.3333 8.99996 11.3333C8.91157 11.3333 8.8268 11.2982 8.76429 11.2357L6.09763 8.569C6.03691 8.50613 6.00331 8.42193 6.00407 8.33453C6.00483 8.24713 6.03988 8.16353 6.10169 8.10172C6.16349 8.03992 6.24709 8.00486 6.33449 8.00411C6.42189 8.00335 6.50609 8.03694 6.56896 8.09766L9.23563 10.7643C9.29812 10.8268 9.33322 10.9116 9.33322 11C9.33322 11.0884 9.29812 11.1732 9.23563 11.2357ZM11.2356 9.23566C11.1731 9.29815 11.0883 9.33326 11 9.33326C10.9116 9.33326 10.8268 9.29815 10.7643 9.23566L8.09763 6.569C8.03691 6.50613 8.00331 6.42193 8.00407 6.33453C8.00483 6.24713 8.03988 6.16353 8.10169 6.10172C8.16349 6.03992 8.24709 6.00487 8.33449 6.00411C8.42189 6.00335 8.50609 6.03694 8.56896 6.09766L11.2356 8.76433C11.2981 8.82684 11.3332 8.91161 11.3332 9C11.3332 9.08838 11.2981 9.17315 11.2356 9.23566Z"
        fill="currentColor"
      />
    </svg>
  );
};
