import {
  useNotificationsStore,
  type Notification as NotificationProps
} from '@common/store/notifications';

import { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import { Icon } from '@common/components/foundations/icons';
import clsx from 'clsx';
import { Typography } from '@common/components/foundations/Typography';
import { Button } from '@common/components/atoms/Button';

const notificationBg: { [_K in 'success' | 'fail']: `bg-${'uiGreen' | 'error'}-reduced` } = {
  success: 'bg-uiGreen-reduced',
  fail: 'bg-error-reduced'
};

const Notification = ({
  type,
  message,
  duration,
  onClose
}: NotificationProps & { duration: number; onClose: () => void }) => {
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    if (type === 'fail') return;
    const interval = setInterval(() => {
      setProgress((prevProgress) => prevProgress - 1);
      if (progress <= 0) {
        clearInterval(interval);
        onClose();
      }
    }, duration / 100);

    return () => clearInterval(interval);
  }, [duration, onClose, progress]);

  return (
    <Transition
      appear={true}
      show={type === 'success' ? progress > 0 : true}
      className="w-full max-w-sm"
      enter="transform transition ease-out duration-150"
      enterFrom="translate-y-full opacity-0"
      enterTo="translate-y-0 opacity-100"
      leave="transform transition ease-in duration-100"
      leaveFrom="translate-y-0 opacity-100"
      leaveTo="translate-y-full opacity-0"
    >
      <div
        className={clsx(
          'pointer-events-auto relative w-full max-w-sm overflow-hidden rounded-lg shadow-md',
          notificationBg[type]
        )}
      >
        <div className="p-4">
          {type === 'success' ? (
            <div className="absolute bottom-0 left-0 h-1 w-full">
              <div
                className={clsx('h-full bg-uiGreen bg-opacity-30 transition-all')}
                style={{ width: `${progress}%` }}
              />
            </div>
          ) : null}
          <div className="flex items-center">
            <div className="flex flex-1 items-center">
              <div className="flex-shrink-0">
                {type === 'fail' ? (
                  <Icon.WarningOutline className="h-5 w-5 fill-uiRed" aria-hidden="true" />
                ) : (
                  <Icon.CheckList className="h-5 w-5 fill-uiGreen" aria-hidden="true" />
                )}
              </div>
              <div className="ml-3 flex w-0 flex-1 flex-col pt-0.5">
                <Typography element="span" color="copyTextGrey" className="text-meta-1-medium">
                  {message}
                </Typography>
              </div>
            </div>

            <div className="ml-4 flex flex-shrink-0">
              <Button
                icon
                onClick={() => {
                  onClose();
                }}
              >
                <span className="sr-only">Close</span>
                <Icon.Clear className="h-5 w-5" aria-hidden="true" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export function NotificationToasts() {
  const notifications = useNotificationsStore((state) => state.notifications);
  const removeNotification = useNotificationsStore((state) => state.removeNotification);

  return (
    <>
      <div
        aria-live="assertive"
        className="pointer-events-none fixed bottom-0 z-[5000] flex max-h-screen w-full flex-col-reverse p-4 sm:flex-col"
      >
        <div className="flex w-full flex-col items-center space-y-4">
          {notifications.map((notification) => (
            <Notification
              key={notification.id}
              duration={3000}
              id={notification.id}
              message={notification.message}
              type={notification.type}
              onClose={() => removeNotification(notification.id)}
            />
          ))}
        </div>
      </div>
    </>
  );
}
