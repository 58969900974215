import { isEqual } from 'lodash-es';
import { create } from 'zustand';

interface RatePlanStore {
  enableRatePlanIds: Record<string, string[]>;
  initializeRatePlans: (initialValues: Record<string, string[]>) => void;
  independentRatePlanIds: string[];
  initializeIndependentRatePlans: (initialValues: string[]) => void;
}

const useRatePlanStore = create<RatePlanStore>((set) => ({
  enableRatePlanIds: {},

  initializeRatePlans: (initialValues) =>
    set((state) => {
      if (!isEqual(state.enableRatePlanIds, initialValues)) {
        return { enableRatePlanIds: initialValues };
      }
      return state;
    }),
  independentRatePlanIds: [],
  initializeIndependentRatePlans: (initialValues) =>
    set((state) => {
      if (!isEqual(state.independentRatePlanIds, initialValues)) {
        return { independentRatePlanIds: initialValues };
      }
      return state;
    })
}));

export default useRatePlanStore;
