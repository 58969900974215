import { Typography } from '@common/components/foundations/Typography';
import { useModal } from '@common/hooks/useModal';
import { useState } from 'react';
import { useHotelCompetitor } from '@pages/Client/Calendar/hooks/useHotelCompetitor';
import { MarketIntelligenceChart } from '@pages/Client/PricingStrategy/MarketIntelligence/components/MarketIntelligenceChart';
import { MarketIntelligenceChartModal } from '@pages/Client/PricingStrategy/MarketIntelligence/components/MarketIntelligenceChartModal';
import { useCompetitorScore } from '@pages/Client/PricingStrategy/MarketIntelligence/hooks/useCompetitorScore';
import { ChartValue } from '@pages/Client/PricingStrategy/MarketIntelligence/types/chart';
import { formattedCurrency } from '@pages/Client/PricingStrategy/MarketIntelligence/utils/formatedCurrency';
import { Divider } from '@mantine/core';

export const Analytics = () => {
  const { hotelCompetitor } = useHotelCompetitor();
  const { competitorScore } = useCompetitorScore();
  const { isOpen: isChartOpen, openModal: chartClick, closeModal: chartClose } = useModal();

  const [dataChart, setDataChart] = useState<ChartValue[]>();
  const [hotelName, setHotelName] = useState('');
  const [graphTitle, setGraphTitle] = useState('');

  return (
    <div className="rounded-md bg-white py-4">
      <div className="my-4 grid grid-cols-12 items-center gap-2">
        <div className="col-span-1  text-center">
          <Typography>Index</Typography>
        </div>
        <div className="col-span-1">
          <Typography>Hotel</Typography>
        </div>
        <div className="col-span-2">
          <Typography>Test</Typography>
        </div>
        <div className="col-span-2">
          <Typography>Pricing Statistic</Typography>
        </div>
        <div className="col-span-2 text-center">
          <Typography>Graph `unprocessed`</Typography>
        </div>
        <div className="col-span-2 text-center">
          <Typography>Graph `cleaned`</Typography>
        </div>
        <div className="col-span-2 text-center">
          <Typography>Graph `imputed`</Typography>
        </div>
      </div>
      <Divider />

      {hotelCompetitor?.results?.map((item) => {
        const DATA_CHART_ClEANED = Object.entries(
          competitorScore?.amalgamation_imputed_price || {}
        ).map(([date, data]) => {
          const value = data[item?.competitor?.hotel?.id]?.cleaned
            ? parseFloat(data[item?.competitor?.hotel?.id].cleaned)
            : 0;

          return {
            date: date,
            value: isNaN(value) ? 0 : value
          };
        });

        const DATA_CHART_UNPROCESSED = Object.entries(
          competitorScore?.amalgamation_imputed_price || {}
        ).map(([date, data]) => {
          const value = data[item?.competitor?.hotel?.id]?.raw
            ? parseFloat(data[item?.competitor?.hotel?.id].raw)
            : 0;

          return {
            date: date,
            value: isNaN(value) ? 0 : value
          };
        });

        const DATA_CHART_IMPUTED = Object.entries(
          competitorScore?.amalgamation_imputed_price || {}
        ).map(([date, data]) => {
          const value = data[item?.competitor?.hotel?.id]?.imputed
            ? parseFloat(data[item?.competitor?.hotel?.id].imputed)
            : 0;
          return {
            date: date,
            value: isNaN(value) ? 0 : value
          };
        });

        return (
          <div key={item.index}>
            <div className="my-4 grid grid-cols-12 items-start gap-3 ">
              <div className="col-span-1  text-center">
                <Typography variant="paragraph-3">{item.index}</Typography>
              </div>
              <div className="col-span-1">
                <Typography variant="paragraph-3">{item?.competitor?.hotel?.name} </Typography>
              </div>
              <div className="col-span-2">
                <div className="flex justify-between">
                  <div className="flex flex-col gap-2">
                    <Typography variant="meta-2" element="p">
                      Pricing Completeness:
                    </Typography>
                    <Typography variant="meta-2" element="p">
                      P.C. Near Future:
                    </Typography>
                    <Typography variant="meta-2" element="p">
                      N.A. Patterns:
                    </Typography>
                    <Typography variant="meta-2" element="p">
                      Pearson Corr Score:
                    </Typography>
                    <Typography variant="meta-2" element="p">
                      Unique Value Score:
                    </Typography>
                    <Typography variant="meta-2" element="p">
                      Final Test Score:
                    </Typography>
                    <Typography variant="meta-2" element="p">
                      Validation Check:
                    </Typography>
                  </div>
                  <div className="flex flex-col gap-2 ">
                    <Typography variant="meta-2" element="p" color="darkGrey">
                      {isNaN(
                        competitorScore?.missing_prices_scores[item?.competitor?.hotel.id]
                          ?.pricing_completeness_score as number
                      )
                        ? '-'
                        : competitorScore?.missing_prices_scores[
                            item?.competitor?.hotel.id
                          ]?.pricing_completeness_score?.toFixed(2) ?? '-'}
                    </Typography>
                    <Typography variant="meta-2" element="p" color="darkGrey">
                      {isNaN(
                        competitorScore?.missing_prices_scores[item?.competitor?.hotel.id]
                          ?.missing_prices_near_future_score as number
                      )
                        ? '-'
                        : competitorScore?.missing_prices_scores[
                            item?.competitor?.hotel.id
                          ]?.missing_prices_near_future_score?.toFixed(2) ?? '-'}
                    </Typography>
                    <Typography variant="meta-2" element="p" color="darkGrey">
                      {isNaN(
                        competitorScore?.missing_prices_scores[item?.competitor?.hotel.id]
                          ?.missing_prices_pattern_score as number
                      )
                        ? '-'
                        : competitorScore?.missing_prices_scores[
                            item?.competitor?.hotel.id
                          ]?.missing_prices_pattern_score?.toFixed(2) ?? '-'}
                    </Typography>
                    <Typography variant="meta-2" element="p" color="darkGrey">
                      {isNaN(
                        competitorScore?.similarity_scores[item?.competitor?.hotel.id]
                          ?.pearson_corr_score as number
                      )
                        ? '-'
                        : competitorScore?.similarity_scores[
                            item?.competitor?.hotel.id
                          ]?.pearson_corr_score?.toFixed(2) ?? '-'}
                    </Typography>
                    <Typography variant="meta-2" element="p" color="darkGrey">
                      {isNaN(
                        competitorScore?.similarity_scores[item?.competitor?.hotel.id]
                          ?.unique_value_score as number
                      )
                        ? '-'
                        : competitorScore?.similarity_scores[
                            item?.competitor?.hotel.id
                          ]?.unique_value_score?.toFixed(2) ?? '-'}
                    </Typography>
                    <Typography variant="meta-2" element="p" color="darkGrey">
                      {isNaN(
                        competitorScore?.missing_similarity_testscores_result[
                          item?.competitor?.hotel.id
                        ] as number
                      )
                        ? '-'
                        : competitorScore?.missing_similarity_testscores_result[
                            item?.competitor?.hotel.id
                          ]?.toFixed(2) ?? '-'}
                    </Typography>

                    <div
                      className={`rounded-[2px] px-1 text-xs font-bold text-white ${
                        competitorScore?.imputation_statics[item?.competitor?.hotel.id] === 'True'
                          ? 'bg-uiGreen'
                          : 'bg-uiRed'
                      }`}
                    >
                      {competitorScore?.imputation_statics[item?.competitor?.hotel.id] === 'True'
                        ? 'True'
                        : 'False'}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-2">
                <div className="flex flex-col gap-2">
                  <div className="flex justify-between">
                    <Typography variant="meta-2" element="p">
                      Number of prices:
                    </Typography>
                    <Typography
                      variant="meta-2"
                      element="p"
                      color="darkGrey"
                      className="text-right"
                    >
                      {isNaN(item?.competitor?.number_of_price as number)
                        ? '-'
                        : item?.competitor?.number_of_price ?? '-'}
                    </Typography>
                  </div>
                  <div className="flex justify-between">
                    <Typography variant="meta-2" element="p">
                      Prices scraped within last 4 weeks:
                    </Typography>
                    <Typography
                      variant="meta-2"
                      element="p"
                      color="darkGrey"
                      className="text-right"
                    >
                      {isNaN(item?.competitor?.number_of_price_4_week as number)
                        ? '-'
                        : item?.competitor?.number_of_price_4_week ?? '-'}
                    </Typography>
                  </div>
                  <div className="flex justify-between">
                    <Typography variant="meta-2" element="p">
                      Min Price:
                    </Typography>
                    <Typography
                      variant="meta-2"
                      element="p"
                      color="darkGrey"
                      className="text-right"
                    >
                      {item?.competitor?.min_price == null || isNaN(item?.competitor?.min_price)
                        ? '-'
                        : formattedCurrency(Number(item?.competitor?.min_price.toFixed(2))) ?? '-'}
                    </Typography>
                  </div>
                  <div className="flex justify-between">
                    <Typography variant="meta-2" element="p">
                      Max Price:
                    </Typography>
                    <Typography
                      variant="meta-2"
                      element="p"
                      color="darkGrey"
                      className="text-right"
                    >
                      {item?.competitor?.max_price == null ||
                      isNaN(item?.competitor?.max_price as number)
                        ? '-'
                        : formattedCurrency(Number(item?.competitor?.max_price.toFixed(2))) ?? '-'}
                    </Typography>
                  </div>
                  <div className="flex justify-between">
                    <Typography variant="meta-2" element="p">
                      Average Price:
                    </Typography>
                    <div>
                      <Typography
                        variant="meta-2"
                        element="p"
                        color="darkGrey"
                        className="text-right"
                      >
                        {item?.competitor?.avg_price == null ||
                        isNaN(item?.competitor?.avg_price as number)
                          ? '-'
                          : formattedCurrency(Number(item?.competitor?.avg_price.toFixed(2))) ??
                            '-'}
                      </Typography>
                      <Typography
                        variant="meta-2"
                        element="p"
                        color="darkGrey"
                        className="text-right"
                      >
                        {item?.imputed_average == null || isNaN(item?.imputed_average)
                          ? '-'
                          : `(${formattedCurrency(Number(item?.imputed_average.toFixed(2)))})` ??
                            '-'}
                      </Typography>
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <Typography variant="meta-2" element="p">
                      Price Diff vs Comp:
                    </Typography>
                    <div>
                      <Typography
                        variant="meta-2"
                        element="p"
                        color="darkGrey"
                        className="text-right"
                      >
                        {item?.pricediff == null || isNaN(item?.pricediff)
                          ? '-'
                          : `${item?.pricediff.toFixed(2)}%` ?? '-'}
                      </Typography>
                      <Typography
                        variant="meta-2"
                        element="p"
                        color="darkGrey"
                        className="text-right"
                      >
                        {item?.imputed_pricediff == null || isNaN(item?.imputed_pricediff)
                          ? '-'
                          : `(${item?.imputed_pricediff.toFixed(2)})%` ?? '-'}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-span-2 text-center"
                onClick={() => {
                  setDataChart(DATA_CHART_UNPROCESSED);
                  setHotelName(item?.competitor?.hotel?.name);
                  setGraphTitle('RAW GRAPH');
                  chartClick();
                }}
              >
                <MarketIntelligenceChart data={DATA_CHART_UNPROCESSED} />
              </div>
              <div
                className="col-span-2 text-center"
                onClick={() => {
                  setDataChart(DATA_CHART_ClEANED);
                  setHotelName(item?.competitor?.hotel?.name);
                  setGraphTitle('CLEANED GRAPH');
                  chartClick();
                }}
              >
                <MarketIntelligenceChart data={DATA_CHART_ClEANED} />
              </div>
              <div
                className="col-span-2 text-center"
                onClick={() => {
                  setDataChart(DATA_CHART_IMPUTED);
                  setHotelName(item?.competitor?.hotel?.name);
                  setGraphTitle('IMPUTED GRAPH');
                  chartClick();
                }}
              >
                <MarketIntelligenceChart data={DATA_CHART_IMPUTED} />
              </div>
            </div>
          </div>
        );
      })}
      <MarketIntelligenceChartModal
        isOpen={isChartOpen}
        graphTitle={graphTitle}
        data={dataChart ? dataChart : []}
        hotelName={hotelName}
        onClose={chartClose}
      />
    </div>
  );
};
