import { NotificationToasts } from '@common/components/molecules/NotificationToasts';
import { WarningMessages } from '@common/components/molecules/WarningMessages';

import '@mantine/core/styles.css';
import '@mantine/spotlight/styles.css';
import '@mantine/charts/styles.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@common/styles/index.css';

import ReactDOM from 'react-dom/client';
import { App } from './App';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { Suspense } from 'react';

import TagManager from 'react-gtm-module';
import { getEnvVar } from '@common/utils/env';

const tagManagerArgs = {
  gtmId: 'GTM-M795FX3',
  events: {
    'gtm.start': new Date().getTime(),
    event: 'gtm.js'
  }
};

TagManager.initialize(tagManagerArgs);

// Wrap the App component with LaunchDarkly provider
const AppWithLD = withLDProvider({
  clientSideID: getEnvVar('VITE_LAUNCHDARKLY_CLIENT_SIDE_ID'),
  reactOptions: { useCamelCaseFlagKeys: false },
  context: {
    kind: 'multi',
    hotel: { key: 'hotel' },
    user: { key: 'user' }
  }
})(App);

// Create a wrapper component
function AppWrapper() {
  return (
    <Suspense fallback={<div className="h-screen bg-appBackground" />}>
      <AppWithLD />
      <NotificationToasts />
      <WarningMessages />
    </Suspense>
  );
}

const root = ReactDOM.createRoot(document.getElementById('app')!);
root.render(<AppWrapper />);
