import { setAuthToken as setAxiosAuthToken } from '@common/api/axiosInstance';
import { useNotificationsStore } from '@common/store/notifications';
import {
  AccountError,
  ErrorMessage,
  LoginResponse,
  PropertiesResponse,
  SSOAuthInput,
  ssoCloudbeds,
  ssoCloudbedsAuth,
  SSOInput,
  ssoSeekom,
  ssoSeekomAuth
} from '@common/api/auth';
import { useAuthStore, useLogout } from '@common/store/auth';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

export const useSSOSeekom = () => {
  const { t } = useTranslation();
  const addNotification = useNotificationsStore((state) => state.addNotification);
  const { setAuth } = useAuthStore();

  return useMutation<PropertiesResponse, AxiosError<ErrorMessage>, SSOInput>({
    mutationFn: ssoSeekom,
    onSuccess: async (data) => {
      // Set token for all axios requests
      setAxiosAuthToken(data.token);

      setAuth(data.token, null);
    },
    onError: () => {
      addNotification('fail', t('Something went wrong'));
    }
  });
};

export const useSSOSeekomAuth = () => {
  const { t } = useTranslation();
  const addNotification = useNotificationsStore((state) => state.addNotification);
  const { setAuth } = useAuthStore();
  const clientLogout = useLogout();

  return useMutation<LoginResponse, AccountError, SSOAuthInput>({
    mutationFn: ssoSeekomAuth,
    onSuccess: async (data) => {
      if (!data.token || !data.user) {
        clientLogout();
        return;
      }

      // Set token for all axios requests
      setAxiosAuthToken(data.token);
      setAuth(data.token, data.user);

      addNotification('success', t('SSO Successful'));
    },
    onError: () => {
      addNotification('fail', t('SSO Failed'));
    }
  });
};

export const useSSOCloudbeds = () => {
  const { t } = useTranslation();
  const addNotification = useNotificationsStore((state) => state.addNotification);
  const { setAuth } = useAuthStore();

  return useMutation<PropertiesResponse, AxiosError<ErrorMessage>, SSOInput>({
    mutationFn: ssoCloudbeds,
    onSuccess: async (data) => {
      // Set token for all axios requests
      setAxiosAuthToken(data.token);

      setAuth(data.token, null);
    },
    onError: () => {
      addNotification('fail', t('Something went wrong'));
    }
  });
};

export const useSSOCloudbedsAuth = () => {
  const { t } = useTranslation();
  const addNotification = useNotificationsStore((state) => state.addNotification);
  const { setAuth } = useAuthStore();
  const clientLogout = useLogout();

  return useMutation<LoginResponse, AccountError, SSOAuthInput>({
    mutationFn: ssoCloudbedsAuth,
    onSuccess: async (data) => {
      if (!data.token || !data.user) {
        clientLogout();
        return;
      }

      // Set token for all axios requests
      setAxiosAuthToken(data.token);
      setAuth(data.token, data.user);
    },
    onError: () => {
      addNotification('fail', t('Something went wrong'));
    }
  });
};
