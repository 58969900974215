import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sortBy, truncate } from 'lodash-es';
import { useIsFetching } from '@tanstack/react-query';
import { format, parseISO } from 'date-fns';
import { Checkbox, Divider } from '@mantine/core';
import { useAnalyticsDashboardFilters } from '@pages/Client/Dashboard/hooks/useAnalyticsDashboardCache';
import { useDashboardPageStore } from '@pages/Client/Dashboard/store/dashboard';
import { DateRangePicker } from '@common/components/molecules/DateRangePicker/DateRangePicker';
import { Modal } from '@common/components/molecules/Modal';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import { AnalyticsDashboardQueryKeys } from '@common/types/query-keys';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { Typography } from '@common/components/foundations/Typography';

type DateRange = {
  from: Date | undefined;
  to: Date | undefined;
};
type FilterValue = (string | number)[] | string;

export const AnalyticsFilters = ({
  isModalOpen,
  onClose
}: {
  isModalOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { analyticsDashboardFilters } = useAnalyticsDashboardFilters();
  const { advancedAnalyticsFilters, updateHotelAnalyticsFilters } = useDashboardPageStore();
  const { hotelDetails } = useHotelDetails();

  const isFetching = useIsFetching({
    queryKey: [
      AnalyticsDashboardQueryKeys.GET_ANALYTICS_DASHBOARD_CACHE,
      advancedAnalyticsFilters,
      hotelDetails?.id
    ]
  });

  const [selectedFilters, setSelectedFilters] = useState<Record<string, Record<string, boolean>>>(
    {}
  );
  const [bookingDate, setBookingDate] = useState<DateRange>({
    from: undefined,
    to: undefined
  });
  const [stayDate, setStayDate] = useState<DateRange>({
    from: undefined,
    to: undefined
  });

  const filterMapping: { [key: string]: { id: string; label: string } } = {
    weekdays: {
      id: 'filter_weekdays',
      label: 'Weekday of Stay'
    },
    channels: {
      id: 'filter_channels',
      label: 'Channel'
    },
    segments: {
      id: 'filter_segments',
      label: 'Segment'
    },
    room_types: {
      id: 'filter_room_types',
      label: 'Room Type'
    },
    rates: {
      id: 'filter_rate_plans',
      label: 'Rate Plan'
    }
  };
  const orderedKeys = ['weekdays', 'room_types', 'channels', 'rates', 'segments'];

  const reorderedFilters =
    analyticsDashboardFilters &&
    sortBy(Object.entries(analyticsDashboardFilters), ([parent]) => orderedKeys.indexOf(parent));

  const areAllFiltersChecked = (parent: string) => {
    const allValues =
      analyticsDashboardFilters?.[parent as keyof typeof analyticsDashboardFilters] || {};
    return Object.keys(allValues).every((key) => selectedFilters[parent]?.[key]);
  };

  const resetFilterState = () => {
    setBookingDate({ from: undefined, to: undefined });
    setStayDate({ from: undefined, to: undefined });
    for (const parent of Object.keys(filterMapping)) {
      handleSelectAllToggle(parent, true);
    }
  };

  const handleDateFilters = (
    filters: Record<string, any>
  ): { updatedBookingDate: DateRange; updatedStayDate: DateRange } => {
    const updatedBookingDate: DateRange = { from: undefined, to: undefined };
    const updatedStayDate: DateRange = { from: undefined, to: undefined };

    for (const [key, values] of Object.entries(filters)) {
      switch (key) {
        case 'filter_booking_date_from': {
          updatedBookingDate.from = parseISO(values as string);
          break;
        }
        case 'filter_booking_date_to': {
          updatedBookingDate.to = parseISO(values as string);
          break;
        }
        case 'filter_stay_date_from': {
          updatedStayDate.from = parseISO(values as string);
          break;
        }
        case 'filter_stay_date_to': {
          updatedStayDate.to = parseISO(values as string);
          break;
        }
        default:
          break;
      }
    }
    return { updatedBookingDate, updatedStayDate };
  };

  const handleNonDateFilters = (
    filters: Record<string, any>
  ): Record<string, Record<string, boolean>> => {
    const updatedSelectedFilters: Record<string, Record<string, boolean>> = {};

    for (const filterKey of Object.keys(filterMapping)) {
      const filterId = filterMapping[filterKey].id;
      const filterValues = filters[filterId];

      updatedSelectedFilters[filterKey] = {};

      if (filterValues) {
        (filterValues as (string | number)[]).forEach((value: string | number) => {
          updatedSelectedFilters[filterKey][value] = true;
        });
      } else {
        const allValues =
          analyticsDashboardFilters?.[filterKey as keyof typeof analyticsDashboardFilters] || {};

        Object.keys(allValues).forEach((key: string | number) => {
          if (!updatedSelectedFilters[filterKey]) {
            updatedSelectedFilters[filterKey] = {};
          }
          updatedSelectedFilters[filterKey][key] = true;
        });
      }
    }
    return { ...selectedFilters, ...updatedSelectedFilters };
  };

  const handleSelectAllToggle = useCallback(
    (parent: string, isChecked: boolean) => {
      // If the new state is checked (true), then we want to select all. Otherwise, deselect all.
      const allValues =
        analyticsDashboardFilters?.[parent as keyof typeof analyticsDashboardFilters] || {};

      const allSelected = isChecked
        ? Object.keys(allValues).reduce((acc, key) => ({ ...acc, [key]: true }), {})
        : {};

      setSelectedFilters((prevState) => ({
        ...prevState,
        [parent]: allSelected
      }));
    },
    [analyticsDashboardFilters]
  );

  useEffect(() => {
    if (hotelDetails?.id && advancedAnalyticsFilters?.[hotelDetails.id]) {
      const filters = advancedAnalyticsFilters[hotelDetails.id];

      const { updatedBookingDate, updatedStayDate } = handleDateFilters(filters);
      const updatedSelectedFilters = handleNonDateFilters(filters);

      setBookingDate(updatedBookingDate);
      setStayDate(updatedStayDate);
      setSelectedFilters(updatedSelectedFilters);
    } else {
      resetFilterState();
    }
  }, [advancedAnalyticsFilters, hotelDetails?.id, handleSelectAllToggle]);

  const handleSaveFilters = () => {
    if (!hotelDetails?.id) return;

    const formattedFilters: { [key: string]: FilterValue } = {};

    for (const [filterKey, filterValues] of Object.entries(selectedFilters)) {
      const mappedKey = filterMapping[filterKey]?.id;
      if (mappedKey) {
        let values: (string | number)[] = [];

        if (filterKey === 'weekdays') {
          values = Object.keys(filterValues)
            .filter((key) => filterValues[key])
            .map((key) => parseInt(key, 10));
        } else {
          values = Object.keys(filterValues).filter((key) => filterValues[key]);
        }

        // Here's where we check if all the values are selected for this filter
        const allFilterValues =
          analyticsDashboardFilters?.[filterKey as keyof typeof analyticsDashboardFilters];
        const isAllSelected =
          allFilterValues && values.length === Object.keys(allFilterValues).length;

        // Only set the key if there are values and not all values are selected
        if (values.length > 0 && !isAllSelected) {
          formattedFilters[mappedKey] = values;
        }
      }
    }
    if (bookingDate.from) {
      formattedFilters['filter_booking_date_from'] = format(bookingDate.from, 'yyyy-MM-dd');
    }
    if (bookingDate.to) {
      formattedFilters['filter_booking_date_to'] = format(bookingDate.to, 'yyyy-MM-dd');
    }
    if (stayDate.from) {
      formattedFilters['filter_stay_date_from'] = format(stayDate.from, 'yyyy-MM-dd');
    }
    if (stayDate.to) {
      formattedFilters['filter_stay_date_to'] = format(stayDate.to, 'yyyy-MM-dd');
    }
    updateHotelAnalyticsFilters(hotelDetails.id, formattedFilters);
    onClose();
  };

  const handleClearFilters = () => {
    if (!hotelDetails?.id) return;
    resetFilterState();
    updateHotelAnalyticsFilters(hotelDetails.id, {});
    onClose();
  };

  const CheckItem = ({
    filter,
    disableHighlight = false
  }: {
    filter: { parent: string; id: string; value: string };
    disableHighlight?: boolean;
  }) => {
    const isChecked = !!selectedFilters?.[filter.parent]?.[filter.id] || false;

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;

      setSelectedFilters((prevState) => ({
        ...prevState,
        [filter.parent]: {
          ...prevState[filter.parent],
          [filter.id]: checked
        }
      }));
    };

    let displayValue = filter.value;

    if (['segments', 'weekdays', 'rates'].includes(filter.parent)) {
      displayValue = t(filter.value);
    }
    return (
      <TooltipProvider delayDuration={75}>
        <Tooltip>
          <TooltipTrigger asChild>
            <div className="mb-2 flex items-center gap-1.5">
              <Checkbox
                label={truncate(displayValue, { length: 18 })}
                id={filter.value}
                className={disableHighlight ? 'opacity-50 grayscale' : ''}
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
            </div>
          </TooltipTrigger>
          <TooltipContent side="bottom" className="max-w-xs">
            {displayValue}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  };

  return (
    <Modal
      open={isModalOpen}
      size="xxl"
      onClose={onClose}
      onCustomCancel={handleClearFilters}
      cancelText={t('Clear all') as string}
      okText={t('Save') as string}
      onClick={handleSaveFilters}
      isLoading={!!isFetching}
      disableCloseOnOk={true}
    >
      <div className="max-h-[600px] overflow-y-auto">
        <div className="sticky top-0 z-10 bg-white pb-1">
          <Typography variant="h5" className="text-meta-1 font-medium" color="darkGrey">
            {t('Advanced Filters')}
          </Typography>
        </div>

        <div className="mb-3 mt-3 w-full text-sm">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
            <div>
              <div className="mb-1">
                <Typography color="darkGrey">{t('Booking Date')}</Typography>
              </div>
              <DateRangePicker
                ISOWeek={hotelDetails?.starts_monday}
                timezone={hotelDetails?.timezone}
                startDate={bookingDate.from}
                endDate={bookingDate.to}
                onDateChange={(date) => {
                  setBookingDate({ from: date?.from, to: date?.to });
                }}
                allowPastDates
                isClearable
                allowFutureDates={false}
                fullWidth
                enablePredefinedRanges
              />
            </div>
            <div>
              <div className="mb-1">
                <Typography color="darkGrey">{t('Stay Date')}</Typography>
              </div>
              <DateRangePicker
                ISOWeek={hotelDetails?.starts_monday}
                timezone={hotelDetails?.timezone}
                startDate={stayDate.from}
                endDate={stayDate.to}
                onDateChange={(date) => {
                  setStayDate({ from: date?.from, to: date?.to });
                }}
                allowPastDates
                isClearable
                fullWidth
                enablePredefinedRanges
              />
            </div>
          </div>
        </div>

        <div className="mb-5 mt-5 grid w-full grid-cols-1 gap-x-6 text-sm md:grid-cols-3 lg:grid-cols-5">
          {reorderedFilters?.map(([parent, values]) => (
            <div key={parent} className="col-span-1">
              <div className="flex items-center justify-between">
                <div className="mb-2">
                  <Typography color="darkGrey">{t(filterMapping[parent].label)}</Typography>
                </div>
              </div>
              <div className="mb-2 flex items-center gap-1.5">
                <Checkbox
                  label={t('Select all')}
                  id={`select-all-${parent}`}
                  checked={areAllFiltersChecked(parent)}
                  onChange={(e) => handleSelectAllToggle(parent, e.target.checked)}
                />
              </div>
              <Divider mb={8} />
              {Object.entries(values)
                .sort((a, b) => {
                  // Apply sorting alphabetically only if the condition is met
                  if (!['weekdays'].includes(parent)) {
                    return a[1].localeCompare(b[1]);
                  }
                  return 0;
                })
                .map(([id, value]) => (
                  <CheckItem
                    key={id}
                    filter={{ parent, id, value }}
                    disableHighlight={areAllFiltersChecked(parent)}
                  />
                ))}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};
