import { useCallback, useEffect, useState } from 'react';
import { isEmpty, sortBy, truncate } from 'lodash-es';
import { Button, Checkbox, Modal, Divider } from '@mantine/core';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import { Typography } from '@common/components/foundations/Typography';
import { useCountryList } from '@common/hooks/useSystem';
import { pmsLookup } from '@common/constants/pmsList';
import {
  FilterOptions,
  FilterGroup,
  useClientMapStore,
  FilterGroups,
  initialFilters
} from '@common/store/client-map-store';
import { ModalFooter } from '@common/mantine/components/modal-footer';
import { planLookup, subscriptionStatus } from '@common/config/app-config';
import { RENDER_MAP_CLUSTERER_DELAY } from '@pages/Admin/Clients/pages/ClientMap';

interface ClientMapFiltersProps {
  isModalOpen: boolean;
  onClose: () => void;
  clearMap: () => void;
}

type FilterMapping = {
  [K in FilterGroup]: { label: string };
};

type FilterData = {
  [K in FilterGroup]: { [key: string]: string };
};

export const ClientMapFilters = ({ isModalOpen, onClose, clearMap }: ClientMapFiltersProps) => {
  const { countryList = [] } = useCountryList();
  const { filters, setFilters, reset } = useClientMapStore();
  const [selectedFilters, setSelectedFilters] = useState<FilterGroups>(initialFilters);

  useEffect(() => {
    if (!countryList) return;

    const updatedFilters = Object.keys(initialFilters).reduce((acc, filterGroup) => {
      // If filters[filterGroup] is empty, it means select all, set all related items to true
      const filterValues = filters[filterGroup as FilterGroup];
      if (isEmpty(filterValues)) {
        const allValues = filtersData[filterGroup as FilterGroup];
        acc[filterGroup as FilterGroup] = Object.keys(allValues).reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {} as FilterOptions);
      } else {
        acc[filterGroup as FilterGroup] = filterValues;
      }
      return acc;
    }, {} as FilterGroups);

    setSelectedFilters(updatedFilters);
  }, [filters, setSelectedFilters, countryList]);

  const filterMapping: FilterMapping = {
    subscription_state: { label: 'Subscription State' },
    pms: { label: 'PMS' },
    country: { label: 'Country' },
    subscription_plan: { label: 'Subscription Plan' }
  };

  const filtersData: FilterData = {
    subscription_state: Object.fromEntries(
      Object.entries(subscriptionStatus).map(([stateId, state]) => [stateId, state.label])
    ),
    subscription_plan: Object.fromEntries(
      Object.entries(planLookup).map(([planId, planLabel]) => [planId, planLabel])
    ),
    pms: Object.fromEntries(Object.entries(pmsLookup).map(([pmsId, pmsName]) => [pmsId, pmsName])),
    country: countryList.reduce(
      (acc, country) => ({ ...acc, [country.id]: country.name }),
      {} as FilterData['country']
    )
  };

  const areAllFiltersChecked = (filterGroup: FilterGroup) => {
    const allValues = filtersData[filterGroup];
    return Object.keys(allValues).every((key) => selectedFilters[filterGroup]?.[key]);
  };

  const handleSelectAllToggle = useCallback(
    (filterGroup: FilterGroup, isChecked: boolean) => {
      const allValues = filtersData[filterGroup];
      const updatedFilters: FilterOptions = isChecked
        ? Object.keys(allValues).reduce((acc, key) => ({ ...acc, [key]: true }), {})
        : {};

      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        [filterGroup]: updatedFilters
      }));
    },
    [filtersData]
  );

  const handleSaveFilters = () => {
    const formattedFilters: FilterGroups = {
      country: {},
      pms: {},
      subscription_state: {},
      subscription_plan: {}
    };

    Object.entries(selectedFilters).forEach(([filterKey, filterValues]) => {
      const allFilterValues = filtersData[filterKey as FilterGroup];
      const selectedKeys = Object.keys(filterValues).filter((key) => filterValues[key]);

      // Here's where we check if all the values are selected for this filter
      const isAllSelected = selectedKeys.length === Object.keys(allFilterValues).length;

      // Only set the key if there are selected values and not all values are selected
      if (selectedKeys.length > 0 && !isAllSelected) {
        formattedFilters[filterKey as FilterGroup] = selectedKeys.reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {} as FilterOptions);
      } else if (isAllSelected) {
        // If all values are selected, set an empty object to indicate this
        formattedFilters[filterKey as FilterGroup] = {};
      }
    });

    clearMap();
    onClose();

    setTimeout(() => {
      // Sync to Zustand store with a bit delay to prevent
      // to get the clusterers frozen
      setFilters(formattedFilters);
    }, RENDER_MAP_CLUSTERER_DELAY);
  };

  const handleClearFilters = () => {
    clearMap();
    reset();
    onClose();
  };

  const CheckItem = ({
    filter,
    disableHighlight = false
  }: {
    filter: { parent: FilterGroup; id: string; value: string };
    disableHighlight?: boolean;
  }) => {
    const isChecked = selectedFilters?.[filter.parent]?.[filter.id] || false;

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;

      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        [filter.parent]: {
          ...prevFilters[filter.parent],
          [filter.id]: checked
        }
      }));
    };

    return (
      <TooltipProvider delayDuration={75}>
        <Tooltip>
          <TooltipTrigger asChild>
            <div className="mb-2 flex items-center gap-1.5">
              <Checkbox
                label={truncate(filter.value, { length: 20 })}
                id={filter.value}
                className={disableHighlight ? 'opacity-50 grayscale' : ''}
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
            </div>
          </TooltipTrigger>
          <TooltipContent side="bottom" className="max-w-xs">
            {filter.value}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  };

  return (
    <Modal.Root opened={isModalOpen} onClose={onClose} size="xl">
      <Modal.Overlay />
      <Modal.Content>
        <div className="max-h-[700px] overflow-y-auto">
          <Modal.Header bg="white">
            <Modal.Title>Filters</Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>

          <Modal.Body>
            <div className="grid w-full grid-cols-1 gap-x-6 text-sm md:grid-cols-4 lg:grid-cols-4">
              {Object.entries(filtersData).map(([filterGroup, values]) => (
                <div key={filterGroup} className="col-span-1">
                  <div className="flex items-center justify-between">
                    <Typography color="darkGrey" className="mb-3">
                      {filterMapping[filterGroup as FilterGroup].label}
                    </Typography>
                  </div>
                  <div className="mb-2 flex items-center gap-1.5">
                    <Checkbox
                      label="Select all"
                      id={`select-all-${filterGroup}`}
                      checked={areAllFiltersChecked(filterGroup as FilterGroup)}
                      onChange={(e) =>
                        handleSelectAllToggle(filterGroup as FilterGroup, e.target.checked)
                      }
                    />
                  </div>
                  <Divider mb={8} />
                  {sortBy(Object.entries(values), ([, value]) => value).map(([id, value]) => (
                    <CheckItem
                      key={id}
                      filter={{ parent: filterGroup as FilterGroup, id, value }}
                      disableHighlight={areAllFiltersChecked(filterGroup as FilterGroup)}
                    />
                  ))}
                </div>
              ))}
            </div>
          </Modal.Body>

          <ModalFooter>
            <Button variant="subtle" onClick={handleClearFilters}>
              Clear all
            </Button>
            <Button type="submit" onClick={handleSaveFilters}>
              Save
            </Button>
          </ModalFooter>
        </div>
      </Modal.Content>
    </Modal.Root>
  );
};
