import { getEnvVar } from '@common/utils/env';
import axios from 'axios';

export const axiosInstance = axios.create({
  baseURL: getEnvVar('VITE_API'),
  headers: { 'Content-Type': 'application/json' }
});

export function setAuthToken(token: string | null, isJwt?: boolean) {
  if (token) {
    if (isJwt) {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      axiosInstance.defaults.headers.common['Authorization'] = `Token ${token}`;
    }
  } else {
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
}
