import { MembershipPricingResponse } from '@common/api/hotel/types';
import { ModalFooter } from '@common/mantine/components/modal-footer';
import { Feature, useFeaturesStore } from '@common/store/features';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Modal, NumberInput, SegmentedControl, Select, Stack } from '@mantine/core';
import {
  useOverridePriceSubscription,
  useRemoveOverridePriceSubscription
} from '@pages/Client/Account/Subscription/hooks/useOverridePriceSubscription';
import { useMemberShipPricing } from '@pages/Client/Account/Subscription/hooks/usePayment';
import { isNil, map, mapValues } from 'lodash-es';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

const OverridePriceSchema = z.object({
  plan: z.string().nonempty('Plan is required'),
  subscription_type: z.enum(['1', '2'], {
    required_error: 'Subscription type is required'
  }),
  price: z.number().min(0, 'Price must be greater than or equal to 0')
});

type OverridePriceFormData = z.infer<typeof OverridePriceSchema>;

interface OverridePriceModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const OverridePriceModal = ({ isOpen, onClose }: OverridePriceModalProps) => {
  const { subscriptionPriceData }: { subscriptionPriceData: MembershipPricingResponse } =
    useMemberShipPricing();

  const { overridePrice } = useOverridePriceSubscription();
  const {
    removeOverridePrice,
    isLoading: isRemoveLoading,
    isSuccessRemoveOverride
  } = useRemoveOverridePriceSubscription();

  const { features } = useFeaturesStore();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors }
  } = useForm<OverridePriceFormData>({
    resolver: zodResolver(OverridePriceSchema),
    defaultValues: {
      plan: '',
      subscription_type: '1',
      price: 0
    }
  });

  const subscriptionType = watch('subscription_type');
  const selectedPlan = watch('plan');

  const handleDropdownChange = (selectedPlan: string) => {
    const planData = subscriptionPriceData?.pricing[selectedPlan];

    if (planData) {
      setValue('price', subscriptionType === '1' ? planData.monthly.price : planData.yearly.price);
    }
  };

  const handleSegmentedControlChange = (selectedValue: string) => {
    const planData = subscriptionPriceData?.pricing[selectedPlan];

    if (planData) {
      setValue('price', selectedValue === '1' ? planData.monthly.price : planData.yearly.price);
    }
  };

  function getDropdownOptionsPrice(pricingData: Record<string, any>) {
    return map(pricingData, (plan, key) => ({
      label: plan.plan_name,
      value: key
    }));
  }

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit = (data: OverridePriceFormData) => {
    overridePrice(mapValues(data, (value) => Number(value)));
    handleClose();
  };

  useEffect(() => {
    if (isSuccessRemoveOverride) {
      handleClose();
    }
  }, [isSuccessRemoveOverride]);

  return (
    <Modal
      opened={isOpen}
      onClose={handleClose}
      title="Override Price"
      size="md"
      centered
      styles={{
        body: {
          padding: '0'
        }
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack px="md" pb="md" gap="lg">
          {/* Dropdown for plan */}
          <Controller
            name="plan"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                data={getDropdownOptionsPrice(subscriptionPriceData?.pricing)}
                placeholder="Select Plan"
                onChange={(selectedPlan) => {
                  field.onChange(selectedPlan || '');
                  handleDropdownChange(selectedPlan || '');
                }}
                error={errors.plan?.message}
              />
            )}
          />
          {/* Segmented control for subscription_type */}
          <Controller
            name="subscription_type"
            control={control}
            render={({ field }) => (
              <SegmentedControl
                {...field}
                value={field.value}
                onChange={(value) => {
                  field.onChange(value);
                  handleSegmentedControlChange(value);
                }}
                data={[
                  { label: 'Monthly', value: '1' },
                  {
                    label: 'Yearly',
                    value: '2',
                    disabled: features?.includes(Feature.SmallBusiness) && selectedPlan === '5'
                  }
                ]}
              />
            )}
          />
          {/* Price Field */}
          <Controller
            name="price"
            control={control}
            render={({ field }) => {
              const currencySymbol =
                Object.values(subscriptionPriceData?.pricing || {})[0]?.currency?.symbol || '';

              return (
                <NumberInput
                  {...field}
                  leftSection={<span>{currencySymbol || ''}</span>}
                  placeholder="Price"
                  variant="filled"
                  hideControls
                  error={errors.price?.message}
                />
              );
            }}
          />
        </Stack>
        <ModalFooter>
          {!isNil(subscriptionPriceData?.override_price) ? (
            <Button
              type="button"
              color="red"
              variant="filled"
              onClick={() => removeOverridePrice()}
              loading={isRemoveLoading}
            >
              Remove Override Price
            </Button>
          ) : null}
          <Button type="button" variant="transparent" color="gray.8" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default OverridePriceModal;
