import { useParams } from 'react-router-dom';

import { Button } from '@common/components/atoms/Button';
import { Input } from '@common/components/atoms/Input';
import { Icon } from '@common/components/foundations/icons';
import { Genie } from '@common/components/foundations/Logo';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';
import { useGetSession } from '@pages/Auth/SetNewPassword/hooks/useGetSession';
import { useResetPassword } from '@pages/Auth/SetNewPassword/hooks/useResetPassword';
import {
  specialCharRegex,
  PasswordValidationMessage,
  numberRegex
} from '@common/constants/password';
import { Alert, Box, Stack, Title } from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons-react';

const schema = z
  .object({
    password: z
      .string()
      .min(8, PasswordValidationMessage.Length)
      .max(50)
      .refine((value) => /[A-Z]/.test(value), {
        message: PasswordValidationMessage.UppercaseLetter
      })
      .refine((value) => numberRegex.test(value), {
        message: PasswordValidationMessage.Number
      })
      .refine((value) => specialCharRegex.test(value), {
        message: PasswordValidationMessage.SpecialChar
      }),
    confirm_password: z.string().min(8, PasswordValidationMessage.Length).max(50)
  })
  .refine((data) => data.password === data.confirm_password, {
    message: 'Password does not match',
    path: ['confirm_password']
  });

export function SetNewPassword() {
  const { token } = useParams();
  const {
    query: { isError: isExpireSession, isLoading }
  } = useGetSession(token);
  const {
    resetPassword,
    isError: isResetPasswordError,
    error: errorPasswordMessage
  } = useResetPassword();

  const { handleSubmit, control, reset } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const onSubmit = (data: any) => {
    resetPassword({
      token,
      new_password: data.password
    });
    reset();
  };

  return (
    <div className="flex w-full flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:w-1/2 lg:flex-none lg:px-20 xl:px-24">
      <div className=" mx-auto w-full rounded-md bg-white p-8 2xl:w-3/4">
        <div>
          <div className="mb-4 flex items-center gap-x-2 lg:hidden">
            <div className="h-auto w-10">
              <Genie className="fill-indigo" />
            </div>
          </div>
          <Title size="h2" mb="xs">
            Set new password
          </Title>
        </div>
        {isExpireSession ? (
          <Alert variant="light" color="red" icon={<IconAlertTriangle />}>
            Your link to reset your password has expired. Please try again.
          </Alert>
        ) : null}
        <Box mt="lg">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack>
              <Controller
                control={control}
                name="password"
                rules={{ required: true }}
                render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
                  <Input
                    disabled={isExpireSession || isLoading}
                    tabIndex={2}
                    label="Password*"
                    background="grey"
                    placeholder="Password"
                    type={showPassword ? 'text' : 'password'}
                    value={value || ''}
                    trailingAddon={
                      <button type="button" onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? (
                          <Icon.Visible className="h-5 w-5" />
                        ) : (
                          <Icon.Hidden className="h-5 w-5" />
                        )}
                      </button>
                    }
                    error={invalid || isResetPasswordError}
                    helperMessage={
                      error?.message || errorPasswordMessage?.response?.data.password[0]
                    }
                    onChange={(e) => {
                      onChange(e);
                    }}
                    showClearButton={false}
                  />
                )}
              />

              <Controller
                control={control}
                name="confirm_password"
                rules={{ required: true }}
                render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
                  <Input
                    disabled={isExpireSession || isLoading}
                    tabIndex={2}
                    label="Confirm Password*"
                    background="grey"
                    placeholder="Confirm Password"
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={value || ''}
                    trailingAddon={
                      <button
                        type="button"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      >
                        {showConfirmPassword ? (
                          <Icon.Visible className="h-5 w-5" />
                        ) : (
                          <Icon.Hidden className="h-5 w-5" />
                        )}
                      </button>
                    }
                    error={invalid}
                    helperMessage={error?.message}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    showClearButton={false}
                  />
                )}
              />
            </Stack>

            <Stack mt="lg">
              <Button
                intent="primary"
                size="large"
                type="submit"
                disabled={isExpireSession || isLoading}
              >
                Reset Password
              </Button>
              <Button
                type="button"
                intent="outline"
                size="large"
                onClick={() => navigate('/login')}
              >
                Go back to login
              </Button>
            </Stack>
          </form>
        </Box>
      </div>
    </div>
  );
}
