import { useEffect, useState } from 'react';
import { useFeaturesStore } from '@common/store/features';
import { useViewStore } from '@common/store/view';
import {
  PERFORMANCE_OPTIONS,
  PerformanceFilter,
  useDashboardPageStore
} from '@pages/Client/Dashboard/store/dashboard';
import { PmsProvider, usePmsProvider } from '@pages/Client/hooks/usePmsProvider';

export const usePerformanceFilter = ({ isSetBudgetForm }: { isSetBudgetForm?: boolean } = {}) => {
  const { view: clientViewMode } = useViewStore();
  const { performanceFilter, setPerformanceFilter } = useDashboardPageStore();
  const { showLimitedDashboard } = useFeaturesStore();
  const { hasIncludedServicesEnabled, pmsProviderId } = usePmsProvider();

  const [isHovered, setIsHovered] = useState({
    grossValuesExclExtraServices: false,
    grossValuesInclExtraServices: false,
    netValuesExclExtraServices: false,
    netValuesInclExtraServices: false
  });

  const performanceOptions = PERFORMANCE_OPTIONS.filter((item) => {
    if (pmsProviderId === PmsProvider.BED_24_V2_API) {
      return item.value === 'grossValuesInclExtraServices';
    }
    if ((clientViewMode === 'admin' || hasIncludedServicesEnabled()) && !isSetBudgetForm) {
      return true;
    }
    return (
      item.value !== 'grossValuesInclExtraServices' && item.value !== 'netValuesInclExtraServices'
    );
  });

  useEffect(() => {
    if (pmsProviderId === PmsProvider.BED_24_V2_API) {
      return setPerformanceFilter('grossValuesInclExtraServices');
    }

    if (
      clientViewMode !== 'client' ||
      performanceOptions.map((item) => item.value).includes(performanceFilter)
    ) {
      return;
    }

    setPerformanceFilter(performanceOptions[0].value);
  }, [clientViewMode, pmsProviderId]);

  const currentPerformanceFilter = performanceOptions.find(
    (item) => item.value === performanceFilter
  );

  const performanceOptionsDisabled = (option: { value: string }) => {
    if (clientViewMode === 'admin') {
      return false;
    }
    return (
      showLimitedDashboard() &&
      [
        'netValuesExclExtraServices',
        'netValuesInclExtraServices',
        'grossValuesInclExtraServices'
      ].includes(option.value)
    );
  };

  const onPerformanceOptionChange = (filter: PerformanceFilter) => {
    setPerformanceFilter(filter);
  };

  return {
    performanceFilter,
    performanceOptions,
    currentPerformanceFilter,
    performanceOptionsDisabled,
    onPerformanceOptionChange,
    isHovered,
    setIsHovered
  };
};
