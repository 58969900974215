import { useQuotationsListStore } from '@common/store/group-displacement-list-store';
import { Select, ComboboxItem } from '@mantine/core';
import { quotationStatusOptions } from '@pages/Client/PricingStrategy/GroupDisplacement/schema/quotation';
import { find, isNil } from 'lodash-es';
import { useTranslation } from 'react-i18next';

const quotationStatusSelect = Object.entries(quotationStatusOptions).map(([key, value]) => ({
  value: key,
  label: value.label
}));
export function FilterByStatus() {
  const { t } = useTranslation();
  const paramKey = 'status';
  const { params, setParams, setFilters } = useQuotationsListStore();

  function handleChange(option: ComboboxItem) {
    setFilters({
      [paramKey]: isNil(option) ? undefined : option.label
    });
    setParams({ [paramKey]: isNil(option) ? undefined : option.value });
  }

  return (
    <Select
      searchable
      clearable
      variant="filled"
      label={t('Status')}
      placeholder={String(t('All statuses'))}
      value={
        find(quotationStatusSelect, (option) => option.value === String(params[paramKey]))?.value ||
        null
      }
      data={Object.keys(quotationStatusOptions).map((key) => ({
        value: key,
        label: quotationStatusOptions[key as keyof typeof quotationStatusOptions].label
      }))}
      onChange={(_value, option) => handleChange(option)}
      comboboxProps={{ withinPortal: false }}
    />
  );
}
