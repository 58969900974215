import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import { API_DATE_FORMAT } from '@common/constants/date';
import { CalendarOptions } from '@pages/Client/Calendar/components/CalendarOptions/CalendarOptions';
import { useHotelEvents } from '@pages/Client/Calendar/hooks/useHotelEvents';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { useCalendarPageStore } from '@pages/Client/Calendar/store/calendar';
import { CalendarView } from '@pages/Client/Calendar/components/BigCalendar/CalendarView';
import { ListView } from '@pages/Client/Calendar/components/BigCalendar/ListView';
import { PricingCellContent } from '@pages/Client/Calendar/pages/Pricing/components/PricingCellContent';
import {
  MedianOptions,
  ELASTICITY_LIST,
  AdjustmentType,
  shorterOccupancyList,
  NO_MARKET_DATA
} from '@pages/Client/Calendar/constants';
import { Typography } from '@common/components/foundations/Typography';
import { useHotelRoomNotes } from '@pages/Client/Calendar/hooks/useHotelRoomNotes';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { Icon } from '@common/components/foundations/icons';
import { Day } from 'use-lilius';
import { useCalendarInteractions } from '@pages/Client/Calendar/hooks/useCalendarInteractions';
import { CalendarOccupancy } from '@pages/Client/Calendar/components/CalendarOccupancy';
import { useTranslation } from 'react-i18next';
import { Feature, useFeaturesStore } from '@common/store/features';
import { useTailwindColor } from '@common/hooks/useTailwindColors';
import { OccupancyLegend } from '@pages/Client/Calendar/pages/Occupancy/components/OccupancyLegend';
import { cn } from '@common/utils/cn';
import { useSurgePrice } from '@pages/Client/Calendar/hooks/useSurgePrice';
import { useLocation } from 'react-router-dom';
import { usePriceDrawerStore } from '@common/store/priceDrawer';
import { useSurgeEvents } from '@pages/Client/PricingStrategy/SurgeProtection/hooks/useSurgeProtection';
import { useMinStayValue } from '@pages/Client/Calendar/hooks/useMinStayValue';
import { useDocumentTitle } from '@mantine/hooks';
import { rem, SimpleGrid, Stack, ThemeIcon, Tooltip } from '@mantine/core';
import { priceTrendStatus } from '@pages/Client/Calendar/utils/priceTrendStatus';
import { IconBoltOff } from '@tabler/icons-react';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { CalendarUserflowIds } from '@common/types/userflow-ids';

export const Pricing = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Pricing Calendar'));
  const {
    liliusInstance: lilius,
    comparePriceTo,
    isTableView,
    adjustment
  } = useCalendarPageStore();
  const [view, _setView] = useState<'list' | 'calendar'>('calendar');
  const {
    roomPrices,
    pricingSettings,
    cachePriceQuery: { isFetching: isCachePriceLoading },
    isClosedRooms,
    isFull: isFullRooms
  } = useRoomPrices();
  const { features, hasMinStayFeature } = useFeaturesStore();
  const { getEventNameByDay } = useHotelEvents();
  const { getNoteByDay } = useHotelRoomNotes();
  const { selectedHotelRoom, selectedHotelRoomId } = useHotelRoomsList();
  const interactions = useCalendarInteractions();
  const orange = useTailwindColor('orange');
  const grey = useTailwindColor('grey');
  const { getSurgePriceForDay } = useSurgePrice();
  const { getSurgeEventByDay } = useSurgeEvents();
  const { setDrawerState, setViewingDate } = usePriceDrawerStore();
  const { getMinStayValue } = useMinStayValue();
  const { hotelDetails } = useHotelDetails();

  // Open calendar drawer with date from query params
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const queryValues = useMemo(() => {
    const date = query.get('date');
    const open = query.get('open');
    return { date, open };
  }, [query]);

  useEffect(() => {
    const { date, open } = queryValues;
    const parsedDate = dayjs(date, 'DD-MM-YYYY', true);

    // Set viewingDate based on queryValues.date
    if (date && parsedDate.isValid()) {
      setViewingDate(parsedDate.toDate());
      if (open === 'false' && lilius) {
        lilius.setViewing(parsedDate.toDate());
      }
    } else {
      setViewingDate(dayjs().startOf('day').toDate());
    }

    // Set drawerState based on queryValues.open
    setDrawerState(open === 'true');
  }, [queryValues.date, queryValues.open]);
  // End open calendar drawer with date from query params

  const getPricingCellContent = (
    day: Date
  ): React.ComponentProps<typeof PricingCellContent>['content'] => {
    const formattedDate = dayjs(day).startOf('day').format(API_DATE_FORMAT);

    if (!roomPrices || !roomPrices.prices.data?.[formattedDate] || !selectedHotelRoom || !lilius) {
      return {
        trending: undefined
      };
    }

    const roomId = selectedHotelRoom.id;

    const roomData = {
      ...roomPrices.prices.data[formattedDate][roomId],
      ...roomPrices.prices.data[formattedDate].property
    };

    const comparePrice =
      comparePriceTo === 'last-week-price'
        ? roomData.one_week_price
        : comparePriceTo === 'yesterday-price'
          ? roomData.yesterday_price
          : roomData.original_price;

    const setting = pricingSettings?.dates?.[formattedDate]?.[roomId];
    const { surgePrice, isSurgePrice } = getSurgePriceForDay(formattedDate, roomId);
    const rawPrice = isSurgePrice && surgePrice ? surgePrice : roomData.price;
    const priceChange = ((rawPrice - comparePrice) / comparePrice) * 100;
    const defaultSettings = pricingSettings?.default?.[roomId];
    const occupancy = `${roomData.occupancy !== undefined ? roomData.occupancy + '%' : 'n.A.'}`;
    const price = isSurgePrice
      ? CurrencyFormatter.format(surgePrice)
      : roomPrices.prices.data[formattedDate].error &&
          roomPrices.prices.data[formattedDate].reason === 'No availability found'
        ? t('No Data')
        : roomData.price
          ? CurrencyFormatter.format(Math.round(roomData.price))
          : 'n.A.';
    const isClosed = isClosedRooms(dayjs(formattedDate).toDate());
    const isFull = isFullRooms(dayjs(formattedDate).toDate());
    const isHasPercentAdj = setting?.percentage_adjustment ? true : false;
    const isHasFixPrice = setting?.fix_price ? true : false;
    const trending = priceTrendStatus({
      isLocked: !!setting?.fix_price || isSurgePrice,
      priceChange,
      rawPrice
    });

    if (comparePriceTo) {
      const comparePrice =
        comparePriceTo === 'last-week-price'
          ? roomData.one_week_price
          : comparePriceTo === 'yesterday-price'
            ? roomData.yesterday_price
            : roomData.original_price;

      return {
        occupancy,
        secondaryLabel: comparePrice ? CurrencyFormatter.format(comparePrice) : 'n.A.',
        primaryLabel: price,
        trending,
        isHasPercentAdj,
        isHasFixPrice,
        isFull,
        isClosed
      };
    }

    if (adjustment) {
      const adjustmentData = {
        occupancy,
        primaryLabel: price,
        isFull,
        isHasPercentAdj,
        isHasFixPrice,
        trending,
        isClosed
      };

      switch (adjustment) {
        case AdjustmentType.Percent: {
          const individualAdj =
            pricingSettings?.dates[formattedDate]?.individual_adjustment?.percentage ||
            pricingSettings?.dates[formattedDate]?.[roomId]?.percentage_adjustment;
          const isShowAdj =
            pricingSettings?.dates[formattedDate]?.individual_adjustment !== undefined ||
            pricingSettings?.dates[formattedDate]?.[roomId]?.percentage_adjustment !== undefined;
          const ind_adj = individualAdj ?? 0;

          Object.assign(adjustmentData, {
            secondaryLabel: (
              <span
                className={cn(isShowAdj ? 'font-bold' : 'font-normal')}
                data-testid="percentageAdjustmentCellValue"
              >
                {t('Adj. by')} {ind_adj < 0 ? '' : ind_adj === 0 ? '' : '+'} {ind_adj}%
              </span>
            )
          });
          break;
        }

        case AdjustmentType.MinMax: {
          const min = setting?.min_price ?? pricingSettings?.default[roomId].min_price ?? 0;
          const max = setting?.max_price ?? pricingSettings?.default[roomId].max_price ?? 0;
          const isReferenceRoom = selectedHotelRoom.is_reference_room;

          Object.assign(adjustmentData, {
            secondaryLabel: (
              <div className="flex flex-col">
                <span
                  className={cn(setting?.min_price !== undefined ? 'font-bold' : 'font-normal')}
                >
                  Min{' '}
                  {isReferenceRoom || features?.includes(Feature.IndividualMinMax)
                    ? CurrencyFormatter.format(min)
                    : 'Derived'}
                </span>
                <span
                  className={cn(setting?.max_price !== undefined ? 'font-bold' : 'font-normal')}
                >
                  Max{' '}
                  {isReferenceRoom || features?.includes(Feature.IndividualMinMax)
                    ? CurrencyFormatter.format(max)
                    : 'Derived'}
                </span>
              </div>
            )
          });
          break;
        }

        case AdjustmentType.MinStay: {
          const {
            originalMinStay,
            suggestedMinStay,
            overrideMinStay,
            shouldUseSuggestedMinStay,
            isSkipMinStayRule,
            hasOverrideMinStay
          } = getMinStayValue(formattedDate, roomId) ?? {};

          const isPastDate = dayjs(day).isBefore(dayjs().startOf('day'));
          const highlightColor = isPastDate ? grey : orange;

          const overrideMinStayToZero = overrideMinStay ?? 0;
          const originalMinStayToZero = originalMinStay ?? 0;

          const renderHighlight = () =>
            shouldUseSuggestedMinStay ? (
              <span
                className="ml-[3px] inline-flex items-center font-bold"
                style={{ color: highlightColor }}
              >
                <Icon.Flash className="h-4 w-4" />
                {suggestedMinStay === 0 ? t('None') : suggestedMinStay}
              </span>
            ) : null;

          const renderPMSMinStay = (): string | null => {
            const pmsOrChannelManager = hotelDetails?.is_channel_manager ? 'CM' : 'PMS';

            if (originalMinStayToZero === 0) {
              return t(`No ${pmsOrChannelManager} Min Stay`);
            }

            return t(`${pmsOrChannelManager} Min Stay {{originalMinStay}}`, {
              originalMinStay: originalMinStayToZero
            });
          };

          const renderMinStayLabel = () => {
            return (
              <>
                {hasOverrideMinStay
                  ? overrideMinStayToZero !== 0
                    ? t('Min Stay')
                    : t('No Min Stay')
                  : t('Min Stay')}
                <div
                  className={cn(
                    shouldUseSuggestedMinStay ? 'line-through decoration-orange decoration-2' : null
                  )}
                >
                  {hasOverrideMinStay
                    ? overrideMinStayToZero === 0
                      ? null
                      : overrideMinStayToZero
                    : t('Not Set')}
                </div>
              </>
            );
          };

          const renderSkipRuleIcon = () =>
            isSkipMinStayRule ? (
              <Tooltip label={t('Correction Ignored')}>
                <ThemeIcon
                  size="xs"
                  variant="transparent"
                  color={isPastDate ? 'gray' : 'dark'}
                  c={isPastDate ? 'gray' : 'dark'}
                >
                  <IconBoltOff />
                </ThemeIcon>
              </Tooltip>
            ) : null;

          Object.assign(adjustmentData, {
            secondaryLabel: (
              <Stack gap={rem(1)} lh="xs">
                {renderPMSMinStay()}
                <div
                  className={cn(
                    'flex items-center justify-end gap-1',
                    hasOverrideMinStay ? 'font-bold' : 'font-normal'
                  )}
                >
                  {renderMinStayLabel()}
                  {renderHighlight()}
                  {renderSkipRuleIcon()}
                </div>
              </Stack>
            )
          });
          break;
        }

        case AdjustmentType.Derivation: {
          const derivation = Math.round(
            setting?.adjustment_to_reference_room ??
              defaultSettings?.adjustment_to_reference_room ??
              0
          );

          const isReferenceRoom = selectedHotelRoom.is_reference_room;
          const derivationText = pricingSettings?.hotel.adjustment_to_reference_room_is_absolute
            ? CurrencyFormatter.format(derivation)
            : `${Math.abs(derivation)}%`;
          const value = `${derivation ? (derivation > 0 ? '+' : '-') : ''}${derivationText}`;
          Object.assign(adjustmentData, {
            secondaryLabel: (
              <span
                className={cn(
                  setting?.adjustment_to_reference_room !== undefined ? 'font-bold' : 'font-normal'
                )}
              >
                {!isReferenceRoom ? 'Der' : ''} {!isReferenceRoom ? value : '-'}
              </span>
            )
          });
          break;
        }

        case AdjustmentType.FixPrices: {
          const fixedPrice =
            setting?.fix_price !== undefined ? CurrencyFormatter.format(setting?.fix_price) : '-';
          Object.assign(adjustmentData, {
            secondaryLabel: (
              <span className={cn(setting?.fix_price !== undefined ? 'font-bold' : 'font-normal')}>
                {fixedPrice}
              </span>
            )
          });
          break;
        }

        case AdjustmentType.Closed: {
          const closedRooms = setting?.closed_rooms ?? 0;
          Object.assign(adjustmentData, {
            secondaryLabel: (
              <span
                className={cn(setting?.closed_rooms !== undefined ? 'font-bold' : 'font-normal')}
              >
                {t('Closed')}: {closedRooms}
              </span>
            )
          });
          break;
        }

        case AdjustmentType.Occupancy: {
          Object.assign(adjustmentData, {
            secondaryLabel: (
              <span className={cn(setting?.occupancy_pricing ? 'font-bold' : 'font-normal')}>
                {setting?.occupancy_pricing ? t('Edited') : t('Default')}
              </span>
            )
          });
          break;
        }

        case AdjustmentType.Aggressiveness: {
          let ind_aggressiveness = pricingSettings?.rpg_arguments.PELL_weekday;
          const dayOfWeek = dayjs(day).startOf('day').day();

          if (dayOfWeek === Day.FRIDAY || dayOfWeek === Day.SATURDAY) {
            ind_aggressiveness = pricingSettings?.rpg_arguments.PELL_weekend;
          }

          if (pricingSettings?.dates[formattedDate]?.PELL) {
            ind_aggressiveness = pricingSettings.dates[formattedDate].PELL;
          }

          const aggr = ELASTICITY_LIST.find((val) => val.value === ind_aggressiveness);

          Object.assign(adjustmentData, {
            secondaryLabel: (
              <span
                className={cn(
                  pricingSettings?.dates[formattedDate]?.PELL !== undefined
                    ? 'font-bold'
                    : 'font-normal'
                )}
              >
                {aggr
                  ? aggr?.label?.replace(shorterOccupancyList[0], shorterOccupancyList[1])
                  : 'Standard'}
              </span>
            )
          });
          break;
        }

        case AdjustmentType.Median: {
          const defaultMedian = pricingSettings?.rpg_arguments.median_lead_time;
          const defaultOption = MedianOptions.find((val) => val.value == defaultMedian);
          const dateMedian = pricingSettings?.dates[formattedDate]?.median_lead_time;
          const dateMedianOption = MedianOptions.find((val) => val.value === dateMedian);

          Object.assign(adjustmentData, {
            secondaryLabel: (
              <span
                className={cn(
                  pricingSettings?.dates[formattedDate]?.median_lead_time !== undefined
                    ? 'font-bold'
                    : 'font-normal'
                )}
              >
                {dateMedian ? dateMedianOption?.label : defaultOption?.label}
              </span>
            )
          });
          break;
        }
        default:
          break;
      }

      return adjustmentData;
    }

    return {
      occupancy: `${roomData.occupancy != undefined ? roomData.occupancy + '%' : 'n.A.'}`,
      secondaryLabel: comparePrice ? CurrencyFormatter.format(comparePrice) : 'n.A.',
      primaryLabel: roomData.suggested_price
        ? CurrencyFormatter.format(roomData.suggested_price)
        : 'n.A.',
      trending
    };
  };

  const getCellSurgeProtectionColorClassName = (day: Date) => {
    if (!selectedHotelRoomId) return '';

    const isSurgeEvent = getSurgeEventByDay(day)?.active;

    if (!isSurgeEvent) return '';

    return 'bg-uiGreen bg-opacity-20 text-darkGreen transition-all duration-200 font-bold';
  };

  const getNoteAndEvent = (day: Date): string | null => {
    if (!selectedHotelRoomId) return null;
    const formattedDate = dayjs(day).startOf('day').format(API_DATE_FORMAT);
    const noMarketData =
      roomPrices?.prices.data?.[formattedDate]?.property?.note === NO_MARKET_DATA
        ? t('No Market Data')
        : null;
    const note = getNoteByDay(day);
    const event = getEventNameByDay(day);
    const isSurgeEvent = getSurgeEventByDay(day)?.active;

    const surgeEventNote = isSurgeEvent ? t('Surge Event') : null;

    return noMarketData ?? surgeEventNote ?? note ?? event ?? null;
  };

  if (!roomPrices?.prices?.data && !isCachePriceLoading)
    return (
      <div className="mx-auto max-w-md sm:max-w-3xl">
        <div className="w-full rounded-lg border-2 border-dashed border-mediumGrey p-12">
          <div className="flex flex-col items-center gap-4">
            <Icon.Calendar className="text-copyTextGrey" />
            <div className="flex flex-col items-center gap-1">
              <Typography element="span" variant="paragraph-1" color="copyTextGrey">
                {t('Pricing data not yet available')}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div>
      <SimpleGrid spacing="lg" cols={{ base: 1, xl: 2 }}>
        {isTableView ? <div /> : <CalendarOccupancy />}
        {isTableView ? (
          <div className="inline-flex flex-1 items-center justify-end text-paragraph-3 leading-none text-copyTextGrey">
            <OccupancyLegend />
            {hasMinStayFeature() ? (
              <>
                <div className="mr-4 h-4 border border-darkGrey-reduced"></div>
                <div className="mr-4 inline-flex items-center">
                  <div className={cn('mr-1 h-4 w-4 shrink-0 rounded-full bg-darkGreen-reduced')} />
                  <Typography variant="meta-1">{t('Min Stay Visualiser')}</Typography>
                </div>
              </>
            ) : null}
          </div>
        ) : null}
        {isTableView ? null : <CalendarOptions optionType="pricing" />}
      </SimpleGrid>

      <div>
        {view === 'calendar' ? (
          <CalendarView
            {...interactions}
            context="pricing"
            isLoading={isCachePriceLoading}
            surgeEvent={(day) => {
              const isSurgeEvent = getSurgeEventByDay(day)?.active ?? false;
              const surgePriceForDay = getSurgePriceForDay(
                dayjs(day).format(API_DATE_FORMAT),
                selectedHotelRoomId
              );
              const hasSurgePrice = surgePriceForDay?.isSurgePrice;
              return {
                isSurgeEvent,
                hasSurgePrice
              };
            }}
            topRightCellContent={(day) => <>{getNoteAndEvent(day)}</>}
            dateCellContent={(day) => (
              <PricingCellContent content={getPricingCellContent(day)} day={day} />
            )}
            dateCellClassName={(day) => cn(getCellSurgeProtectionColorClassName(day))}
            pricingSettings={pricingSettings}
            selectedRoomId={selectedHotelRoomId}
            roomPrices={roomPrices}
            liliusInstance={lilius!}
            getPricingCellContent={getPricingCellContent}
          />
        ) : null}
        {view === 'list' && <ListView></ListView>}
      </div>
    </div>
  );
};
