import { useMutation, useQueryClient } from '@tanstack/react-query';
import { RoomsQueryKeys } from '@common/types/query-keys';
import { cleanPriceCache } from '@common/api/pricingAlgorithm';
import { useNotificationsStore } from '@common/store/notifications';
import { CleanPriceCachePayload } from '@common/api/pricingAlgorithm/types';

export const useCleanPriceCache = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (payload: CleanPriceCachePayload) => {
      return cleanPriceCache(payload);
    },
    onSuccess: () => {
      addNotification('success', 'Price Cache Cleared Successfully');

      queryClient.invalidateQueries({
        queryKey: [RoomsQueryKeys.GET_ROOM_PRICES]
      });
    },
    onError: () => {
      addNotification('fail', 'Failed to Clear Price Cache');
    }
  });

  return {
    cleanPriceCache: mutate,
    isLoading
  };
};
