import { useState, useMemo, useEffect, useCallback } from 'react';
import { isNil, debounce, isEqual, isEmpty } from 'lodash-es';
import { FieldValues, useFormContext, UseFormSetError } from 'react-hook-form';
import {
  useRecommendedMinStay,
  useRecommendedPrice
} from '@pages/Client/Calendar/components/Tables/hooks';
import dayjs from 'dayjs';
import { applyIndividualAdjustment } from '@common/api/hotel';
import { useTransformData } from '@pages/Client/Calendar/components/BulkEdit/hooks/useTransformFormData';
import { TFunction } from 'i18next';
import { ADJUSTMENT } from '@pages/Client/Calendar/components/BulkEdit/types/adjustments';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Flags } from '@common/constants/flags';

const REASON_TO_FORM_NAME = {
  min_price: ADJUSTMENT.MIN_MAX,
  max_price: ADJUSTMENT.MIN_MAX,
  fix_price: ADJUSTMENT.FIX_PRICES,
  price_too_low: 'priceTooLow'
} as const;

const REASON_TO_FIELD_KEY = {
  min_price: 'minPrice',
  max_price: 'maxPrice',
  fix_price: 'fixPrice',
  price_too_low: 'priceTooLow'
} as const;

type ApplyAdjustmentsErrorResponse = AxiosError<{
  error: Record<
    string,
    Record<
      string,
      {
        error: boolean;
        reason: Record<string, number>;
      }
    >
  >;
}>;

const getFormNameFromReason = (reason: string): ADJUSTMENT | 'priceTooLow' | null => {
  return REASON_TO_FORM_NAME[reason as keyof typeof REASON_TO_FORM_NAME] ?? null;
};

const getFieldKeyFromReason = (reason: string): string => {
  return REASON_TO_FIELD_KEY[reason as keyof typeof REASON_TO_FIELD_KEY] ?? '';
};

const API_ERROR_PATH = 'apiErrors' as const;

const handlePreflightErrors = (
  error: ApplyAdjustmentsErrorResponse,
  currentDateStr: string,
  setError: UseFormSetError<FieldValues>,
  t: TFunction
) => {
  const errors = error.response?.data.error[currentDateStr];

  if (!errors) return;

  Object.entries(errors).forEach(([roomId, roomError]) => {
    Object.keys(roomError.reason).forEach((reason) => {
      const formName = getFormNameFromReason(reason);
      const fieldKey = getFieldKeyFromReason(reason);

      if (formName && fieldKey) {
        const errorPath = `${API_ERROR_PATH}.${formName}.${roomId}.${fieldKey}`;
        const requiredPrice = roomError.reason[reason];

        const errorMessage =
          formName === 'priceTooLow'
            ? t('Price is too low. Please contact support.')
            : t('Value must be at least {{price}}', {
                price: CurrencyFormatter.format(requiredPrice)
              });

        setError(errorPath, {
          message: errorMessage
        });
      }
    });
  });
};

export function useFormPreflight() {
  const { transformData } = useTransformData();
  const flags = useFlags();
  const formContext = useFormContext();
  const { t } = useTranslation();
  const [isUpdating, setIsUpdating] = useState(false);
  const { setRecommendedPriceData } = useRecommendedPrice(
    formContext ? dayjs(formContext.getValues('editDate')) : undefined
  );
  const { pricingSettingsQuery } = usePricingSettings();
  const { originalMinStayData, recommendedMinStayData, setRecommendedMinStayData } =
    useRecommendedMinStay(formContext ? dayjs(formContext.getValues('editDate')) : undefined);

  const clearApiErrors = useCallback(() => {
    if (!formContext) return;

    // Clear all errors under the API_ERROR_PATH namespace
    Object.values(ADJUSTMENT).forEach((adjustment) => {
      formContext.clearErrors(`${API_ERROR_PATH}.${adjustment}`);
    });
    // Also clear price too low errors
    formContext.clearErrors(`${API_ERROR_PATH}.priceTooLow`);
  }, [formContext]);

  const runPreflight = useCallback(
    async (priceSettings: string, currentDateStr: string) => {
      setIsUpdating(true);
      try {
        if (isNil(priceSettings) || isEqual(priceSettings, 'null')) {
          throw new Error('Price settings are empty');
        }

        const { data } = await applyIndividualAdjustment({
          start_date: currentDateStr,
          end_date: currentDateStr,
          json_settings: priceSettings,
          is_write_to_cache: false
        });

        // Only clear errors after successful API response
        clearApiErrors();

        const newRecommendedPrice: Record<
          number,
          {
            price: number;
            suggested_price: number;
          }
        > = {};
        const newSuggestedMinStay = { ...originalMinStayData, ...recommendedMinStayData } as Record<
          number,
          number
        >;

        for (const roomId in data[currentDateStr] ?? {}) {
          const roomData = data[currentDateStr][roomId] ?? {};
          newRecommendedPrice[roomId] = newRecommendedPrice[roomId] ?? {};
          newRecommendedPrice[roomId].price = roomData.price ?? undefined;
          newRecommendedPrice[roomId].suggested_price = roomData.suggested_price ?? undefined;
          newSuggestedMinStay[roomId] = roomData.suggested_min_stay ?? undefined;
        }

        setRecommendedPriceData(newRecommendedPrice);
        setRecommendedMinStayData(newSuggestedMinStay);
      } catch (error: unknown) {
        if (axios.isAxiosError(error) && error.response?.data) {
          // Clear previous API errors before setting new ones
          clearApiErrors();
          handlePreflightErrors(
            error as ApplyAdjustmentsErrorResponse,
            currentDateStr,
            formContext.setError,
            t
          );
        }
        console.error(error);
      } finally {
        setIsUpdating(false);
      }
    },
    [formContext, t, clearApiErrors]
  );

  const shouldRunPreflight = useCallback(() => {
    // Get all error keys except apiErrors
    const frontendErrors = Object.keys(formContext.formState.errors).filter(
      (key) => key !== API_ERROR_PATH
    );

    const hasFrontendErrors = frontendErrors.length > 0;

    // If there are frontend errors, only run if feature flag is ON
    // If no frontend errors, always run
    const shouldRun = !hasFrontendErrors || flags[Flags.PricingCheeseLayers];

    return shouldRun;
  }, [formContext?.formState.errors, flags]);

  const debouncedPreflight = useMemo(
    () =>
      debounce(async () => {
        if (!formContext) return;

        const isBulkEdit = formContext.getValues('isBulkEdit');
        if (isBulkEdit) return;

        // Early return if we shouldn't run preflight
        if (!shouldRunPreflight()) {
          console.info('Skipping preflight due to frontend errors');
          return;
        }

        try {
          const formValues = formContext.getValues();
          const { data: latestPricingSettings } = await pricingSettingsQuery.refetch();
          const { newPricing, dates } = await transformData(latestPricingSettings, formValues);

          await runPreflight(JSON.stringify(newPricing), dates[0]);
        } catch (error) {
          console.error('Preflight failed:', error);
        }
      }, 700),
    [formContext, flags]
  );

  useEffect(() => {
    return () => {
      debouncedPreflight.cancel();
    };
  }, []);

  return {
    preflightCallback: debouncedPreflight,
    isUpdating,
    runPreflight
  };
}
