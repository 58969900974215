import {
  EditQuotationPayload,
  Quotation,
  QuotationResultForm
} from '@common/api/pricingAlgorithm/types';
import { Icon } from '@common/components/foundations/icons';
import { useEventTracking } from '@common/hooks/useEventTracking';
import { ModalFooter } from '@common/mantine/components/modal-footer';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Avatar,
  Badge,
  Button,
  Chip,
  Divider,
  Flex,
  Grid,
  Group,
  NumberInput,
  Paper,
  rem,
  ScrollArea,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
  Textarea,
  Title,
  Tooltip,
  useMantineTheme
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import ResultsTable from '@pages/Client/PricingStrategy/GroupDisplacement/components/QuotationResult/ResultsTable';
import {
  useSaveQuotation,
  useUpdateQuotation
} from '@pages/Client/PricingStrategy/GroupDisplacement/hooks/useGroupDisplacement';
import {
  EditQuotationResultSchema,
  QuotationStatusOption,
  quotationStatusOptions
} from '@pages/Client/PricingStrategy/GroupDisplacement/schema/quotation';
import useStepStore from '@pages/Client/PricingStrategy/GroupDisplacement/store/step';
import dayjs from 'dayjs';
import { get, isEmpty, isEqual, sumBy } from 'lodash-es';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface QuotationResultProps {
  data?: Quotation;
  isLoading?: boolean;
  onClose: () => void;
  mode: 'edit' | 'add';
}
interface OverrideValues {
  totalQuote: number;
  avgPricePerRoomPerNight: number;
  groupProfit: number;
  discountRate: number;
}
export const QuotationResult = ({ data, isLoading, onClose, mode }: QuotationResultProps) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints?.md})`);
  const { t } = useTranslation();
  const { setActiveStepQuotation } = useStepStore();
  const subdomain = window.location.hostname.split('.')[0];
  const isIframe = subdomain.includes('iframe');
  const { trackEvent } = useEventTracking();

  const {
    control,
    reset,
    getValues,
    watch,
    setValue,
    formState: { errors }
  } = useForm<QuotationResultForm>({
    resolver: zodResolver(EditQuotationResultSchema),
    defaultValues: {
      notes: '',
      total_quote: ''
    }
  });

  const totalGroupRooms = sumBy(Object.values(data?.results?.data || {}), 'group_rooms');
  const newTotalQuote = watch('total_quote');
  const totalQuote = data?.total_quote;
  const recommendedGroupPrice = data?.results?.recommended_group_price;

  const { hotelDetails } = useHotelDetails();
  const { saveQuotation } = useSaveQuotation();
  const [status, setStatus] = useState<QuotationStatusOption | null>('IN_REVIEW');
  const [isOverridePrice, setIsOverridePrice] = useState<boolean>(false);
  const { updateQuotation } = useUpdateQuotation();
  const [overrideValues, setOverrideValues] = useState<OverrideValues>();

  let format = hotelDetails?.prefered_date_format;
  format = format?.replace(/([-./]?Y{2,4})/g, '').trim();
  format = format?.replace(/([-./]Y{2,4})/g, '').trim();

  const handleSaveQuotation = () => {
    if (!data) return;

    let dataOverride: EditQuotationPayload = {
      total_quote: recommendedGroupPrice,
      average_price: parseFloat((Number(recommendedGroupPrice) / totalGroupRooms).toFixed(0))
    };

    if (isOverridePrice) {
      dataOverride = {
        total_quote: overrideValues?.totalQuote,
        average_price: overrideValues?.avgPricePerRoomPerNight
      };
    }

    if (mode === 'add') {
      saveQuotation({
        ...data,
        notes: getValues('notes') as string,
        status,
        ...(!isEmpty(dataOverride) ? dataOverride : {})
      });
      trackEvent('QuoteSaved', {
        tags: {
          quote: JSON.stringify({
            hotelId: data.hotel_id,
            startDate: data.start_date,
            lengthOfStay: data.length_of_stay,
            roomCount: data.total_rooms,
            priceOverrideUsed: !!isOverridePrice
          })
        }
      });
    }
    if (mode === 'edit') {
      updateQuotation({
        notes: getValues('notes'),
        status,
        id: data?.id,
        ...(!isEmpty(dataOverride) ? dataOverride : {})
      });
      trackEvent('QuoteUpdated');
      reset();
    }
    onClose();
  };

  const assignOverrideValues = (quote?: number) => {
    if (quote === undefined || quote <= 0) return;

    if (isEqual(quote, recommendedGroupPrice)) {
      setIsOverridePrice(false);
    } else {
      setIsOverridePrice(true);
    }
    setOverrideValues({
      totalQuote: Number(quote),
      avgPricePerRoomPerNight: parseFloat((Number(quote) / totalGroupRooms).toFixed(0)),
      groupProfit: Number(quote) - (data?.results?.displaced_revenue ?? 0),
      discountRate: data?.results?.total_base_rate
        ? Math.round(
            ((data?.results?.total_base_rate - Number(quote)) / data?.results?.total_base_rate) *
              100
          )
        : 0
    });
  };

  useEffect(() => {
    assignOverrideValues(totalQuote);
  }, [totalQuote]);

  useEffect(() => {
    if (data?.notes && mode === 'edit') {
      setValue('notes', data?.notes);
    }
  }, [data, mode]);

  useEffect(() => {
    assignOverrideValues(Number(newTotalQuote));
  }, [newTotalQuote]);

  return (
    <>
      <Stack px="lg" py={0} pt={isMobile ? 0 : 'md'} gap="md">
        {mode === 'add' && (
          <Title c="dark" size="md">
            {data?.group_name}
          </Title>
        )}
        <Grid gutter="md">
          <Grid.Col span={isMobile ? 12 : 7}>
            <Paper withBorder p="md" h="100%">
              <Flex justify="space-between">
                <Group>
                  <Avatar radius="xl" bg="indigo">
                    <Icon.UserGroup className="fill-white w-5 h-5" />
                  </Avatar>
                  <Stack gap="0">
                    <Text size="lg" c="dark" td={isOverridePrice ? 'line-through' : 'none'}>
                      {CurrencyFormatter.format(data?.results?.recommended_group_price ?? 0)}
                    </Text>
                    <Group gap={rem(4)}>
                      <Text size="xs" miw={120}>
                        {t('Recommended Group Price')}
                      </Text>
                      <Tooltip
                        label={
                          <Text size="xs" c="white">
                            {t(
                              'Recommended Group Price is the price that we recommend charging the group. It is a price that both a) gives a discount to the client and b) makes profit for you.'
                            )}
                          </Text>
                        }
                        maw={rem(300)}
                        py="xs"
                        px="xs"
                        multiline
                        position="bottom"
                      >
                        <span>
                          <Icon.Help className="h-4 w-4 fill-grey" />
                        </span>
                      </Tooltip>
                    </Group>
                  </Stack>
                </Group>
                <Stack gap="0" align="center">
                  <Text size="xs">Overwrite</Text>
                  <Controller
                    control={control}
                    name="total_quote"
                    render={({ field }) => (
                      <NumberInput
                        {...field}
                        leftSection={<Text size="sm">{CurrencyFormatter.currencySymbol()}</Text>}
                        value={!isEqual(totalQuote, recommendedGroupPrice) ? totalQuote : ''}
                        onInput={(event) => {
                          field.onChange(event);
                          if (event.currentTarget.value === '') setIsOverridePrice(false);
                        }}
                        variant="filled"
                        maw={rem(110)}
                        error={errors.total_quote?.message}
                        hideControls
                      />
                    )}
                  />
                </Stack>
              </Flex>

              <Stack gap="xs">
                <Divider mt={rem(16)} />
                <Group justify="space-between">
                  <Text size="sm">{t('Avg price per room per night')}</Text>
                  {isLoading ? (
                    <Skeleton height={rem(20)} maw={rem(50)} />
                  ) : (
                    <Group gap={rem(4)}>
                      <Text
                        size={isOverridePrice ? 'sm' : 'md'}
                        c="dark"
                        td={isOverridePrice ? 'line-through' : 'none'}
                      >
                        {CurrencyFormatter.format(data?.results?.average_price_per_room_per_night)}
                      </Text>
                      {isOverridePrice && (
                        <Text size="md" c="dark">
                          {CurrencyFormatter.format(overrideValues?.avgPricePerRoomPerNight)}
                        </Text>
                      )}
                    </Group>
                  )}
                </Group>
                <Group justify="space-between">
                  <Group gap={rem(4)}>
                    <Text size="sm">{t('Displaced Revenue')}</Text>
                    <Tooltip
                      label={
                        <Text size="xs" c="white">
                          {t(
                            'The amount of revenue we predict you would lose if you let this group stay for free. You should charge the group a higher price than the displaced revenue in order to make money from the booking.'
                          )}
                        </Text>
                      }
                      maw={rem(300)}
                      py="xs"
                      px="xs"
                      multiline
                      position="bottom"
                    >
                      <span className="mx-auto">
                        <Icon.Help className="h-4 w-4 fill-grey" />
                      </span>
                    </Tooltip>
                  </Group>
                  {isLoading ? (
                    <Skeleton height={rem(20)} maw={rem(50)} />
                  ) : (
                    <Text size="md" c="dark">
                      {CurrencyFormatter.format(data?.results?.displaced_revenue)}
                    </Text>
                  )}
                </Group>
                <Group justify="space-between">
                  <Group gap={rem(4)}>
                    <Text size="sm">{t('Group Profit')}</Text>
                    <Tooltip
                      label={
                        <Text size="xs" c="white">
                          {t(
                            'The extra profit we predict you will make by accepting this group at this price'
                          )}
                        </Text>
                      }
                      maw={rem(300)}
                      py="xs"
                      px="xs"
                      multiline
                      position="bottom"
                    >
                      <span className="mx-auto">
                        <Icon.Help className="h-4 w-4 fill-grey" />
                      </span>
                    </Tooltip>
                  </Group>
                  {isLoading ? (
                    <Skeleton height={rem(20)} maw={rem(50)} />
                  ) : (
                    <Group gap={rem(4)}>
                      <Text
                        size={isOverridePrice ? 'sm' : 'md'}
                        c="dark"
                        td={isOverridePrice ? 'line-through' : 'none'}
                      >
                        {CurrencyFormatter.format(
                          (recommendedGroupPrice || 0) - (data?.results.displaced_revenue || 0)
                        )}
                      </Text>
                      {isOverridePrice && (
                        <Text size="md" c="dark">
                          {CurrencyFormatter.format(overrideValues?.groupProfit)}
                        </Text>
                      )}
                    </Group>
                  )}
                </Group>
                <Divider my={rem(12)} />
                <Group justify="space-between">
                  <Group gap={rem(4)}>
                    <Text size="sm">{t('Total Standard Price')}</Text>
                  </Group>
                  {isLoading ? (
                    <Skeleton height={rem(20)} maw={rem(50)} />
                  ) : (
                    <Text size="md" c="dark">
                      {CurrencyFormatter.format(data?.results?.total_base_rate)}
                    </Text>
                  )}
                </Group>
                <Group justify="space-between">
                  <Text size="sm">{t('Discount vs Standard Price')}</Text>
                  {isLoading ? (
                    <Skeleton height={rem(20)} maw={rem(50)} />
                  ) : (
                    <Group gap={rem(4)}>
                      <Text
                        size={isOverridePrice ? 'sm' : 'md'}
                        c="dark"
                        td={isOverridePrice ? 'line-through' : 'none'}
                      >
                        {data?.results?.discount_vs_base_rate_in_percentage}
                        {'%'}
                      </Text>
                      {isOverridePrice && (
                        <Text size="md" c="dark">
                          {overrideValues?.discountRate}
                          {'% '}
                        </Text>
                      )}
                      <Text size="md" c="dark">
                        {t('off')}
                      </Text>
                    </Group>
                  )}
                </Group>
              </Stack>
            </Paper>
          </Grid.Col>
          <Grid.Col span={isMobile ? 12 : 5}>
            <Stack h="100%">
              <Paper withBorder p="md">
                <Group justify="space-between" mb={5}>
                  <Group>
                    <Avatar radius="xl" bg="green.9">
                      <Icon.Bed className="fill-white w-5 h-5" />
                    </Avatar>
                    <Stack gap={0}>
                      {isLoading ? (
                        <Skeleton height={rem(20)} maw={rem(50)} />
                      ) : (
                        <Text size="lg" c="dark">
                          {totalGroupRooms}
                        </Text>
                      )}
                      <Text size="xs">{t('Room Nights')}</Text>
                    </Stack>
                  </Group>
                </Group>

                <Stack gap="0">
                  <Divider my="md" />
                  <Group justify="space-between">
                    <Text size="sm">{t('Dates')}</Text>
                    {isLoading ? (
                      <Skeleton height={rem(20)} maw={rem(100)} />
                    ) : (
                      <Text size="sm" c="dark">
                        {dayjs(data?.start_date).format('DD/MM/YYYY') ?? '-'} {'-'}{' '}
                        {dayjs(data?.start_date)
                          .add((data?.length_of_stay as number) - 1, 'day')
                          .format('DD/MM/YYYY') ?? '-'}
                      </Text>
                    )}
                  </Group>
                  <Group justify="space-between">
                    <Text size="sm">{t('Nights')}</Text>
                    {isLoading ? (
                      <Skeleton height={rem(20)} maw={rem(100)} />
                    ) : (
                      <Text size="sm" c="dark">
                        {data?.length_of_stay} Nights
                      </Text>
                    )}
                  </Group>
                </Stack>
              </Paper>
              <Group grow h="100%">
                <Paper withBorder py="md" px="xs" h="100%">
                  <Stack gap={rem(8)} justify="space-between" align="center" h="100%">
                    {isLoading ? (
                      <Skeleton height={rem(20)} maw={rem(50)} />
                    ) : (
                      <Text size="sm" c="dark" ta="center" mt="md">
                        {data?.discount_share_in_percentage}%
                      </Text>
                    )}
                    <Flex align="center">
                      <Text size="xs" ta="center" maw="60">
                        {t('Discount Share')}
                      </Text>
                      <Tooltip
                        label={
                          <Text size="xs" c="white">
                            {t(
                              `How much discount do you want to give, at the expense of profit? If Discount share is 0%, the Group will receive no discount. If Discount Share is 100%, you will make no additional profit. As default we set this to 50% - so the benefit of the group is shared between discount and profit.`
                            )}
                          </Text>
                        }
                        maw={rem(300)}
                        py="xs"
                        px="xs"
                        multiline
                        position="bottom"
                      >
                        <span className="mx-auto">
                          <Icon.Help className="h-4 w-4 fill-grey" />
                        </span>
                      </Tooltip>
                    </Flex>
                  </Stack>
                </Paper>

                <Paper withBorder py="md" px="xs" h="100%">
                  <Stack gap={rem(8)} justify="space-between" align="center" h="100%">
                    {isLoading ? (
                      <Skeleton height={rem(20)} maw={rem(50)} />
                    ) : (
                      <Text size="sm" c="dark" ta="center" mt="md">
                        {`${get(data, 'base_rate_offset', 0) === 0 ? '' : get(data, 'base_rate_offset', 0) > 0 ? '+' : ''}${CurrencyFormatter.format(get(data, 'base_rate_offset', 0))}`}
                      </Text>
                    )}
                    <Flex align="center">
                      <Text size="xs" ta="center">
                        {t('Base Rate Offset')}
                      </Text>
                      <Tooltip
                        label={
                          <Text size="xs" c="white">
                            {t(
                              `The difference between the mapped Base rate for your Reference Room and the rate relevant for this group. e.g. to include breakfast or where your 'Run of House' rate is different from your Reference Room rate.`
                            )}
                          </Text>
                        }
                        maw={rem(300)}
                        py="xs"
                        px="xs"
                        multiline
                        position="bottom"
                      >
                        <span className="mx-auto">
                          <Icon.Help className="h-4 w-4 fill-grey" />
                        </span>
                      </Tooltip>
                    </Flex>
                  </Stack>
                </Paper>
                <Paper withBorder py="md" px="xs" h="100%">
                  <Stack justify="space-between" align="center" gap={rem(8)} h="100%">
                    <Text size="sm" c="dark" ta="center" mt="md">
                      {t('Run of House')}
                    </Text>
                    <Group mb="md" gap={rem(6)}>
                      <Text size="xs" ta="center">
                        {t('Mode')}
                      </Text>
                      <Tooltip
                        label={
                          <Text size="xs" c="white">
                            {t(
                              `Run of House pricing is where the group pays one price regardless of room types. This is useful for keeping things simple when you don't have enough of one room type to host the whole group.`
                            )}
                          </Text>
                        }
                        maw={rem(300)}
                        py="xs"
                        px="xs"
                        multiline
                        position="bottom"
                      >
                        <span className="mx-auto">
                          <Icon.Help className="h-4 w-4 fill-grey" />
                        </span>
                      </Tooltip>
                    </Group>
                  </Stack>
                </Paper>
              </Group>
            </Stack>
          </Grid.Col>
        </Grid>

        <ScrollArea>
          <Stack gap={0}>
            <Title c="dark" size="md" mb="sm" mt="sm">
              {t('Calculation Details')}
            </Title>
            <ResultsTable data={data} isLoading={isLoading} />
          </Stack>
        </ScrollArea>
      </Stack>
      <Stack gap="xs" ml="lg" py="md">
        <Text size="sm">{t('Status')}</Text>
        <Group>
          <Chip.Group>
            {Object.values(quotationStatusOptions).map((item) => (
              <Chip
                radius="xs"
                styles={{
                  iconWrapper: { display: 'none' },
                  label: {
                    padding: 'var(--mantine-spacing-xs) var(--mantine-spacing-sm)'
                  }
                }}
                component={Badge}
                color={item.color}
                variant="filled"
                key={item.value}
                value={item.value}
                checked={status === item.value}
                onChange={() => setStatus(item.value as QuotationStatusOption)}
              >
                {t(item.label)}
              </Chip>
            ))}
          </Chip.Group>
        </Group>
      </Stack>
      <Group align="center" justify="start" pb="lg" mx="lg">
        {isLoading ? (
          <Skeleton height={rem(40)} />
        ) : (
          <Controller
            name="notes"
            control={control}
            render={({ field }) => (
              <Textarea
                {...field}
                label={t('Notes')}
                placeholder={t('Add your notes here') as string}
                className="flex-1"
                error={errors.notes?.message}
              />
            )}
          />
        )}
      </Group>
      {isIframe ? (
        isLoading ? (
          <Skeleton height={rem(16)} maw="30%" />
        ) : (
          <Flex align="center" pb="md">
            <Text size="xs" c="gray.6">
              {t('Created by')} {data?.author} {t('on')}{' '}
              {dayjs(data?.created_at).tz().format('YYYY-MM-DD HH:mm')}
            </Text>
          </Flex>
        )
      ) : null}
      <ModalFooter>
        {mode === 'add' ? (
          <Button
            type="button"
            variant="transparent"
            color="gray.8"
            onClick={() => {
              setActiveStepQuotation(0);
            }}
          >
            {t('Back')}
          </Button>
        ) : null}
        {mode === 'edit' ? (
          <Button
            type="button"
            variant="transparent"
            color="gray.8"
            onClick={() => {
              onClose();
            }}
          >
            {t('Cancel')}
          </Button>
        ) : null}
        <Button onClick={handleSaveQuotation}>{t('Save')}</Button>
      </ModalFooter>
    </>
  );
};
