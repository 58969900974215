export const readOnlyRoutes: string[] = [
  '/client/:id/dashboard',
  '/client/:id/dashboard/performance',
  '/client/:id/dashboard/analytics',
  '/client/:id/dashboard/market',
  '/client/:id/calendar',
  '/client/:id/calendar/pricing',
  '/client/:id/calendar/occupancy',
  '/client/:id/calendar/pickup',
  '/client/:id/calendar/revenue',
  '/client/:id/chart',
  '/client/:id/chart/price',
  '/client/:id/account',
  '/client/:id/account/profile-settings',
  '/client/:id/account/profile-settings/language',
  '/client/:id/account/profile-settings/password',
  '/client/:id/account/profile-settings/personal-information',
  '/client/:id/help'
];
