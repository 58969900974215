import { useQuotationsListStore } from '@common/store/group-displacement-list-store';
import { Stack } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { useTranslation } from 'react-i18next';

export function FilterByStartDate({
  setIsDateFocused
}: { setIsDateFocused: (focused: boolean) => void }) {
  const { t } = useTranslation();
  const paramKey = 'start_date';
  const { params, setParams, setFilters } = useQuotationsListStore();

  function handleChange(value: Date | null) {
    const date = isNil(value) ? undefined : dayjs(value).format('YYYY-MM-DD');
    setFilters({
      [paramKey]: date
    });
    setParams({ [paramKey]: isNil(date) ? undefined : date.toString() });
  }

  return (
    <Stack gap="0">
      <DateInput
        clearable
        variant="filled"
        value={params[paramKey] ? new Date(params[paramKey]) : null}
        valueFormat="YYYY-MM-DD"
        label={t('Start Date')}
        placeholder="All dates"
        onChange={handleChange}
        onFocus={() => setIsDateFocused(true)}
        onBlur={() => setIsDateFocused(false)}
      />
    </Stack>
  );
}
