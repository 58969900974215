import { portalMembershipPricing } from '@common/api/hotel';
import { Icon } from '@common/components/foundations/icons';
import { useAppFlags } from '@common/hooks/useAppFlags';
import { useModal } from '@common/hooks/useModal';
import { useHotelStore } from '@common/store/auth';
import { useViewStore } from '@common/store/view';
import {
  ActionIcon,
  Button,
  ButtonVariant,
  Divider,
  Flex,
  Group,
  Popover,
  Stack
} from '@mantine/core';
import { ConnectChargebeeModal } from '@pages/Client/Account/Subscription/components/ConnectChargebeeModal';
import { DisconnectSubscriptionModal } from '@pages/Client/Account/Subscription/components/DisconnectSubscriptionModal';
import OverridePriceModal from '@pages/Client/Account/Subscription/components/OverridePriceModal';
import { ReactivateSubscriptionModal } from '@pages/Client/Account/Subscription/components/ReactivateChargebeeModal';
import { useChargebee } from '@pages/Client/Account/Subscription/hooks/useChargebee';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { HotelState } from '@pages/Client/hooks/useSubscription';
import { isNil } from 'lodash-es';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ChargebeeAccountInformation = () => {
  const { t } = useTranslation();
  const cbInstance = useChargebee();
  const { isHideOverridePriceSubscriptionPage } = useAppFlags();
  const { hotelDetails: hotel } = useHotelDetails();
  const { isOpen: connectOpen, openModal: connectClick, closeModal: connectClose } = useModal();
  const { isOpen: overrideOpen, openModal: overrideClick, closeModal: overrideClose } = useModal();
  const {
    isOpen: disconnectOpen,
    openModal: disconnectClick,
    closeModal: disconnectClose
  } = useModal();
  const {
    isOpen: reactivateOpen,
    openModal: reactivateClick,
    closeModal: reactivateClose
  } = useModal();
  const [popOverOpened, setPopOverOpened] = useState(false);
  const { hotelAuthToken } = useHotelStore();
  const { view } = useViewStore();

  const isCancelled = hotel?.state === HotelState.CANCELED;

  const handlePortal = async () => {
    if (hotel?.subscription_id) {
      cbInstance?.setPortalSession(() => {
        return new Promise((resolve, _reject) => {
          resolve(portalMembershipPricing(hotelAuthToken as string));
        });
      });
    }
    const cbPortal = cbInstance?.createChargebeePortal();
    cbPortal?.open({
      close() {}
    });
  };

  type PortalSectionType = 'AccountInfo' | 'Address' | 'Payment' | 'Billing';

  const handleAccountInformation = (type: PortalSectionType) => {
    if (hotel?.subscription_id) {
      cbInstance?.setPortalSession(() => {
        return new Promise((resolve, _reject) => {
          resolve(portalMembershipPricing(hotelAuthToken as string));
        });
      });
    }

    const cbPortal = cbInstance?.createChargebeePortal();
    const callbacks = {};

    switch (type) {
      case 'AccountInfo':
        cbPortal.openSection(
          {
            sectionType: window.Chargebee.getPortalSections().ACCOUNT_DETAILS,
            params: {
              subscriptionId: hotel?.subscription_id
            }
          },
          callbacks
        );
        break;
      case 'Address':
        cbPortal.openSection(
          {
            sectionType: window.Chargebee.getPortalSections().ADDRESS,
            params: {
              subscriptionId: hotel?.subscription_id
            }
          },
          callbacks
        );
        break;
      case 'Payment':
        cbPortal.openSection(
          {
            sectionType: window.Chargebee.getPortalSections().PAYMENT_SOURCES,
            params: {
              subscriptionId: hotel?.subscription_id
            }
          },
          callbacks
        );
        break;
      case 'Billing':
        cbPortal.openSection(
          {
            sectionType: window.Chargebee.getPortalSections().BILLING_HISTORY,
            params: {
              subscriptionId: hotel?.subscription_id
            }
          },
          callbacks
        );
        break;
      default:
        break;
    }
  };

  const ButtonOverridePrice = ({ variant }: { variant?: ButtonVariant }) =>
    isHideOverridePriceSubscriptionPage ? null : (
      <Button variant={variant} color="red" onClick={overrideClick}>
        {t('Set price manually')}
      </Button>
    );

  return (
    <>
      {view === 'admin' && !hotel?.subscription_id ? (
        <Flex gap="sm">
          <Button variant="outline" onClick={connectClick}>
            {t('Connect A Chargebee Subscription')}
          </Button>
          <ButtonOverridePrice variant="outline" />
        </Flex>
      ) : null}
      {hotel?.plan !== null && (
        <>
          <Flex gap="xl" my="lg" justify="space-between">
            <Group>
              <Button onClick={handlePortal}>{t('Edit Subscription And Billing')}</Button>
              <Button
                variant="transparent"
                c="gray.9"
                onClick={() => handleAccountInformation('AccountInfo')}
              >
                {t('Account Information')}
              </Button>
              <Button
                variant="transparent"
                c="gray.9"
                onClick={() => handleAccountInformation('Address')}
              >
                {t('Billing Address')}
              </Button>
              <Button
                variant="transparent"
                c="gray.9"
                onClick={() => handleAccountInformation('Payment')}
              >
                {t('Payment Methods')}
              </Button>
              <Button
                variant="transparent"
                c="gray.9"
                onClick={() => handleAccountInformation('Billing')}
              >
                {t('Billing History')}
              </Button>
            </Group>
            {view === 'admin' ? (
              <Popover
                position="right-start"
                shadow="md"
                styles={{
                  dropdown: {
                    border: 'none'
                  }
                }}
                opened={popOverOpened}
                onChange={setPopOverOpened}
              >
                <Popover.Target>
                  <ActionIcon variant="white" onClick={() => setPopOverOpened((o) => !o)}>
                    <Icon.MoreVertical />
                  </ActionIcon>
                </Popover.Target>
                <Popover.Dropdown>
                  <Stack>
                    <Button
                      variant="outline"
                      onClick={() => {
                        setPopOverOpened(false);
                        connectClick();
                      }}
                    >
                      {t('Connect A Chargebee Subscription')}
                    </Button>
                    {isCancelled ? (
                      <Button
                        variant="filled"
                        onClick={() => {
                          setPopOverOpened(false);
                          reactivateClick();
                        }}
                      >
                        {t('Reactivate Subscription')}
                      </Button>
                    ) : null}
                    {!isNil(hotel?.subscription_id) && !isCancelled ? (
                      <Button
                        variant="transparent"
                        c="red"
                        onClick={() => {
                          setPopOverOpened(false);
                          disconnectClick();
                        }}
                      >
                        {t('Disconnect Subscription')}
                      </Button>
                    ) : null}
                    <ButtonOverridePrice variant="transparent" />
                  </Stack>
                </Popover.Dropdown>
              </Popover>
            ) : null}
          </Flex>

          <Divider />
        </>
      )}
      <ConnectChargebeeModal isOpen={connectOpen} onClose={connectClose} />
      <DisconnectSubscriptionModal isOpen={disconnectOpen} onClose={disconnectClose} />
      <ReactivateSubscriptionModal isOpen={reactivateOpen} onClose={reactivateClose} />
      <OverridePriceModal isOpen={overrideOpen} onClose={overrideClose} />
    </>
  );
};
