import React, { Fragment } from 'react';
import classNames from 'classnames';
import styles from '@common/components/molecules/Modal/modal.module.css';
import { Icon } from '@common/components/foundations/icons';
import { Button } from '@common/components/atoms/Button';
import { Dialog, Transition } from '@headlessui/react';
import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';
import { ModalBg } from '@common/assets/images';

type TModalSize = 'sm' | 'lg' | 'xl' | 'xxl' | 'tips' | 'full' | 'custom';

type IModalProps = React.ComponentProps<typeof Dialog> & {
  onCopy?: () => void;
  onCloseModal?: () => void;
  onCustomCancel?: () => void;
  size?: TModalSize;
  withTextCopy?: boolean;
  continueIntent?: 'primary' | 'danger';
  showHeader?: boolean;
  showFooter?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  overFlowHidden?: boolean;
  footerText?: string;
  okText?: string;
  disableOkBtn?: boolean;
  cancelText?: string;
  hideCloseButton?: boolean;
  disableCloseOnOk?: boolean;
  displayCancel?: boolean;
  displayContinue?: boolean;
};

const ModalFooter = ({
  continueIntent = 'primary',
  showFooter = true,
  cancelText = 'Cancel',
  okText = 'Continue',
  disableOkBtn,
  isLoading = false,
  isError = false,
  footerText,
  disableCloseOnOk = false,
  displayCancel = true,
  displayContinue = true,
  ...props
}: IModalProps) => {
  const { t } = useTranslation();
  cancelText = t(cancelText);
  return (
    <div
      className={classNames(
        styles.modalFooter,
        !showFooter && 'hidden',
        // This is a quick workaround for keeping rounded edges,
        // we should fix showFooter=false case properly later,
        // it was quite highly coupled that I could not touch easily
        !displayCancel && !displayContinue && 'h-5'
      )}
    >
      <Button
        type="button"
        intent="text"
        onClick={props.onCopy}
        className={classNames({
          hidden: props.size === 'tips' || !props.withTextCopy
        })}
      >
        Copy to clipboard
      </Button>

      <span className="text-paragraph-3 text-copyTextGrey">{footerText}</span>

      <div className={classNames(styles.modalFooterButtonsContainer)}>
        {displayCancel ? (
          <Button
            type="button"
            intent="text"
            onClick={() => {
              if (props.onCustomCancel) {
                return props.onCustomCancel();
              }
              props.onClose(false);
            }}
            className={classNames({
              'text-copyTextGrey': true,
              hidden: props.size === 'tips'
            })}
          >
            {cancelText}
          </Button>
        ) : null}
        {displayContinue ? (
          <Button
            type="button"
            disabled={isLoading || disableOkBtn}
            intent={continueIntent}
            onClick={() => {
              props.onClick && props.onClick();
              !isError ? !disableCloseOnOk && props.onClose(true) : null;
            }}
            isLoading={isLoading}
          >
            {okText}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

const Close = ({
  onCancel,
  isTips,
  hideCloseButton
}: {
  onCancel: () => void;
  isTips?: boolean;
  hideCloseButton?: boolean;
}) => {
  if (hideCloseButton) return null;
  if (isTips)
    return (
      <Button type="button" icon className={classNames(styles.closeIconTips)} onClick={onCancel}>
        <Icon.Clear className="h-5 w-5 fill-white hover:fill-darkGrey" />
      </Button>
    );
  return (
    <Button type="button" icon className={classNames(styles.closeIcon, 'z-20')} onClick={onCancel}>
      <Icon.Clear className="h-5 w-5" />
    </Button>
  );
};

const SmModal = (props: IModalProps) => {
  return (
    <div className={styles.modalSm}>
      <div className={classNames(styles.modalSmall, styles.modalDefault, props.className)}>
        <div className={classNames(styles.modalBody)}>
          <Close onCancel={() => props.onClose(false)} hideCloseButton={props.hideCloseButton} />
          {props.children as any}
        </div>
      </div>
      <ModalFooter {...props} />
    </div>
  );
};

const LgModal = (props: IModalProps) => {
  return (
    <div className={styles.modalLg}>
      <div className={classNames(styles.modalSmall, styles.modalDefault, props.className)}>
        <div className={classNames(styles.modalBody)}>
          <Close onCancel={() => props.onClose(false)} hideCloseButton={props.hideCloseButton} />
          {props.children as any}
        </div>
      </div>
      <ModalFooter {...props} />
    </div>
  );
};

const XlModal = (props: IModalProps) => {
  return (
    <div className={styles.modalXl}>
      <div className={classNames(styles.modalSmall, styles.modalDefault, props.className)}>
        <div className={classNames(styles.modalBody)}>
          <Close onCancel={() => props.onClose(false)} hideCloseButton={props.hideCloseButton} />
          {props.children as any}
        </div>
      </div>
      <ModalFooter {...props} />
    </div>
  );
};

const XXlModal = (props: IModalProps) => {
  return (
    <div className={styles.modalXXl}>
      <div className={classNames(styles.modalSmall, styles.modalDefault, props.className)}>
        <div className={classNames(styles.modalBody)}>
          <Close onCancel={() => props.onClose(false)} hideCloseButton={props.hideCloseButton} />
          {props.children as any}
        </div>
      </div>
      <ModalFooter {...props} />
    </div>
  );
};

const FullModal = (props: IModalProps) => {
  return (
    <div className={styles.modalFull}>
      <div className={classNames(styles.modalTall, styles.modalDefault, props.className)}>
        <div className={classNames(styles.modalBody)}>
          <Close
            onCancel={() => (props.onCloseModal ? props.onCloseModal() : props.onClose(false))}
            hideCloseButton={props.hideCloseButton}
          />
          {props.children as any}
        </div>
      </div>
      <ModalFooter {...props} />
    </div>
  );
};

const TipsModal = (props: IModalProps) => {
  return (
    <div className={styles.modalTips}>
      <div className={styles.tipsHeader}>
        <Close
          isTips
          onCancel={() => props.onClose(false)}
          hideCloseButton={props.hideCloseButton}
        />
        <img src={ModalBg} alt="header-background" />
      </div>
      <div className={styles.bodyContainer}>{props.children as any}</div>
      <ModalFooter {...props} />
    </div>
  );
};

/**
 * A blank modal, ready to be customized
 */
const CustomModal = (props: IModalProps) => {
  return <>{props.children}</>;
};

export const Modal = (props: IModalProps) => {
  const {
    onCopy,
    size = 'sm',
    withTextCopy,
    okText,
    cancelText,
    children,
    footerText,
    showFooter,
    isLoading,
    hideCloseButton,
    overFlowHidden = false,
    disableCloseOnOk,
    onCloseModal,
    ...headlessModalProps
  } = props;

  const modals = {
    sm: SmModal,
    lg: LgModal,
    xl: XlModal,
    xxl: XXlModal,
    tips: TipsModal,
    full: FullModal,
    custom: CustomModal
  };

  const panelClass = {
    sm: `${styles.modalSm} ${overFlowHidden ? 'overflow-hidden' : ''}`,
    lg: `${styles.modalLg} ${overFlowHidden ? 'overflow-hidden' : ''}`,
    xl: `${styles.modalXl} ${overFlowHidden ? 'overflow-hidden' : ''}`,
    xxl: `${styles.modalXXl} ${overFlowHidden ? 'overflow-hidden' : ''}`,
    tips: styles.modalTips,
    full: styles.modalFull,
    custom: ''
  };

  const renderModalContent = () => {
    const Modal = modals[size];
    return <Modal {...props} />;
  };

  const { onCustomCancel, displayContinue, displayCancel, ...otherProps } = headlessModalProps;

  return (
    <Transition appear show={props.open ?? false} as={Fragment}>
      {/* Based on https://headlessui.com/react/dialog */}
      <Dialog
        as="div"
        {...otherProps}
        className={clsx(styles.baseDialog, headlessModalProps.className)}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {(ref) => <div ref={ref} className="fixed inset-0 z-30 bg-darkGrey bg-opacity-70" />}
        </Transition.Child>
        <div className="fixed inset-0 z-40 overflow-y-auto">
          <div className="z-40 flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-150"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-150"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className={clsx(panelClass[size])}>{renderModalContent()}</Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
