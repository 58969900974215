import { PmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { map, sortBy, startCase } from 'lodash-es';

export const pmsLookup: Record<number, string> = {
  1: 'Apaleo',
  2: 'Mews',
  3: 'Booking Factory',
  4: 'Little Hotelier',
  5: 'SiteMinder',
  7: 'Clock System',
  10: 'Hotel Spider(Deprecated)',
  15: 'Protel',
  14: 'Cover',
  20: 'Channex',
  21: 'Globeres',
  22: 'Guestline',
  23: 'Bocco',
  24: 'seekom',
  25: 'Beds24(Deprecated)',
  26: 'Booking Automation Partner',
  27: 'RMS',
  28: 'Web Hotelier',
  29: 'kognitiv',
  30: 'Channex Wubook',
  34: 'Vertical Booking',
  35: 'cultswitch',
  37: 'SiteMinder RMS API',
  38: 'Semper',
  39: 'MappingMaster',
  40: 'Ibelsa',
  41: 'RoomRaccoon',
  42: 'Guesty',
  44: 'Reconline',
  45: 'D-edge',
  46: 'eviivo',
  47: 'Ghix',
  48: 'OneHotel',
  49: 'HLS',
  50: 'Cubilis',
  51: 'Roomcloud',
  52: 'Freetobook',
  53: 'Profitroom',
  54: 'Zimmersoftware',
  57: 'Noovy',
  58: 'Dirs21',
  59: 'Hotsoft',
  60: 'Stayntouch',
  61: 'OperaCloud',
  62: 'Hotelavailabilities',
  63: 'RateTiger',
  64: 'SiteMinder New',
  65: 'SiteMinder RMS API New',
  66: 'Thais',
  68: 'Resly',
  69: 'Wincloud',
  70: 'Cloudbeds',
  71: 'SabeeApp',
  72: 'Newbook',
  73: 'STAAH',
  74: 'Anand System',
  76: 'Rezcontrol',
  77: 'RoomMaster Anywhere',
  78: 'Hotline',
  79: '3RPMS',
  80: 'CASABLANCA',
  82: 'Lobbi',
  83: 'Hotel Spider',
  84: 'Beds24',
  85: 'WebRezPro',
  86: 'HS/3',
  88: 'VIPS CloudPMS',
  87: 'Skytouch',
  89: 'GuestPoint',
  90: 'HotelNetSolutions',
  92: 'ResNexus'
};

export const pmsProviderListForOccupancyBasedPricing = [
  PmsProvider.HOTEL_SPIDER_DEPRECATED,
  PmsProvider.PROTEL,
  PmsProvider.KOGNITIV,
  PmsProvider.IBELSA,
  PmsProvider.BOCCO,
  PmsProvider.RECONLINE,
  PmsProvider.GHIX,
  PmsProvider.WEB_HOTELIER,
  PmsProvider.PROFITROOM,
  PmsProvider.MAPPING_MASTER,
  PmsProvider.D_EDGE,
  PmsProvider.CUBILIS,
  PmsProvider.DIRS21,
  PmsProvider.STAYNTOUCH,
  PmsProvider.OPERA_CLOUD,
  PmsProvider.CULTSWITCH,
  PmsProvider.RATETIGER,
  PmsProvider.WINCLOUD,
  PmsProvider.HOTLINE,
  PmsProvider.ROOM_MASTER,
  PmsProvider.CASABLANCA,
  PmsProvider.HOTEL_SPIDER_PUSH_MODEL,
  PmsProvider.WEB_REZ_PRO,
  PmsProvider.HS_3,
  PmsProvider.HOTSOFT,
  PmsProvider.SKYTOUCH,
  PmsProvider.HOTEL_NET_SOLUTIONS
];

export const pmsProviderListForDerivedRates = [
  PmsProvider.APALEO,
  PmsProvider.ZIMMERSOFTWARE,
  PmsProvider.DIRS21,
  PmsProvider.IBELSA,
  PmsProvider.STAYNTOUCH,
  PmsProvider.RATETIGER,
  PmsProvider.EVIIVO,
  PmsProvider.STAAH,
  PmsProvider.HOTEL_SPIDER_PUSH_MODEL,
  PmsProvider.MEWS,
  PmsProvider.HOTSOFT
];

export const pushPmsList = [
  PmsProvider.PROTEL,
  PmsProvider.VERTICAL_BOOKING,
  PmsProvider.GUESTLINE,
  PmsProvider.ROOMRACCOON,
  PmsProvider.D_EDGE,
  PmsProvider.EVIIVO,
  PmsProvider.ONE_HOTEL,
  PmsProvider.HLS,
  PmsProvider.CUBILIS,
  PmsProvider.FREE_TO_BOOK,
  PmsProvider.PROFITROOM,
  PmsProvider.ZIMMERSOFTWARE,
  PmsProvider.NOOVY,
  PmsProvider.DIRS21,
  PmsProvider.AMENITIZ,
  PmsProvider.RATETIGER,
  PmsProvider.UPDATE247,
  PmsProvider.WINCLOUD,
  PmsProvider.STAAH,
  PmsProvider.ANAND_SYSTEM,
  PmsProvider.REZCONTROL,
  PmsProvider.ROOM_MASTER,
  PmsProvider.HOTLINE,
  PmsProvider.CASABLANCA,
  PmsProvider.HOTEL_SPIDER_PUSH_MODEL,
  PmsProvider.WEB_REZ_PRO,
  PmsProvider.HS_3,
  PmsProvider.SKYTOUCH,
  PmsProvider.GUEST_POINT,
  PmsProvider.HOTEL_NET_SOLUTIONS
];

export const pmsHasRoomType = [
  PmsProvider.PROTEL,
  PmsProvider.KOGNITIV,
  PmsProvider.ROOMRACCOON,
  PmsProvider.GUESTLINE,
  PmsProvider.VERTICAL_BOOKING,
  PmsProvider.D_EDGE,
  PmsProvider.WINCLOUD,
  PmsProvider.CASABLANCA,
  PmsProvider.ROOM_MASTER,
  PmsProvider.HS_3,
  PmsProvider.SKYTOUCH
];

export const pmsHasRegionField = [
  PmsProvider.LITTLE_HOTELIER,
  PmsProvider.SITEMINDER,
  PmsProvider.PROTEL,
  PmsProvider.GUESTLINE,
  PmsProvider.RMS,
  PmsProvider.STAYNTOUCH,
  PmsProvider.OPERA_CLOUD,
  PmsProvider.SITEMINDER_NEW,
  PmsProvider.SITEMINDER_RMS_NEW,
  PmsProvider.THAIS,
  PmsProvider.NEWBOOK
];

export const pmsHasRoomRate = [
  PmsProvider.PROTEL,
  PmsProvider.ROOMRACCOON,
  PmsProvider.GUESTLINE,
  PmsProvider.VERTICAL_BOOKING,
  PmsProvider.D_EDGE,
  PmsProvider.WINCLOUD,
  PmsProvider.ROOM_MASTER,
  PmsProvider.HS_3
];

export const pmsHasUpdateRate = [PmsProvider.D_EDGE, PmsProvider.GUESTLINE, PmsProvider.HOTLINE];

export const pmsHasInvTypeCode = [
  PmsProvider.PROTEL,
  PmsProvider.D_EDGE,
  PmsProvider.ROOM_MASTER,
  PmsProvider.HS_3
];

export const pmsHasOutExtraColumns = [
  PmsProvider.PROTEL,
  PmsProvider.D_EDGE,
  PmsProvider.SITEMINDER_NEW,
  PmsProvider.ROOM_MASTER,
  PmsProvider.HS_3,
  PmsProvider.GUESTLINE,
  PmsProvider.CASABLANCA,
  PmsProvider.SKYTOUCH
];

export const pmsHasRoomTypesColumn = [
  PmsProvider.PROTEL,
  PmsProvider.SITEMINDER_NEW,
  PmsProvider.ROOM_MASTER,
  PmsProvider.HS_3
];

export const pmsHasRequestFullSync = [
  PmsProvider.FREE_TO_BOOK,
  PmsProvider.EVIIVO,
  PmsProvider.SABEE_APP,
  PmsProvider.STAAH,
  PmsProvider.THREE_RPMS,
  PmsProvider.GUEST_POINT
];

export const pmsList = Object.entries(pmsLookup)
  .map(([key, value]) => ({
    label: `${value}${import.meta.env.DEV ? ` - ${key}` : ''}`,
    value: parseInt(key)
  }))
  .sort((a, b) => a.label.localeCompare(b.label));

export const pmsListSelect = sortBy(
  map(pmsLookup, (value, key) => ({
    value: String(key),
    label: `${startCase(value)}${import.meta.env.DEV ? ` - ${key}` : ''}`
  })),
  ['label'],
  ['asc']
);
