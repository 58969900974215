import { DayDetails } from '@common/api/pricingAlgorithm/types';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { Box, Group, rem, Text } from '@mantine/core';
import { usePriceInfo } from '@pages/Client/PricingStrategy/GroupDisplacement/hooks/usePriceInfo';
import { useTranslation } from 'react-i18next';

interface PriceInfoProps {
  dayData: DayDetails;
  baseRateOffset?: number;
}

const PriceHover = ({ dayData, baseRateOffset }: PriceInfoProps) => {
  const { t } = useTranslation();
  const priceKpiContent = usePriceInfo(dayData?.price_info);

  return (
    <Box px="xs" py="sm" style={{ width: rem(326) }}>
      <Text size="xs" c="gray">
        {t('Prices')}
      </Text>

      <Box mt="sm">
        <Group justify="space-between" mt="xs">
          <Text size="xs" c="gray.1">
            {t('Base Price')}
          </Text>
          <Text size="xs" fw={500} c="white">
            {priceKpiContent?.basePriceValue ?? 'n.A.'}
          </Text>
        </Group>

        {priceKpiContent?.breakdownPrice?.map((item, index) => (
          <Group key={`priceKpiContent-${index}`} justify="space-between" mt="xs">
            <Text size="xs" c="gray.1">
              {item.title}
            </Text>
            <Group gap="xs" align="center">
              <Group gap={0}>
                <Text size="xs" c="gray.2">
                  {item.icon}
                </Text>
                <Text size="xs" fw={500} c="gray.2">
                  {item.percentage}
                </Text>
              </Group>
              <Text size="xs" fw={500} c="white">
                {item.value}
              </Text>
            </Group>
          </Group>
        ))}
        <Group justify="space-between" mt="xs">
          <Text size="xs" c="gray.1">
            {t('Base Rate Offset')}
          </Text>
          <Text size="xs" fw={500} c="white">
            {CurrencyFormatter.format(baseRateOffset) ?? 'n.A.'}
          </Text>
        </Group>
        <Group justify="space-between" mt="xs">
          <Text size="xs" c="gray.1">
            {t('Base Rate')}
          </Text>
          <Text size="xs" fw={500} c="white">
            {CurrencyFormatter.format(dayData?.base_rate) ?? 'n.A.'}
          </Text>
        </Group>
      </Box>
    </Box>
  );
};

export default PriceHover;
