import { Checkbox, Divider } from '@mantine/core';
import { Input } from '@common/components/atoms/Input';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { Controller, useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { editSchema } from '@pages/Client/PricingStrategy/RoomSetup/common/formSchema';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { useHotelPmsDataMap } from '@pages/Client/PricingStrategy/RoomSetup/hooks/useHotelPmsDataMap';
import React, { useEffect, Fragment, useState } from 'react';
import { useHotelPricePerRate } from '@pages/Client/PricingStrategy/RoomSetup/hooks/useHotelPricePerRate';
import { Rate } from '@common/api/hotel/types';
import { isEmpty, sortBy } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { InputHelperMessage } from '@common/components/atoms/InputHelperMessage';
import { Icon } from '@common/components/foundations/icons';

export const DerivedRatesV1: React.FC = () => {
  const { t } = useTranslation();
  const { hotelDetails } = useHotelDetails();
  const [availableRates, setAvailableRates] = useState<Rate[]>([]);
  const { control, watch, setValue, reset } = useFormContext<z.infer<typeof editSchema>>();
  const { pmsMapping, derivedRates } = watch();
  const { hotelPricePerRate } = useHotelPricePerRate(pmsMapping.roomInPms);
  const { hotelPmsDataMap } = useHotelPmsDataMap();

  useEffect(() => {
    if (!hotelPricePerRate) return;

    reset({
      ...watch(),
      derivedRates: hotelPricePerRate.map((pricePerRate) => ({
        id: pricePerRate.id,
        derivedRateInPms: pricePerRate.pms_rate,
        derivation: pricePerRate.derivation,
        percentDerivation: !pricePerRate.is_absolute,
        productDerivation: pricePerRate.is_product_derivation
      }))
    });
  }, [hotelPricePerRate]);

  useEffect(() => {
    if (pmsMapping.roomInPms !== 0) return;

    reset({
      ...watch(),
      derivedRates: []
    });

    setAvailableRates([]);
  }, [pmsMapping.roomInPms]);

  useEffect(() => {
    if (hotelPmsDataMap && hotelPricePerRate) {
      const newAvailableRates =
        hotelPmsDataMap.mapped_data
          .find((item) => item.id === pmsMapping.roomInPms)
          ?.rates.filter(
            (rate) =>
              rate.id !== pmsMapping.baseRateInPms &&
              !hotelPricePerRate.some((derivedRate) => derivedRate.pms_rate === rate.id)
          ) || [];

      setAvailableRates(newAvailableRates);
    }
  }, [hotelPmsDataMap, hotelPricePerRate, pmsMapping.roomInPms]);

  const isEviivo = hotelDetails?.pms_provider === 46;

  return (
    <div className="flex flex-col gap-y-6">
      {Array.from({ length: 12 }).map((_, index) => {
        const derivedRate = derivedRates?.[index];
        let currentRates = availableRates;

        if (derivedRate?.derivedRateInPms) {
          const matchedRate = hotelPmsDataMap?.mapped_data
            .find((item) => item.id === pmsMapping.roomInPms)
            ?.rates.find((rate) => rate.id === derivedRate.derivedRateInPms);
          if (matchedRate) {
            currentRates = availableRates.some((rate) => rate.id === matchedRate.id)
              ? availableRates
              : [...availableRates, matchedRate];
          }
        }

        return (
          <Fragment
            key={
              isEmpty(derivedRates) || !derivedRate?.derivedRateInPms
                ? `no-set-derived-rates-${index}`
                : derivedRate?.id
            }
          >
            <div className="flex w-full flex-col gap-y-6 md:w-2/3">
              <Controller
                control={control}
                name={`derivedRates.${index}.derivedRateInPms`}
                defaultValue={null}
                render={({ field: { value, onChange, name } }) => (
                  <SelectDropdown
                    fullWidth
                    options={[
                      {
                        label: 'Not Priced',
                        value: null
                      },
                      ...sortBy(
                        currentRates
                          ?.filter(
                            (rate) =>
                              !derivedRates?.some(
                                (derivedRate) => derivedRate.derivedRateInPms === rate.id
                              ) || value === rate.id
                          )
                          .map((rate) => ({
                            label: `${rate.name} (${rate.rate_id})`,
                            value: rate.id
                          })) || [],
                        'label'
                      )
                    ]}
                    hint="Derived Rate in PMS"
                    background="grey"
                    name={name}
                    value={value}
                    onChange={(selectedRate) => {
                      onChange(selectedRate);

                      if (value) {
                        // If a previous rate was selected, add it back to availableRates
                        const previousSelectedRate = currentRates.find((rate) => rate.id === value);
                        if (previousSelectedRate) {
                          setAvailableRates((prev) => [...prev, previousSelectedRate]);
                        }
                      }

                      if (selectedRate) {
                        // If a new rate is selected, remove it from availableRates
                        setAvailableRates((prev) =>
                          prev.filter((rate) => rate.id !== selectedRate)
                        );
                      }
                    }}
                  />
                )}
              />

              <Controller
                control={control}
                name={`derivedRates.${index}.derivation`}
                defaultValue={0}
                render={({ field: { value, onChange, name }, fieldState: { error, invalid } }) => (
                  <Input
                    showClearButton={false}
                    leadingAddon={
                      derivedRate?.percentDerivation ? '%' : CurrencyFormatter.currencySymbol()
                    }
                    type="number"
                    label="Derivation"
                    placeholder="Please enter derivation"
                    background="grey"
                    error={invalid}
                    helperMessage={
                      invalid ? (
                        <InputHelperMessage
                          icon={<Icon.WarningOutline className="h-4 w-4" />}
                          message={error?.message}
                        />
                      ) : null
                    }
                    name={name}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />

              {isEviivo && (
                <Input
                  showClearButton={false}
                  type="number"
                  label="Reference Occupancy"
                  background="grey"
                  value={
                    currentRates.find((item) => derivedRate?.derivedRateInPms === item.id)
                      ?.default_occupancy
                  }
                  readOnly
                />
              )}

              <div className="flex gap-x-4">
                <Controller
                  control={control}
                  name={`derivedRates.${index}.percentDerivation`}
                  defaultValue={false}
                  render={({ field: { value, onChange, name } }) => (
                    <Checkbox
                      label={t('Percent Derivation')}
                      id={name}
                      name={name}
                      checked={value}
                      onChange={(e) => {
                        onChange(e.target.checked);
                        if (e.target.checked) {
                          setValue(`derivedRates.${index}.productDerivation`, false);
                        }
                      }}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name={`derivedRates.${index}.productDerivation`}
                  defaultValue={false}
                  render={({ field: { value, onChange, name } }) => (
                    <Checkbox
                      label={t('Per Person Derivation')}
                      id={name}
                      name={name}
                      checked={value}
                      onChange={(e) => {
                        onChange(e.target.checked);
                        if (e.target.checked) {
                          setValue(`derivedRates.${index}.percentDerivation`, false);
                        }
                      }}
                    />
                  )}
                />
              </div>
            </div>
            {index < 11 && <Divider />}
          </Fragment>
        );
      })}
    </div>
  );
};

export default DerivedRatesV1;
