import { getAccountHotel } from '@common/api/account';
import { setAuthToken } from '@common/api/axiosInstance';
import { useAuthStore, useLogout } from '@common/store/auth';
import { useNotificationsStore } from '@common/store/notifications';
import { HotelQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const useGetAccountHotel = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const clientLogout = useLogout();
  const { user } = useAuthStore();
  const addNotification = useNotificationsStore((state) => state.addNotification);

  const query = useQuery({
    queryKey: [HotelQueryKeys.GET_ACCOUNT_HOTEL],
    queryFn: () => getAccountHotel(Number(id)),
    enabled: !!id && !isNaN(+id)
  });

  useEffect(() => {
    if (query.isError) {
      const isStaff = user?.is_staff;
      if (!isStaff) {
        setAuthToken(null);
        clientLogout();
        addNotification('fail', t('Something went wrong. Please login again.'));
      } else {
        addNotification(
          'fail',
          t(
            'The Property you are trying to access does not exist. Edit the property ID in the URL manually.'
          )
        );
      }
    }
  }, [query.isError]);

  if (query.isSuccess) {
    if (!query.data?.token) return query;
    setAuthToken(query.data.token);
  }

  return query;
};
