import {
  useSetHotelState,
  useSetToTestMode
} from '@pages/Client/Account/Subscription/hooks/usePayment';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { Switch } from '@mantine/core';

export const DemoHotelSwitcher = () => {
  const setToTestHotel = useSetToTestMode();
  const { setHotelState } = useSetHotelState();
  const { hotelDetails: hotel } = useHotelDetails();

  return (
    <div className="flex items-end justify-between">
      <Switch
        label="Demo Hotel"
        checked={hotel?.state === 8}
        onChange={(e) => {
          if (e.currentTarget.checked) {
            setToTestHotel();
            setHotelState({
              state: 8
            });
          } else {
            setHotelState({
              state: 0
            });
          }
        }}
      />
    </div>
  );
};
