import { FC, ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { isEmpty, isEqual, truncate } from 'lodash-es';
import { Card, Divider, Text, Button, Flex, Skeleton } from '@mantine/core';
import {
  Autocomplete,
  GoogleMap,
  Marker,
  MarkerClusterer,
  useJsApiLoader,
  OverlayView,
  MarkerClustererProps
} from '@react-google-maps/api';
import { Icon } from '@common/components/foundations/icons';
import { Hotel } from '@common/api/hotel/types';
import { getEnvVar } from '@common/utils/env';
import { filterEmptyValues } from '@common/utils/filterEmptyValues';
import { useDocumentTitle } from '@mantine/hooks';
import {
  useHotelClientMap,
  useHotelClientMapDetail
} from '@pages/Admin/Clients/hooks/useHotelClientMap';
import { pmsLookup } from '@common/constants/pmsList';
import { formattedPercentage } from '@pages/Client/Dashboard/utils/formattedPercentage';
import { useTailwindColor } from '@common/hooks/useTailwindColors';
import { useDate } from '@common/hooks/useDate';
import { formattedCurrency } from '@pages/Client/Dashboard/utils/formattedCurrency';
import { useCountryList } from '@common/hooks/useSystem';
import { GoogleMapsLibrary } from '@common/components/atoms/GoogleMapsAutoComplete';
import { HotelState } from '@pages/Client/hooks/useSubscription';
import { Typography } from '@common/components/foundations/Typography';
import { ClientMapFilters } from '@pages/Admin/components/filters/client-map-filters';
import { FilterGroup, useClientMapStore } from '@common/store/client-map-store';

const LIMIT = 10000;
export const RENDER_MAP_CLUSTERER_DELAY = 300;

interface Viewport {
  min_lat: number;
  max_lat: number;
  min_lng: number;
  max_lng: number;
}

interface CustomInfoWindowProps {
  position: google.maps.LatLngLiteral;
  children: ReactNode;
}

const CustomInfoWindow: FC<CustomInfoWindowProps> = ({ position, children }) => {
  return (
    <OverlayView
      position={position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={() => ({ x: 0, y: -30 })}
    >
      <div className="custom-info-window">
        <Card>{children}</Card>
      </div>
    </OverlayView>
  );
};

type ClustererType = NonNullable<MarkerClustererProps['onLoad']> extends (
  clusterer: infer T
) => void
  ? T
  : never;

export const ClientMap = () => {
  useDocumentTitle('Client Map');
  const mediumGrey = useTailwindColor('mediumGrey');
  const darkGrey = useTailwindColor('darkGrey');
  const indigo = useTailwindColor('indigo');
  const gold = useTailwindColor('gold');
  const indigoOpacity = 'rgb(91, 72, 238, 0.2)';
  const { filters, reset } = useClientMapStore();

  const {
    query: { isLoading: isCountriesLoading }
  } = useCountryList();
  const { currentMonth, nextMonth } = useDate({ isHotelAccount: false });
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const mapRef = useRef<google.maps.Map | null>(null);
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const markerClustererRef = useRef<ClustererType | null>(null);

  const clearClusterers = useCallback(() => {
    if (markerClustererRef.current) {
      markerClustererRef.current.clearMarkers();
    }
    setTimeout(() => {
      // Force re-render by updating the key with a slight delay
      // to prevent the map to get frozen with high load
      setClustererKey((prevKey) => prevKey + 1);
    }, RENDER_MAP_CLUSTERER_DELAY);
  }, [markerClustererRef]);

  const initialCenter = { lat: 0, lng: 0 };

  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
    styles: [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }]
      },
      {
        featureType: 'poi.business',
        stylers: [{ visibility: 'off' }]
      },
      {
        featureType: 'transit',
        stylers: [{ visibility: 'off' }]
      }
    ]
  };

  const [center, setCenter] = useState(initialCenter);
  const [viewport, setViewport] = useState<Viewport>({
    min_lat: -90, // Minimum latitude
    max_lat: 90, // Maximum latitude
    min_lng: -180, // Minimum longitude
    max_lng: 180 // Maximum longitude
  });

  const [hoveredMarker, setHoveredMarker] = useState<Partial<Hotel> | null>(null);

  // Transform filters into comma-separated string values for API
  const transformedFilters: Record<FilterGroup, string> = useMemo(() => {
    const transformed: Record<FilterGroup, string> = {
      country: '',
      pms: '',
      subscription_state: '',
      subscription_plan: ''
    };

    for (const key in filters) {
      const filterKey = key as FilterGroup;
      const values = filters[filterKey];
      transformed[filterKey] = Object.keys(values)
        .filter((k) => values[k])
        .join(',');
    }
    return transformed;
  }, [filters]);

  const {
    hotelClientMapData,
    query: { isLoading }
  } = useHotelClientMap(
    filterEmptyValues({
      min_lat: viewport.min_lat,
      max_lat: viewport.max_lat,
      min_lng: viewport.min_lng,
      max_lng: viewport.max_lng,
      limit: LIMIT,
      pms: transformedFilters.pms,
      country: transformedFilters.country,
      subscription_state: transformedFilters.subscription_state,
      subscription_plan: transformedFilters.subscription_plan
    })
  );

  const {
    hotelClientMapDetailData,
    query: { isLoading: isDetailLoading }
  } = useHotelClientMapDetail(hoveredMarker?.id);

  const [clustererKey, setClustererKey] = useState(0);

  const handleMapChange = useCallback(() => {
    if (mapRef.current) {
      const bounds = mapRef.current.getBounds();
      if (bounds) {
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();
        if (ne && sw) {
          const newViewport = {
            min_lat: sw.lat(),
            max_lat: ne.lat(),
            min_lng: sw.lng(),
            max_lng: ne.lng()
          };

          if (!isEqual(newViewport, viewport)) {
            setViewport(newViewport);
            clearClusterers();
          }
        }
      }
    }
  }, [viewport.min_lat, viewport.max_lat, viewport.min_lng, viewport.max_lng]);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: getEnvVar('VITE_GOOGLEAPIKEY'),
    libraries: [GoogleMapsLibrary.Places]
  });

  const handlePlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place.geometry) {
        const location = place.geometry.location;
        if (location) {
          setCenter({ lat: location.lat(), lng: location.lng() });
        }

        const bounds = new google.maps.LatLngBounds();
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else if (place.geometry.location) {
          bounds.extend(place.geometry.location);
        }
        if (mapRef.current) {
          mapRef.current.fitBounds(bounds);
        }
      }
    }
  };

  if (!isLoaded) {
    return <div>Loading..</div>;
  }

  const infoBoxData = (hotel: Partial<Hotel> | undefined) => [
    {
      title: 'General',
      items: [
        {
          label: 'Property',
          value: truncate(hotel?.name, { length: 30 })
        },
        {
          label: 'PMS',
          value: hotel?.pms ? pmsLookup?.[hotel.pms] : null
        },
        {
          label: 'No. of Rooms',
          value: hotel?.number_of_rooms
        },
        {
          label: 'Subscription Date',
          value: hotel?.subscription_started
        }
      ]
    },
    {
      title: 'Occupancy',
      items: [
        {
          label: `Occ. ${currentMonth.shortName}`,
          value: formattedPercentage(hotel?.occupancy?.[currentMonth.key], true)
        },
        {
          label: `Occ. ${nextMonth.shortName}`,
          value: formattedPercentage(hotel?.occupancy?.[nextMonth.key], true)
        }
      ]
    },
    ...(hotel?.reporting
      ? [
          {
            title: 'Reporting',
            items: [
              {
                label: `Revenue vs. STLY ${currentMonth.shortName}`,
                value: formattedPercentage(hotel.reporting?.revPctChng?.[currentMonth.key], true)
              },
              {
                label: `Revenue vs. STLY ${nextMonth.shortName}`,
                value: formattedPercentage(hotel.reporting?.revPctChng?.[nextMonth.key], true)
              },
              {
                label: `Occ vs. STLY ${currentMonth.shortName}`,
                value: formattedPercentage(hotel.reporting?.occChng?.[currentMonth.key], true)
              },
              {
                label: `Occ vs. STLY ${nextMonth.shortName}`,
                value: formattedPercentage(hotel.reporting?.occChng?.[nextMonth.key], true)
              },
              {
                label: `ADR vs. STLY ${currentMonth.shortName}`,
                value: formattedCurrency(hotel.reporting?.adrChng?.[currentMonth.key])
              },
              {
                label: `ADR vs. STLY ${nextMonth.shortName}`,
                value: formattedCurrency(hotel.reporting?.adrChng?.[nextMonth.key])
              }
            ]
          }
        ]
      : [])
  ];

  // Function to get SVG marker as data URL
  const getMarkerIcon = (color: string) => {
    const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="${color}" stroke="white" stroke-width="1" stroke-linecap="round" stroke-linejoin="round">
      <path d="M21 10c0 7.5-9 13-9 13S3 17.5 3 10a9 9 0 1 1 18 0z"/>
      <circle stroke-width="2" cx="12" cy="10" r="3"/>
    </svg>`;
    return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`;
  };

  const getMarkerColor = (state?: number) => {
    switch (state) {
      case HotelState.FREE_TRIAL:
        return gold;
      case HotelState.SUBSCRIBED:
      case HotelState.SUBSCRIBED_PARTNER:
        return indigo;
      case HotelState.FREE_TRIAL_EXPIRED:
      case HotelState.CANCELED:
      case HotelState.SUSPENDED:
      case HotelState.NOT_ACTIVE:
      case HotelState.CANCELED_PARTNER:
      case HotelState.PAUSED:
        return mediumGrey;
      default:
        return darkGrey;
    }
  };

  const legendData = [
    { color: gold, label: 'Free Trial' },
    { color: indigo, label: 'Client & Partner' },
    { color: mediumGrey, label: 'Cancellations' }
  ];

  const clusterStyles = [
    {
      textColor: 'white',
      url: '/images/map-clusterer-m3.png',
      height: 53,
      width: 53
    }
  ];

  const Legend = () => {
    return (
      <div className="flex items-center justify-center gap-3">
        {legendData.map((item, index) => (
          <div key={index} className="flex items-center gap-2">
            <div className={`h-4 w-4 rounded-full`} style={{ backgroundColor: item.color }} />
            <Typography variant="paragraph-3" element="p">
              {item.label}
            </Typography>
          </div>
        ))}
      </div>
    );
  };

  const shouldHighlightFilters =
    !isEmpty(filters.country) ||
    !isEmpty(filters.pms) ||
    !isEmpty(filters.subscription_state) ||
    !isEmpty(filters.subscription_plan);

  const filtersCount = filters
    ? Object.keys(filters).filter((key) => !isEmpty(filters[key as FilterGroup])).length
    : 0;

  return (
    <div>
      <Flex align="center" justify="space-between" gap="sm" className="mb-4">
        <div>
          <Skeleton visible={isLoading || isCountriesLoading}>
            <div className="rounded-sm bg-white px-2 py-[1px] shadow-sm">
              <span className="text-meta-1 font-semibold">{hotelClientMapData?.count}</span>
              <span className="text-meta-2 text-grey"> Properties Found</span>
            </div>
          </Skeleton>
        </div>

        <div className="hidden items-center justify-center md:flex">
          <Legend />
        </div>

        <div className="flex items-end gap-1">
          <div>
            <Skeleton visible={isLoading}>
              <Button
                className=" bg-white px-4 py-2 text-darkGrey"
                data-html2canvas-ignore="true"
                style={
                  shouldHighlightFilters
                    ? {
                        backgroundColor: indigoOpacity
                      }
                    : {}
                }
                onClick={() => setIsFiltersOpen(true)}
              >
                <Icon.FilterOutline className="mr-1 h-5 w-5" />
                Filters {filtersCount ? ` (${filtersCount})` : ''}
                {shouldHighlightFilters ? (
                  <Icon.Clear
                    className="ml-1 h-4 w-4 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      reset();
                      clearClusterers();
                    }}
                  />
                ) : null}
              </Button>
            </Skeleton>
          </div>
        </div>
      </Flex>

      <div className="mb-4 flex items-center justify-center md:hidden">
        <Legend />
      </div>

      <div className="relative h-[70vh] overflow-hidden rounded-md">
        <Autocomplete
          onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
          onPlaceChanged={handlePlaceChanged}
        >
          <div className="absolute left-4 top-4 z-10 w-60">
            <input
              id="search-box"
              className="border-gray-300 focus:border-indigo-500 focus:ring-indigo-200 w-full rounded-md border p-3 shadow-sm focus:ring focus:ring-opacity-50"
              type="text"
              placeholder="Search"
            />
          </div>
        </Autocomplete>
        <GoogleMap
          center={center}
          zoom={1.3}
          options={mapOptions}
          onLoad={(map) => {
            mapRef.current = map;
            handleMapChange();
          }}
          onIdle={handleMapChange}
          mapContainerClassName="w-full h-full"
        >
          <MarkerClusterer
            key={clustererKey}
            averageCenter
            minimumClusterSize={10}
            styles={clusterStyles}
            onLoad={(clusterer) => (markerClustererRef.current = clusterer)}
          >
            {(clusterer) => (
              <>
                {hotelClientMapData?.results?.map((hotel: Partial<Hotel>, index: number) => (
                  <Marker
                    key={index}
                    position={{ lat: Number(hotel.lat), lng: Number(hotel.lng) }}
                    clusterer={clusterer}
                    onClick={() =>
                      hotel?.id ? window.open(`/client/${hotel.id}/calendar`, '_blank') : null
                    }
                    icon={{
                      url: getMarkerIcon(getMarkerColor(hotel?.state) as string),
                      scaledSize: new google.maps.Size(40, 40),
                      origin: new google.maps.Point(0, 0),
                      anchor: new google.maps.Point(20, 40)
                    }}
                    onMouseOver={() => setHoveredMarker(hotel)}
                    onMouseOut={() => setHoveredMarker(null)}
                  >
                    {hoveredMarker?.id &&
                      hoveredMarker.lat === hotel.lat &&
                      hoveredMarker.lng === hotel.lng && (
                        <CustomInfoWindow
                          position={{ lat: Number(hotel.lat), lng: Number(hotel.lng) }}
                        >
                          <Flex direction="column" gap="xs" className="min-w-[250px]">
                            {infoBoxData(hotelClientMapDetailData).map((section, sectionIndex) => {
                              const isLastSection =
                                sectionIndex === infoBoxData(hotelClientMapDetailData).length - 1;
                              return (
                                <Flex
                                  key={sectionIndex}
                                  direction="column"
                                  gap="3"
                                  className="mb-2"
                                >
                                  <Text size="sm" c="dark" className="font-medium">
                                    {section.title}
                                  </Text>
                                  {section.items.map((item, itemIndex) => (
                                    <Skeleton key={itemIndex} visible={isDetailLoading}>
                                      <Flex
                                        key={itemIndex}
                                        align="center"
                                        justify="space-between"
                                        gap="xs"
                                      >
                                        <Text size="sm" c="dark">
                                          {item.label}
                                        </Text>
                                        <Text size="sm" c="dark" className="font-medium">
                                          {item.value || '-'}
                                        </Text>
                                      </Flex>
                                    </Skeleton>
                                  ))}
                                  {!isLastSection ? <Divider mt={4} /> : null}
                                </Flex>
                              );
                            })}
                          </Flex>
                        </CustomInfoWindow>
                      )}
                  </Marker>
                ))}
              </>
            )}
          </MarkerClusterer>
        </GoogleMap>
      </div>
      {hotelClientMapData ? (
        <ClientMapFilters
          isModalOpen={isFiltersOpen}
          onClose={() => setIsFiltersOpen(false)}
          clearMap={() => clearClusterers()}
        />
      ) : null}
    </div>
  );
};
