import { Checkbox } from '@mantine/core';
import { Input } from '@common/components/atoms/Input';
import { Controller, useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { schema } from '@pages/Client/PricingStrategy/RoomSetup/common/formSchema';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { useFeaturesStore } from '@common/store/features';
import { InputHelperMessage } from '@common/components/atoms/InputHelperMessage';
import { Icon } from '@common/components/foundations/icons';
import { useViewStore } from '@common/store/view';
import { useTranslation } from 'react-i18next';
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider
} from '@common/components/molecules/Tooltip';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useRoomSetupStore } from '@pages/Client/PricingStrategy/RoomSetup/store/roomSetup';
import { usePmsProvider } from '~/Client/hooks/usePmsProvider';

export const PricingSetup: React.FC<{ referenceRoomBasePrice: number | undefined }> = ({
  referenceRoomBasePrice
}) => {
  const { t } = useTranslation();
  const {
    control,
    setValue,
    formState: { isSubmitting }
  } = useFormContext<z.infer<typeof schema>>();
  const { isAbsoluteAdjustmentToReferenceRoom, isApartment } = useRoomSetupStore();
  const { view } = useViewStore();
  const { features } = useFeaturesStore();
  const { hotelDetails } = useHotelDetails();
  const { hasAdditionalPricePerPerson } = usePmsProvider();

  return (
    <>
      <div className="flex w-full flex-col gap-y-6 md:w-2/3">
        <Controller
          control={control}
          name="pricingSetup.basePrice"
          render={({ field: { value, onChange, name }, fieldState: { error, invalid } }) => (
            <Input
              showClearButton={false}
              disabled={isSubmitting}
              leadingAddon={CurrencyFormatter.currencySymbol()}
              type="number"
              label={`${t('Base Price')}*`}
              placeholder={t('Please enter the base price') as string}
              background="grey"
              name={name}
              value={value}
              error={invalid}
              trailingAddon={
                <TooltipProvider delayDuration={75}>
                  <Tooltip>
                    <TooltipTrigger type="button">
                      <Icon.Help className="h-5 w-5 fill-grey" />
                    </TooltipTrigger>
                    <TooltipContent side="bottom" className="max-w-sm text-left">
                      {t(
                        `The Base Price is the most important number in your system. You can think of it as the average price for this ${hotelDetails?.room_apartment_space_name.toLowerCase()} and rate over the whole year. It’s not your ADR but more an average BAR.  In general, if you move it up by $50 then all your prices will go up by an average of around $50. Please be very careful changing it. You can read more in the Help Center.`
                      )}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              }
              helperMessage={
                invalid ? (
                  <InputHelperMessage
                    icon={<Icon.WarningOutline className="h-3 w-3 fill-uiRed" />}
                    message={error?.message}
                  />
                ) : null
              }
              onChange={(e) => {
                onChange(e);
                setValue(
                  'pricingSetup.derivationFromReferenceRoom',
                  isAbsoluteAdjustmentToReferenceRoom
                    ? parseInt(e.target.value) - (referenceRoomBasePrice || 0)
                    : Math.round(
                        ((parseInt(e.target.value) - (referenceRoomBasePrice || 0)) /
                          (referenceRoomBasePrice || 0)) *
                          100
                      )
                );
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="pricingSetup.derivationFromReferenceRoom"
          render={({ field: { value, onChange, name }, fieldState: { invalid, error } }) => (
            <Input
              showClearButton={false}
              disabled={isSubmitting}
              leadingAddon={
                isAbsoluteAdjustmentToReferenceRoom ? CurrencyFormatter.currencySymbol() : '%'
              }
              type="number"
              label={
                (t(`Derivation from Reference ${isApartment ? 'Apartment' : 'Room'}`) as string) +
                '*'
              }
              placeholder={t('Please enter the derivation from reference room') as string}
              background="grey"
              name={name}
              value={value}
              onChange={(e) => {
                onChange(e);
                setValue(
                  'pricingSetup.basePrice',
                  isAbsoluteAdjustmentToReferenceRoom
                    ? (referenceRoomBasePrice || 0) + parseInt(e.target.value)
                    : Math.round(
                        (referenceRoomBasePrice || 0) * (1 + parseInt(e.target.value) * 0.01)
                      )
                );
              }}
              error={invalid}
              helperMessage={
                invalid ? (
                  <InputHelperMessage
                    icon={<Icon.WarningOutline className="h-3 w-3 fill-uiRed" />}
                    message={error?.message}
                  />
                ) : null
              }
            />
          )}
        />
        {hasAdditionalPricePerPerson() ? (
          <div className="flex w-full flex-col gap-y-6 ">
            <Controller
              control={control}
              name="occupancyPricing.additionalAdultPrice"
              render={({ field: { value, onChange, name }, fieldState: { error, invalid } }) => (
                <Input
                  showClearButton={false}
                  background="grey"
                  leadingAddon={CurrencyFormatter.currencySymbol()}
                  label={(t('Additional Price Per Person') as string) + '*'}
                  name={name}
                  value={value}
                  onChange={onChange}
                  error={invalid}
                  helperMessage={
                    invalid ? (
                      <InputHelperMessage
                        icon={<Icon.WarningOutline className="h-3 w-3 fill-uiRed" />}
                        message={error?.message}
                      />
                    ) : null
                  }
                />
              )}
            />
          </div>
        ) : null}
        {features?.includes(6) ? (
          <>
            <Controller
              control={control}
              name="pricingSetup.defaultMinPrice"
              render={({ field: { value, onChange, name }, fieldState: { error, invalid } }) => (
                <Input
                  showClearButton={false}
                  disabled={isSubmitting}
                  leadingAddon={CurrencyFormatter.currencySymbol()}
                  type="number"
                  label={(t('Default Minimum Price') as string) + '*'}
                  placeholder={t('Please enter the default minimum price') as string}
                  background="grey"
                  name={name}
                  value={value}
                  error={invalid}
                  trailingAddon={
                    <TooltipProvider delayDuration={75}>
                      <Tooltip>
                        <TooltipTrigger type="button">
                          <Icon.Help className="h-5 w-5 fill-grey" />
                        </TooltipTrigger>
                        <TooltipContent side="bottom" className="max-w-sm text-left">
                          {t(
                            `The Default Minimum Price is the price that we will not sell this ${hotelDetails?.room_apartment_space_name.toLowerCase()} below. For example, if you set a minimum of $99 and we will never set this ${hotelDetails?.room_apartment_space_name.toLowerCase()} and rate below $99 unless you specifically over-ride it. To over-ride the Default Minimum, you can either use ‘Fix Price’ to fix the price below. Or you can change the minimum (up or down) on an individual day basis, using the Adjustment View. Find out more about minimum and maximum prices in our Help Center.`
                          )}
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  }
                  helperMessage={
                    invalid ? (
                      <InputHelperMessage
                        icon={<Icon.WarningOutline className="h-3 w-3 fill-uiRed" />}
                        message={error?.message}
                      />
                    ) : null
                  }
                  onChange={onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="pricingSetup.defaultMaxPrice"
              render={({ field: { value, onChange, name }, fieldState: { error, invalid } }) => (
                <Input
                  showClearButton={false}
                  disabled={isSubmitting}
                  leadingAddon={CurrencyFormatter.currencySymbol()}
                  type="number"
                  label={(t('Default Maximum Price') as string) + '*'}
                  background="grey"
                  placeholder={t('Please enter the default maximum price') as string}
                  name={name}
                  value={value}
                  onChange={onChange}
                  error={invalid}
                  trailingAddon={
                    <TooltipProvider delayDuration={75}>
                      <Tooltip>
                        <TooltipTrigger type="button">
                          <Icon.Help className="h-5 w-5 fill-grey" />
                        </TooltipTrigger>
                        <TooltipContent side="bottom" className="max-w-sm text-left">
                          {t(
                            `The Default Maximum Price is the price that we will not sell this ${hotelDetails?.room_apartment_space_name.toLowerCase()} above. For example, if you set a maximum of $299 and we will never set this ${hotelDetails?.room_apartment_space_name.toLowerCase()} and rate above $299 unless you specifically over-ride it. To over-ride the Default Maximum, you can either use ‘Fix Price’ to fix the price higher. Or you can change the maximum (up or down) on an individual day basis, using the Adjustment View. Find out more about minimum and maximum prices in our Help Center.`
                          )}
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  }
                  helperMessage={
                    invalid ? (
                      <InputHelperMessage
                        icon={<Icon.WarningOutline className="h-3 w-3 fill-uiRed" />}
                        message={error?.message}
                      />
                    ) : null
                  }
                />
              )}
            />
          </>
        ) : null}
      </div>

      {view === 'admin' ? (
        <Controller
          control={control}
          name="pricingSetup.dontCountUploads"
          render={({ field: { value, onChange, name } }) => (
            <div className="flex items-center gap-1.5">
              <Checkbox
                label={t("Don't count uploads")}
                id={name}
                name={name}
                checked={value}
                onChange={onChange}
              />
            </div>
          )}
        />
      ) : null}
    </>
  );
};
