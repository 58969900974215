import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { DatePicker } from '@common/components/molecules/DatePicker';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useAddFreeTrial } from '@pages/Client/Account/Subscription/hooks/usePayment';
import { Button, Group } from '@mantine/core';
import { DateInput, DatesProvider } from '@mantine/dates';
import { useUserLocale } from '@common/hooks/useUserLocale';
import { Day } from 'use-lilius';

const schema = z.object({
  free_trial_end: z.date()
});

export const FreeTrialForm = () => {
  const { t } = useTranslation();
  const { hotelDetails: hotel } = useHotelDetails();
  const { locale } = useUserLocale();
  const { mutate: addFreeTrialTime, isLoading: addFreeTrialLoading } = useAddFreeTrial(true);

  const { control, handleSubmit, watch } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      free_trial_end: dayjs(hotel?.free_trial_end).toDate()
    }
  });

  const onSubmit = () => {
    addFreeTrialTime({
      free_trial_end: dayjs(watch('free_trial_end')).tz(hotel?.timezone).format('YYYY-MM-DD')
    });
  };

  return (
    <>
      <Group gap="xs">
        <Controller
          control={control}
          name="free_trial_end"
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <DatesProvider
              settings={{
                locale: locale.code,
                firstDayOfWeek: hotel?.starts_monday ? Day.MONDAY : Day.SUNDAY
              }}
            >
              <DateInput
                value={value}
                onChange={onChange}
                error={errors.free_trial_end ? errors?.free_trial_end?.message?.toString() : ''}
                defaultDate={dayjs().add(7, 'day').toDate()}
                minDate={dayjs().subtract(1, 'day').toDate()}
              />
            </DatesProvider>
          )}
        />

        <Button onClick={handleSubmit(onSubmit)} loading={addFreeTrialLoading}>
          {t('Save')}
        </Button>
      </Group>
    </>
  );
};
