import { createNewUser } from '@common/api/account';
import {
  ClientRegisterPayload,
  ResellerMember,
  SearchClientResults
} from '@common/api/account/types';
import { setAuthToken } from '@common/api/axiosInstance';
import { CreateHotelPayload, SearchHotelResult } from '@common/api/hotel/types';
import AutoCompleteInput from '@common/components/atoms/AutoCompleteInput';
import { isBookingUrl } from '@common/utils/validateBookingUrl';

import { GoogleMapsLibrary } from '@common/components/atoms/GoogleMapsAutoComplete';
import { Input } from '@common/components/atoms/Input';
import { RadioGroup } from '@common/components/atoms/RadioGroup';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Header } from '@common/components/molecules/Header/Header';
import { Page } from '@common/components/organisms/Page';
import { LANGUAGES } from '@common/constants/language';
import {
  useGetResellerList,
  useGetResellerMember,
  useGetSalesList
} from '@common/hooks/useResellerPartner';
import { useCountryList, useCurrencyList } from '@common/hooks/useSystem';
import { useUserRole } from '@common/hooks/useUserRole';
import { useAuthStore } from '@common/store/auth';
import { useNotificationsStore } from '@common/store/notifications';
import { getEnvVar } from '@common/utils/env';
import { zodResolver } from '@hookform/resolvers/zod';
import { StandaloneSearchBox, useLoadScript } from '@react-google-maps/api';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';
import { useRegisterHotel } from '@pages/Admin/CreateProperty/hooks/useRegisterHotel';
import { useSearchClient } from '@pages/Admin/CreateProperty/hooks/useSearchClient';
import { useSearchHotelList } from '@pages/Admin/CreateProperty/hooks/useSearchHotel';
import { useGetPartnersUserList } from '@pages/Admin/PartnerUsers/hooks/usePartnerUserList';
import { useDocumentTitle } from '@mantine/hooks';
import { Button, SegmentedControl, Divider } from '@mantine/core';
import { useCheckBookingUrlStore } from '@pages/Client/Account/Property/store/bookingUrl';
import { LoadingCircle } from '@common/components/foundations/icons/icons';
import { useFindBookingUrl } from '@pages/Admin/CreateProperty/hooks/useFindBookingUrl';

enum DealOwnerTab {
  Sales = 'sales',
  Partner = 'partner'
}

const libraries = [GoogleMapsLibrary.Places];

const schema = z.object({
  lat: z.number().optional(),
  lng: z.number().optional(),
  currency: z.number(),
  address: z.string().min(1),
  country: z.number(),
  name: z.string().min(1),
  booking_ref_url: z
    .string()
    .default('')
    .refine((value) => isBookingUrl(value), {
      message: 'Invalid booking URL'
    })
    .optional(),
  hotels_ref_url: z.string().optional().default(''),
  expedia_ref_url: z.string().optional().default(''),
  number_of_rooms: z
    .number()
    .or(z.string().transform(Number))
    .refine((value) => value > 0, {
      message: 'Number of rooms must be greater than 0'
    }),

  timezone: z.string().min(1),
  hotel_list: z
    .object({
      label: z.string(),
      value: z.number(),
      address: z.string().optional(),
      country: z.number(),
      currency: z.number().nullish(),
      lat: z.number(),
      lng: z.number(),
      region: z.string().nullish(),
      review: z.number(),
      booking_ref_url: z.string().optional()
    })
    .optional()
});

const notPartnerSchema = z.object({
  // partner
  reseller: z.union([z.number(), z.null()]),
  // partner user & Sales
  responsible_person: z.number()
});

const defaultReferenceRoomData = {
  name: 'Double Room',
  max_occupancy: 2,
  avg_price: 125
};

const clientRegisterSchema = z.object({
  username: z.string().email(),
  first_name: z.string().trim().min(1, { message: 'Required' }),
  last_name: z.string().trim().min(1, { message: 'Required' }),
  language: z.string().optional()
});

const clientSchema = z.object({
  client: z.object({
    value: z.number(),
    label: z.string()
  })
});

const CREATE_OPTIONS = [
  {
    label: 'Search in Database',
    value: 0
  },
  {
    label: 'Add New Property',
    value: 1
  }
];
const CLIENT_OPTIONS = [
  {
    label: 'Create New User',
    value: 0
  },
  {
    label: 'Assign To Client',
    value: 1
  }
];

export const CreateProperty = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Create Property'));
  const { token, user } = useAuthStore();
  const { isPartner } = useUserRole();
  const { addNotification } = useNotificationsStore();
  const { findBookingUrl, isLoading: isCheckBookingLoading } = useFindBookingUrl();
  const { isValidUrl, suggestBookingUrl, isSuccessUrl } = useCheckBookingUrlStore();

  const [createState, setCreateState] = useState(1);
  const [clientState, setClientState] = useState(0);
  const [searchClientQuery, setSearchClientQuery] = useState('');
  const [searchHotelQuery, setSearchHotelQuery] = useState('');
  const [staticsDataRegisterHotel, setStaticsDataRegisterHotel] = useState({
    notes: '',
    review: 0,
    website: '',
    expedia_ref_url: '',
    hotels_ref_url: '',
    region: '',
    postcode: '',
    reference_room_data: JSON.stringify(defaultReferenceRoomData)
  });
  const [dealOwnerTab, setDealOwnerTab] = useState<DealOwnerTab>(DealOwnerTab.Sales);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: getEnvVar('VITE_GOOGLEAPIKEY'),
    libraries
  });

  const {
    countryList,
    query: { isLoading: countryFetchLoading }
  } = useCountryList();
  const {
    currencyList,
    query: { isLoading: currencyFetchLoading }
  } = useCurrencyList();

  const mergedSchema = z.object({
    ...schema.shape,
    ...clientRegisterSchema.shape,
    ...notPartnerSchema.shape
  });

  const partnerSchema = z.object({
    ...schema.shape,
    ...clientRegisterSchema.shape
  });

  const assignToClientPartnerSchema = z.object({
    ...schema.shape,
    ...clientSchema.shape
  });

  const assignToClientSchema = z.object({
    ...schema.shape,
    ...clientSchema.shape,
    ...notPartnerSchema.shape
  });

  type FormValues =
    | z.infer<typeof schema>
    | z.infer<typeof clientRegisterSchema>
    | z.infer<typeof clientSchema>
    | z.infer<typeof mergedSchema>
    | z.infer<typeof partnerSchema>;

  const getSchema = () => {
    if (clientState === 0 && !isPartner) {
      return mergedSchema;
    } else if (clientState === 0 && isPartner) {
      return partnerSchema;
    } else if (clientState === 1 && isPartner) {
      return assignToClientPartnerSchema;
    } else {
      return assignToClientSchema;
    }
  };

  const { control, watch, setValue, handleSubmit } = useForm<FormValues>({
    resolver: zodResolver(getSchema())
  });

  const searchBoxRef = useRef<google.maps.places.SearchBox | null>(null);

  const {
    partnerUserList
    // query: { isLoading }
  } = useGetPartnersUserList({
    page: 1,
    search: user?.email
  });

  const partnerAdditionalData = {
    responsible_person: partnerUserList?.results[0]?.user?.id,
    reseller: partnerUserList?.results[0]?.reseller?.id
  };

  const onPlacesChanged = useCallback(() => {
    if (searchBoxRef.current) {
      const places = searchBoxRef.current.getPlaces();

      if (places && places.length > 0) {
        const selectedPlace = places[0];
        setValue('address', selectedPlace.formatted_address as string);
        const { geometry } = selectedPlace;
        if (geometry) {
          const { location } = geometry;
          const latitude = location?.lat();
          const longitude = location?.lng();
          setValue('lat', latitude as number);
          setValue('lng', longitude as number);
          const timeZoneApiUrl = `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=${Math.floor(
            new Date().getTime() / 1000
          )}&key=${getEnvVar('VITE_GOOGLEAPIKEY')}`;
          fetch(timeZoneApiUrl)
            .then((response) => response.json())
            .then((data) => {
              const timeZone = data.timeZoneId;
              setValue('timezone', timeZone);
            });
        }
      }
    }
  }, [setValue, getEnvVar]);

  const { reseller } = useGetResellerList();
  const { reseller_member } = useGetResellerMember();
  const { sales, isLoading: isSalesLoading } = useGetSalesList();
  const {
    clientList: searchClientList,
    query: { isLoading: clientFetchLoading }
  } = useSearchClient({
    search: searchClientQuery
  });

  const {
    hotelList,
    query: { isLoading: hotelFetchLoading }
  } = useSearchHotelList({
    search: searchHotelQuery,
    country: watch('country')
  });

  const { createHotel, isLoading: isCreateHotelLoading } = useRegisterHotel();

  const resellerMember = reseller_member?.filter(
    (item: ResellerMember) => item.reseller.id === watch('reseller')
  );

  const prevLat = useRef<number>();
  const prevLng = useRef<number>();

  useEffect(() => {
    const lat = watch('lat');
    const lng = watch('lng');

    if (lat && lng && (lat !== prevLat.current || lng !== prevLng.current)) {
      prevLat.current = lat;
      prevLng.current = lng;

      if (createState === 0) {
        const fetchTimeZone = async () => {
          const timestamp = Math.floor(new Date().getTime() / 1000);
          const apiKey = getEnvVar('VITE_GOOGLEAPIKEY');
          const timeZoneApiUrl = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${timestamp}&key=${apiKey}`;

          try {
            const response = await fetch(timeZoneApiUrl);
            if (!response.ok) {
              throw new Error(`API call failed: ${response.status}`);
            }

            const data = await response.json();
            const timeZone = data.timeZoneId;
            setValue('timezone', timeZone);
          } catch (error) {
            console.error('Failed to fetch timezone:', error);
          }
        };

        fetchTimeZone();
      }
    }
  }, [watch('lat'), watch('lng'), setValue, createState]);

  const desiredProperties = [
    'address',
    'booking_ref_url',
    'client',
    'country',
    'currency',
    'lat',
    'lng',
    'name',
    'number_of_rooms',
    'reseller',
    'responsible_person',
    'timezone'
  ];

  const payload = watch();
  const newUser = useMutation({
    mutationFn: (newClient: ClientRegisterPayload) => createNewUser(newClient)
  });
  const onSubmit = async () => {
    const filteredPayload = Object.keys(payload)
      .filter((key) => desiredProperties.includes(key))
      .reduce(
        (obj, key) => {
          obj[key as keyof typeof payload] = payload[key as keyof typeof payload];
          return obj;
        },
        {} as Partial<typeof payload>
      );
    // Create With New User
    if (clientState === 0 && (createState === 0 || createState === 1)) {
      const newClient = {
        username: watch('username'),
        first_name: watch('first_name'),
        last_name: watch('last_name'),
        language: watch('language') ? watch('language') : 'en-US',
        password: 'RPG.getstarted!$1'
      };

      setAuthToken(token);
      await newUser.mutate(newClient as unknown as ClientRegisterPayload, {
        onSuccess: (data) => {
          const newHotel = {
            ...filteredPayload,
            ...staticsDataRegisterHotel,
            booking_ref_url: watch('booking_ref_url') ? watch('booking_ref_url') : '',
            client: data.id,
            reseller: isPartner ? partnerAdditionalData.reseller : watch('reseller'),
            responsible_person: isPartner
              ? partnerAdditionalData.responsible_person
              : watch('responsible_person')
          };
          createHotel(newHotel as unknown as CreateHotelPayload);
        },
        onError: (error: unknown) => {
          const axiosError = error as any;
          if (axiosError.response?.data?.username) {
            addNotification('fail', `${axiosError.response.data.username[0]}`);
          } else if (axiosError.response?.data?.last_name) {
            addNotification('fail', `${axiosError.response.data.last_name[0]}`);
          } else if (axiosError.response?.data?.first_name) {
            addNotification('fail', `${axiosError.response.data.first_name[0]}`);
          } else {
            addNotification('fail', 'Failed to create new user');
          }
        }
      });
    } else if (createState === 0) {
      const newHotel = {
        ...filteredPayload,
        ...staticsDataRegisterHotel,
        booking_ref_url: watch('booking_ref_url') || '',
        client: watch('client')?.value,
        reseller: isPartner ? partnerAdditionalData.reseller : watch('reseller'),
        responsible_person: isPartner
          ? partnerAdditionalData.responsible_person
          : watch('responsible_person')
      };
      createHotel(newHotel as CreateHotelPayload);
    } else {
      const newHotel = {
        ...filteredPayload,
        ...staticsDataRegisterHotel,
        booking_ref_url: watch('booking_ref_url') || '',
        client: watch('client')?.value,
        reseller: isPartner ? partnerAdditionalData.reseller : watch('reseller'),
        responsible_person: isPartner
          ? partnerAdditionalData.responsible_person
          : watch('responsible_person')
      };
      createHotel(newHotel as CreateHotelPayload);
    }
  };

  const ASSIGN_CLIENT_OPTIONS = searchClientList?.results?.map((item: SearchClientResults) => ({
    label: `${item.first_name} ${item.last_name} - (${item.email})`,
    value: item.id
  }));

  const HOTEL_LIST_OPTIONS = hotelList?.results?.map((item: SearchHotelResult) => ({
    label: item.name,
    value: item.id,
    address: item?.address,
    country: item?.country?.id,
    currency: item?.currency?.id,
    lat: item.lat,
    lng: item.lng,
    region: item.region,
    name: item.name,
    review: item.review,
    booking_ref_url: item?.ota_reference
  }));

  return (
    <Page
      header={
        <Header
          title="Create Property"
          actions={
            <Button type="submit" onClick={handleSubmit(onSubmit)} loading={isCreateHotelLoading}>
              Create Property
            </Button>
          }
        />
      }
    >
      <div className="rounded-md bg-white p-4 shadow-none">
        <div className="grid grid-cols-1 gap-8 p-3">
          <div className="grid grid-cols-1 gap-4">
            <Typography variant="h6" color="darkGrey" className="text-h6-bold">
              Property
            </Typography>
            <RadioGroup
              options={CREATE_OPTIONS}
              value={createState}
              defaultSelectedOption={0}
              onChange={(value) => setCreateState(value)}
              optionsWrapperClassName="flex gap-4"
            />
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 grid grid-cols-1 gap-3 md:col-span-6">
                <div>
                  <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                    Country*
                  </Typography>
                  <Controller
                    control={control}
                    name="country"
                    render={({ field: { value, name, onChange } }) => (
                      <SelectDropdown
                        fullWidth
                        value={value}
                        background="grey"
                        name={name}
                        options={
                          countryFetchLoading
                            ? []
                            : countryList?.map((item) => ({
                                label: `${item.name} (${item.abbreviation})`,
                                value: item.id
                              }))
                        }
                        placeholder="Select country"
                        onChange={(value) => {
                          onChange(value);
                          setSearchHotelQuery('');
                        }}
                      />
                    )}
                  />
                </div>
                <div>
                  <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                    Address
                  </Typography>

                  {isLoaded ? (
                    <StandaloneSearchBox
                      onLoad={(ref) => (searchBoxRef.current = ref)}
                      onPlacesChanged={onPlacesChanged}
                    >
                      <Controller
                        name="address"
                        control={control}
                        rules={{
                          required: `${t('Please enter address')}`
                        }}
                        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                          <Input
                            placeholder={`${t('Please enter address')}`}
                            onChange={onChange}
                            disabled={watch('country') === undefined}
                            onBlur={onBlur}
                            value={value}
                            background="grey"
                            type="text"
                            error={error ? true : false}
                            onClear={() => {
                              setValue('address', '');
                              setValue('lat', 0);
                              setValue('lng', 0);
                              setValue('timezone', '');
                            }}
                            helperMessage={
                              error ? (
                                <>
                                  <div className="flex items-center gap-2 text-error">
                                    <Icon.WarningOutline className="w-4" />
                                    <Typography element="span" color="error" variant="meta-2">
                                      {t(`${error?.message || ''}`)}
                                    </Typography>
                                  </div>
                                </>
                              ) : null
                            }
                          />
                        )}
                      />
                    </StandaloneSearchBox>
                  ) : (
                    'Loading...'
                  )}
                </div>
                <Controller
                  name="booking_ref_url"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      label="Booking Url"
                      background="grey"
                      placeholder="Please enter booking url"
                      value={value}
                      trailingAddon={
                        isCheckBookingLoading ? <LoadingCircle className="ml-2" /> : null
                      }
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value && !error && isBookingUrl(value)) {
                          findBookingUrl({
                            booking_ref_url: value,
                            country_id: watch('country'),
                            lat: parseFloat(watch('lat')?.toFixed(6) ?? '0'),
                            long: parseFloat(watch('lng')?.toFixed(6) ?? '0')
                          });
                        }
                        onChange(value);
                      }}
                      onClear={() => setValue('booking_ref_url', '')}
                      error={!!error}
                      helperMessage={
                        (error && (
                          <div className="flex items-center gap-2 text-error">
                            <Icon.WarningOutline className="w-4" />
                            <Typography element="span" color="error" variant="meta-2">
                              {error?.message?.toString() ?? 'Invalid booking URL'}
                            </Typography>
                          </div>
                        )) ||
                        (!isValidUrl && value !== suggestBookingUrl && (
                          <div className="flex items-center gap-2 text-error">
                            <Icon.WarningOutline className="w-4" />
                            <Typography element="span" color="error" variant="meta-2">
                              {isSuccessUrl
                                ? `${t('Suggested: ')}${suggestBookingUrl}`
                                : suggestBookingUrl}
                            </Typography>
                          </div>
                        ))
                      }
                    />
                  )}
                />
                <Controller
                  name="number_of_rooms"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      label="Total Number of Rooms"
                      background="grey"
                      type="number"
                      placeholder="Please enter total rooms"
                      value={value}
                      onChange={onChange}
                      onClear={() => setValue('number_of_rooms', 1)}
                      error={!!error}
                      helperMessage={
                        error && (
                          <div className="flex items-center gap-2 text-error">
                            {error ? <Icon.WarningOutline className="w-4" /> : ''}
                            <Typography element="span" color="error" variant="meta-2">
                              {error ? error?.message?.toString() : ''}
                            </Typography>
                          </div>
                        )
                      }
                    />
                  )}
                />
              </div>
              <div className="col-span-12 grid grid-cols-1 gap-3 md:col-span-6">
                {createState === 1 ? (
                  <Controller
                    name="name"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <Input
                        label="Property Name"
                        background="grey"
                        placeholder="Please enter property name"
                        value={value}
                        onChange={onChange}
                        onClear={() => setValue('name', '')}
                        error={!!error}
                        helperMessage={
                          error && (
                            <div className="flex items-center gap-2 text-error">
                              {error ? <Icon.WarningOutline className="w-4" /> : ''}
                              <Typography element="span" color="error" variant="meta-2">
                                {error ? error?.message?.toString() : ''}
                              </Typography>
                            </div>
                          )
                        }
                      />
                    )}
                  />
                ) : (
                  <div>
                    <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                      Search Hotel
                    </Typography>
                    <Controller
                      control={control}
                      name="hotel_list"
                      render={({ field: { value, name, onChange }, fieldState: { error } }) => (
                        <>
                          <AutoCompleteInput
                            placeholder="Search Hotel ..."
                            name={name}
                            disabled={!watch('country')}
                            hint="Please enter at least 5 characters"
                            options={HOTEL_LIST_OPTIONS}
                            loadingOptions={hotelFetchLoading}
                            value={value}
                            onChange={(value) => {
                              onChange(value);
                              setStaticsDataRegisterHotel((prevState) => ({
                                ...prevState,
                                review: value?.review,
                                postcode: value?.postcode,
                                region: value?.region
                              }));
                              setValue('address', value?.address as string);
                              setValue('lat', value?.lat as number);
                              setValue('lng', value?.lng as number);
                              setValue('name', value?.name as string);
                              setValue('booking_ref_url', value?.booking_ref_url);
                              setValue('currency', value?.currency);
                              setValue('name', value?.name);
                            }}
                            query={searchHotelQuery}
                            onQueryChange={(query) => setSearchHotelQuery(query)}
                          />
                          {error ? (
                            <div className="flex items-center gap-2 text-error">
                              {error ? <Icon.WarningOutline className="w-4" /> : ''}
                              <Typography element="span" color="error" variant="meta-2">
                                Some fields could not be auto-filled. Please enter them manually or
                                use &#34;Add New Property&#34;.
                              </Typography>
                            </div>
                          ) : null}
                        </>
                      )}
                    />
                  </div>
                )}

                <Controller
                  name="lat"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      label="Latitude"
                      background="grey"
                      type="number"
                      placeholder=""
                      readOnly
                      value={value}
                      onChange={onChange}
                      showClearButton={false}
                      error={!!error}
                      helperMessage={
                        error && (
                          <div className="flex items-center gap-2 text-error">
                            {error ? <Icon.WarningOutline className="w-4" /> : ''}
                            <Typography element="span" color="error" variant="meta-2">
                              {error ? error?.message?.toString() : ''}
                            </Typography>
                          </div>
                        )
                      }
                    />
                  )}
                />
                <Controller
                  name="lng"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      label="Longitude"
                      background="grey"
                      readOnly
                      type="number"
                      placeholder=""
                      value={value}
                      onChange={onChange}
                      showClearButton={false}
                      error={!!error}
                      helperMessage={
                        error && (
                          <div className="flex items-center gap-2 text-error">
                            {error ? <Icon.WarningOutline className="w-4" /> : ''}
                            <Typography element="span" color="error" variant="meta-2">
                              {error ? error?.message?.toString() : ''}
                            </Typography>
                          </div>
                        )
                      }
                    />
                  )}
                />

                <div>
                  <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                    Currency*
                  </Typography>
                  <Controller
                    control={control}
                    name="currency"
                    render={({
                      field: { value, name, onChange },
                      fieldState: { error, invalid }
                    }) => (
                      <>
                        <SelectDropdown
                          fullWidth
                          value={value}
                          background="grey"
                          options={
                            currencyFetchLoading
                              ? []
                              : currencyList?.map((item) => ({
                                  label: `${item.name} (${item.symbol})`,
                                  value: item.id
                                }))
                          }
                          name={name}
                          placeholder="Select currency"
                          error={invalid}
                          onChange={(value) => {
                            onChange(value);
                          }}
                        />
                        <div className="flex items-center gap-2 text-error">
                          {error ? <Icon.WarningOutline className="w-4" /> : ''}
                          <Typography element="span" color="error" variant="meta-2">
                            {error ? error?.message?.toString() : ''}
                          </Typography>
                        </div>
                      </>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <Divider />
          <div className="grid grid-cols-1 gap-4">
            <Typography variant="h6" color="darkGrey" className="text-h6-bold">
              Client
            </Typography>
            <RadioGroup
              options={CLIENT_OPTIONS}
              value={clientState}
              defaultSelectedOption={0}
              onChange={(value) => setClientState(value)}
              optionsWrapperClassName="flex gap-4"
            />
            <div className="grid grid-cols-12 gap-4">
              {clientState === 1 ? (
                <div className="col-span-12 md:col-span-6">
                  <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                    Client Name*
                  </Typography>
                  <Controller
                    control={control}
                    name="client"
                    render={({ field: { value, name, onChange }, fieldState: { error } }) => (
                      <>
                        <AutoCompleteInput
                          placeholder="Search Client ..."
                          name={name}
                          hint="Please enter at least 5 characters"
                          options={ASSIGN_CLIENT_OPTIONS}
                          loadingOptions={clientFetchLoading}
                          value={value}
                          onChange={onChange}
                          query={searchClientQuery}
                          onQueryChange={(query) => setSearchClientQuery(query)}
                        />
                        <div className="flex items-center gap-2 text-error">
                          {error ? <Icon.WarningOutline className="w-4" /> : ''}
                          <Typography element="span" color="error" variant="meta-2">
                            {error ? error?.message?.toString() : ''}
                          </Typography>
                        </div>
                      </>
                    )}
                  />
                </div>
              ) : (
                <>
                  <div className="col-span-12 grid grid-cols-1 gap-3 md:col-span-6">
                    <Controller
                      name="username"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Input
                          label="Email*"
                          type="email"
                          placeholder="Please enter an email"
                          background="grey"
                          value={value}
                          onChange={onChange}
                          onClear={() => setValue('username', '')}
                          error={!!error}
                          helperMessage={
                            error && (
                              <div className="flex items-center gap-2 text-error">
                                {error ? <Icon.WarningOutline className="w-4" /> : ''}
                                <Typography element="span" color="error" variant="meta-2">
                                  {error ? error?.message?.toString() : ''}
                                </Typography>
                              </div>
                            )
                          }
                        />
                      )}
                    />
                    <div>
                      <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                        Preferred Language
                      </Typography>
                      <Controller
                        control={control}
                        name="language"
                        render={({ field: { value, name, onChange } }) => (
                          <SelectDropdown
                            fullWidth
                            value={value}
                            options={LANGUAGES}
                            name={name}
                            background="grey"
                            placeholder="Select Language"
                            onChange={(value) => {
                              onChange(value);
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 grid grid-cols-1 gap-3 md:col-span-6">
                    <Controller
                      name="first_name"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Input
                          label="First Name*"
                          type="text"
                          placeholder="Please enter an first name"
                          background="grey"
                          value={value}
                          onChange={onChange}
                          onClear={() => setValue('first_name', '')}
                          error={!!error}
                          helperMessage={
                            error && (
                              <div className="flex items-center gap-2 text-error">
                                {error ? <Icon.WarningOutline className="w-4" /> : ''}
                                <Typography element="span" color="error" variant="meta-2">
                                  {error ? error?.message?.toString() : ''}
                                </Typography>
                              </div>
                            )
                          }
                        />
                      )}
                    />
                    <Controller
                      name="last_name"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Input
                          label="Last Name*"
                          type="text"
                          placeholder="Please enter an last name"
                          background="grey"
                          value={value}
                          onChange={onChange}
                          onClear={() => setValue('last_name', '')}
                          error={!!error}
                          helperMessage={
                            error && (
                              <div className="flex items-center gap-2 text-error">
                                {error ? <Icon.WarningOutline className="w-4" /> : ''}
                                <Typography element="span" color="error" variant="meta-2">
                                  {error ? error?.message?.toString() : ''}
                                </Typography>
                              </div>
                            )
                          }
                        />
                      )}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          {isPartner ? null : (
            <div className="grid grid-cols-1 gap-4">
              <Typography variant="h6" color="darkGrey" className="text-h6-bold">
                Deal Owner
              </Typography>

              <div className="flex w-full flex-col gap-3 md:w-1/2">
                <SegmentedControl
                  value={dealOwnerTab}
                  onChange={(value) => {
                    setDealOwnerTab(value as DealOwnerTab);
                  }}
                  data={[
                    { label: 'Sales', value: DealOwnerTab.Sales },
                    { label: 'Partner', value: DealOwnerTab.Partner }
                  ]}
                />

                {dealOwnerTab === DealOwnerTab.Partner ? (
                  <>
                    <div>
                      <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                        Partner*
                      </Typography>
                      <Controller
                        control={control}
                        name="reseller"
                        render={({
                          field: { value, name, onChange },
                          fieldState: { error, invalid }
                        }) => (
                          <>
                            <SelectDropdown
                              fullWidth
                              value={value}
                              background="grey"
                              options={reseller?.map((item) => ({
                                label: item.name,
                                value: item.id
                              }))}
                              placeholder="Select Partner"
                              error={invalid}
                              name={name}
                              onChange={(value) => {
                                onChange(value);
                              }}
                            />
                            <div className="flex items-center gap-2 text-error">
                              {error ? <Icon.WarningOutline className="w-4" /> : ''}
                              <Typography element="span" color="error" variant="meta-2">
                                {error ? error?.message?.toString() : ''}
                              </Typography>
                            </div>
                          </>
                        )}
                      />
                    </div>

                    <div>
                      <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                        Partner User
                      </Typography>
                      <Controller
                        control={control}
                        name="responsible_person"
                        render={({ field: { value, name, onChange } }) => (
                          <SelectDropdown
                            fullWidth
                            value={value}
                            background="grey"
                            options={resellerMember?.map((item) => ({
                              label: `${item.user.first_name} ${item.user.last_name}`,
                              value: item.user.id
                            }))}
                            placeholder="Select partner user"
                            name={name}
                            onChange={(value) => {
                              onChange(value);
                            }}
                          />
                        )}
                      />
                    </div>
                  </>
                ) : null}

                {dealOwnerTab === DealOwnerTab.Sales ? (
                  <div>
                    <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                      Sales Team*
                    </Typography>
                    <Controller
                      control={control}
                      name="responsible_person"
                      render={({
                        field: { value, name, onChange },
                        fieldState: { error, invalid }
                      }) => (
                        <>
                          <SelectDropdown
                            fullWidth
                            value={value}
                            background="grey"
                            options={
                              isSalesLoading
                                ? []
                                : sales?.map((item) => ({
                                    label: `${item.first_name} ${item.last_name}`,
                                    value: item.id
                                  }))
                            }
                            error={invalid}
                            placeholder="Select sales team"
                            name={name}
                            onChange={(value) => {
                              onChange(value);
                              setValue('reseller', null);
                            }}
                          />
                          <div className="flex items-center gap-2 text-error">
                            {error ? <Icon.WarningOutline className="w-4" /> : ''}
                            <Typography element="span" color="error" variant="meta-2">
                              {error ? error?.message?.toString() : ''}
                            </Typography>
                          </div>
                        </>
                      )}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </div>
      </div>
    </Page>
  );
};
