import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Modal } from '@common/components/molecules/Modal';
import { useTranslation } from 'react-i18next';
import { useDeletePmsRoomType } from '@pages/Client/Account/Integrations/hooks/usePmsRoomType';
import { UpdateRoomTypes } from '@common/api/integration/roomTypesSchema';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  selectedRoomType: UpdateRoomTypes;
};

export const DeleteRoomTypeModal = ({ selectedRoomType, isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { deleteRoomType } = useDeletePmsRoomType();

  const onDelete = () => {
    if (!selectedRoomType?.id) return;
    deleteRoomType(Number(selectedRoomType?.id));
    onClose();
  };

  return (
    <Modal
      onClick={onDelete}
      open={isOpen}
      okText={`${t('Delete')}`}
      onClose={onClose}
      size={'lg'}
      continueIntent="danger"
      children={
        <>
          <div className="flex  flex-col items-start gap-3">
            <div className="my-3">
              <div className=" rounded-full bg-error p-3">
                <Icon.Delete color="white" />
              </div>
            </div>
            <Typography className="mb-1" element="h6" color="darkGrey" variant="h6">
              {t(`Delete: ${selectedRoomType?.name}`)}
            </Typography>
            <Typography>
              {t(`Are you sure you want to delete ${selectedRoomType?.name} ?`)}
            </Typography>
          </div>
        </>
      }
    />
  );
};
