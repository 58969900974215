export interface RoomSetupDrawerTabProps {
  baseRateInPmsOptions: { label: string; value: number }[];
  roomInPmsOptions: { label: string; value: number }[];
  roomOccupancyPricingOptions: { label: string; value: number }[];
}

export enum ROOMS_SETUP_TABS {
  PMSMapping,
  PricingSetup,
  OccupancyPricing,
  DerivedRates
}
