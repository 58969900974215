import { MantineColor } from '@mantine/core';

interface Status {
  label: string;
  bgColor?: MantineColor;
}

const StatusList: Record<number, Status> = {
  1: { label: 'Scheduled', bgColor: 'indigo.6' },
  2: { label: 'In progress', bgColor: 'orange.6' },
  3: { label: 'Success', bgColor: 'green.9' },
  4: { label: 'Error', bgColor: 'red.6' },
  5: { label: 'Revoked', bgColor: 'dark.9' }
};

export const getStatusDetails = (statusCode: number): Status => {
  const status = StatusList[statusCode];
  if (status) {
    return {
      label: status.label,
      bgColor: status.bgColor
    };
  }
  // Default values if status code is not found in the list
  return {
    label: 'Unknown',
    bgColor: 'darkGrey'
  };
};
