import { Button } from '@common/components/atoms/Button';
import { Typography } from '@common/components/foundations/Typography';
import { Icon } from '@common/components/foundations/icons';
import { TabBar } from '@common/components/molecules/TabsBar';
import { API_DATE_FORMAT } from '@common/constants/date';
import { BasePusherEventResponse, PusherEventNames } from '@common/constants/pusher';
import { Feature, useFeaturesStore } from '@common/store/features';
import { useNotificationsStore } from '@common/store/notifications';
import { Tabs, usePriceDrawerStore } from '@common/store/priceDrawer';
import { usePusherStore } from '@common/store/pusher';
import { useViewStore } from '@common/store/view';
import { useUploadPMSPriceModalState } from '@pages/Client/Calendar/components/BulkEdit/store/useUploadPMSPriceModalState';
import styles from '@pages/Client/Calendar/components/PriceDrawerContent/PriceDrawerContent.module.css';
import { UploadPricesModal } from '@pages/Client/Calendar/components/PriceDrawerContent/UploadPrices/UploadPricesModal';
import {
  useRecommendedMinStay,
  useRecommendedPrice
} from '@pages/Client/Calendar/components/Tables/hooks';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { useSurgePrice } from '@pages/Client/Calendar/hooks/useSurgePrice';
import { useWeather } from '@pages/Client/Calendar/hooks/useWeather';
import { useCalendarPageStore } from '@pages/Client/Calendar/store/calendar';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';
import { useUpselling } from '@pages/Client/hooks/useUpselling';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { Overview } from '@pages/Client/Calendar/components/PriceDrawerContent/Overview';
import { EditPrices } from '@pages/Client/Calendar/components/PriceDrawerContent/EditPrices';
import { AllRoomTypes } from '@pages/Client/Calendar/components/PriceDrawerContent/AllRoomTypes';
import { RateEvolution } from '@pages/Client/Calendar/components/PriceDrawerContent/RateEvolution';
import { PriceDrawerUserflowIds } from '@common/types/userflow-ids';
import { Badge } from '@mantine/core';

interface TabsConfig {
  spaceName: string | undefined;
  view?: string;
  isReadOnly?: boolean;
  features?: Feature[];
  isRateEvolutionEntryPoint?: boolean;
  isRateEvolutionUpselling: boolean;
  SparkIcon: any;
  isHovered: { pricingEvolution: boolean };
  setIsHovered: (value: { pricingEvolution: boolean }) => void;
  disabledEditPrices?: boolean;
}

export interface PriceDrawerContentProps {
  viewingDate?: Date;
  onClose?: () => void;
}

export const PriceDrawerContent = (props: PriceDrawerContentProps) => {
  const { t } = useTranslation();

  const getTabs: ({
    spaceName,
    view,
    isReadOnly,
    features,
    isRateEvolutionEntryPoint,
    isRateEvolutionUpselling,
    SparkIcon,
    isHovered,
    setIsHovered,
    disabledEditPrices
  }: TabsConfig) => React.ComponentProps<typeof TabBar>['options'] = ({
    spaceName,
    view,
    isReadOnly,
    features,
    isRateEvolutionEntryPoint,
    disabledEditPrices
  }) => [
    {
      label: t('Overview'),
      value: Tabs.Overview,
      userflowId: PriceDrawerUserflowIds.OVERVIEW_TAB_ITEM
    },
    ...(isReadOnly
      ? []
      : [
          {
            id: 'editPricesTab',
            label: t('Edit Prices'),
            value: Tabs.EditPrices,
            disabled: disabledEditPrices,
            tooltip: disabledEditPrices
              ? t('Please dismiss the surge event before editing prices')
              : undefined,
            userflowId: PriceDrawerUserflowIds.EDIT_PRICES_TAB_ITEM
          }
        ]),
    {
      label: t(`All ${spaceName} Types`),
      value: Tabs.AllRoomTypes,
      userflowId: PriceDrawerUserflowIds.ALL_ROOM_TYPES_TAB_ITEM
    },
    ...(isRateEvolutionEntryPoint
      ? [
          {
            label: t(`Price Evolution`),
            value: Tabs.RateEvolution,
            renderAdjacent: () => {
              if (view === 'admin' && !features?.includes(Feature.PriceEvolution)) {
                return <Badge color={'yellow.6'}>{t('Admin')}</Badge>;
              }
              return null;
            },
            userflowId: PriceDrawerUserflowIds.PRICE_EVOLUTION_TAB_ITEM
          }
        ]
      : [])
  ];

  const debug = false;
  const { viewingDate } = props;
  const { activeTab, setActiveTab, setDrawerState, setAdjustmentsData, drawerState } =
    usePriceDrawerStore();
  const { hotelDetails } = useHotelDetails();
  const [currentViewingDate, setCurrentViewingDate] = useState(dayjs(viewingDate));
  const { pricingSettings } = usePricingSettings();
  const { liliusInstance } = useCalendarPageStore();
  const { addNotification } = useNotificationsStore();
  const { setRecommendedPriceData } = useRecommendedPrice();
  const { setRecommendedMinStayData } = useRecommendedMinStay();
  const { cachePriceQuery, pricingSettingsQuery } = useRoomPrices();
  const { view, isReadOnly } = useViewStore();
  const { features } = useFeaturesStore();
  const { SparkIcon, isRateEvolutionUpselling, ProEntryPoints } = useUpselling();
  const { toggleIsInterMediate, setIntermediateFunction } = useUploadPMSPriceModalState();
  const { getSurgePriceForDay } = useSurgePrice();
  const [isHovered, setIsHovered] = useState({
    pricingEvolution: false
  });

  const { selectedHotelRoomId } = useCalendarPageStore();

  const key = currentViewingDate.format(API_DATE_FORMAT);

  const { isDisableEditPrice } = getSurgePriceForDay(key, selectedHotelRoomId);

  // Set tab to Overview on drawer open
  useEffect(() => {
    if (!drawerState) return;

    setActiveTab(debug ? Tabs.EditPrices : Tabs.Overview);
    liliusInstance?.setViewing(currentViewingDate.toDate());
    toggleIsInterMediate(false);
    setIntermediateFunction(() => {});
  }, [drawerState]);

  const renderTabContent = () => {
    // Return null if drawer is closed OR if we're transitioning between tabs
    if (!drawerState) return null;

    // Use a key to force unmount when tab changes
    const key = `tab-${activeTab}-${drawerState}`;

    switch (activeTab) {
      case Tabs.EditPrices:
        return <EditPrices key={key} currentViewingDate={currentViewingDate} drawerProps={props} />;
      case Tabs.AllRoomTypes:
        return (
          <AllRoomTypes key={key} currentViewingDate={currentViewingDate} drawerProps={props} />
        );
      case Tabs.RateEvolution:
        return <RateEvolution key={key} currentViewingDate={currentViewingDate} />;
      case Tabs.Overview:
        return <Overview key={key} currentViewingDate={currentViewingDate} drawerProps={props} />;
      default:
        return null;
    }
  };

  // Reset temp pricing settings data (used for price edit) when changing date
  useEffect(() => {
    if (!pricingSettings) return;
    setAdjustmentsData({});
    setRecommendedPriceData(undefined);
    setRecommendedMinStayData(undefined);
  }, [currentViewingDate, pricingSettings]);

  const previousDay = () => {
    setCurrentViewingDate(currentViewingDate.add(-1, 'day'));
  };

  const nextDay = () => {
    setCurrentViewingDate(currentViewingDate.add(1, 'day'));
  };
  const { getWeatherForDate } = useWeather();

  const weatherInfo = useMemo(() => {
    return getWeatherForDate(currentViewingDate);
  }, [currentViewingDate]);

  const { channelBind, channelUnbind } = usePusherStore();

  const inventoryMessageCb = async (data: BasePusherEventResponse) => {
    setDrawerState(false);

    if (data.error) {
      addNotification(
        'fail',
        typeof data.message === 'string'
          ? data.message
          : data.message && typeof data.message.detail === 'string'
            ? data.message.detail
            : 'Something went Wrong!'
      );
    } else {
      await Promise.all([cachePriceQuery.refetch(), pricingSettingsQuery.refetch()]);
    }
  };

  useEffect(() => {
    channelBind(PusherEventNames.UploadPriceMessage, inventoryMessageCb);

    return () => {
      channelUnbind(PusherEventNames.UploadPriceMessage, inventoryMessageCb);
    };
  }, [inventoryMessageCb]);

  return (
    <>
      {drawerState ? (
        <div className={styles.drawer}>
          <div className={styles.drawerContent}>
            <div className={styles.drawerHeader}>
              <div className="flex flex-wrap items-center gap-x-4 ">
                <div className="flex items-center">
                  <div className="min-w-[210px]">
                    <Typography className="text-h5 font-medium" color="darkGrey">
                      {currentViewingDate.format('ddd, L')}
                    </Typography>
                  </div>

                  <Button type="button" icon className={styles.navIcon} onClick={previousDay}>
                    <Icon.ChevronLeft className="h-4 w-4" />
                  </Button>

                  <Button type="button" icon className={styles.navIcon} onClick={nextDay}>
                    <Icon.ChevronRight className="h-4 w-4" />
                  </Button>
                </div>

                {weatherInfo && (
                  <span className="mb-0.5 flex items-center gap-x-1 self-end text-meta-2">
                    <img src={weatherInfo.icon_id} width={25} alt="" />
                    {weatherInfo.max_temperature}°
                    <span className="text-grey">{weatherInfo.description}</span>
                  </span>
                )}
              </div>
              <div className="mt-3">
                <TabBar
                  onTabClick={(value) => setActiveTab(value)}
                  options={getTabs({
                    spaceName: hotelDetails?.room_apartment_space_name,
                    view,
                    isReadOnly,
                    features,
                    SparkIcon,
                    isRateEvolutionUpselling,
                    isRateEvolutionEntryPoint: ProEntryPoints.isRateEvolutionEntryPoint,
                    isHovered,
                    setIsHovered,
                    disabledEditPrices: isDisableEditPrice
                  }).map((tab) => ({
                    ...tab,
                    label: t(tab.label)
                  }))}
                  activeTab={activeTab}
                />
              </div>
            </div>

            {renderTabContent()}
          </div>
        </div>
      ) : null}

      <UploadPricesModal viewingDate={currentViewingDate} />
    </>
  );
};
