import { ErrorResponse } from '@common/api/auth';
import { axiosInstance, setAuthToken } from '@common/api/axiosInstance';
import axios, { AxiosError } from 'axios';
import { isEqual, isNil } from 'lodash-es';
import {
  AddRoomRequest,
  ApplyIndividualAdjustmentResponse,
  BookingScrape,
  BookingScraperListResponse,
  CalculateAveragePriceResponse,
  CheckBookingUrlPayload,
  CheckoutSubscription,
  ClientListSchema,
  CompetitorScore,
  CompetitorSetupResponse,
  CreateHotelPayload,
  CreateHSTicketPayload,
  DownloadOutboundApiLogs,
  EnableIframePayload,
  EnableIframeResponse,
  FindBookingUrlPayload,
  FreeTrial,
  FutureDailyResponse,
  GetHotelImportantEventsResponse,
  GetHotelRoomListResponse,
  GetNoteRequest,
  GetTopTwentyCompetitor,
  Hotel,
  HotelClientListPagination,
  HotelCompetitorListResponse,
  HotelInboundApiLogsPagination,
  HotelOutboundApiLogsPagination,
  HotelPmsDataMap,
  HotelPmsList,
  HotelPricePerOccupancyPayloadV2,
  HotelPricePerOccupancyRequest,
  HotelPricePerOccupancyResponse,
  HotelPricePerOccupancyResponseV2,
  HotelPricePerRateRequest,
  HotelPricePerRateResponse,
  HotelRoomEventResponse,
  HotelRoomList,
  HotelRoomNoteResponse,
  HotelState,
  HubspotTicketPayload,
  NotesResponse,
  OverridePricePayload,
  PricingSettings,
  ProfitReportResponse,
  RateDerivationResponse,
  RateDerivationsPayload,
  RatePlansPayload,
  RateRestrictionResponse,
  ReferFriendPayload,
  RegisterPmsPayload,
  ReportIssuePayload,
  ReservationStatusResponse,
  RoomTypesRestrictionsResponse,
  SabeeAppPayload,
  SaveMarketData,
  ScoreCompetitor,
  ScrapeCompetitor,
  ScrapeListResponse,
  SearchHotelCompetitorListResponse,
  SearchHotelResponse,
  SearchMarketCode,
  SSOLoginPropertiesResponse,
  SSOPropertiesResponse,
  SSORegisterPropertiesResponse,
  SubscriptionInformationResponse,
  SyncPmsUpdatePayload,
  UpdateCompetitor,
  UpdateRoomRequest
} from './types';

export const getHotelImportantEvents = () => {
  return axiosInstance.post<GetHotelImportantEventsResponse>('/hotel/important_events/');
};

export const getHotelRoomsList = () => {
  return axiosInstance.get<GetHotelRoomListResponse>('/hotel/room/list/');
};

export const getHotelRoomNotes = () => {
  return axiosInstance.post<HotelRoomNoteResponse>('/hotel/pricing-settings/user-notes/');
};

export const getHotelRoomEvents = async (
  request: GetNoteRequest,
  signal: AbortSignal | undefined
) => {
  return axiosInstance.post<HotelRoomEventResponse>('hotel/new_events_category/', request, {
    signal
  });
};

export const getHotelClientDetail = async () => {
  try {
    const { data } = await axiosInstance.get<Hotel>('/hotel/client/detail/');
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getHotelClientList = async (params: ClientListSchema) => {
  try {
    const { data } = await axiosInstance.get<HotelClientListPagination>('/hotel/client/list/', {
      params: {
        page: params.page,
        ordering: params.ordering,
        country_filter: params.country_filter,
        plan: params.plan,
        state: params.state,
        pms_provider: params.pms_provider,
        deal_owner_filter: params.deal_owner_filter,
        deal_owner_type: params.deal_owner_type,
        search: params.search,
        open_api_partner_id: params.open_api_partner_id
      }
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getHotelClientMap = async (
  min_lat: number,
  max_lat: number,
  min_lng: number,
  max_lng: number,
  limit?: number,
  pms?: string,
  country?: string,
  subscription_state?: string,
  subscription_plan?: string
) => {
  try {
    const { data } = await axiosInstance.get('/hotel/client/list/map', {
      params: {
        min_lat,
        max_lat,
        min_lng,
        max_lng,
        limit,
        pms,
        country,
        subscription_state,
        subscription_plan
      }
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getHotelClientMapDetail = async (hotelId: number): Promise<Hotel> => {
  try {
    const { data } = await axiosInstance.get(`/hotel/client/list/map/${hotelId}`);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getHotelRoomList = async () => {
  try {
    const { data } = await axiosInstance.get<HotelRoomList>('/hotel/room/list/');
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const updateHotelProperty = async (data: any) => {
  try {
    const response = await axiosInstance.patch('/hotel/update/', data);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const updateHourlyExecution = async (include_in_hourly_execution: boolean) => {
  try {
    const { data } = await axiosInstance.patch('/hotel/update/', {
      include_in_hourly_execution
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const checkBookingUrl = async (data: CheckBookingUrlPayload) => {
  try {
    const response = await axiosInstance.post('/hotel/check/booking_ref_url/', data);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const findBookingUrl = async (data: FindBookingUrlPayload) => {
  try {
    const response = await axiosInstance.post('/hotel/check/find_booking_url/', data);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const autoSaveAveragePrice = async () => {
  try {
    const response = await axiosInstance.post('/hotel/room/auto-save-average-price/');
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error?.response?.data?.error?.message) {
        throw new Error(error.response.data.error.message);
      }
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

/**
 * @param settings All pricing settings, JSON stringified.
 */
export const savePricingSettings = (settings: string) => {
  return axiosInstance.post(
    'hotel/pricing-settings/',
    JSON.stringify({
      settings
    })
  );
};

export const updatePricingSettings = async <T>(settings: T) => {
  try {
    const { data } = await axiosInstance.patch<PricingSettings>(
      '/hotel/pricing-settings/',
      settings
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const applyIndividualAdjustment = (request: {
  end_date: string;
  start_date: string;
  json_settings: string;
  is_write_to_cache?: boolean;
}) => {
  if (isNil(request.json_settings) || isEqual(request.json_settings, 'null')) {
    throw new Error('json_settings is required');
  }

  return axiosInstance.post<ApplyIndividualAdjustmentResponse>(
    'hotel/adjustment/individual/apply/',
    {
      is_write_to_cache: true,
      ...request
    }
  );
};

export const saveLastPricingUpdate = (request: {
  last_run_pricing_type: string;
  token: string;
}) => {
  return axiosInstance.post('/hotel/last-pricing-update/', request);
};

export const getMembershipPricing = async () => {
  try {
    const data = await axiosInstance.get('/hotel/membership/pricing/');
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const overridePriceMemberShipPricing = async (data: OverridePricePayload) => {
  try {
    const response = await axiosInstance.post('/hotel/membership/pricing/', data);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};
export const removeOverridePriceMemberShipPricing = async () => {
  try {
    const response = await axiosInstance.delete('/hotel/membership/pricing/');
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const portalMembershipPricing = async (token: string) => {
  setAuthToken(token);
  try {
    const { data } = await axiosInstance.post('/hotel/payment/portal/');
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const connectChargebee = async (data: any) => {
  try {
    const response = await axiosInstance.put('/hotel/link-chargebee-subscription/', data);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const disconnectChargebee = async () => {
  try {
    const response = await axiosInstance.delete('/hotel/link-chargebee-subscription/');
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const reactivateChargebee = async () => {
  try {
    const response = await axiosInstance.post('hotel/reactivate-subscription/');
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const changeHotelState = async (data: HotelState) => {
  try {
    const response = await axiosInstance.post('/hotel/update/state/', data);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const changeToTest = async () => {
  try {
    const response = await axiosInstance.post('/hotel/set-to-test/');
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const addFreeTrialTime = async (data: FreeTrial) => {
  try {
    const response = await axiosInstance.post('/hotel/free-trial-end/', data);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const checkoutSubscription = async (data: CheckoutSubscription) => {
  try {
    const response = await axiosInstance.post('/hotel/payment/checkout/', data);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const linkCheckoutChargeebee = async (data: any) => {
  try {
    const response = await axiosInstance.post('/hotel/chargebee/link/', data);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const getHotelCompetitorList = async () => {
  return await axiosInstance.get<HotelCompetitorListResponse>('hotel/competitor/list/');
};

export const getHotelPmsList = async () => {
  try {
    const { data } = await axiosInstance.get<HotelPmsList[]>('/hotel/pms/list/');
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const deleteHotelRoom = async (room_id: number | undefined) => {
  return await axiosInstance.delete(`/hotel/room/delete/${room_id}/`);
};

export const updateHotelRoom = async ({
  room_id,
  data
}: {
  room_id: number | undefined;
  data: UpdateRoomRequest;
}) => {
  try {
    const response = await axiosInstance.patch(`/hotel/room/update/${room_id}/`, data);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const addHotelRoom = async ({ data }: { data: AddRoomRequest }) => {
  try {
    const response = await axiosInstance.post(`/hotel/room/register/`, data);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const updateHotelRoomDerivedGenerateMaxMinPrice = async (update_minmax_price: boolean) => {
  try {
    const { data } = await axiosInstance.post('/hotel/room/derived/generate/max-min-price/', {
      update_minmax_price
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const updateHotelCalculateNoOfRooms = async (is_calculate_no_of_rooms: boolean) => {
  return await axiosInstance.post('/hotel/calculate_no_of_rooms/', {
    is_calculate_no_of_rooms
  });
};

export const getHotelFreeToBookAutoUploadDisableClientAccess = async () => {
  return await axiosInstance.get('hotel/freetobook/auto-upload-disable-client-access/');
};

export const getHotelPmsDataMap = async () => {
  try {
    const { data } = await axiosInstance.get<HotelPmsDataMap>('hotel/pms/data/map/');
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const updateHotelPmsData = async ({
  id,
  provider
}: {
  id: number | undefined;
  provider: number | undefined;
}) => {
  if (!id || !provider) {
    return;
  }
  try {
    const { data } = await axiosInstance.patch(`hotel/pms/update/${id}/`, {
      provider
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const updateHotelPmsOccupancyData = async ({
  id,
  token,
  occupancy_percent_derivation
}: {
  id: number | undefined;
  token: string | null;
  occupancy_percent_derivation: boolean;
}) => {
  if (!id || !token) {
    return;
  }
  try {
    const { data } = await axiosInstance.patch(`hotel/pms/occupancy/update/${id}/`, {
      Authtoken: token,
      id,
      occupancy_percent_derivation
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const syncHotelPmsData = async () => {
  try {
    const { data } = await axiosInstance.post('hotel/pms-sync/update/');
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const updateHotelPmsRoomMap = async ({
  selected_pms_ref_room_id,
  selected_pms_ref_room_name,
  selected_pms_ref_rate_id,
  token
}: {
  selected_pms_ref_room_id: number | undefined;
  selected_pms_ref_room_name: string | undefined;
  selected_pms_ref_rate_id: number | undefined;
  token: string | null;
}) => {
  if (!token || !selected_pms_ref_room_id || !selected_pms_ref_room_name) return;
  try {
    return await axiosInstance.post('hotel/pms/room/map/', {
      selected_pms_ref_room_id,
      selected_pms_ref_room_name,
      selected_pms_ref_rate_id,
      token
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const getHotelPricePerRate = async (id: number | null) => {
  if (!id) return;
  try {
    const { data } = await axiosInstance.get<HotelPricePerRateResponse[]>(
      `hotel/price-per-rate/${id}/`
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const getHotelSettingsLogs = async () => {
  try {
    const { data } = await axiosInstance.get('hotel/settings-logs/');
    const logs = data.map((log: any) => {
      return {
        ...log,
        data: JSON.stringify(log.data).replace(/[@\\]/g, '')
      };
    });
    return logs;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getHotelOutboundLogs = async (
  page = 1,
  search?: string,
  request_type?: number,
  date?: string,
  ordering?: string,
  is_success?: string
) => {
  try {
    const { data } = await axiosInstance.get<HotelOutboundApiLogsPagination>(
      '/hotel/get-outboud-logs/',
      {
        params: {
          page,
          search,
          request_type,
          date,
          ordering,
          is_success
        }
      }
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const getHotelPricePerOccupancy = async (id: number | undefined) => {
  if (!id) return;
  try {
    const { data } = await axiosInstance.get<HotelPricePerOccupancyResponse[]>(
      `hotel/price-per-occupancy/${id}/`
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const saveHotelPricePerOccupancy = async ({
  id,
  data
}: {
  id: number | undefined;
  data: HotelPricePerOccupancyRequest[] | undefined;
}) => {
  try {
    const response = await axiosInstance.post<HotelPricePerOccupancyResponse[]>(
      `hotel/price-per-occupancy/${id}/`,
      data
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const getHotelPricePerOccupancyV2 = async (id: number | undefined) => {
  if (!id) return;
  try {
    const { data } = await axiosInstance.get<HotelPricePerOccupancyResponseV2>(
      `/hotel/rooms/${id}/occupancy-derivations`
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const saveHotelPricePerOccupancyV2 = async ({
  id,
  payload
}: {
  id: number | undefined;
  payload: HotelPricePerOccupancyPayloadV2;
}) => {
  try {
    const response = await axiosInstance.put<HotelPricePerOccupancyPayloadV2>(
      `/hotel/rooms/${id}/occupancy-derivations`,
      payload
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const saveHotelPricePerRate = async ({
  id,
  data
}: {
  id: number | null;
  data: HotelPricePerRateRequest[];
}) => {
  try {
    const response = await axiosInstance.post<HotelPricePerRateResponse[]>(
      `hotel/price-per-rate/${id}/`,
      data
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const getHotelOutboundLogsBlob = async (payload: DownloadOutboundApiLogs) => {
  try {
    const response = await axiosInstance.post('/hotel/download-outboud-logs/', payload);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getHotelInboundLogs = async (
  page = 1,
  search?: string,
  date?: string,
  ordering?: string,
  category?: string
) => {
  try {
    const { data } = await axiosInstance.get<HotelInboundApiLogsPagination>('/hotel/get_logs/', {
      params: {
        page,
        search,
        date,
        ordering,
        category
      }
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const downloadInboundLogs = async (id: string) => {
  try {
    const { data } = await axiosInstance.get(`/hotel/log/${id}/metadata/`);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getHotelReservationLogs = async (
  page = 1,
  request_type: number,
  search?: string,
  date?: string,
  ordering?: string
) => {
  try {
    const { data } = await axiosInstance.get<HotelInboundApiLogsPagination>('/hotel/get_logs/', {
      params: {
        page,
        request_type,
        search,
        date,
        ordering
      }
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const deleteHotel = async () => {
  try {
    const response = await axiosInstance.post('/hotel/delete/client_hotel/');
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const referFriend = async (data: ReferFriendPayload) => {
  try {
    const response = await axiosInstance.post('/hotel/hubspot_referral_deal/create/', data);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};
export const referFriendList = async (token: string) => {
  try {
    const response = await axiosInstance.post(
      '/hotel/hubspot_referral_deal/referral_lists/',
      token
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const calculateAveragePrice = async ({
  roomId,
  token
}: {
  roomId: number | undefined;
  token: string | null;
}) => {
  try {
    const { data } = await axiosInstance.post<CalculateAveragePriceResponse>(
      '/hotel/room/calculate-average-price/',
      {
        room_id: roomId,
        token
      }
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const reportIssue = async (data: ReportIssuePayload) => {
  try {
    const response = await axiosInstance.post('/hotel/hs_report_ticket/create/', data);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const createHSTicket = async (data: CreateHSTicketPayload) => {
  try {
    const response = await axiosInstance.post('/hotel/hs_ticket/create/', data);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getCompetitorSetup = async () => {
  try {
    const response = await axiosInstance.get<CompetitorSetupResponse>(
      'hotel/competitor/setup/?type=1'
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getTopTwentyCompetitorSetup = async (payload: GetTopTwentyCompetitor) => {
  try {
    const response = await axiosInstance.post('hotel/competitor/setup/', payload);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getCompetitorScore = async () => {
  try {
    const response = await axiosInstance.post<CompetitorScore>('/hotel/competitor/scores/');
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const updateCompetitor = async (payload: UpdateCompetitor) => {
  try {
    const response = await axiosInstance.post<UpdateCompetitor>(
      '/hotel/competitor/bulk-update/',
      payload
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const scrapeCompetitor = async (payload: ScrapeCompetitor) => {
  try {
    const response = await axiosInstance.post('/hotel/competitor/scrape/', payload);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const scoreCompetitor = async (payload: ScoreCompetitor) => {
  try {
    const response = await axiosInstance.post('/hotel/score_top_20_competitors/', payload);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const scrapeBookingUrl = async (payload: BookingScrape) => {
  try {
    const response = await axiosInstance.post('/hotel/booking/scraper/', payload);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const searchCompetitor = async (search?: string) => {
  try {
    const response = await axiosInstance.get<SearchHotelCompetitorListResponse>(
      '/hotel/list/find-competitor/',
      {
        params: {
          search
        }
      }
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getMarketCode = async () => {
  try {
    const response = await axiosInstance.post('/hotel/get-market-code-lat-long/');
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const searchMarketCode = async (payload?: SearchMarketCode) => {
  try {
    const response = await axiosInstance.post('/hotel/get-market-codes/', payload);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const saveMarketCode = async (payload: SaveMarketData) => {
  try {
    const response = await axiosInstance.post('/hotel/save-market-code/', {
      selected_market_code: payload
    });
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getFutureDaily = async () => {
  try {
    const response = await axiosInstance.get<FutureDailyResponse>('/hotel/get-future-daily/');
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const createHotel = async (payload: CreateHotelPayload) => {
  try {
    const response = await axiosInstance.post('/hotel/register/', payload);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const searchHotelList = async (search?: string, country?: number) => {
  try {
    const response = await axiosInstance.get<SearchHotelResponse>('/hotel/list/', {
      params: {
        search,
        country
      }
    });
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};
// PMS
export const registerPms = async (payload: RegisterPmsPayload) => {
  try {
    const response = await axiosInstance.post('/hotel/pms/register/', payload);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const enableIframe = async (payload: EnableIframePayload) => {
  try {
    const response = await axiosInstance.post<EnableIframeResponse>(
      '/hotel/enable-iframe/',
      payload
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};
export const getEnableIframeStatus = async () => {
  try {
    const response = await axiosInstance.get<EnableIframeResponse>('/hotel/enable-iframe/');
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const reprocessData = async (payload: string) => {
  try {
    const response = await axiosInstance.get(`/hotel/reprocess_all_pms/?reprocess_date=${payload}`);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const updateSyncPms = async (payload: SyncPmsUpdatePayload) => {
  try {
    const response = await axiosInstance.post('/hotel/pms-sync/update/', payload);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const staahPushEnable = async (payload: boolean) => {
  try {
    const response = await axiosInstance.post('/hotel/staah/toggle-push/', {
      enabled: payload
    });
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const updatePms = async (id: number | undefined, payload: RegisterPmsPayload) => {
  try {
    const response = await axiosInstance.patch(`/hotel/pms/update/${id}/`, payload);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const resyncEviivoPms = async () => {
  try {
    const response = await axiosInstance.post('/hotel/eviivo/reset/');
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const resyncFreetobookPms = async () => {
  try {
    const response = await axiosInstance.post('/hotel/freetobook/reset/');
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const initDataPmsSync = async () => {
  try {
    const response = await axiosInstance.get('/hotel/pms/init-data-sync/');
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const disconnectPms = async () => {
  try {
    const response = await axiosInstance.delete('/hotel/pms/disconnect/');
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

// SabeeApp
export const getSabeeProperty = async (payload: SabeeAppPayload) => {
  try {
    const response = await axiosInstance.post('/hotel/sabeapp/get_properties/', payload);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const getSabeePropertyList = async (payload: SabeeAppPayload) => {
  try {
    const response = await axiosInstance.post('/hotel/sabeapp/list_properties/', payload);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

// Channex
export const getChannexPropertyList = async () => {
  try {
    const response = await axiosInstance.get('/hotel/channex/get-properties/');
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const getExportHotelClientList = async () => {
  try {
    const response = await axiosInstance.get(`/hotel/client/list/export`);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const visitorIdentification = async ({
  email,
  firstName,
  lastName
}: {
  email: string;
  firstName: string;
  lastName: string;
}) => {
  try {
    const response = await axiosInstance.post('/hotel/hubspot/visitor-identification/', {
      email,
      firstName,
      lastName
    });
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const getProfitReport = async () => {
  try {
    const data = await axiosInstance.get<ProfitReportResponse>(
      '/hotel/profitwithuncertainity_pdf/'
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

// Scrape
export const getHotelScrapeList = async (page?: number) => {
  try {
    const data = await axiosInstance.get<ScrapeListResponse>('/hotel/scrape/list/', {
      params: {
        page
      }
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const deleteHotelScrape = async (id: number) => {
  try {
    const { data } = await axiosInstance.delete(`/hotel/scrape/list/delete/${id}/`);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

// Booking Scrape
export const getBookingScrapeList = async (page?: number) => {
  try {
    const data = await axiosInstance.get<BookingScraperListResponse>(
      '/hotel/booking/scraper/list/',
      {
        params: {
          page
        }
      }
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const createNote = async (note: string) => {
  try {
    const response = await axiosInstance.post('/hotel/note/', {
      note
    });
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const getNotes = async () => {
  try {
    const { data } = await axiosInstance.get<NotesResponse>('/hotel/note/');
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const deleteNote = async (id: number) => {
  try {
    const response = await axiosInstance.delete(`/hotel/note/${id}/`);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const editNote = async (id: number, note: string) => {
  try {
    const response = await axiosInstance.patch(`/hotel/note/${id}/`, {
      note
    });
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const enablePricing = async (is_pricing_turn_on: boolean) => {
  try {
    const response = await axiosInstance.patch('/hotel/update/', {
      is_pricing_turn_on
    });
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getSubscriptionInformation = async () => {
  try {
    const data = await axiosInstance.get<SubscriptionInformationResponse>(
      '/hotel/get-payment-info-chargebee/'
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const hlsPmsInitRooms = async () => {
  try {
    const response = await axiosInstance.post('/hotel/hls/init-rooms/');
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const retrieveBlockedRooms = async ({
  start_date,
  end_date
}: {
  start_date: string;
  end_date: string;
}) => {
  try {
    const response = await axiosInstance.post('/hotel/blocked-rooms/retrieve/', {
      start_date,
      end_date
    });
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const enablePushPms = async (payload: boolean) => {
  try {
    const response = await axiosInstance.post('/hotel/pms/toggle-push/', {
      enabled: payload
    });
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const getSuccessReportData = async (force?: boolean | undefined) => {
  try {
    const data = await axiosInstance.get('/hotel/get_success_report_data/', {
      params: {
        force
      }
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

// NEW SSO API
export const getProperties = async () => {
  try {
    const response = await axiosInstance.get<SSOPropertiesResponse>('/hotel/sso/properties');
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const ssoLoginProperties = async (propertiesCode: string) => {
  try {
    const response = await axiosInstance.post<SSOLoginPropertiesResponse>(
      `/hotel/sso/properties/${propertiesCode}/login`
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const ssoRegisterProperties = async (propertiesCode: string) => {
  try {
    const response = await axiosInstance.post<SSORegisterPropertiesResponse>(
      `/hotel/sso/properties/${propertiesCode}/register`
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const ssoSwitchProperties = async (propertiesCode: string) => {
  try {
    const response = await axiosInstance.post<SSOLoginPropertiesResponse>(
      `/hotel/sso/properties/${propertiesCode}/switch`
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const getReservationStatus = async () => {
  try {
    const response = await axiosInstance.get<ReservationStatusResponse>(
      '/hotel/internal-reservation-status/'
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const getRoomTypesRestrictions = async (page: number) => {
  try {
    const response = await axiosInstance.get<RoomTypesRestrictionsResponse>(
      `/hotel/restrictions/room-types/?page=${page}`
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const getRateRestrictions = async (code: string | null | undefined, page: number) => {
  try {
    const response = await axiosInstance.get<RateRestrictionResponse>(
      '/hotel/restrictions/rate-plans/',
      {
        params: {
          room_code: code,
          page
        }
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const addRatePlansRestriction = async (payload: RatePlansPayload) => {
  try {
    const response = await axiosInstance.patch(
      '/hotel/restrictions/rate-plans/update-flags/',
      payload
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const getRatePlanDerivation = async (id: number | null) => {
  try {
    const response = await axiosInstance.get<RateDerivationResponse>(
      `/hotel/rooms/${id}/rate-plan-derivations`
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const createRatePlanDerivation = async ({
  id,
  payload
}: {
  id: number | null;
  payload: RateDerivationsPayload;
}) => {
  try {
    const response = await axiosInstance.post(`/hotel/rooms/${id}/rate-plan-derivations`, {
      ...payload
    });
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const getInternalRoomMapping = async () => {
  try {
    const response = await axiosInstance.get(`/hotel/internal-room-mapping/`);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const updateInternalRoomMapping = async (payload: Record<string, string>) => {
  try {
    const response = await axiosInstance.post(`/hotel/internal-room-mapping/`, {
      ...payload
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const populateRoomMappingFromRoomSetup = async () => {
  const response = await axiosInstance.post(`/hotel/populate-room-mapping-from-room-setup/`);
  return response.data;
};

export const createHubspotTicket = async (payload: HubspotTicketPayload) => {
  try {
    const response = await axiosInstance.post('/hotel/hubspot/create-ticket/', {
      ...payload
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};
