import { axiosInstance } from '@common/api/axiosInstance';
import {
  CreateRatePlans,
  ParamsRatePlan,
  RatePlansResponse,
  UpdateRatePlans
} from '@common/api/integration/ratePlansSchema';
import {
  CreateRoomTypes,
  ParamsRoomType,
  RoomTypesResponse,
  UpdateRoomTypes
} from '@common/api/integration/roomTypesSchema';
import axios, { AxiosError } from 'axios';

const INTERNAL_BASE_PATH = '/integrations/internal/api/v1';

// RatePlans
export const createRatePlans = async (payload: CreateRatePlans) => {
  try {
    const { data } = await axiosInstance.post<RatePlansResponse>(
      `${INTERNAL_BASE_PATH}/rate-plans`,
      payload
    );
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err as AxiosError;
    }
  }
};

export const getRatePlans = async ({ page, ordering }: ParamsRatePlan) => {
  try {
    const { data } = await axiosInstance.get<RatePlansResponse>(
      `${INTERNAL_BASE_PATH}/rate-plans`,
      {
        params: { page, ordering, page_size: 1000 }
      }
    );
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err as AxiosError;
    }
  }
};

export const updateRatePlans = async (payload: UpdateRatePlans) => {
  try {
    const { id, ...dataWithoutId } = payload;
    const { data } = await axiosInstance.patch<RatePlansResponse>(
      `${INTERNAL_BASE_PATH}/rate-plans/${id}`,
      dataWithoutId
    );
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err as AxiosError;
    }
  }
};

export const deleteRatePlans = async (id: number) => {
  try {
    const { data } = await axiosInstance.delete<RatePlansResponse>(
      `${INTERNAL_BASE_PATH}/rate-plans/${id}`
    );
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err as AxiosError;
    }
  }
};

// RoomTypes
export const createRoomTypes = async (payload: CreateRoomTypes) => {
  try {
    const { data } = await axiosInstance.post<RoomTypesResponse>(
      `${INTERNAL_BASE_PATH}/room-types`,
      payload
    );
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err as AxiosError;
    }
  }
};

export const getRoomTypes = async ({ page, ordering }: ParamsRoomType) => {
  try {
    const { data } = await axiosInstance.get<RoomTypesResponse>(
      `${INTERNAL_BASE_PATH}/room-types`,
      {
        params: { page, ordering, page_size: 1000 }
      }
    );
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err as AxiosError;
    }
  }
};

export const updateRoomTypes = async (payload: UpdateRoomTypes) => {
  try {
    const { id, ...dataWithoutId } = payload;
    const { data } = await axiosInstance.patch<RoomTypesResponse>(
      `${INTERNAL_BASE_PATH}/room-types/${payload.id}`,
      dataWithoutId
    );
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err as AxiosError;
    }
  }
};

export const deleteRoomTypes = async (id: number) => {
  try {
    const { data } = await axiosInstance.delete<RoomTypesResponse>(
      `${INTERNAL_BASE_PATH}/room-types/${id}`
    );
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err as AxiosError;
    }
  }
};
