import { create } from 'zustand';

interface StepStore {
  activeStepQuotation: number;
  setActiveStepQuotation: (step: number) => void;
}

const useStepStore = create<StepStore>((set) => ({
  activeStepQuotation: 0,
  setActiveStepQuotation: (step) => set({ activeStepQuotation: step })
}));

export default useStepStore;
