import {
  AppliedFiltersSchema,
  QuotationsListSchema
} from '@pages/Client/PricingStrategy/GroupDisplacement/schema/quotations-list';
import { omit } from 'lodash-es';
import { number } from 'zod';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type QuotationsSortItem = { id: string; desc: boolean };

export interface QuotationsListParams {
  defaultParams: QuotationsListSchema;
  params: QuotationsListSchema;
  sorting: QuotationsSortItem[];
  filters: AppliedFiltersSchema;
  defaultFilters: AppliedFiltersSchema;
  setFilters: (filter: Partial<AppliedFiltersSchema>) => void;
  setParams: (param: Partial<QuotationsListSchema>) => void;
  setSorting: (sorting: QuotationsSortItem[]) => void;
  resetParams: () => void;
}

const defaultValues = {
  page: 1,
  ordering: '-id',
  status: undefined,
  search: undefined,
  name: undefined,
  length_of_stay: undefined,
  average_price: undefined,
  total_quote: undefined,
  last_updated_at: undefined,
  number_of_rooms: undefined
};

export const defaultFilters = omit(defaultValues, ['page', 'ordering']);

export const useQuotationsListStore = create<QuotationsListParams>()(
  persist(
    (set) => ({
      defaultParams: defaultValues,
      defaultFilters,
      filters: defaultFilters,
      setFilters: (filter: Partial<AppliedFiltersSchema>) =>
        set((state) => {
          const newFilters = { ...state.filters, ...filter };
          const newParams = { ...state.params, page: 1 };
          return { filters: newFilters, params: newParams };
        }),
      params: defaultValues,
      setParams: (param: Partial<QuotationsListSchema>) =>
        set((state) => ({ params: { ...state.params, ...param } })),
      sorting: [{ id: 'id', desc: false }],
      setSorting: (sorting: QuotationsSortItem[]) => set({ sorting }),
      resetParams: () =>
        set({ params: defaultValues, filters: omit(defaultValues, ['page', 'ordering']) })
    }),
    {
      name: 'quotationsListStore',
      onRehydrateStorage: () => {
        return (state) => {
          return state;
        };
      }
    }
  )
);
