import {
  createRoomTypes,
  deleteRoomTypes,
  getRoomTypes,
  updateRoomTypes
} from '@common/api/integration/internal';
import {
  CreateRoomTypes,
  ParamsRoomType,
  RoomTypeErrorResponse,
  RoomTypesResponse,
  UpdateRoomTypes
} from '@common/api/integration/roomTypesSchema';
import { useNotificationsStore } from '@common/store/notifications';
import { HotelQueryKeys, PricingQueryKeys, RoomsQueryKeys } from '@common/types/query-keys';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const usePmsRoomType = ({ ordering, page }: ParamsRoomType) => {
  const query = useQuery({
    queryKey: [RoomsQueryKeys.PMS_ROOM_TYPE_LIST, ordering, page],
    queryFn: () => getRoomTypes({ ordering, page })
  });

  const ROOMS_OPTIONS = query.data?.data?.map((item) => ({
    value: `${item.id}`,
    label: item.name
  }));

  return {
    pmsRoomTypeList: query.data as RoomTypesResponse,
    isLoading: query.isLoading,
    error: query.error,
    ROOMS_OPTIONS
  };
};

export const useCreatePmsRoomType = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();
  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (payload: CreateRoomTypes) => {
      return createRoomTypes(payload);
    },
    onSuccess: () => {
      addNotification('success', 'Create Room Type Successfully');
      queryClient.invalidateQueries({ queryKey: [RoomsQueryKeys.PMS_ROOM_TYPE_LIST] });
      queryClient.invalidateQueries({
        queryKey: [PricingQueryKeys.GET_HOTEL_PRICE_PER_OCCUPANCYV2]
      });
    },
    onError: (error: RoomTypeErrorResponse) => {
      const errorMessage =
        error?.response?.data?.errors?.[0]?.non_field_errors?.[0] || 'Failed to Create Room Type';
      addNotification('fail', errorMessage);
    }
  });

  return {
    createRoomType: mutate,
    isLoading,
    isSuccess
  };
};

export const useUpdatePmsRoomType = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();
  const { hotelDetails } = useHotelDetails();
  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (payload: UpdateRoomTypes) => {
      return updateRoomTypes(payload);
    },
    onSuccess: () => {
      addNotification(
        'success',
        `${hotelDetails?.room_apartment_space_name} Type Updated Successfully`
      );
      queryClient.invalidateQueries({ queryKey: [RoomsQueryKeys.PMS_ROOM_TYPE_LIST] });
      queryClient.invalidateQueries({ queryKey: [HotelQueryKeys.GET_HOTEL_PMS_DATA_MAP] });
      queryClient.invalidateQueries({
        queryKey: [PricingQueryKeys.GET_HOTEL_PRICE_PER_OCCUPANCYV2]
      });
    },
    onError: () => {
      addNotification('fail', `Failed to update ${hotelDetails?.room_apartment_space_name} Type`);
    }
  });

  return {
    updatePmsRoomType: mutate,
    isLoading,
    isSuccess
  };
};

export const useDeletePmsRoomType = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();
  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (id: number) => {
      return deleteRoomTypes(id);
    },
    onSuccess: () => {
      addNotification('success', 'Delete Room Type Successfully');
      queryClient.invalidateQueries({ queryKey: [RoomsQueryKeys.PMS_ROOM_TYPE_LIST] });
      queryClient.invalidateQueries({ queryKey: [RoomsQueryKeys.PMS_ROOM_RATE_LIST] });
    },
    onError: () => {
      addNotification('fail', 'Failed to Delete Room Type');
    }
  });

  return {
    deleteRoomType: mutate,
    isLoading,
    isSuccess
  };
};
