import { axiosInstance } from '@common/api/axiosInstance';
import { useNotificationsStore } from '@common/store/notifications';
import { RoomsQueryKeys } from '@common/types/query-keys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useState } from 'react';

interface Args {
  url: string;
  file: File;
  fieldName: string;
}

interface SuccessResponse {
  data: {
    message: string;
  };
}

export const useImportRoom = () => {
  const [progress, setProgress] = useState(0);
  const queryClient = useQueryClient();
  const { addNotification } = useNotificationsStore();

  const uploadFile = async (args: Args): Promise<AxiosResponse<SuccessResponse>> => {
    const formData = new FormData();
    formData.append(args.fieldName, args.file);

    return axiosInstance.post<SuccessResponse>(args.url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress(progressEvent) {
        if (progressEvent.total) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        } else {
          setProgress(0);
        }
      }
    });
  };

  const mutation = useMutation<
    AxiosResponse<SuccessResponse>,
    AxiosError<{ error?: { message: string } }>,
    Args
  >({
    mutationFn: uploadFile,
    onMutate: () => {
      setProgress(0);
    },
    onSuccess: (response) => {
      addNotification('success', response?.data?.data?.message);
      queryClient.invalidateQueries({ queryKey: [RoomsQueryKeys.PMS_ROOM_TYPE_LIST] });
      queryClient.invalidateQueries({ queryKey: [RoomsQueryKeys.PMS_ROOM_RATE_LIST] });
    },
    onError: (error) => {
      if (error?.response?.status === 500) {
        addNotification('fail', 'Failed to upload file');
      } else {
        addNotification('fail', error.response?.data?.error?.message || 'Unknown error');
      }
    }
  });

  return {
    ...mutation,
    uploadFile: (args: Args) => mutation.mutate(args),
    progress
  };
};
