import { Icon } from '@common/components/foundations/icons';
import { useEventTracking } from '@common/hooks/useEventTracking';
import { useQuotationsListStore } from '@common/store/group-displacement-list-store';
import { Popover, Indicator, Button, Flex, Stack, Text, ActionIcon } from '@mantine/core';
import { FilterByName } from '@pages/Client/PricingStrategy/GroupDisplacement/components/QuotationsTable/Filters/FilterByName';
import { FilterByNrOfNights } from '@pages/Client/PricingStrategy/GroupDisplacement/components/QuotationsTable/Filters/FilterByNrOfNights';
import { FilterByNrOfRooms } from '@pages/Client/PricingStrategy/GroupDisplacement/components/QuotationsTable/Filters/FilterByNrOfRooms';
import { FilterByStartDate } from '@pages/Client/PricingStrategy/GroupDisplacement/components/QuotationsTable/Filters/FilterByStartDate';
import { FilterByStatus } from '@pages/Client/PricingStrategy/GroupDisplacement/components/QuotationsTable/Filters/FilterByStatus';
import { isEqual, replace, startCase } from 'lodash-es';
import { useState } from 'react';

export function QuotationsFiltersPopover() {
  const { params, defaultParams, resetParams } = useQuotationsListStore();
  const hasChanges = !isEqual(JSON.stringify(params), JSON.stringify(defaultParams));
  const [opened, setOpened] = useState(false);
  const [isDateFocused, setIsDateFocused] = useState(false);
  const { trackEvent } = useEventTracking();

  return (
    <Popover
      width={300}
      position="bottom"
      shadow="md"
      trapFocus
      opened={opened || isDateFocused} // Keep popover open when DateInput is focused
      onChange={setOpened}
      onClose={() => trackEvent('FiltersApplied')}
    >
      <Popover.Target>
        <Indicator
          size={7}
          offset={3}
          disabled={!hasChanges}
          mt={3}
          onClick={(e) => {
            e.preventDefault();
            setOpened(!opened);
          }}
        >
          <ActionIcon p={3}>
            <Icon.FilterList />
          </ActionIcon>
        </Indicator>
      </Popover.Target>
      <Popover.Dropdown pb="lg">
        <Flex justify="space-between" align="center">
          <Text size="xs">Sorted by: {startCase(replace(params.ordering, '-', ''))}</Text>
          <Button variant="subtle" size="xs" onClick={resetParams} disabled={!hasChanges}>
            Reset All
          </Button>
        </Flex>
        <Stack>
          <FilterByName />
          <FilterByStartDate setIsDateFocused={setIsDateFocused} />
          <FilterByNrOfRooms />
          <FilterByNrOfNights />
          <FilterByStatus />
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
}
