import { addDays, differenceInDays, format, isValid, parseISO, subDays } from 'date-fns';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Typography } from '@common/components/foundations/Typography';
import { Colors } from '@common/components/foundations/colorVariants';
import { Icon } from '@common/components/foundations/icons';
import { Card } from '@common/components/molecules/Card';
import { DATE_FORMAT_DATE_FNS } from '@common/constants/date';
import { Feature, useFeaturesStore } from '@common/store/features';
import { formattedCurrency } from '@pages/Client/Dashboard/utils/formattedCurrency';
import { formattedPercentage } from '@pages/Client/Dashboard/utils/formattedPercentage';
import { DataCell } from '@pages/Client/HealthReport/components/DataCell';
import { useValueReport } from '@pages/Client/HealthReport/hooks/useValueReport';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { isDevelopment } from '@common/utils/env';
import { useViewStore } from '@common/store/view';
import { useEffect } from 'react';
import { useSidebarStore } from '@common/store/sidebar';
import { useDocumentTitle } from '@mantine/hooks';
import { formatNumberWithSuffix } from '@common/utils/formatNumberWithSuffix';
import { useUserLocale } from '@common/hooks/useUserLocale';
import { TooltipIcon } from '@common/components/molecules/TooltipIcon';
import { Flex, Skeleton } from '@mantine/core';

export const ValueReport = () => {
  const { t } = useTranslation();
  const { formatLocaleNumber } = useUserLocale();
  useDocumentTitle(t('Customer Value Report'));
  const {
    valueReport,
    data,
    isLoading: valueReportLoading,
    forceRefetch,
    forceRefetchLoading
  } = useValueReport({ disableSuccessNotification: true });
  const { features } = useFeaturesStore();
  const { isAutoUploadOn } = useHotelDetails();
  const { isAdmin } = useViewStore();
  const { setSidebarOpen } = useSidebarStore();

  const disableAccess =
    (!isDevelopment() || !isAdmin || !features?.includes(Feature.LimitedDashboard)) &&
    (!features?.includes(Feature.LimitedDashboard) || !isAutoUploadOn || !isAdmin);

  useEffect(() => {
    // Regenerate the value report on initial load
    !disableAccess && forceRefetch();
    setSidebarOpen(false);
  }, []);

  if (disableAccess) return null;

  const isLoading = valueReportLoading || forceRefetchLoading;

  type ReportType = 'revenue' | 'adr' | 'nights_sold' | 'median_lead_time';

  const getColor = (value: number, type?: ReportType) => {
    if (type === 'median_lead_time') {
      // Color change is not relevant for median lead time
      return 'darkGrey';
    }
    if (value > 0) {
      return 'darkGreen' as Colors;
    }
    if (value < 0) {
      return 'uiRed' as Colors;
    }
    return 'darkGrey';
  };

  const getDiffData = (type: ReportType, comparison: 'stly' | 'before') => {
    let diff: number;
    let diffToDisplay: string;
    const prevValue = data[`${comparison}_${type}`];
    const newValue = data[`after_${type}`];

    if (type === 'median_lead_time') {
      // Calculate absolute change
      diff = newValue - prevValue;
      diffToDisplay = diff > 0 ? `+${diff}` : `${diff}`;
    } else {
      // Calculate percentage change
      if (data[`${comparison}_${type}`] === 0) {
        diff = newValue === 0 ? 0 : 100;
      } else {
        diff = ((newValue - prevValue) / prevValue) * 100;
      }
      diffToDisplay = formattedPercentage(diff, true);
    }

    let label = 'Same';
    let icon = <Icon.ArrowLongRight />;
    const iconColor = getColor(diff, type);

    if (diff > 0) {
      if (type === 'revenue') label = 'Increase';
      if (type === 'adr') label = 'Higher';
      if (type === 'nights_sold') label = 'More';
      if (type === 'median_lead_time') label = 'Higher';
      icon = <Icon.TrendingUp />;
    } else if (diff < 0) {
      if (type === 'revenue') label = 'Decrease';
      if (type === 'adr') label = 'Lower';
      if (type === 'nights_sold') label = 'Less';
      if (type === 'median_lead_time') label = 'Lower';
      icon = <Icon.TrendingDown />;
    }
    return { diff: diffToDisplay, label, icon, iconColor };
  };

  const getDateRanges = () => {
    const activation_date_str = valueReport?.activation_date_str;
    const created_on = valueReport?.created_on;

    if (!activation_date_str || !created_on) return null;

    const activationDate = parseISO(activation_date_str);
    const creationDate = parseISO(created_on);

    if (!isValid(activationDate) || !isValid(creationDate)) return null;

    const keyFiguresStart = addDays(activationDate, 1);
    const keyFiguresRange = `${format(keyFiguresStart, DATE_FORMAT_DATE_FNS)} – ${format(
      creationDate,
      DATE_FORMAT_DATE_FNS
    )}`;
    const keyFiguresDaysCount = differenceInDays(creationDate, keyFiguresStart) + 1;

    const comparisonToLastYearStart = subDays(addDays(activationDate, 1), 365);
    const comparisonToLastYearRange = `${format(
      comparisonToLastYearStart,
      DATE_FORMAT_DATE_FNS
    )} – ${format(subDays(creationDate, 365), DATE_FORMAT_DATE_FNS)}`;

    const comparisonDaysBetween = differenceInDays(creationDate, activationDate);
    const comparisonPreActivationStart = addDays(activationDate, -comparisonDaysBetween + 1);
    const comparisonPreActivationRange = `${format(
      comparisonPreActivationStart,
      DATE_FORMAT_DATE_FNS
    )} – ${format(activationDate, DATE_FORMAT_DATE_FNS)}`;

    return {
      keyFiguresRange,
      keyFiguresDaysCount,
      comparisonToLastYearRange,
      comparisonPreActivationRange,
      comparisonPreActivationDayCount: comparisonDaysBetween
    };
  };

  const dateRanges = getDateRanges();

  return (
    <>
      {!isLoading && isEmpty(valueReport) ? (
        <div className="flex w-full items-center justify-center">
          <Typography variant="h6" color="darkGrey" className="text-center">
            {t('No data found')}
          </Typography>
        </div>
      ) : (
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-9 shadow-none">
            <div className="mt-3 space-y-4">
              <div className="flex flex-row items-center justify-between">
                <div className="flex flex-col">
                  <Typography variant="h5" className="font-medium" color="darkGrey">
                    {t('Key figures for')} {dateRanges?.keyFiguresDaysCount}{' '}
                    {t('days on Auto-Upload')}
                  </Typography>
                  <Skeleton visible={isLoading}>
                    <Typography variant="paragraph-2" color="copyTextGrey">
                      {t('Booking Date Range:')} {dateRanges?.keyFiguresRange}
                    </Typography>
                  </Skeleton>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-3">
                  <DataCell
                    isHighlight
                    title={formattedCurrency(data.after_revenue)}
                    subtitle={t('Pickup Revenue on Auto-Upload') as string}
                    cardClassName="shadow-none"
                    icon={<Icon.DollarSign />}
                    isLoading={isLoading}
                  />
                </div>
                <div className="col-span-3">
                  <DataCell
                    isHighlight
                    title={formattedCurrency(data.after_adr)}
                    subtitle={t('ADR on Auto-Upload') as string}
                    cardClassName="shadow-none"
                    icon={<Icon.DiscountTag />}
                    isLoading={isLoading}
                  />
                </div>
                <div className="col-span-3">
                  <DataCell
                    isHighlight
                    title={formatLocaleNumber(data.after_nights_sold)}
                    subtitle={t('Nights Sold on Auto-Upload') as string}
                    cardClassName="shadow-none"
                    icon={<Icon.Hotel />}
                    isLoading={isLoading}
                  />
                </div>
                <div className="col-span-3">
                  <DataCell
                    isHighlight
                    title={formatLocaleNumber(data.after_median_lead_time)}
                    subtitle={t('Median Lead Time on Auto-Upload') as string}
                    cardClassName="shadow-none"
                    icon={<Icon.Stopwatch />}
                    isLoading={isLoading}
                  />
                </div>
              </div>
            </div>
            <div className="mt-8 space-y-4">
              <div className="flex flex-col">
                <Flex gap="8px">
                  <Typography variant="h5" className="font-medium" color="darkGrey">
                    {t('Comparison to Same Period Last Year')}
                  </Typography>
                  <TooltipIcon
                    content={t(
                      'Same Period Last Year = Same 297 days 1 year earlier (same range of weekdays)'
                    )}
                  />
                </Flex>
                <Skeleton visible={isLoading}>
                  <Typography variant="paragraph-2" color="copyTextGrey">
                    {t('Booking Date Range:')} {dateRanges?.comparisonToLastYearRange}
                  </Typography>
                </Skeleton>
              </div>
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-3">
                  <DataCell
                    title={getDiffData('revenue', 'stly').diff}
                    subtitle={t(`${getDiffData('revenue', 'stly').label} in Revenue`) as string}
                    cardClassName="shadow-none"
                    icon={getDiffData('revenue', 'stly').icon}
                    iconColor={getDiffData('revenue', 'stly').iconColor}
                    descriptionTitle={formattedCurrency(data.stly_revenue)}
                    descriptionSubtitle={t('STLY') as string}
                    isLoading={isLoading}
                    additionalInfoTitle={formattedCurrency(data.after_revenue)}
                    additionalInfoSubtitle={t('On Auto-Upload') as string}
                  />
                </div>
                <div className="col-span-3">
                  <DataCell
                    title={getDiffData('adr', 'stly').diff}
                    subtitle={t(`${getDiffData('adr', 'stly').label} ADR`) as string}
                    cardClassName="shadow-none"
                    icon={getDiffData('adr', 'stly').icon}
                    iconColor={getDiffData('adr', 'stly').iconColor}
                    descriptionTitle={formattedCurrency(data.stly_adr)}
                    descriptionSubtitle={t('STLY') as string}
                    isLoading={isLoading}
                    additionalInfoTitle={formattedCurrency(data.after_adr)}
                    additionalInfoSubtitle={t('On Auto-Upload') as string}
                  />
                </div>
                <div className="col-span-3">
                  <DataCell
                    title={getDiffData('nights_sold', 'stly').diff}
                    subtitle={
                      t(`${getDiffData('nights_sold', 'stly').label} Nights Sold`) as string
                    }
                    cardClassName="shadow-none"
                    icon={getDiffData('nights_sold', 'stly').icon}
                    iconColor={getDiffData('nights_sold', 'stly').iconColor}
                    descriptionTitle={formatLocaleNumber(data.stly_nights_sold)}
                    descriptionSubtitle={t('STLY') as string}
                    isLoading={isLoading}
                    additionalInfoTitle={formatLocaleNumber(data.after_nights_sold)}
                    additionalInfoSubtitle={t('On Auto-Upload') as string}
                  />
                </div>
                <div className="col-span-3">
                  <DataCell
                    title={getDiffData('median_lead_time', 'stly').diff}
                    subtitle={
                      t(
                        `${getDiffData('median_lead_time', 'stly').label} Median Lead Time`
                      ) as string
                    }
                    cardClassName="shadow-none"
                    icon={getDiffData('median_lead_time', 'stly').icon}
                    iconColor={getDiffData('median_lead_time', 'stly').iconColor}
                    descriptionTitle={formatLocaleNumber(data.stly_median_lead_time)}
                    descriptionSubtitle={t('STLY') as string}
                    isLoading={isLoading}
                    additionalInfoTitle={formatLocaleNumber(data.after_median_lead_time)}
                    additionalInfoSubtitle={t('On Auto-Upload') as string}
                  />
                </div>
              </div>
            </div>
            <div className="mt-8 space-y-4">
              <div className="flex flex-col">
                <Typography variant="h5" className="font-medium" color="darkGrey">
                  {t('Comparison')} {dateRanges?.comparisonPreActivationDayCount}{' '}
                  {t('days immediately before activation Auto-Upload')}
                </Typography>
                <Skeleton visible={isLoading}>
                  <Typography variant="paragraph-2" color="copyTextGrey">
                    {t('Booking Date Range:')} {dateRanges?.comparisonPreActivationRange}
                  </Typography>
                </Skeleton>
              </div>
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-3">
                  <DataCell
                    title={getDiffData('revenue', 'before').diff}
                    subtitle={t(`${getDiffData('revenue', 'before').label} in Revenue`) as string}
                    cardClassName="shadow-none"
                    icon={getDiffData('revenue', 'before').icon}
                    iconColor={getDiffData('revenue', 'before').iconColor}
                    descriptionTitle={formattedCurrency(data.before_revenue)}
                    descriptionSubtitle={t('Before Auto-Upload') as string}
                    isLoading={isLoading}
                    additionalInfoTitle={formattedCurrency(data.after_revenue)}
                    additionalInfoSubtitle={t('On Auto-Upload') as string}
                  />
                </div>
                <div className="col-span-3">
                  <DataCell
                    title={getDiffData('adr', 'before').diff}
                    subtitle={t(`${getDiffData('adr', 'before').label} ADR`) as string}
                    cardClassName="shadow-none"
                    icon={getDiffData('adr', 'before').icon}
                    iconColor={getDiffData('adr', 'before').iconColor}
                    descriptionTitle={formattedCurrency(data.before_adr)}
                    descriptionSubtitle={t('Before Auto-Upload') as string}
                    isLoading={isLoading}
                    additionalInfoTitle={formattedCurrency(data.after_adr)}
                    additionalInfoSubtitle={t('On Auto-Upload') as string}
                  />
                </div>
                <div className="col-span-3">
                  <DataCell
                    title={getDiffData('nights_sold', 'before').diff}
                    subtitle={
                      t(`${getDiffData('nights_sold', 'before').label} Nights Sold`) as string
                    }
                    cardClassName="shadow-none"
                    icon={getDiffData('nights_sold', 'before').icon}
                    iconColor={getDiffData('nights_sold', 'before').iconColor}
                    descriptionTitle={formatLocaleNumber(data.before_nights_sold)}
                    descriptionSubtitle={t('Before Auto-Upload') as string}
                    isLoading={isLoading}
                    additionalInfoTitle={formatLocaleNumber(data.after_nights_sold)}
                    additionalInfoSubtitle={t('On Auto-Upload') as string}
                  />
                </div>
                <div className="col-span-3">
                  <DataCell
                    title={getDiffData('median_lead_time', 'before').diff}
                    subtitle={
                      t(
                        `${getDiffData('median_lead_time', 'before').label} Median Lead Time`
                      ) as string
                    }
                    cardClassName="shadow-none"
                    icon={getDiffData('median_lead_time', 'before').icon}
                    iconColor={getDiffData('median_lead_time', 'before').iconColor}
                    descriptionTitle={formatLocaleNumber(data.before_median_lead_time)}
                    descriptionSubtitle={t('Before Auto-Upload') as string}
                    isLoading={isLoading}
                    additionalInfoTitle={formatLocaleNumber(data.after_median_lead_time)}
                    additionalInfoSubtitle={t('On Auto-Upload') as string}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-3">
            <Card backgroundColor="white">
              <div>
                <div className="mb-5">
                  <Typography variant="h5" className="font-medium" color="darkGrey">
                    {t('RoomPriceGenie Insights')}
                  </Typography>
                </div>
                <div className="mb-3">
                  <DataCell
                    variant="centered"
                    title={
                      (data.monthly_extra_revenue < 0 ? '-' : '') +
                      formattedCurrency(data.monthly_extra_revenue)
                    }
                    bgColor={getColor(data.monthly_extra_revenue)}
                    subtitle={
                      <Flex direction="column" align="center">
                        <span>{t('Monthly Extra Revenue')}</span>
                        <Flex gap="8px">
                          <span>
                            {t('vs.')} {formattedCurrency(data.monthly_subscription_value)}{' '}
                            {t('cost')}
                          </span>
                          <TooltipIcon
                            content={
                              <Flex direction="column">
                                <span>{t('Monthly Extra Revenue =')} </span>
                                <span>
                                  {t('0.5 * Monthly Extra Revenue Same Period Last Year')}
                                </span>
                                <span>
                                  {t(
                                    '+ 0.5 * Monthly Extra Revenue Immediately before activation of Auto-Upload'
                                  )}
                                </span>
                              </Flex>
                            }
                          />
                        </Flex>
                      </Flex>
                    }
                    isLoading={isLoading}
                  />
                </div>
                <div className="mb-3">
                  <DataCell
                    variant="centered"
                    title={formattedCurrency(data.average_increase)}
                    subtitle={
                      <div className="flex flex-col">
                        <span>{t('Average Increase in Revenue')}</span>
                        <span>{t('per Day')}</span>
                      </div>
                    }
                    isLoading={isLoading}
                  />
                </div>
                <div className="mb-3">
                  <DataCell
                    variant="centered"
                    title={`${data.auto_upload_active_since_num_days} ${t('hours')}`}
                    subtitle={
                      <div className="flex flex-col">
                        <span>{t('Estimated time saved')}</span>
                        <span>{t('since activating Auto-Upload')}</span>
                      </div>
                    }
                    isLoading={isLoading}
                  />
                </div>
                <div>
                  <DataCell
                    variant="centered"
                    title={`>${formatNumberWithSuffix(data.optimised_prices_count)}`}
                    subtitle={
                      <div className="flex flex-col">
                        <span>{t('Prices optimised')}</span>
                        <span>{t('since activating Auto-Upload')}</span>
                      </div>
                    }
                    isLoading={isLoading}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
      )}
    </>
  );
};
