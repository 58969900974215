import React, { useMemo, useRef, useState } from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Area,
  ReferenceLine,
  ReferenceArea
} from 'recharts';
import dayjs from 'dayjs';
import { groupBy } from 'lodash-es';
import { Card } from '@common/components/molecules/Card';
import { Typography } from '@common/components/foundations/Typography';
import { useTailwindColor } from '@common/hooks/useTailwindColors';
import { formattedCurrency } from '@pages/Client/PriceChart/utils/formattedCurrency';
import { Icon } from '@common/components/foundations/icons';
import { useFeaturesStore } from '@common/store/features';
import { useTranslation } from 'react-i18next';
import { ScrollArea, ScrollBar } from '@common/components/molecules/ScrollArea';
import { useDeepCompareMemo } from '@common/hooks/useDeepCompareMemo';
import { API_DATE_FORMAT } from '@common/constants/date';
import { usePriceDrawerStore } from '@common/store/priceDrawer';
import { useSurgePrice } from '@pages/Client/Calendar/hooks/useSurgePrice';
import { cn } from '@common/utils/cn';
import { useSurgeEvents } from '@pages/Client/PricingStrategy/SurgeProtection/hooks/useSurgeProtection';
import { useDate } from '@common/hooks/useDate';
import { TooltipIcon } from '@common/components/molecules/TooltipIcon';

type DataKey =
  | 'priceRecommendation'
  | 'marketPriceIndex'
  | 'hotelPriceIndex'
  | 'airbnbPriceIndex'
  | 'surgeEvent';

interface Props {
  isLoading: boolean;
  priceRecommendation: { date: string; value: number }[];
  marketPriceIndex: { date: string; value?: number | null }[];
  hotelPriceIndex: { date: string; value: number }[];
  airbnbPriceIndex: { date: string; value: number }[];
  isMonthly: boolean;
  currentMonth?: string;
  surgeEvent: { date: string; value: boolean }[];
  referenceRoomId: number;
}

interface DataPoint {
  startDate: string;
  endDate: string;
}

export const MarketFactorChart: React.FC<Props> = ({
  isLoading,
  priceRecommendation,
  marketPriceIndex,
  hotelPriceIndex,
  airbnbPriceIndex,
  isMonthly,
  currentMonth,
  surgeEvent,
  referenceRoomId
}) => {
  const { t } = useTranslation();
  const { features } = useFeaturesStore();
  const { getNowInHotelTimezone } = useDate();
  const limitDate = dayjs().add(365, 'day').startOf('day');
  const indigo = useTailwindColor('indigo');
  const orange = useTailwindColor('orange');
  const uiGreen = useTailwindColor('uiGreen');
  const gold = useTailwindColor('gold');
  const grey = useTailwindColor('grey');
  const darkGrey = useTailwindColor('darkGrey');
  const mediumGrey = useTailwindColor('mediumGrey');
  const fontFamily = "'Inter var', sans-serif";
  const fontSize = '12px';
  const fontWeight = 400;
  const lastLabelDisplayedRef = useRef('');
  const { getSurgePriceForDay } = useSurgePrice();
  const { getSurgeEventByDay } = useSurgeEvents();

  const customLegendPayload = [
    {
      dataKey: 'priceRecommendation',
      value: t('Price Recommendation'),
      color: indigo
    },
    {
      dataKey: 'marketPriceIndex',
      value: t(`Market Price Index`),
      color: darkGrey
    },
    {
      dataKey: 'hotelPriceIndex',
      value: t('Hotel Price Index'),
      color: gold
    },
    {
      dataKey: 'airbnbPriceIndex',
      value: t('Vacation Rental Price Index'),
      color: orange
    },
    {
      dataKey: 'surgeEvent',
      value: t('Surge Event'),
      color: uiGreen
    }
  ];

  const { setDrawerState, setViewingDate } = usePriceDrawerStore();

  const handleOpenPriceDrawer = (date: string) => {
    const formattedDate = new Date(dayjs(date).format(API_DATE_FORMAT));
    setDrawerState(true);
    setViewingDate(formattedDate);
  };

  const [seriesVisibility, setSeriesVisibility] = useState<Record<DataKey, boolean>>({
    marketPriceIndex: true,
    hotelPriceIndex: true,
    airbnbPriceIndex: true,
    priceRecommendation: false,
    surgeEvent: true
  });

  const data = useDeepCompareMemo(() => {
    const uniqueDatesSet = new Set();
    const surgeEventMap = new Map(surgeEvent.map((item) => [item.date, item.value]));
    const priceRecommendationMap = new Map(
      priceRecommendation.map((item) => [item.date, item.value])
    );
    const marketPriceIndexMap = new Map(marketPriceIndex.map((item) => [item.date, item.value]));
    const hotelPriceIndexMap = new Map(hotelPriceIndex.map((item) => [item.date, item.value]));
    const airbnbPriceIndexMap = new Map(airbnbPriceIndex.map((item) => [item.date, item.value]));

    const allDatesRaw = [
      ...priceRecommendation,
      ...marketPriceIndex,
      ...hotelPriceIndex,
      ...airbnbPriceIndex,
      ...surgeEvent
    ]
      .map(({ date }) => ({ raw: date, formattedDate: dayjs(date).format('YYYY-MM') }))
      .filter(({ raw }) => {
        if (uniqueDatesSet.has(raw)) return false;
        uniqueDatesSet.add(raw);
        const day = dayjs(raw);
        const now = getNowInHotelTimezone();
        let dateCondition = day.isSameOrAfter(now, 'day');
        if (!features?.includes(23)) {
          dateCondition = dateCondition && day.isBefore(limitDate);
        }

        return dateCondition;
      });

    const processData = ({ raw }: { raw: string; formattedDate: string }) => ({
      date: raw,
      priceRecommendation: priceRecommendationMap.get(raw),
      marketPriceIndex: marketPriceIndexMap.get(raw),
      hotelPriceIndex: hotelPriceIndexMap.get(raw),
      airbnbPriceIndex: airbnbPriceIndexMap.get(raw),
      surgeEvent: surgeEventMap.get(raw)
    });

    if (isMonthly) {
      const groupedByMonth = groupBy(allDatesRaw, 'formattedDate');
      const groupedDates = Object.entries(groupedByMonth).reduce(
        (acc: { [key: string]: any }, [month, dates]) => {
          acc[month] = dates.map(processData);
          return acc;
        },
        {}
      );

      if (currentMonth) return groupedDates[currentMonth];
      // If currentMonth is not provided, return all the dates
      return Object.values(groupedByMonth).flat().map(processData);
    } else {
      return allDatesRaw.map(processData);
    }
  }, [
    currentMonth,
    priceRecommendation,
    marketPriceIndex,
    hotelPriceIndex,
    airbnbPriceIndex,
    isMonthly,
    features,
    seriesVisibility,
    surgeEvent
  ]);

  const groupConsecutiveSurgeEvents = (
    data: Array<{ date: string; surgeEvent: boolean }>
  ): DataPoint[] => {
    if (!data) return [];
    const surgeEvents: DataPoint[] = [];
    let periodStart: string | null = null;

    data.forEach((entry, index) => {
      if (entry.surgeEvent) {
        if (periodStart === null) periodStart = entry.date;
      } else if (periodStart !== null) {
        surgeEvents.push({ startDate: periodStart, endDate: data[index - 1].date });
        periodStart = null;
      }
    });

    // If the last period continues until the end
    if (periodStart !== null)
      surgeEvents.push({ startDate: periodStart, endDate: data[data.length - 1].date });

    return surgeEvents;
  };

  const surgeEvents = useMemo(() => groupConsecutiveSurgeEvents(data), [data]);

  const dataMax = Math.floor(
    Math.max(
      ...(data || []).map(
        (item: {
          priceRecommendation: number;
          marketPriceIndex: number;
          hotelPriceIndex: number;
          airbnbPriceIndex: number;
        }) =>
          Math.max(
            item.priceRecommendation,
            item.marketPriceIndex,
            item.hotelPriceIndex,
            item.airbnbPriceIndex
          ) * 1.1
      )
    )
  ); // 10% padding
  const dataMin = Math.floor(
    Math.min(
      ...(data || []).map(
        (item: {
          priceRecommendation: number;
          marketPriceIndex: number;
          hotelPriceIndex: number;
          airbnbPriceIndex: number;
        }) =>
          Math.min(
            item.priceRecommendation,
            item.marketPriceIndex,
            item.hotelPriceIndex,
            item.airbnbPriceIndex
          ) * 0.9
      )
    )
  ); // 10% padding

  const tooltipContent = (type: DataKey) => {
    if (type === 'marketPriceIndex')
      return 'A value for your reference room, given by the combined Hotel and Vacation Rental rates, as well as Vacation Rental occupancy data. This is used to calculate the Market Factor in the algorithm. The calculation used is:  Market Factor = (Market Index/Base Price)-1';
    if (type === 'hotelPriceIndex')
      return 'A current price index based on the rates of your market basket, scaled to your base price.';
    if (type === 'airbnbPriceIndex')
      return 'A current price index based on local Vacation Rental rates, scaled to your base price.';
    return '';
  };

  const CustomLegend: React.FC<any> = (props) => {
    const { payload } = props;

    // Add onClick handler to toggle series visibility
    const handleLegendClick = (dataKey: DataKey | DataKey[]) => {
      if (Array.isArray(dataKey)) {
        dataKey.forEach((key) => {
          setSeriesVisibility((prev) => ({ ...prev, [key]: !prev[key] }));
        });
      } else {
        setSeriesVisibility((prev) => ({ ...prev, [dataKey]: !prev[dataKey] }));
      }
    };

    return (
      <ul className="mb-8 flex list-none justify-center gap-8">
        {payload?.map(
          (entry: { dataKey: DataKey; value: string; color: string }, index: number) => {
            if (entry.dataKey === 'surgeEvent' && surgeEvents.length === 0) return null;

            return (
              <li
                key={`item-${index}`}
                className="flex cursor-pointer items-center"
                onClick={() => handleLegendClick(entry.dataKey)}
              >
                {isLoading ? (
                  <Icon.LoadingCircle
                    className="mr-1 h-4 w-4"
                    style={{
                      color: entry.color
                    }}
                  />
                ) : (
                  <div
                    className={cn(
                      'mr-1 rounded-full',
                      entry.dataKey === 'surgeEvent' ? 'h-5 w-5' : ' h-3 w-3'
                    )}
                    style={{
                      backgroundColor: entry.color,
                      opacity: Array.isArray(entry.dataKey)
                        ? (entry.dataKey as DataKey[]).every((key) => seriesVisibility[key])
                          ? 1
                          : 0.3
                        : seriesVisibility[entry.dataKey]
                          ? 1
                          : 0.3
                    }}
                  >
                    {entry.dataKey === 'surgeEvent' ? (
                      <Icon.Ripple className="h-5 w-5 text-white" />
                    ) : null}
                  </div>
                )}
                <Typography
                  color="copyTextGrey"
                  variant="meta-2"
                  className={cn(
                    Array.isArray(entry.dataKey)
                      ? (entry.dataKey as DataKey[]).every((key) => seriesVisibility[key])
                        ? 'opacity-100'
                        : 'opacity-30'
                      : seriesVisibility[entry.dataKey]
                        ? 'opacity-100'
                        : 'opacity-30'
                  )}
                >
                  {isLoading ? t('Loading...') : entry.value}
                </Typography>
                {tooltipContent(entry.dataKey) ? (
                  <TooltipIcon iconClassName="ml-1" content={t(tooltipContent(entry.dataKey))} />
                ) : null}
              </li>
            );
          }
        )}
      </ul>
    );
  };

  const CustomTooltip: React.FC<any> = (props) => {
    const { active, payload } = props;
    if (!active || !payload || !payload[0]) return null;
    const dataPoint = payload[0].payload;

    const { isSurgePrice } = getSurgePriceForDay(dataPoint.date, referenceRoomId);
    const isSurgeEvent = getSurgeEventByDay(dataPoint.date)?.active;
    return (
      <Card backgroundColor="white">
        <Typography variant="meta-1" className="font-semibold" color="darkGrey">
          <div className="flex items-center gap-1">
            {isSurgeEvent ? <Icon.Ripple className="h-6 w-6 text-uiGreen" /> : null}
            {dayjs(dataPoint.date).format('ddd, DD MMM YYYY')}
          </div>
        </Typography>
        <div className="flex items-center justify-between gap-x-3">
          <div className="flex items-center gap-2">
            {isSurgePrice ? (
              <Icon.Lock className="h-2 w-2" />
            ) : (
              <div className="h-2 w-2 rounded-full bg-indigo" />
            )}
            <Typography
              variant="meta-2"
              color="darkGrey"
              className={cn(isSurgePrice ? 'text-meta-2-semibold' : null)}
            >
              {isSurgePrice
                ? t('Surge Protection Price Reference Room')
                : t('Price Recommendation')}
            </Typography>
          </div>
          <Typography variant="meta-2" className="text-meta-2-medium" color="darkGrey">
            <b>{formattedCurrency(dataPoint.priceRecommendation)}</b>
          </Typography>
        </div>
        <div className="flex items-center justify-between gap-x-3">
          <div className="flex items-center gap-2">
            <div className="h-2 w-2 rounded-full bg-darkGrey" />
            <Typography variant="meta-2" color="darkGrey">
              {t(`Market Price Index`)}
            </Typography>
          </div>
          <Typography variant="meta-2" className="text-meta-2-medium" color="darkGrey">
            <b>{formattedCurrency(dataPoint.marketPriceIndex)}</b>
          </Typography>
        </div>
        <div className="flex items-center justify-between gap-x-3">
          <div className="flex items-center gap-2">
            <div className="h-2 w-2 rounded-full bg-gold" />
            <Typography variant="meta-2" color="darkGrey">
              {t(`Hotel Price Index`)}
            </Typography>
          </div>
          <Typography variant="meta-2" className="text-meta-2-medium" color="darkGrey">
            <b>{formattedCurrency(dataPoint.hotelPriceIndex)}</b>
          </Typography>
        </div>
        <div className="flex items-center justify-between gap-x-3">
          <div className="flex items-center gap-2">
            <div className="h-2 w-2 rounded-full bg-orange" />
            <Typography variant="meta-2" color="darkGrey">
              {t(`Vacation Rental Price Index`)}
            </Typography>
          </div>
          <Typography variant="meta-2" className="text-meta-2-medium" color="darkGrey">
            <b>{formattedCurrency(dataPoint.airbnbPriceIndex)}</b>
          </Typography>
        </div>
        <div className="mt-2 text-xs italic text-copyTextGrey">
          {t('Click the chart for more details.')}
        </div>
      </Card>
    );
  };

  const xAxisTickFormatter = (
    value: any,
    isMonthly: boolean,
    lastLabelDisplayedRef: React.MutableRefObject<string | undefined>
  ) => {
    const currentLabel = isMonthly
      ? dayjs(value).format('MMM DD')
      : dayjs(value).format('MMM YYYY');
    if (currentLabel === lastLabelDisplayedRef.current) return '';
    lastLabelDisplayedRef.current = currentLabel;
    return currentLabel;
  };

  const CustomTick: React.FC<any> = ({ x, y, payload }) => {
    const { isToday, isWeekend } = useDate();
    const date = new Date(payload.value);

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={10}
          dy={0}
          textAnchor="end"
          fill={grey}
          fontFamily={fontFamily}
          fontSize={fontSize}
          fontWeight={isToday(date) ? 'bold' : fontWeight}
          textDecoration={isWeekend(date) ? 'underline' : 'none'}
          transform="rotate(-45 0 10)"
        >
          {dayjs(payload.value).format('MMM DD')}
        </text>
      </g>
    );
  };

  return (
    <div className="relative h-full w-full">
      <Card backgroundColor="white">
        <ScrollArea>
          <ResponsiveContainer minWidth={800} width="100%" height={500}>
            <ComposedChart
              data={data}
              className="cursor-pointer"
              margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
              onClick={(data: any, event: any) => {
                if (['path', 'svg'].includes(event?.target?.tagName))
                  handleOpenPriceDrawer(data?.activeLabel);
              }}
            >
              <defs>
                <linearGradient id="totalGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor={indigo} stopOpacity={0.5} />
                  <stop offset="100%" stopColor={indigo} stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid stroke={mediumGrey} strokeDasharray="2" vertical={false} />
              <XAxis
                key={currentMonth}
                axisLine={false}
                tick={isMonthly ? <CustomTick /> : { fill: grey }}
                tickLine={false}
                fontFamily={fontFamily}
                fontSize={fontSize}
                fontWeight={fontWeight}
                dataKey="date"
                tickFormatter={(value) =>
                  xAxisTickFormatter(value, isMonthly, lastLabelDisplayedRef)
                }
                interval={isMonthly ? 0 : 'preserveEnd'}
                angle={-45}
                textAnchor={'end'}
                height={isMonthly ? 40 : 60}
              />
              <YAxis
                domain={[dataMin, dataMax]}
                axisLine={false}
                width={100}
                tick={{ fill: grey }}
                tickLine={false}
                fontFamily={fontFamily}
                fontSize={fontSize}
                fontWeight={fontWeight}
                tickFormatter={(value) => formattedCurrency(value)}
              />
              <Tooltip
                content={(props) => <CustomTooltip {...props} />}
                wrapperStyle={{ outline: 'none' }}
              />
              <Legend
                verticalAlign="top"
                content={(props) => <CustomLegend {...props} payload={customLegendPayload} />}
              />

              {seriesVisibility.priceRecommendation && (
                <Area
                  visibility={seriesVisibility.priceRecommendation ? 'visible' : 'hidden'}
                  dataKey="priceRecommendation"
                  name={t('Price Recommendation') as string}
                  stroke={indigo}
                  strokeWidth={2}
                  fill="url(#totalGradient)"
                  fillOpacity={0.2}
                  activeDot={{
                    stroke: indigo,
                    strokeWidth: 4,
                    className: 'cursor-pointer',
                    elevation: 4,
                    onClick: (_, data: any) => handleOpenPriceDrawer(data.payload.date)
                  }}
                />
              )}

              {seriesVisibility.marketPriceIndex && (
                <Line
                  isAnimationActive={false}
                  visibility={seriesVisibility.marketPriceIndex ? 'visible' : 'hidden'}
                  dataKey="marketPriceIndex"
                  name={t(`Market Price Index`) as string}
                  stroke={darkGrey}
                  fill={darkGrey}
                  strokeWidth={1}
                  dot={false}
                  activeDot={{
                    stroke: darkGrey,
                    strokeWidth: 4,
                    className: 'cursor-pointer',
                    onClick: (_, data: any) => handleOpenPriceDrawer(data.payload.date)
                  }}
                />
              )}

              {seriesVisibility.hotelPriceIndex && (
                <Line
                  isAnimationActive={false}
                  visibility={seriesVisibility.hotelPriceIndex ? 'visible' : 'hidden'}
                  dataKey="hotelPriceIndex"
                  name={t(`Hotel Price Index`) as string}
                  stroke={gold}
                  fill={gold}
                  strokeWidth={1}
                  dot={false}
                  activeDot={{
                    stroke: gold,
                    strokeWidth: 4,
                    className: 'cursor-pointer',
                    onClick: (_, data: any) => handleOpenPriceDrawer(data.payload.date)
                  }}
                />
              )}

              {seriesVisibility.airbnbPriceIndex && (
                <Line
                  isAnimationActive={false}
                  visibility={seriesVisibility.airbnbPriceIndex ? 'visible' : 'hidden'}
                  dataKey="airbnbPriceIndex"
                  name={t(`Vacation Rental Price Index`) as string}
                  stroke={orange}
                  fill={orange}
                  strokeWidth={1}
                  dot={false}
                  activeDot={{
                    stroke: orange,
                    strokeWidth: 4,
                    className: 'cursor-pointer',
                    onClick: (_, data: any) => handleOpenPriceDrawer(data.payload.date)
                  }}
                />
              )}

              {seriesVisibility.surgeEvent &&
                surgeEvents.map((period, index) => {
                  if (period.startDate === period.endDate) {
                    return (
                      <ReferenceLine
                        name={t('Surge Event') as string}
                        visibility={seriesVisibility.surgeEvent ? 'visible' : 'hidden'}
                        key={`surgeEvent-referenceLine-${index}`}
                        x={period.startDate}
                        stroke={uiGreen}
                        strokeWidth={isMonthly ? 20 : 5}
                        strokeOpacity={0.4}
                      />
                    );
                  }

                  return (
                    <ReferenceArea
                      name={t('Surge Event') as string}
                      visibility={seriesVisibility.surgeEvent ? 'visible' : 'hidden'}
                      key={`surgeEvent-referenceArea-${index}`}
                      x1={period.startDate}
                      x2={period.endDate}
                      fill={uiGreen}
                      stroke={uiGreen}
                      strokeWidth={0}
                      fillOpacity={0.4}
                    />
                  );
                })}
            </ComposedChart>
          </ResponsiveContainer>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      </Card>
    </div>
  );
};
