import { z } from 'zod';

export const createRatePlansSchema = z.object({
  rate_id: z.string().trim().min(1, { message: 'Required' }),
  room_type_ids: z.array(z.union([z.string(), z.number()])).optional(),
  name: z.string().trim().min(1, { message: 'Required' }),
  description: z.string().nullable().optional(),
  property_code: z.string().optional(),
  min_occupancy: z.number().optional(),
  max_occupancy: z.number().optional(),
  default_occupancy: z.number().optional(),
  max_child_occupancy: z.number().optional(),
  max_adult_occupancy: z.number().optional(),
  extra: z.string().nullable().optional(),
  other_rate: z.string().nullable().optional(),
  checkin: z.string().optional(),
  checkout: z.string().optional()
});

const resultSchema = z.object({
  id: z.number(),
  rate_id: z.string(),
  room_type_ids: z.array(z.number()),
  name: z.string(),
  description: z.string(),
  property_code: z.string(),
  min_occupancy: z.number(),
  max_occupancy: z.number(),
  default_occupancy: z.number(),
  max_child_occupancy: z.number(),
  max_adult_occupancy: z.number(),
  extra: z.string(),
  other_rate: z.string(),
  checkin: z.string(),
  checkout: z.string()
});

const MetadataSchema = z.object({
  count: z.number(),
  current: z.number(),
  next: z.string().url().optional()
});

const ratePlansResponseSchema = z.object({
  metadata: MetadataSchema,
  data: z.array(resultSchema)
});

export const ParamsRatePlanSchema = z.object({
  page: z.number().optional(),
  ordering: z.string().nullable().optional()
});

export type RatePlansResponse = z.infer<typeof ratePlansResponseSchema>;
export type CreateRatePlans = z.infer<typeof createRatePlansSchema>;
export type UpdateRatePlans = Partial<CreateRatePlans> & { id?: number };
export type ParamsRatePlan = z.infer<typeof ParamsRatePlanSchema>;

export function getRatePlansSchema(hasIndependentRates: boolean, mode: 'create' | 'update') {
  const dependentRatesSchema = z
    .array(z.union([z.string(), z.number()]))
    .nonempty({ message: 'Room rate is required' });
  const extendedSchema = createRatePlansSchema.extend({
    room_type_ids: dependentRatesSchema
  });
  if (mode === 'create') {
    return hasIndependentRates ? createRatePlansSchema : extendedSchema;
  } else {
    const updateSchema = extendedSchema.extend({
      id: z.number().int().positive({ message: 'ID must be a positive integer' }).optional()
    });
    return hasIndependentRates ? createRatePlansSchema : updateSchema;
  }
}
