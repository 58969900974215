import { useTranslation } from 'react-i18next';
import { find } from 'lodash-es';
import { Icon } from '@common/components/foundations/icons';
import { Button, Menu } from '@mantine/core';
import { UploadPrices } from '@pages/Client/Calendar/components/UploadPrices/UploadPrices';
import {
  AutoUploadModal,
  EditAutoUploadModal,
  OnOkArgument,
  StopAutoUploadModal,
  UPLOAD_NEXT_OPTIONS
} from '@pages/Client/Calendar/components/AutoUpload/AutoUploadModal';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { Modal } from '@common/components/molecules/Modal';
import React, { ComponentProps, useCallback, useEffect, useRef, useState } from 'react';
import { updateHotelProperty } from '@common/api/hotel';
import { useMutation } from '@tanstack/react-query';
import { rainConfetti } from '@pages/Client/Calendar/utils/rainConfetti';
import { useNotificationsStore } from '@common/store/notifications';
import { useHotelStore } from '@common/store/auth';
import { uploadPrice } from '@common/api/pricingAlgorithm';
import dayjs from 'dayjs';
import { API_DATE_FORMAT, API_DATE_FORMAT_FULL } from '@common/constants/date';
import { useEventTracking } from '@common/hooks/useEventTracking';
import { useGetDetailProviderConfig } from '@pages/Client/hooks/useProviderConfig';
import { usePusherStore } from '@/store/pusher';
import { PusherEventNames, PusherEventResponse } from '@/constants/pusher';

export function ConsolidatedUploadPricesButton() {
  const { t } = useTranslation();
  const { trackEvent } = useEventTracking();
  const {
    hotelDetails,
    pmsChannelManagerText,
    query: { refetch: refetchHotelDetails, isLoading: isLoadingHotel }
  } = useHotelDetails();
  const { hotelAuthToken } = useHotelStore();
  const { addNotification } = useNotificationsStore();
  const [isFetchingPrices, setIsFetchingPrices] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [autoUpload, setAutoUpload] = useState<boolean | null>(null);
  const [showAutoUploadModal, setShowAutoUploadModal] = useState(false);
  const [showUploadPricesModal, setShowUploadPricesModal] = useState(false);
  const [showEditAutoUploadModal, setShowEditAutoUploadModal] = useState(false);
  const [showStopAutoUploadModal, setShowStopAutoUploadModal] = useState(false);

  const { mutateAsync: uploadPriceMutate, isPending: isUploadingPrice } = useMutation({
    mutationFn: uploadPrice
  });
  const { mutateAsync: updateHotelPropertyMutate, isPending: isUpdatingHotel } = useMutation({
    mutationFn: updateHotelProperty
  });
  const uploadPricesFormRef: ComponentProps<typeof UploadPrices>['ref'] = useRef(null);
  const { hasUploadPriceButton, hasNoUploadPricesButton } = useGetDetailProviderConfig();
  const { channelBind, channelUnbind } = usePusherStore();

  const uploadMessageCb = () => {
    setIsFetchingPrices(false);
  };

  // Listen to Pusher events
  useEffect(() => {
    channelBind(PusherEventNames.UploadPriceMessage, uploadMessageCb);

    return () => {
      channelUnbind(PusherEventNames.UploadPriceMessage, uploadMessageCb);
    };
  }, [uploadMessageCb]);

  useEffect(() => {
    if (hotelDetails) {
      setAutoUpload(hotelDetails.is_update_to_pms);
    }
    setInitialized(true);
  }, [hotelDetails]);

  async function onAutoUploadChange(checked: boolean) {
    if (checked) {
      setShowAutoUploadModal(true);
    } else {
      setShowStopAutoUploadModal(true);
    }
  }

  const onUploadPricesToPMS = async (ok: boolean) => {
    if (!ok) {
      setShowUploadPricesModal(false);
      return;
    }

    const form = uploadPricesFormRef.current!.form.getValues();
    const valid = await uploadPricesFormRef.current!.form.trigger();

    if (!valid) return;

    const clientTimeZone = dayjs.tz.guess();

    try {
      setIsFetchingPrices(true);
      await uploadPriceMutate({
        client_timezone: clientTimeZone,
        client_time: dayjs().tz().format(API_DATE_FORMAT_FULL),
        command: 'upload_price_to_pms',
        disable_threshold: form.uploadAllPrices,
        start_date: dayjs(form.startDate).tz().format(API_DATE_FORMAT),
        end_date: dayjs(form.endDate).tz().format(API_DATE_FORMAT)
      });
      setShowUploadPricesModal(false);
      addNotification(
        'success',
        `${t('Sending Prices. Waiting for the')} ${pmsChannelManagerText} ${t('to confirm')}.`
      );
    } catch (_) {
      addNotification('fail', 'Something Went Wrong!');
    }
  };

  async function onAutoUploadUserOk({ skipUploadType, uploadNextType }: OnOkArgument) {
    await updateHotelPropertyMutate({
      is_update_to_pms: true,
      token: hotelAuthToken,
      update_to_pms_skip_type: skipUploadType,
      update_to_pms_type: uploadNextType
    });
    await refetchHotelDetails();
    setAutoUpload(true);
    rainConfetti();
    trackEvent('AutoUploadPricesEnabled');
    setShowAutoUploadModal(false);
    addNotification('success', t('Hotel updated successfully'));
  }

  async function onEditAutoUploadUserOk({ skipUploadType, uploadNextType }: OnOkArgument) {
    await updateHotelPropertyMutate({
      is_update_to_pms: true,
      token: hotelAuthToken,
      update_to_pms_skip_type: skipUploadType,
      update_to_pms_type: uploadNextType
    });
    await refetchHotelDetails();
    setAutoUpload(true);
    setShowEditAutoUploadModal(false);
    addNotification('success', t('Hotel updated successfully'));
  }

  const onStopAutoUpload = async () => {
    await updateHotelPropertyMutate({
      is_update_to_pms: false,
      token: hotelAuthToken
    });
    await refetchHotelDetails();
    setAutoUpload(false);
    setShowStopAutoUploadModal(false);
    addNotification('success', t('Hotel updated successfully'));
  };

  if (!hasUploadPriceButton) {
    return null;
  }

  if (!initialized) {
    return <Button variant="outline" className="w-32" loading />;
  }

  return (
    <>
      {autoUpload ? (
        <Menu trigger="hover" openDelay={50} closeDelay={150}>
          <Menu.Target>
            <Button
              disabled={isFetchingPrices}
              rightSection={
                isFetchingPrices ? (
                  <Icon.LoadingCircle className="h-5 w-5 rounded-full" />
                ) : (
                  <Icon.TurnOff className={'h-5 w-5 rounded-full'} />
                )
              }
              onClick={() => setShowUploadPricesModal(true)}
            >
              {isFetchingPrices ? t('Uploading Prices') : t('Upload Prices')}
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            {!hasNoUploadPricesButton && (
              <Menu.Item
                leftSection={<Icon.Publish className="h-5 w-5" />}
                onClick={() => setShowUploadPricesModal(true)}
              >
                {t('Upload Prices Manually')}
              </Menu.Item>
            )}

            <Menu.Item
              leftSection={<Icon.Calendar className="h-5 w-5" />}
              onClick={() => setShowEditAutoUploadModal(true)}
            >
              {t('Auto-Upload Next')}{' '}
              {t(
                find(UPLOAD_NEXT_OPTIONS, (item) => item.value === hotelDetails?.update_to_pms_type)
                  ?.label || ''
              )}
            </Menu.Item>

            <Menu.Item
              leftSection={<Icon.TurnOff className="h-5 w-5" />}
              rightSection={isLoadingHotel && <Icon.LoadingCircle className="h-5 w-5" />}
              onClick={() => onAutoUploadChange(false)}
            >
              {t('Turn off Auto-Upload')}
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      ) : (
        <>
          {!hasNoUploadPricesButton && (
            <Button
              variant="outline"
              disabled={isFetchingPrices}
              rightSection={
                isFetchingPrices ? (
                  <Icon.LoadingCircle className="h-5 w-5" />
                ) : (
                  <Icon.Publish className="h-5 w-5" />
                )
              }
              onClick={() => setShowUploadPricesModal(true)}
            >
              {isFetchingPrices ? t('Uploading Prices') : t('Upload Prices')}
            </Button>
          )}

          <Button
            variant={'filled'}
            className="bg-orange hover:bg-orange-darker"
            rightSection={<Icon.TurnOff className="h-5 w-5" />}
            onClick={() => onAutoUploadChange(!autoUpload)}
          >
            {t('Turn on Auto-Upload')}
          </Button>
        </>
      )}

      <Modal onClose={setShowAutoUploadModal} open={showAutoUploadModal} size="custom">
        <AutoUploadModal
          isLoading={isUpdatingHotel}
          onClose={setShowAutoUploadModal}
          onOk={onAutoUploadUserOk}
        />
      </Modal>

      <Modal onClose={setShowEditAutoUploadModal} open={showEditAutoUploadModal} size="custom">
        <EditAutoUploadModal
          isLoading={isUpdatingHotel}
          onClose={setShowEditAutoUploadModal}
          onOk={onEditAutoUploadUserOk}
        />
      </Modal>

      <Modal
        onClose={setShowStopAutoUploadModal}
        open={showStopAutoUploadModal}
        okText={t('Cancel') as string}
        cancelText={t('Stop') as string}
        size="custom"
        showFooter={false}
      >
        <StopAutoUploadModal
          isLoading={isUpdatingHotel}
          onClose={setShowStopAutoUploadModal}
          onConfirm={onStopAutoUpload}
        />
      </Modal>

      <Modal
        isLoading={isUploadingPrice}
        onClose={onUploadPricesToPMS}
        size="xl"
        open={showUploadPricesModal}
        cancelText={t('Cancel') as string}
        okText={t('Upload Prices') as string}
      >
        <UploadPrices ref={uploadPricesFormRef} />
      </Modal>
    </>
  );
}
