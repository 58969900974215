import { useEffect } from 'react';
import { useFeaturesStore } from '@common/store/features';
import { useNavigate, useLocation, useParams, matchPath } from 'react-router-dom';

export const useRedirectRules = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { isInitialised, hasReportingFeature } = useFeaturesStore();

  const subdomain = window.location.hostname.split('.')[0];
  const isIframe = subdomain.includes('iframe');

  /**
   * routeRedirectList:
   *   Key: the path pattern (must match how your router is defined)
   *   Value: {
   *     check() => returns true if we should redirect,
   *     redirectUrl => returns url string if no default redirect url
   *   }
   */
  const routeRedirectList: Record<string, { check: () => boolean; redirectUrl?: string }> = {
    '/client/:id/dashboard/*': {
      check: () => !hasReportingFeature()
    },
    '/client/:id/account/profile-settings/*': {
      check: () => isIframe,
      redirectUrl: `/client/${id}/account/hotel-settings/`
    }
  };

  useEffect(() => {
    if (!isInitialised) return; // wait until features are loaded

    const matchedKey = Object.keys(routeRedirectList).find((pattern) =>
      Boolean(matchPath({ path: pattern, end: false }, location.pathname))
    );

    if (!matchedKey) return;

    const shouldRedirect = routeRedirectList[matchedKey].check();
    const redirectUrl = routeRedirectList[matchedKey]?.redirectUrl || `/client/${id}`;

    if (shouldRedirect) {
      navigate(redirectUrl);
    }
  }, [isInitialised, location.pathname]);
};
