import { getUserDetail } from '@common/api/account';
import { useAuthStore } from '@common/store/auth';
import { UtilQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';
import isNil from 'lodash-es/isNil';
import { useEffect } from 'react';

export type Hotel = {
  id: number;
  state: number;
  is_client_access_disabled: boolean;
};

export const useUserDetail = (enable: boolean) => {
  const { setUser } = useAuthStore();
  const { data, isLoading, isSuccess, error } = useQuery({
    queryKey: [UtilQueryKeys.USER_DETAIL],
    queryFn: () => {
      return getUserDetail();
    },
    enabled: enable,
    retry: true
  });

  useEffect(() => {
    if (!isSuccess) return;
    setUser(data);
  }, [isSuccess]);

  return {
    detailUser: data,
    isLoading,
    error
  };
};
