import { FormInput } from '@common/components/atoms/Input';
import { Fragment, useEffect, useState } from 'react';
import { LineTable } from '@common/components/molecules/LineTable/LineTable';
import { Checkbox } from '@mantine/core';
import { isEmpty, isNil, map, result, round } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { ADJUSTMENT } from '@pages/Client/Calendar/components/BulkEdit/types/adjustments';
import { useBuildAdjustmentInputsArray } from '@pages/Client/Calendar/components/BulkEdit/hooks/useBuildAdjustmentInputsArray';
import { ClosedSpaces } from '@pages/Client/Calendar/components/BulkEdit/types/schema/closedSpacesSchema';

/**
 * Should only be used within a React Hooks Form component
 * as it relies on the parent form context.
 * https://react-hook-form.com/docs/useformcontext
 */

export function ClosedSpacesAdjustmentForm() {
  const { t } = useTranslation();
  const formName = ADJUSTMENT.CLOSED;
  const { hotelDetails } = useHotelDetails();
  const { pricingSettings } = useRoomPrices();
  const [closeAll, setCloseAll] = useState(false);
  const { sortedHotelRooms } = useHotelRoomsList();
  const { control, formState, setValue, watch, trigger } = useFormContext();
  const adjustmentFields: ClosedSpaces[] = useBuildAdjustmentInputsArray<ClosedSpaces>(formName);
  const { fields, replace } = useFieldArray({ name: formName, control });
  const roomsSettings = result(pricingSettings, 'rooms');
  const refRoomCount = round(Number(result(roomsSettings, 'reference.number_of_rooms' ?? 0)));

  useEffect(() => {
    if (isEmpty(fields)) return;
    map(sortedHotelRooms, (room, index) => {
      const derivedRoomCount = round(
        Number(pricingSettings?.rooms?.derived?.[room.id]?.number_of_rooms ?? 0)
      );
      const valueToSet = room.is_reference_room ? refRoomCount : derivedRoomCount;
      setValue(`${formName}.${index}.value`, closeAll ? valueToSet : undefined);
    });
  }, [closeAll]);

  useEffect(() => {
    if (isEmpty(fields) && !isEmpty(pricingSettings)) replace(adjustmentFields);
  }, [adjustmentFields, pricingSettings]);

  useEffect(() => {
    const subscription = watch((_) => {
      trigger(formName);
    });
    return () => subscription.unsubscribe();
  }, [watch, fields]);

  return (
    <Fragment>
      <Checkbox
        label={t('Close All')}
        checked={closeAll}
        onChange={(e) => setCloseAll(e.target.checked)}
      />
      <LineTable>
        <thead>
          <tr>
            <th>{t(`${hotelDetails?.room_apartment_space_name} Types`)}</th>
            <th>{t(`Closed ${hotelDetails?.room_apartment_space_name}s`)}</th>
          </tr>
        </thead>

        <tbody>
          {fields.map((field, index) => {
            const room = sortedHotelRooms[index];
            return !isNil(room) ? (
              <tr key={field.id}>
                <td>
                  {room.name} {`${import.meta.env.DEV ? room.id : ''}`}
                </td>
                <td>
                  <div className="w-full">
                    <FormInput
                      type="number"
                      nameKey={`${formName}.${index}.id` as const}
                      valueKey={`${formName}.${index}.value` as const}
                      background="grey"
                      showClearButton={false}
                      helperMessage={result(formState, `errors.${formName}[${index}].message`)}
                    />
                  </div>
                </td>
              </tr>
            ) : null;
          })}
        </tbody>
      </LineTable>
    </Fragment>
  );
}
