import { UpdateRoomTypes } from '@common/api/integration/roomTypesSchema';
import { Button } from '@common/components/atoms/Button';
import { Divider } from '@mantine/core';
import { Input } from '@common/components/atoms/Input';
import { InputHelperMessage } from '@common/components/atoms/InputHelperMessage';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { Typography } from '@common/components/foundations/Typography';
import { Icon } from '@common/components/foundations/icons';
import { LineTable } from '@common/components/molecules/LineTable/LineTable';
import { Modal } from '@common/components/molecules/Modal';
import { useNotificationsStore } from '@common/store/notifications';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUpdatePmsRoomType } from '@pages/Client/Account/Integrations/hooks/usePmsRoomType';
import { useUnitStore } from '@pages/Client/Account/Integrations/store/unitRoom';
import { useHotelPmsDataMap } from '@pages/Client/PricingStrategy/RoomSetup/hooks/useHotelPmsDataMap';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

interface Unit {
  id: number | null | string;
  unit: string;
}

interface TransformedData {
  id: number | string;
  name: string;
  extra: string;
  raw_extra: Unit[];
}

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onClick?: () => void;
  selectedRoomType: TransformedData;
};

const schema = z.object({
  unit: z.string().trim().min(1, { message: 'Required' })
});

export const UpdateRoomMappingModal = ({ isOpen, onClose, selectedRoomType }: ModalProps) => {
  const { t } = useTranslation();
  const { updatePmsRoomType } = useUpdatePmsRoomType();
  const { addNotification } = useNotificationsStore();
  const { unitRooms } = useUnitStore();
  const { hotelPmsDataMap } = useHotelPmsDataMap();

  const { control, setValue, watch } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  const [roomTypes, setRoomTypes] = useState<Unit[]>(selectedRoomType?.raw_extra);

  useEffect(() => {
    if (selectedRoomType?.raw_extra) {
      setRoomTypes(selectedRoomType?.raw_extra);
    }
  }, [selectedRoomType]);

  const allRoomType = hotelPmsDataMap?.mapped_data?.flatMap((item) => {
    if (item.extra) {
      try {
        const extra = JSON.parse(item.extra);
        return extra.room_units && Array.isArray(extra.room_units)
          ? extra.room_units.map((roomUnit: Unit) => ({ id: roomUnit.id, name: roomUnit.unit }))
          : [];
      } catch {
        return [];
      }
    }
    return [];
  });

  const handleUpdateRoomType = async () => {
    if (!selectedRoomType?.id) return;
    const jsonExtra = {
      room_units: roomTypes
    };
    const payload: Partial<UpdateRoomTypes> = {
      extra: JSON.stringify(jsonExtra),
      id: selectedRoomType?.id as number
    };
    await updatePmsRoomType(payload);
    onClose();
  };

  // Extract all id values from existingRoom into an array
  const existingIds = allRoomType?.map((room) => room.id);

  // Filter out items from unitRoom that match any id in existingIds
  const filteredUnitRooms = unitRooms.filter((item) => {
    // Check if the room's value is not in existingIds and not already in roomTypes
    return !existingIds?.includes(item.value) && !roomTypes.some((room) => room.id === item.value);
  });

  return (
    <Modal
      onClose={() => {
        onClose();
        setRoomTypes([]);
      }}
      onClick={handleUpdateRoomType}
      okText={`${t('Save')}`}
      size={'xl'}
      open={isOpen}
    >
      <div className="min-w-[380px]">
        <div className="flex flex-col items-start gap-4">
          <Typography className="mb-2" element="h6" color="darkGrey" variant="h6">
            {t(`Edit`)}. {selectedRoomType?.name ? `${selectedRoomType.name}` : ''}
          </Typography>
          <div className="w-full">
            <LineTable className="my-3 mb-3 pb-3 text-white">
              <thead>
                <tr>
                  <th className="text-meta-2-medium text-darkGrey">{t('Unit')}</th>
                  <th className="text-right text-meta-2-medium text-darkGrey">{t('Action')}</th>
                </tr>
              </thead>

              <tbody>
                {roomTypes?.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td className="text-meta-2-medium text-darkGrey">{item.unit}</td>
                      <td className="text-right text-meta-2-medium text-darkGrey">
                        {!item.id ? (
                          <Button
                            icon
                            size="small"
                            onClick={() => {
                              const filteredRoomTypes = roomTypes.filter(
                                (roomType) => roomType.unit !== item.unit
                              );
                              setRoomTypes(filteredRoomTypes);
                            }}
                          >
                            <Icon.Delete className="text-uiRed" />
                          </Button>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </LineTable>
            <SelectDropdown
              fullWidth
              options={filteredUnitRooms || []}
              onChange={(idUnit) => {
                if (!roomTypes.some((room) => room.id === idUnit)) {
                  const selectedRoom = unitRooms.find((unit) => unit.value === idUnit);
                  if (selectedRoom) {
                    setRoomTypes([
                      ...roomTypes,
                      {
                        id: idUnit as string,
                        unit: selectedRoom.label
                      }
                    ]);
                  }
                }
              }}
              name="roomtype"
              customOptions={
                <>
                  <div className="space-y-2">
                    <Divider my={8} />
                    <Controller
                      name="unit"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                        <Input
                          disabled={false}
                          type="text"
                          background="grey"
                          showClearButton={false}
                          tabIndex={1}
                          placeholder={`${t('Custom Room')}`}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              const unit = watch('unit');
                              const isNameExist = roomTypes.some(
                                (roomType) => roomType.unit === unit
                              );
                              const isRoomInAllRooms = allRoomType?.some(
                                (roomType) => roomType.name === unit
                              );
                              if (unit && !isNameExist && !isRoomInAllRooms) {
                                setRoomTypes([...roomTypes, { id: null, unit }]);
                                setValue('unit', '');
                              } else if (isNameExist) {
                                addNotification('fail', 'Room name already exists');
                                onClose();
                              } else if (isRoomInAllRooms) {
                                addNotification('fail', 'Room name already exists');
                                onClose();
                              }
                            }
                          }}
                          value={value || ''}
                          error={invalid}
                          helperMessage={
                            invalid && (
                              <InputHelperMessage
                                icon={
                                  error ? (
                                    <Icon.WarningOutline className="h-3 w-3 fill-uiRed" />
                                  ) : null
                                }
                                message={error?.message}
                              />
                            )
                          }
                          onChange={onChange}
                        />
                      )}
                    />
                    <Button
                      className="w-full"
                      intent="outline"
                      onClick={() => {
                        const unit = watch('unit');
                        const isNameExist = roomTypes.some((roomType) => roomType.unit === unit);
                        const isRoomInAllRooms = allRoomType?.some(
                          (roomType) => roomType.name === unit
                        );
                        if (unit && !isNameExist && !isRoomInAllRooms) {
                          setRoomTypes([...roomTypes, { id: null, unit }]);
                          setValue('unit', '');
                        } else if (isNameExist) {
                          addNotification('fail', 'Room name already exists');
                          onClose();
                        } else if (isRoomInAllRooms) {
                          addNotification('fail', 'Room name already exists');
                          onClose();
                        }
                      }}
                    >
                      Add New
                    </Button>
                  </div>
                </>
              }
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
