import { PriceInfo } from '@common/api/pricingAlgorithm/types';
import { Icon } from '@common/components/foundations/icons';
import { KPILarge } from '@common/components/molecules/KPI';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

export const usePriceInfo = (data: PriceInfo) => {
  const { t } = useTranslation();
  const { hotelDetails } = useHotelDetails();
  const channelManagerOrPms = hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS';

  let marketFactorPer = 0;
  let marketFactor = 0;
  let occupancyFectorPer = 0;
  let occupancyFactor = 0;
  let adjustment = 0;
  let adjustmentPer = 0;

  const {
    average_price: avgPrice,
    weighted_average_price_with_pubp: weightedAvgPriceWithPubp,
    suggested_price,
    suggested_price_without_restriction: suggestedPriceWithoutRestriction
  } = data;

  marketFactorPer = (weightedAvgPriceWithPubp / avgPrice - 1) * 100;
  marketFactor = weightedAvgPriceWithPubp - avgPrice;
  occupancyFectorPer = (suggestedPriceWithoutRestriction / weightedAvgPriceWithPubp - 1) * 100;
  occupancyFactor = suggestedPriceWithoutRestriction - weightedAvgPriceWithPubp;
  adjustmentPer = (suggested_price / suggestedPriceWithoutRestriction - 1) * 100;
  adjustment = suggested_price - suggestedPriceWithoutRestriction;

  const getBreakDownValue = (value: number) => {
    return CurrencyFormatter.format(Math.round(value));
  };

  const getBreakDownPercentage = (number: number) => {
    const value = Math.round(number).toString();
    return `${value}%`;
  };

  const getBreakDownIcon = (value: number) => {
    if (value > 0) return <Icon.ArrowUpWard className="h-4 w-4 fill-uiGreen" />;
    if (value < 0) return <Icon.ArrowDownWard className="h-4 w-4 fill-uiRed" />;
  };

  const priceInfo: ComponentProps<typeof KPILarge>['content'] = {
    basePrice: `${t('Base Price')}${data?.base_price}`,
    basePriceValue: `${CurrencyFormatter.format(avgPrice) ?? ''}`,
    breakdownPrice: [
      {
        title: t('Market Factor'),
        id: 'market-factor',
        value: getBreakDownValue(marketFactor),
        percentage: getBreakDownPercentage(marketFactorPer),
        icon: getBreakDownIcon(marketFactor)
      },
      {
        title: t('Occupancy/Pickup Factor'),
        id: 'occupancy-pickup-factor',
        value: getBreakDownValue(occupancyFactor),
        percentage: getBreakDownPercentage(occupancyFectorPer),
        icon: getBreakDownIcon(occupancyFactor)
      },
      {
        title: t('Your Adjustments'),
        id: 'your-adjustments',
        value: getBreakDownValue(adjustment),
        percentage: getBreakDownPercentage(adjustmentPer),
        icon: getBreakDownIcon(adjustment)
      }
    ],
    prices: [
      {
        title: t(`Price in ${channelManagerOrPms}`),
        id: 'price-in-pms',
        value: `${CurrencyFormatter.format(Math.round(data.original_price)) ?? 'n.A.'}`
      }
    ]
  };

  return priceInfo;
};
