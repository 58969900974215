import { QuotationRequest } from '@common/api/pricingAlgorithm/types';
import { useResourceCenter } from '@common/hooks/useResourceCenter';
import { Modal, rem, Stepper, useMantineTheme } from '@mantine/core';
import '@mantine/dates/styles.css';
import { useMediaQuery } from '@mantine/hooks';
import CalculateQuotationView from '@pages/Client/PricingStrategy/GroupDisplacement/components/CalculateQuotation/CalculateQuotationView';
import { QuotationResult } from '@pages/Client/PricingStrategy/GroupDisplacement/components/QuotationResult/QuotationResult';
import { useCalculateNewQuotation } from '@pages/Client/PricingStrategy/GroupDisplacement/hooks/useGroupDisplacement';
import useStepStore from '@pages/Client/PricingStrategy/GroupDisplacement/store/step';
import { useState } from 'react';

interface QuotationResultProps {
  isOpen: boolean;
  onClose: () => void;
}

const QuotationModal = ({ isOpen, onClose }: QuotationResultProps) => {
  const { activeStepQuotation, setActiveStepQuotation } = useStepStore();
  const { result, calculateGroupDisplacement, isLoading } = useCalculateNewQuotation();
  const [formData, setFormData] = useState<QuotationRequest | null>(null);
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints?.md})`);
  useResourceCenter({ shouldHideResourceCenter: isMobile && isOpen });

  const initialFormState: QuotationRequest = {
    group_name: '',
    group_rooms: [],
    base_rate_offset: 0,
    has_custom_number_of_rooms: false,
    length_of_stay: 0,
    start_date: ''
  };

  const onSubmit = (data: QuotationRequest) => {
    setFormData(data);
    calculateGroupDisplacement(data);
    setActiveStepQuotation(1);
  };

  const handleCloseResult = () => {
    setFormData(initialFormState);
    setActiveStepQuotation(0);
    onClose();
  };

  return (
    <Modal
      opened={isOpen}
      size={activeStepQuotation == 1 ? '2xl' : rem(700)}
      onClose={() => {
        setFormData(initialFormState);
        setActiveStepQuotation(0);
        onClose();
      }}
      styles={{
        header: {
          display: 'none'
        },
        body: { padding: 0 }
      }}
    >
      <Modal.CloseButton />

      <Stepper
        size="sm"
        iconSize={28}
        active={activeStepQuotation}
        onStepClick={setActiveStepQuotation}
        pt="xl"
        orientation={isMobile ? 'vertical' : 'horizontal'}
        styles={{
          separator: {
            maxWidth: rem(350)
          }
        }}
      >
        <Stepper.Step mx="lg" label="Group Details" description="Calculate a new quote">
          <CalculateQuotationView
            onSubmitData={onSubmit}
            initialData={formData ?? initialFormState}
          />
        </Stepper.Step>
        <Stepper.Step
          mx="lg"
          allowStepSelect={false}
          label="Displacement Results"
          description="Review the calculated quote"
          loading={isLoading}
        >
          <QuotationResult
            isLoading={isLoading}
            data={result}
            onClose={handleCloseResult}
            mode="add"
          />
        </Stepper.Step>
      </Stepper>
    </Modal>
  );
};

export default QuotationModal;
