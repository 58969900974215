import { HotelCompetitor } from '@common/api/hotel/types';
import AutoCompleteInput from '@common/components/atoms/AutoCompleteInput';
import { Badge } from '@mantine/core';
import { Button } from '@common/components/atoms/Button';
import { Divider, Skeleton } from '@mantine/core';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { useModal } from '@common/hooks/useModal';
import { useUserRole } from '@common/hooks/useUserRole';
import { useHotelStore } from '@common/store/auth';
import { browserTimezone } from '@common/utils/browserTimezone';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { debounce } from 'lodash-es';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as z from 'zod';
import { useHotelCompetitor } from '@pages/Client/Calendar/hooks/useHotelCompetitor';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { MarketIntelligenceChart } from '@pages/Client/PricingStrategy/MarketIntelligence/components/MarketIntelligenceChart';
import { MarketIntelligenceChartModal } from '@pages/Client/PricingStrategy/MarketIntelligence/components/MarketIntelligenceChartModal';
import { useCompetitorScore } from '@pages/Client/PricingStrategy/MarketIntelligence/hooks/useCompetitorScore';
import { useCompetitorSetup } from '@pages/Client/PricingStrategy/MarketIntelligence/hooks/useCompetitorSetup';
import { useSearchHotelCompetitor } from '@pages/Client/PricingStrategy/MarketIntelligence/hooks/useSearchHotelCompetitor';
import { useUpdateCompetitors } from '@pages/Client/PricingStrategy/MarketIntelligence/hooks/useUpdateCompetitor';
import { AddBookingUrlModal } from '@pages/Client/PricingStrategy/MarketIntelligence/pages/CompetitorList/components/AddBookingUrlModal';
import { CompetitorHeader } from '@pages/Client/PricingStrategy/MarketIntelligence/pages/CompetitorList/components/CompetitorHeader';
import { ChartValue } from '@pages/Client/PricingStrategy/MarketIntelligence/types/chart';

dayjs.extend(utc);
dayjs.extend(timezone);

const WEIGHT_COMPETITOR_OPTION = [0, 1, 5, 10, 20, 30, 40, 50, 60, 70, 80];

const indexCount = 20;
const schemaProperties: Record<string, any> = {};
const schemaHotelCompetitor: Record<string, any> = {};

for (let i = 1; i <= indexCount; i++) {
  const propertyName = `weight_${i}`;
  schemaProperties[propertyName] = z.number();
}
for (let i = 1; i <= indexCount; i++) {
  const propertyName = `index_${i}`;
  schemaHotelCompetitor[propertyName] = z.object({
    id: z.number().optional().nullish(),
    name: z.string().optional().nullish(),
    address: z.string().optional().nullish(),
    query: z.string().optional()
  });
}

const schema = z.object({
  ...schemaProperties,
  ...schemaHotelCompetitor
});

export const CompetitorList = () => {
  const { hotelAuthToken } = useHotelStore();
  const { hotelDetails } = useHotelDetails();
  const { isPartner: partnerUser } = useUserRole();
  const allowedPartner = [7, 9].includes(hotelDetails?.reseller?.id as number);
  const isPartner = partnerUser && !allowedPartner;

  const {
    hotelCompetitor,
    query: {
      refetch: competitorRefetch,
      isFetching: isCompetitorFetchLoading,
      isLoading: isCompetitorLoading
    }
  } = useHotelCompetitor();
  const { competitorSetup } = useCompetitorSetup();
  const { competitorScore } = useCompetitorScore();
  const {
    updateCompetitors,
    isLoading: isUpdateCompetitorLoading,
    isSuccess: isUpdateCompetitorSuccess
  } = useUpdateCompetitors(true);

  const { isOpen: isChartOpen, openModal: chartClick, closeModal: chartClose } = useModal();
  const {
    isOpen: isAddBookingOpen,
    openModal: addBookingClick,
    closeModal: addBookingClose
  } = useModal();

  const [dataChart, setDataChart] = useState<ChartValue[]>();
  const [hotelName, setHotelName] = useState('');
  const [hotelCompetitorData, setHotelCompetitorData] = useState<HotelCompetitor[]>([]);
  const [isResetCompetitorData, setIsResetCompetitorData] = useState<boolean>(true);
  const [_isChangeWeight, setIsChangeWeight] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const [query, setQuery] = useState('');

  const debouncedSearch = debounce((query) => setQuery(query), 1000);

  const handleSearch = (query: string) => {
    debouncedSearch(query);
  };

  const {
    query: { isLoading: searchLoading },
    searchCompetitorResult
  } = useSearchHotelCompetitor({
    search: query
  });

  const { control, watch, setValue } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  const HOTEL_COMPETITOR_OPTIONS = searchCompetitorResult?.results?.map(
    ({ id, name, address, distance }) => ({
      value: id,
      label: `(${distance}km)${name}`,
      address: address
    })
  );

  useEffect(() => {
    if (!isCompetitorLoading || !isCompetitorFetchLoading || isUpdateCompetitorSuccess) {
      if (isResetCompetitorData) {
        setHotelCompetitorData(hotelCompetitor.results);
      }
    }
  }, [
    isCompetitorLoading,
    isCompetitorFetchLoading,
    isResetCompetitorData,
    isUpdateCompetitorSuccess
  ]);

  const onDragEnd = (result: any) => {
    // Dropped outside the list
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;
    const reorderedItems = Array.from(hotelCompetitorData || []);
    const [removed] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, removed);

    // Create a reversed copy of WEIGHT_COMPETITOR_OPTION
    // const reversedWeights = [...WEIGHT_COMPETITOR_OPTION].reverse();

    // Directly updating the weights within the reorderedItems array
    // reorderedItems.forEach((item, index) => {
    //   if (index >= 0 && index < reversedWeights.length) {
    //     if (destination.index > source.index && index === destination.index) {
    //       // Moved down: Set the weight to the weight of the new position
    //       item.weight = reversedWeights[destination.index];
    //     } else if (destination.index < source.index && index === destination.index) {
    //       // Moved up: Set the weight to the weight of the position above
    //       item.weight = reversedWeights[destination.index];
    //     }
    //   }
    // });

    // Update the state with the reordered items
    setHotelCompetitorData(reorderedItems);
  };

  const getDataCompetitor = () => {
    const sortedDataCompetitor = hotelCompetitorData?.map((obj, index) => ({
      weight: watch(`weight_${obj.index}`) ?? 0,
      competitor: watch(`index_${obj.index}`)?.value || null,
      id: obj.id,
      index: index + 1
    }));

    const data = {
      competitors: JSON.stringify(sortedDataCompetitor),
      token: hotelAuthToken
    };
    return data;
  };

  const handleUpdateCompetitors = () => {
    setIsResetCompetitorData(true);
    // if (isChangeWeight) {
    //   setIsResetCompetitorData(true);
    // } else {
    //   setIsResetCompetitorData(false);
    // }
    updateCompetitors(getDataCompetitor());
    competitorRefetch();
  };

  useEffect(() => {
    if (hotelCompetitorData?.length > 0) {
      hotelCompetitorData?.forEach((item) => {
        setValue(`index_${item.index}`, {
          value: item?.competitor?.hotel?.id || null,
          label: item?.competitor?.hotel?.name || '',
          address: item?.competitor?.hotel?.address || ''
        });
        setValue(`weight_${item.index}`, item.weight);
      });
    }
  }, [hotelCompetitorData]);

  useEffect(() => {
    if (isUpdateCompetitorSuccess) {
      setTimeout(() => {
        setIsChangeWeight(false);
      }, 1000);
    }
  }, [isUpdateCompetitorSuccess]);

  return (
    <div className="rounded-lg bg-white p-6">
      {isPartner ? null : (
        <>
          {partnerUser ? null : <CompetitorHeader hotelCompetitor={getDataCompetitor()} />}
          <div className={`my-6 flex justify-between ${!isPartner ? 'mb-9' : ''}`}>
            <div className="flex items-start gap-2">
              {competitorSetup?.is_top_20 && <Badge color={'green.6'}>Top 20 scrape running</Badge>}
              <Typography>
                {competitorSetup?.completed_competitor}/{competitorSetup?.total_competitor}{' '}
                Competitors Scraped
              </Typography>
            </div>
            <div className=" flex justify-end gap-2">
              <Button intent="text" size="large" onClick={addBookingClick}>
                Can&apos;t find competitor?
              </Button>
              <Button onClick={handleUpdateCompetitors} isLoading={isUpdateCompetitorLoading}>
                Save All & Scrape first 10
              </Button>
            </div>
          </div>
        </>
      )}

      <Divider />
      <div className="my-4 grid grid-cols-12 items-center gap-6">
        {isPartner ? null : (
          <div className="col-span-1  text-center">
            <Typography>Order</Typography>
          </div>
        )}
        <div className="col-span-4">
          <Typography>Hotel</Typography>
        </div>
        <div className="col-span-1">
          <Typography>Weight</Typography>
        </div>
        <div className="col-span-4 text-center">
          <Typography>Graph `cleaned`</Typography>
        </div>
        {isPartner ? null : (
          <div className="col-span-2 text-center">
            <Typography>Last Scraped</Typography>
          </div>
        )}
      </div>
      <Divider />
      {isCompetitorLoading || hotelCompetitorData?.length < 0 ? (
        Array.from(Array(hotelCompetitorData?.length || 10).keys()).map((index) => {
          return (
            <div
              className="mt-2 grid grid-cols-12 items-start gap-6"
              key={`skeleton-loader-${index}`}
            >
              <div className="col-span-1 flex items-start gap-2">
                <Skeleton height={32} width={20} />
                <Skeleton height={24} width={12} />
              </div>
              <div className="col-span-4">
                <Skeleton height={40} width={192} />
              </div>
              <div className="col-span-1">
                <Skeleton height={32} width={80} />
              </div>
              <div className="col-span-4 flex justify-center">
                <Skeleton height={128} width={192} />
              </div>
              {isPartner ? null : (
                <div className="col-span-2 flex justify-center">
                  <Skeleton height={20} width={80} />
                </div>
              )}
              <div className="col-span-12">
                <Divider />
              </div>
            </div>
          );
        })
      ) : (
        <div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {hotelCompetitorData?.map((item, index) => {
                    const currentDate = dayjs();
                    const diffInDays = Math.abs(
                      dayjs(item?.competitor?.last_updated_at).diff(currentDate, 'day')
                    );

                    const hotelCompetitorId = watch(`index_${item.index}`)?.value;

                    const DATA_CHART = Object.entries(
                      competitorScore?.amalgamation_imputed_price || {}
                    ).map(([date, data]) => {
                      const value = data[hotelCompetitorId]?.cleaned
                        ? parseFloat(data[hotelCompetitorId].cleaned)
                        : 0;

                      return {
                        date: date,
                        value: isNaN(value) ? null : value
                      };
                    });

                    return (
                      <Draggable
                        isDragDisabled={isPartner}
                        key={item.index}
                        draggableId={item.index.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            className={`my-4 hover:cursor-move ${
                              snapshot.isDragging ? 'bg-lightGrey' : ''
                            }`}
                            key={item.index}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div className="grid grid-cols-12 items-start gap-6 py-2">
                              {isPartner ? null : (
                                <div className="col-span-1 flex gap-4">
                                  <Icon.Drag />
                                  <div className="flex flex-col items-center justify-center">
                                    <Typography>{index + 1}</Typography>
                                  </div>
                                </div>
                              )}

                              <div className="col-span-4">
                                <Controller
                                  control={control}
                                  name={`index_${item.index}`}
                                  render={({ field: { value, name, onChange } }) => (
                                    <AutoCompleteInput
                                      name={name}
                                      truncateLength={32}
                                      key={item.index}
                                      showHint={
                                        currentIndex === item.index && !value?.value && true
                                      }
                                      hint="Please enter at least 5 characters"
                                      options={HOTEL_COMPETITOR_OPTIONS}
                                      loadingOptions={
                                        currentIndex === item.index ? searchLoading : false
                                      }
                                      value={value}
                                      onChange={onChange}
                                      placeholder="Search for a hotel ..."
                                      showOptions={query?.length > 4 && currentIndex === item.index}
                                      query={query}
                                      onQueryChange={(value) => {
                                        setCurrentIndex(item.index);
                                        handleSearch(value);
                                      }}
                                      customOnClear
                                      onClear={() => {
                                        setQuery('');
                                        setCurrentIndex(item.index);
                                        setValue(`index_${item.index}`, {
                                          value: null,
                                          label: null,
                                          address: ''
                                        });
                                        setValue(`weight_${item.index}`, 0);
                                      }}
                                      disabled={isPartner}
                                      additionalInfo={value?.address}
                                    />
                                  )}
                                />
                              </div>
                              <div className="col-span-1">
                                <Controller
                                  control={control}
                                  name={`weight_${item.index}`}
                                  defaultValue={item.weight}
                                  render={({ field: { value, name, onChange } }) => (
                                    <SelectDropdown
                                      fullWidth
                                      name={name}
                                      key={name}
                                      disabled={isPartner}
                                      value={value}
                                      options={WEIGHT_COMPETITOR_OPTION.map((item) => {
                                        return { label: item.toString(), value: item };
                                      })}
                                      onChange={(value) => {
                                        onChange(value);
                                        setIsChangeWeight(true);
                                        setHotelCompetitorData((prevState) => {
                                          const newState = [...prevState];
                                          newState[index].weight = value;
                                          return newState;
                                        });
                                      }}
                                    />
                                  )}
                                />
                              </div>
                              <div
                                className="col-span-4 "
                                onClick={() => {
                                  setDataChart(DATA_CHART);
                                  setHotelName(item?.competitor?.hotel?.name || ' ');
                                  chartClick();
                                }}
                              >
                                <MarketIntelligenceChart data={DATA_CHART} />
                              </div>
                              {isPartner ? null : (
                                <div className="col-span-2 flex justify-center ">
                                  <div>
                                    {item?.competitor?.last_updated_at === null ||
                                    item?.competitor === null ||
                                    hotelCompetitorId === null ||
                                    hotelCompetitorId !== item?.competitor?.hotel?.id ? (
                                      <Badge variant={'light'} color={'red.6'}>
                                        ---:---:----- ---:---
                                      </Badge>
                                    ) : (
                                      <Badge
                                        variant={'light'}
                                        color={diffInDays >= 5 ? 'red.6' : 'green.6'}
                                      >
                                        {dayjs(item?.competitor?.last_updated_at)
                                          .tz(browserTimezone)
                                          .format('DD-MM-YYYY HH:mm')}
                                      </Badge>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                            <Divider />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
      <MarketIntelligenceChartModal
        data={dataChart ? dataChart : []}
        isOpen={isChartOpen}
        onClose={chartClose}
        hotelName={hotelName}
      />
      <AddBookingUrlModal isOpen={isAddBookingOpen} onClose={addBookingClose} />
    </div>
  );
};
