import { MinstaySchema, RuleSchema } from '@pages/Client/Calendar/utils/validateBaseSettings';
import { z } from 'zod';

export type MinStay = z.input<typeof MinstaySchema>;
export type MinStayRules = MinStay['rules'];
export type MinStayRule = z.input<typeof RuleSchema>;
export type ValidatedRule = z.output<typeof RuleSchema>;

export const ratePlanSchema = z.object({
  enable_rate_plan_ids: z.record(z.array(z.string())).optional(),
  disable_rate_plan_ids: z.record(z.array(z.string())).optional(),
  independent_rate_plans: z.array(z.string()).optional()
});

export type RatePlan = z.input<typeof ratePlanSchema>;
