import { buttonStyles } from '@common/components/atoms/Button';
import { Button, Checkbox, Divider } from '@mantine/core';
import { Typography } from '@common/components/foundations/Typography';
import { Icon } from '@common/components/foundations/icons';
import { LoadingCircle } from '@common/components/foundations/icons/icons/LoadingCircle';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import { cn } from '@common/utils/cn';
import { PopoverClose } from '@radix-ui/react-popover';
import { isEmpty, isEqual, truncate } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDashboardPageStore } from '@pages/Client/Dashboard/store/dashboard';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useMultiPropertyFilters } from '@pages/Client/hooks/useMultiPropertyFilters';

type SelectedFiltersType = Record<string, boolean>;

export const MultiPropertyFilters = ({
  onClose
}: {
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const {
    hotelDetails,
    query: { isLoading: isLoadingHotelDetails }
  } = useHotelDetails();
  const { propertyIds, updatePropertyIds } = useDashboardPageStore();
  const {
    dashboardHotels,
    query: { isLoading: isMultiPropertyFiltersLoading }
  } = useMultiPropertyFilters();

  const [selectedFilters, setSelectedFilters] = useState<SelectedFiltersType>({});
  const [tempFilters, setTempFilters] = useState<SelectedFiltersType>({});

  useEffect(() => {
    if (!hotelDetails?.id) return;

    if (!isEmpty(propertyIds[hotelDetails?.id])) {
      const newSelectedFilters = propertyIds?.[hotelDetails?.id].reduce((acc, id) => {
        acc[id.toString()] = true;
        return acc;
      }, {} as SelectedFiltersType);
      if (!isEqual(newSelectedFilters, selectedFilters)) {
        setSelectedFilters(newSelectedFilters);
        setTempFilters(newSelectedFilters);
      }
    } else {
      // If there is no id, set it to current hotel id
      const initialFilters = { [hotelDetails?.id]: true };
      setSelectedFilters(initialFilters);
      setTempFilters(initialFilters);
    }
  }, [hotelDetails?.id, propertyIds]);

  const areAllFiltersChecked = dashboardHotels.every((hotel) => tempFilters[hotel.id]);

  const handleSelectAllToggle = useCallback(
    (isChecked: boolean) => {
      if (!hotelDetails?.id) return;

      let newFilters: Record<string, boolean>;

      if (isChecked) {
        // If isChecked is true, select all hotels
        newFilters = dashboardHotels.reduce(
          (acc, hotel) => {
            acc[hotel.id] = true;
            return acc;
          },
          {} as Record<string, boolean>
        );
      } else {
        // If isChecked is false, only select the default hotel
        newFilters = { [hotelDetails.id]: true };
      }
      setTempFilters(newFilters);
    },
    [dashboardHotels, hotelDetails?.id]
  );

  const handleClearFilters = () => {
    if (hotelDetails?.id) {
      // As initial state, set it to current hotel id
      const clearedFilters = { [hotelDetails.id]: true };
      setTempFilters(clearedFilters);
    }
  };

  const handleSaveFilters = () => {
    setSelectedFilters(tempFilters);
    if (hotelDetails?.id) {
      const ids = Object.keys(tempFilters)
        .filter((key) => tempFilters[key])
        .map(Number);
      updatePropertyIds(hotelDetails?.id, ids);
    }
    onClose();
  };

  return (
    <div className="max-h-[600px] overflow-y-auto">
      <PopoverClose
        type="button"
        className={cn(buttonStyles({ icon: true }), 'absolute right-3 top-3 z-20')}
      >
        <Icon.Clear className="h-4 w-4" />
      </PopoverClose>

      <div className="sticky top-0 z-10 pb-1">
        <Typography className="text-[18px] font-semibold leading-6" color="darkGrey">
          {t('Property Filter')}
        </Typography>
      </div>

      {!isLoadingHotelDetails && !isMultiPropertyFiltersLoading ? (
        <div className="ml-0.5 mt-3">
          <div className="mb-2 flex items-center gap-1.5">
            <Checkbox
              label={t('Select all')}
              id={`select-all-${parent}`}
              checked={areAllFiltersChecked}
              onChange={(e) => handleSelectAllToggle(e.target.checked)}
            />
          </div>
          <Divider mb={8} />
          {dashboardHotels
            // Sort alphabetically by hotel name
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((hotel) => (
              <TooltipProvider key={hotel.id} delayDuration={75}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div className="mb-2 flex items-center gap-1.5">
                      <Checkbox
                        label={truncate(hotel?.name, { length: 40 })}
                        className={areAllFiltersChecked ? 'opacity-50 grayscale' : ''}
                        id={hotel.id.toString()}
                        checked={!!tempFilters[hotel.id] || hotelDetails?.id === hotel.id || false}
                        disabled={hotelDetails?.id === hotel.id}
                        onChange={(e) => {
                          const { checked } = e.target;
                          const hotelId = hotel.id.toString();

                          if (!checked) {
                            const selectedCount = Object.values(selectedFilters).filter(
                              (val) => val
                            ).length;
                            if (selectedCount === 1 && selectedFilters[hotelId]) {
                              // Do not unselect if it's the only selected checkbox
                              return;
                            }
                          }
                          setTempFilters({
                            ...tempFilters,
                            [hotelId]: checked
                          });
                        }}
                      />
                    </div>
                  </TooltipTrigger>
                  <TooltipContent side="bottom" className="max-w-xs" align="start" alignOffset={20}>
                    {hotel?.name}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ))}
        </div>
      ) : (
        <div className="flex items-center justify-center">
          <LoadingCircle className="mt-2" />
        </div>
      )}

      <Divider mt={'md'} />
      <div className="bg-white mt-2 shadow-md">
        <div className="flex justify-end gap-2">
          <Button variant="subtle" onClick={handleClearFilters}>
            {t('Clear all')}
          </Button>
          <Button type="submit" onClick={handleSaveFilters}>
            {t('Save')}
          </Button>
        </div>
      </div>
    </div>
  );
};
