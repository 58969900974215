import { backgroundColors, Colors } from '@common/components/foundations/colorVariants';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { useAuthStore } from '@common/store/auth';
import { cn } from '@common/utils/cn';
import { Disclosure } from '@headlessui/react';
import { Group, Text, Divider, Badge } from '@mantine/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface KPIEventProps {
  date: string;
  name: string;
  tag: string;
  attendance?: number;
  showAttendance?: boolean;
  predictedEventSpending?: number;
}

export function KPIEvent({
  date,
  name,
  tag,
  attendance,
  showAttendance,
  predictedEventSpending
}: KPIEventProps) {
  const { t } = useTranslation();
  const { user } = useAuthStore();

  const defaultLocale = 'en-US';

  function formatPrice(number: number): string {
    return new Intl.NumberFormat(defaultLocale, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    }).format(number);
  }

  return (
    <div className="relative rounded-lg border border-mediumGrey bg-white">
      <div className="p-4">
        <dl className="flex items-start justify-between">
          <div className="flex flex-col">
            <div>
              <dt className="text-meta-2 text-copyTextGrey">{date}</dt>
              <dd className="text-meta-1 text-darkGrey">{name}</dd>
            </div>
            <div>
              {showAttendance ? (
                <dd className="text-meta-2-medium text-darkGrey">
                  {t('Estimated Attendance')}:{' '}
                  {attendance?.toLocaleString(user?.language ?? defaultLocale, {
                    minimumFractionDigits: 0
                  })}
                </dd>
              ) : null}
            </div>
            <div>
              {showAttendance ? (
                <dd className="text-meta-2-medium text-darkGrey">
                  {t('Predicted Accommodation Spend')}:{' '}
                  {predictedEventSpending ? formatPrice(predictedEventSpending) : 'n.A.'}
                </dd>
              ) : null}
            </div>
          </div>

          <Badge>{tag}</Badge>
        </dl>
      </div>
    </div>
  );
}

export interface KPILargeExtraOverviewContent {
  title: string;
  subtitle: string;
}

export interface KPILargeProps {
  title?: string;
  subtitle?: string;
  isOpen?: boolean;
  headerIcon: any;
  headerIconColor: Colors | string | undefined;
  headerContent?: React.ReactNode;
  collapsible?: boolean;
  content:
    | {
        basePrice: string;
        basePriceValue: string;
        userflowId?: string;
        breakdownPrice?: {
          title: React.ReactNode;
          id: string;
          percentage: string;
          value: string;
          icon: any;
          userflowId?: string;
          isUpselling?: boolean;
          upsellingIcon?: React.ReactNode;
          upsellingHandler?: () => void;
        }[];
        prices: {
          title: string;
          id: string;
          value: string;
          userflowId?: string;
        }[];

        extra?: {
          title: string;
          id: string;
          value: string;
          userflowId?: string;
        }[];
      }
    | undefined;
}

export function KPILarge({
  title,
  subtitle,
  headerIcon,
  headerIconColor,
  headerContent,
  content,
  isOpen,
  collapsible = true
}: KPILargeProps) {
  const { t } = useTranslation();
  return (
    <Disclosure
      defaultOpen={isOpen}
      as="div"
      className="rounded-md border border-mediumGrey bg-white"
    >
      {({ open }) => (
        <>
          <Disclosure.Button
            as={!collapsible ? 'div' : undefined}
            disabled={!collapsible}
            className={cn('flex w-full items-center justify-center px-5 py-4 focus:outline-none')}
          >
            <div
              className={cn(
                'flex h-10 w-10 shrink-0 items-center justify-center self-start rounded-full',
                backgroundColors[headerIconColor as Colors] ?? headerIconColor
              )}
            >
              {headerIcon}
            </div>
            <div className="ml-4 flex w-full flex-col items-stretch">
              <div className="flex flex-col text-left">
                {title ? (
                  <Typography element="span" variant="h6" color="darkGrey">
                    {title ?? 'n.A.'}
                  </Typography>
                ) : null}
                {subtitle ? (
                  <Typography element="span" variant="meta-2" color="copyTextGrey">
                    {subtitle ?? 'n.A.'}
                  </Typography>
                ) : null}
                {headerContent}
              </div>
            </div>
            {collapsible ? (
              <div className="shrink-0">
                {open ? <Icon.Remove className="h-6 w-6" /> : <Icon.Add className="h-6 w-6" />}
              </div>
            ) : null}
          </Disclosure.Button>
          <Disclosure.Panel className="px-5 pb-6 pt-2">
            {!content ? (
              <Typography variant="meta-1" color="darkGrey">
                {t('No Pricing Data')}
              </Typography>
            ) : (
              <>
                <Divider />
                {content?.breakdownPrice && (
                  <div className="mb-4 mt-4 flex flex-col gap-3">
                    <div className="flex items-center justify-between">
                      <Typography
                        element="span"
                        variant="meta-1"
                        color="copyTextGrey"
                        userflowId={content?.userflowId}
                      >
                        {content.basePrice}
                      </Typography>
                      <Typography element="span" variant="meta-1" color="copyTextGrey">
                        {content.basePriceValue}
                      </Typography>
                    </div>
                    {content.breakdownPrice?.map((item) => (
                      <div
                        key={`${item.title}-${item.percentage}-${item.value}`}
                        className="flex items-center justify-between"
                      >
                        <Group
                          gap="xs"
                          role={item.upsellingHandler ? 'button' : undefined}
                          onClick={item.upsellingHandler}
                        >
                          <Typography
                            element="span"
                            variant="meta-1"
                            color="copyTextGrey"
                            className="truncate"
                            userflowId={item?.userflowId}
                          >
                            {item.title} {item.isUpselling ? `(${t('Disabled')})` : null}
                          </Typography>
                          {item.upsellingIcon}
                        </Group>
                        <div
                          className={cn(
                            'flex items-center justify-center gap-1 whitespace-nowrap',
                            item.isUpselling ? 'blur-sm' : null
                          )}
                        >
                          {item.isUpselling ? (
                            <Text size="sm">{t('n.A.')}</Text>
                          ) : (
                            <>
                              <div className="flex items-center justify-center">
                                {item.icon}
                                <Typography element="span" variant="meta-1" color="grey">
                                  {item.percentage}
                                </Typography>
                              </div>
                              <Typography element="span" variant="meta-1" color="copyTextGrey">
                                {item.value}
                              </Typography>
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {content?.breakdownPrice && <Divider />}
                <div className="mb-4 mt-4 flex flex-col gap-3">
                  {content?.prices.map((item) => (
                    <div
                      key={`${item.title}-${item.value}`}
                      className="flex items-center justify-between"
                    >
                      <Typography
                        element="span"
                        variant="meta-1"
                        color="copyTextGrey"
                        userflowId={item?.userflowId}
                      >
                        {item.title}
                      </Typography>
                      <Typography element="span" variant="meta-1" color="copyTextGrey">
                        {item.value}
                      </Typography>
                    </div>
                  ))}
                </div>

                {content?.extra?.length ? (
                  <>
                    <Divider />
                    <div className="mb-4 mt-4 flex flex-col gap-3">
                      {content.extra.map((item) => (
                        <div
                          key={`${item.title}-${item.value}`}
                          className="flex items-center justify-between"
                        >
                          <Typography
                            element="span"
                            variant="meta-1"
                            color="copyTextGrey"
                            userflowId={item?.userflowId}
                          >
                            {item.title}
                          </Typography>
                          <Typography element="span" variant="meta-1" color="copyTextGrey">
                            {item.value}
                          </Typography>
                        </div>
                      ))}
                    </div>
                  </>
                ) : null}
              </>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export function KPISmall({
  title,
  subtitle,
  children
}: {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  children?: React.ReactNode;
}) {
  return (
    <Disclosure
      as="div"
      className={cn(
        'rounded-md border border-mediumGrey bg-white',
        !children ? 'pointer-events-none' : null
      )}
    >
      <>
        <Disclosure.Button className="flex w-full items-center justify-center px-6 py-4 focus:outline-none">
          <div className="flex flex-col justify-center">
            <Typography element="span" variant="h6" color="darkGrey">
              {title}
            </Typography>
            <Typography element="span" variant="meta-2" color="copyTextGrey">
              {subtitle}
            </Typography>
          </div>
        </Disclosure.Button>
        {children ? (
          <Disclosure.Panel className="px-6 pb-6 pt-2">
            <Divider />
            <div className="mb-4 mt-4 flex flex-col gap-2">{children}</div>
          </Disclosure.Panel>
        ) : null}
      </>
    </Disclosure>
  );
}
