import React from 'react';
import { useAppFlags } from '@common/hooks/useAppFlags';
import DerivedRatesV1 from './v1/DerivedRatesV1';
import DerivedRatesV2 from './v2/DerivedRatesV2';
import { isNil } from 'lodash-es';

const DerivedRates: React.FC = () => {
  const { isDerivedRatesV2Enabled } = useAppFlags();

  if (isNil(isDerivedRatesV2Enabled)) {
    return null;
  }

  return isDerivedRatesV2Enabled ? <DerivedRatesV2 /> : <DerivedRatesV1 />;
};

export default DerivedRates;
