export const getStatus = (status: number): string => {
  const statusCodes: { [key: number]: string } = {
    1: 'Open',
    2: 'Closed',
    3: 'Blocked'
  };

  return statusCodes[status] || 'Unknown Status';
};
export const getBadgeColor = (status: number): string => {
  const statusCodes: { [key: number]: string } = {
    1: 'green.9',
    2: 'red.7', //'error',
    3: 'dark.9'
  };

  return statusCodes[status] || 'Unknown Status';
};
