import { ComponentProps, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { isAxiosError } from 'axios';
import { uniq } from 'lodash-es';
import { IconChartHistogram } from '@tabler/icons-react';
import { Icon } from '@common/components/foundations/icons';
import { SecureNavLink } from '@pages/Client/components/SecureNavLink';
import { cn } from '@common/utils/cn';
import styles from '@pages/Client/PriceChart/components/ChartHeader.module.css';
import { usePriceChartStore } from '@pages/Client/PriceChart/store/priceChart';
import { Typography } from '@common/components/foundations/Typography';
import { Button } from '@common/components/atoms/Button';
import { RPGPopover } from '@common/components/molecules/Popover/Popover';
import { Modal } from '@common/components/molecules/Modal';
import { DownloadCSV } from '@pages/Client/Calendar/components/DownloadCSV/DownloadCSV';
import { ConsolidatedUploadPricesButton } from '@pages/Client/Calendar/components/UploadPrices/ConsolidatedUploadPricesButton';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { Feature, useFeaturesStore } from '@common/store/features';
import { useViewStore } from '@common/store/view';
import {
  PricingOptions,
  RunPricing
} from '@pages/Client/Calendar/components/RunPricing/RunPricing';
import { API_DATE_FORMAT } from '@common/constants/date';
import { useRunPricingContext } from '@pages/Client/Calendar/hooks/useRunPricing';
import {
  BasePusherEventResponse,
  PusherEventNames,
  PusherEventResponse
} from '@common/constants/pusher';
import { useNotificationsStore } from '@common/store/notifications';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useHotelStore } from '@common/store/auth';
import { saveLastPricingUpdate } from '@common/api/hotel';
import { usePusherStore } from '@common/store/pusher';
import { usePmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { runPricingAndFetchInventoryStatus } from '@common/api/pricingAlgorithm';
import { useUserRole } from '@common/hooks/useUserRole';
import { useEnablePricing } from '@pages/Client/hooks/usePricingEnable';
import { SegmentedControl, Badge } from '@mantine/core';
import { YearlyMonthly } from '@common/types';
import { useGetDetailProviderConfig } from '@pages/Client/hooks/useProviderConfig';

export const ChartHeader = () => {
  const { t } = useTranslation();
  const { addNotification } = useNotificationsStore();
  const { view, setView, liliusInstance } = usePriceChartStore();
  const { features } = useFeaturesStore();
  const { view: sidebarView, isReadOnly } = useViewStore();
  const { cachePriceQuery, pricingSettings, pricingSettingsQuery } = useRoomPrices();
  const { data: cachePriceData } = cachePriceQuery;
  const { hasGetLatestData } = useGetDetailProviderConfig();

  const {
    runPricing,
    showRunPricingLoadingModal,
    setShowRunPricingLoadingModal,
    setProgressMessage
  } = useRunPricingContext()!;
  const { hotelAuthToken } = useHotelStore();
  const {
    hotelDetails,
    pmsChannelManagerText,
    query: { isLoading: isLoadingHotel, refetch: refetchHotelDetails }
  } = useHotelDetails();
  const { isPartner } = useUserRole();
  const { isAdmin } = useViewStore();

  const isMarketFactorVisible = isAdmin || isPartner;

  const [isEnableSuccess, setIsEnableSuccess] = useState(false);
  const {
    enablePricing,
    isLoading: isEnablePricingLoading,
    isSuccess: isPricingEnabledSuccess
  } = useEnablePricing();
  const isPricingEnabled = hotelDetails?.is_pricing_turn_on;
  const isShowTurnOnPricing = (sidebarView === 'admin' || isPartner) && !isPricingEnabled;

  const { channel, channelBind, channelUnbind } = usePusherStore();
  const { hasWebHookMessage } = usePmsProvider();

  const [showRunPricingModal, setShowRunPricingModal] = useState(false);
  const [showDownloadCSVModal, setDownloadCSVModal] = useState(false);

  const [latestRunPricingOption, setLatestRunPricingOption] = useState<PricingOptions>();

  const runPricingFormRef: ComponentProps<typeof RunPricing>['ref'] = useRef(null);
  const downloadCsvRef: ComponentProps<typeof DownloadCSV>['ref'] = useRef(null);

  // useEffect to handle success loading
  useEffect(() => {
    if (isPricingEnabledSuccess) {
      setIsEnableSuccess(true);

      const timeoutId = setTimeout(() => {
        setIsEnableSuccess(false);
      }, 2000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isPricingEnabledSuccess]);

  const initPriceChartPusher = useCallback(() => {
    channelBind(PusherEventNames.WebhookMessage, async (data: any) => {
      if (data.success) {
        const message = 'Prices Uploaded Successfully';
        addNotification('success', message);
        await cachePriceQuery.refetch();
      }
      if (!data.success) {
        const message = 'Prices update Failed';
        addNotification('fail', message);
      }
    });
    channelBind<PusherEventResponse['UploadPriceMessage']>(
      PusherEventNames.UploadPriceMessage,
      async (data) => {
        if (!data.error) {
          const message = hasWebHookMessage()
            ? 'Waiting for response'
            : 'Prices Uploaded Successfully';
          addNotification('success', message);
          await cachePriceQuery.refetch();
        } else {
          const message = data.message ?? 'Prices update Failed';
          addNotification(
            'fail',
            typeof message === 'string'
              ? message
              : message && typeof message.detail === 'string'
                ? message.detail
                : 'Something went Wrong!'
          );
        }
      }
    );
    channelBind(PusherEventNames.InventoryMessage, (data: any) => {
      if (data.error) {
        const message =
          data.message ??
          `Something went Wrong While Fetching Values from ${
            hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS'
          }`;
        addNotification('fail', message);
      }
    });
  }, [channel]);

  // Listen to Pusher events
  useEffect(() => {
    channel && initPriceChartPusher();

    return () => {
      channelUnbind(PusherEventNames.WebhookMessage);
      channelUnbind(PusherEventNames.UploadPriceMessage);
      channelUnbind(PusherEventNames.InventoryMessage);
    };
  }, [channel]);

  // Run Pricing on load if it's required in API data
  useEffect(() => {
    if (!pricingSettings || !hotelDetails) return;

    (async () => {
      const { fetch_inventory, run_pricing } = await runPricingAndFetchInventoryStatus();

      if (!run_pricing) return;

      const startDate = dayjs().utc().tz().format(API_DATE_FORMAT);
      const endDate = dayjs()
        .utc()
        .tz()
        .add(pricingSettings.features?.includes(23) ? 545 : 365, 'days')
        .format(API_DATE_FORMAT);

      if (hasGetLatestData && fetch_inventory) {
        runPricing({
          getLatestPMS: true,
          startDate,
          endDate
        });
      } else {
        runPricing({
          getLatestPMS: false,
          startDate,
          endDate,
          pricingType: hotelDetails.last_run_pricing_type
        });
      }
    })();
  }, [hotelDetails]);

  const allYears = cachePriceData?.data?.prices?.data
    ? uniq(Object.keys(cachePriceData.data.prices.data).map((date) => new Date(date).getFullYear()))
    : [];
  const minYear = allYears[0];
  const maxYear = allYears[allYears.length - 1];

  const viewingDate = liliusInstance?.viewing;
  const viewingYear = viewingDate?.getFullYear();
  const viewingMonth = viewingDate?.getMonth();

  const toPreviousMonth = () => {
    if (viewingYear && viewingMonth !== undefined) {
      const prevMonth = new Date(viewingYear, viewingMonth - 1);
      const currentMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();

      // Only proceed if the previous month is not before the current month and the year is not less than the minimum year
      if (
        !(
          prevMonth.getFullYear() < currentYear ||
          (prevMonth.getFullYear() === currentYear && prevMonth.getMonth() < currentMonth)
        ) &&
        prevMonth.getFullYear() >= minYear
      ) {
        liliusInstance?.viewPreviousMonth();
      }
    }
  };

  const toNextMonth = () => {
    if (viewingYear && viewingMonth !== undefined) {
      const nextMonth = new Date(viewingYear, viewingMonth + 1);
      if (nextMonth.getFullYear() <= maxYear) {
        liliusInstance?.viewNextMonth();
      }
    }
  };

  const toCurrentMonth = () => {
    liliusInstance?.setViewing(new Date());
  };

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  const prevButtonDisabled =
    viewingYear && viewingMonth
      ? (viewingYear <= minYear && viewingMonth === 0) ||
        (viewingYear <= currentYear && viewingMonth <= currentMonth)
      : false;

  const nextButtonDisabled = viewingYear ? viewingYear >= maxYear && viewingMonth === 11 : false;

  const today = dayjs().startOf('day');
  const limitDate = dayjs().add(365, 'day').startOf('day');
  const eighteenMonthsFromNow = dayjs().add(18, 'month').startOf('day');

  const handleTabClick = useCallback(
    (newView: 'monthly' | 'yearly') => {
      setView(newView);
    },
    [setView]
  );

  const handleDownloadCsv = () => {
    downloadCsvRef.current && downloadCsvRef.current.submitDownloadCSV();
  };

  const runPricingMessageCb = async (data: BasePusherEventResponse) => {
    // The run pricing has error at inventory message event step
    if (!showRunPricingLoadingModal) return;

    try {
      if (!data.error) {
        addNotification('success', t('Data Updated Successfully'));
        setShowRunPricingLoadingModal(false);

        await Promise.all([
          pricingSettingsQuery.refetch(),
          cachePriceQuery.refetch(),
          refetchHotelDetails()
        ]);

        if (latestRunPricingOption && hotelAuthToken) {
          saveLastPricingUpdate({
            last_run_pricing_type: `${latestRunPricingOption}`,
            token: hotelAuthToken
          });
        }
      } else {
        const message =
          data.message ??
          `${t('Something went Wrong While Fetching Values from')} ${
            hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS'
          }`;
        addNotification(
          'fail',
          typeof message === 'string'
            ? message
            : message && typeof message.detail === 'string'
              ? message.detail
              : 'Something went Wrong!'
        );
      }
    } catch (e) {
      if (isAxiosError(e) && e.message) {
        addNotification('fail', e.message);
      } else {
        addNotification(
          'fail',
          `${t('Something went Wrong While Fetching Values from')} ${pmsChannelManagerText}`
        );
      }
    } finally {
      setShowRunPricingLoadingModal(false);
    }
  };

  const inventoryMessageCb = (data: BasePusherEventResponse) => {
    // Break the run pricing process on error
    if (data.error) {
      setShowRunPricingLoadingModal(false);
    } else {
      setProgressMessage(t('Optimizing...') as string);
    }
  };

  useEffect(() => {
    channelBind(PusherEventNames.RunPricingMessage, runPricingMessageCb);
    channelBind(PusherEventNames.InventoryMessage, inventoryMessageCb);

    return () => {
      channelUnbind(PusherEventNames.RunPricingMessage, runPricingMessageCb);
      channelUnbind(PusherEventNames.InventoryMessage, inventoryMessageCb);
    };
  }, [runPricingMessageCb, inventoryMessageCb]);

  const onRunPricingClick = () => {
    setShowRunPricingModal(true);
  };

  const onRunPricingClose = async (isOk: boolean) => {
    if (!isOk) {
      setShowRunPricingModal(false);
      return;
    }

    if (!runPricingFormRef.current || !pricingSettings) return;

    const pricingType = runPricingFormRef.current.runPricingOption;
    setLatestRunPricingOption(pricingType);

    let endDate = dayjs().tz();

    // 3 months
    if (pricingType === 3) {
      endDate = endDate.add(95, 'days');
    }
    // 6 months
    else if (pricingType === 4) {
      endDate = endDate.add(6, 'months');
    } else if (pricingType === 5) {
      endDate = endDate.add(365, 'days');
    } else if (pricingType === 6) {
      endDate = endDate.add(18, 'months');
    }

    setShowRunPricingModal(false);

    const getLatestPMS = runPricingFormRef.current.getLatestPMS;
    const startDate = dayjs().tz().format(API_DATE_FORMAT);
    const formattedEndDate = endDate.format(API_DATE_FORMAT);

    await runPricing({
      getLatestPMS,
      startDate,
      endDate: formattedEndDate,
      pricingType
    });
  };

  return (
    <div className="sticky top-0 z-10 bg-appBackground bg-opacity-80 backdrop-blur">
      <div className="flex flex-col">
        <div className="mt-3 flex flex-col justify-between gap-y-4 pl-4 pr-4 md:flex-row md:pl-8 md:pr-8 min-[1755px]:pr-0">
          <div className="flex flex-1 items-center gap-x-4">
            <div className="flex items-center gap-x-1 text-grey">
              <div
                role="button"
                className={cn(
                  view === 'monthly' ? 'cursor-pointer' : 'cursor-default',
                  view === 'monthly' ? 'min-w-[130px]' : 'min-w-[320px]'
                )}
                onClick={view === 'monthly' ? toCurrentMonth : undefined}
              >
                <Typography element="div" variant="h5" className="font-medium" color="darkGrey">
                  {view === 'monthly'
                    ? dayjs(liliusInstance?.viewing).tz().format('MMM YYYY')
                    : cachePriceData?.data?.prices?.data
                      ? `${today.format('MMM YYYY')} - ${
                          !features?.includes(Feature.EighteenMonthsPricing)
                            ? limitDate.format('MMM YYYY')
                            : eighteenMonthsFromNow.format('MMM YYYY')
                        }`
                      : null}
                </Typography>
              </div>
              {view === 'monthly' ? (
                <>
                  <Button icon onClick={toPreviousMonth} disabled={prevButtonDisabled}>
                    <Icon.ChevronLeft />
                  </Button>
                  <Button
                    intent="text"
                    onClick={toCurrentMonth}
                    className="hidden whitespace-nowrap disabled:opacity-30 md:block"
                  >
                    <Typography element="span" variant="meta-1">
                      {t('Current Month')}
                    </Typography>
                  </Button>
                  <Button icon onClick={toNextMonth} disabled={nextButtonDisabled}>
                    <Icon.ChevronRight />
                  </Button>
                </>
              ) : null}
            </div>

            <SegmentedControl
              key={view}
              onChange={(value) => handleTabClick(value as 'monthly' | 'yearly')}
              data={[
                { label: t('Monthly'), value: YearlyMonthly.Monthly },
                { label: t('Yearly'), value: YearlyMonthly.Yearly }
              ]}
              value={view}
            />

            {sidebarView === 'admin' && (
              <Badge color={'yellow.6'} className="hidden md:flex">
                Admin
              </Badge>
            )}

            {isReadOnly && (
              <Badge color={'yellow.6'} className="hidden md:flex">
                Read Only
              </Badge>
            )}

            <div className="flex-1" />
          </div>

          {!isReadOnly ? (
            <div className="ml-0 flex w-full items-center justify-start gap-x-4 md:ml-6 md:justify-end">
              <div className="flex gap-x-2">
                {isShowTurnOnPricing ? (
                  <Button
                    intent="danger"
                    isLoading={isEnablePricingLoading}
                    isSuccess={isEnableSuccess}
                    disabled={isLoadingHotel}
                    onClick={() => enablePricing(true)}
                  >
                    <Icon.TurnOff className="h-4 w-4" />
                    {t('Turn On Pricing')}
                  </Button>
                ) : null}
                <div className="mx-1 hidden items-center md:flex">
                  <RPGPopover
                    className="relative flex items-center justify-self-end"
                    actionOnMouseOver
                  >
                    <RPGPopover.Button as={Button} icon>
                      <Icon.MoreVertical />
                    </RPGPopover.Button>
                    <RPGPopover.Panel className="max-w-xs px-0 pb-5 pt-4">
                      <div
                        className="mt-1.5 flex cursor-pointer items-center gap-x-3 px-6 py-0.5 hover:bg-lightGrey"
                        onClick={() => onRunPricingClick()}
                      >
                        <Icon.TrendingUp className="h-auto w-[14px]" />
                        <Typography color="darkGrey">{t('Run Pricing')}</Typography>
                      </div>
                      <div
                        className="mt-1.5 flex cursor-pointer items-center gap-x-3 px-6 py-0.5 hover:bg-lightGrey"
                        onClick={() => setDownloadCSVModal(true)}
                      >
                        <Icon.Download className="h-auto w-[14px]" />
                        <Typography color="darkGrey">{t('Download CSV')}</Typography>
                      </div>
                    </RPGPopover.Panel>
                  </RPGPopover>

                  <Modal
                    size="lg"
                    open={showDownloadCSVModal}
                    className="p-10"
                    okText={t('Download CSV') as string}
                    onClick={handleDownloadCsv}
                    onClose={() => setDownloadCSVModal(false)}
                  >
                    <DownloadCSV ref={downloadCsvRef} />
                  </Modal>

                  <Modal
                    size="lg"
                    open={showRunPricingModal}
                    className="p-10"
                    okText="Run Pricing"
                    onClose={onRunPricingClose}
                  >
                    <RunPricing ref={runPricingFormRef} />
                  </Modal>
                </div>

                <ConsolidatedUploadPricesButton />
              </div>
            </div>
          ) : null}
        </div>

        <div className={styles.tabs} data-html2canvas-ignore="true">
          <div className="flex items-center gap-x-6 pl-4 pr-4 md:pl-8 md:pr-8 min-[1755px]:pr-0">
            <SecureNavLink to="price">
              {({ isActive }) => (
                <div className={cn(styles.tab, isActive ? styles.active_tab : styles.inactive_tab)}>
                  <div className="mr-2">
                    <Icon.ChartLineUp className="inline-block h-5 w-5 align-middle" />
                  </div>
                  {t('Price Chart')}
                </div>
              )}
            </SecureNavLink>
            {isMarketFactorVisible ? (
              <SecureNavLink to="market-factor">
                {({ isActive }) => (
                  <div
                    className={cn(styles.tab, isActive ? styles.active_tab : styles.inactive_tab)}
                  >
                    <div className="mr-2">
                      <IconChartHistogram className="inline-block h-5 w-5 align-middle" />
                    </div>
                    {t('Market Factor')}
                  </div>
                )}
              </SecureNavLink>
            ) : null}
            <div className="flex-1" />
          </div>
        </div>
      </div>
    </div>
  );
};
