import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '@common/components/atoms/Input';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { validateNumeric } from '@common/utils/validateNumeric';
import { FieldError, UseFormClearErrors, UseFormSetError } from 'react-hook-form';
import { BudgetValues } from '@pages/Client/Dashboard/components/SetBudgetForm';

type SetBudgetInputProps = {
  fieldKey: string;
  value: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  currencySymbol?: string;
  isDirty?: boolean;
  disabled?: boolean;
  error?: FieldError;
  setError?: UseFormSetError<BudgetValues>;
  clearErrors?: UseFormClearErrors<BudgetValues>;
};

export const SetBudgetInput: React.FC<SetBudgetInputProps> = ({
  fieldKey,
  value,
  onChange,
  isDirty,
  disabled,
  error,
  setError,
  clearErrors
}) => {
  const { t } = useTranslation();
  const isValidNumber = validateNumeric(value);

  useEffect(() => {
    if (value && !isValidNumber) {
      setError?.(fieldKey, {
        type: 'manual',
        message: t('Please enter a valid number without commas or dots.') as string
      });
    } else {
      clearErrors?.(fieldKey);
    }
  }, [value, isValidNumber]);

  return (
    <Input
      type="text"
      inputMode="decimal"
      pattern="^\d*\.?\d*$"
      leadingAddon={CurrencyFormatter.currencySymbol()}
      background="grey"
      value={value}
      showClearButton={false}
      onChange={onChange}
      trailingAddon={isDirty ? '*' : null}
      disabled={disabled}
      helperMessage={error?.message}
      error={!!error}
    />
  );
};
