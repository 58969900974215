import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@common/components/atoms/Button';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { StepComponentProps } from '@pages/Client/PricingStrategy/RoomSetup/components/SetupRooms/AdminSetupRoomsCard';

export const RunPricingSetupStep = ({ index }: StepComponentProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id: clientId } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const runPricing = async () => {
    setIsLoading(true);
    navigate(`/client/${clientId}/calendar`);
  };

  return (
    <div className="flex items-center justify-start align-middle">
      <Button
        disabled={isLoading}
        isLoading={isLoading}
        type="button"
        onClick={runPricing}
        intent="outline"
      >
        <span>{index}.</span>
        {t(`Run Pricing`)}
      </Button>
    </div>
  );
};
