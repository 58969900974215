import { useQuotationsListStore } from '@common/store/group-displacement-list-store';
import { Input, NumberInput, Stack, Text } from '@mantine/core';
import { isEmpty, isNil } from 'lodash-es';
import { useTranslation } from 'react-i18next';

export function FilterByNrOfNights() {
  const { t } = useTranslation();
  const paramKey = 'length_of_stay';
  const { params, setParams, setFilters } = useQuotationsListStore();

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFilters({
      [paramKey]: isEmpty(event.target.value) ? undefined : Number(event.target.value)
    });
    setParams({ [paramKey]: isNil(event.target.value) ? undefined : Number(event.target.value) });
  }

  return (
    <Stack gap="0">
      <Text size="sm">{t('Nights')}</Text>
      <Input
        variant="filled"
        placeholder={String(t('All nights'))}
        value={params[paramKey] ?? ''}
        onChange={handleChange}
      />
    </Stack>
  );
}
