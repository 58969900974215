import { AccountError, ConnectionAuthInput, LoginResponse, connectSeekom } from '@common/api/auth';
import { setAuthToken as setAxiosAuthToken } from '@common/api/axiosInstance';
import { useAuthStore } from '@common/store/auth';
import { useNotificationsStore } from '@common/store/notifications';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export const useConnectSeekom = () => {
  const { t } = useTranslation();
  const addNotification = useNotificationsStore((state) => state.addNotification);
  const { setAuth } = useAuthStore();

  return useMutation<LoginResponse, AccountError, ConnectionAuthInput>({
    mutationFn: connectSeekom,
    onSuccess: async (data) => {
      if (!data.token || !data.user) {
        return;
      }

      // Set token for all axios requests
      setAxiosAuthToken(data.token);
      setAuth(data.token, data.user);

      addNotification('success', t('Connection Successful'));
    },
    onError: () => {
      addNotification('fail', t('Connection Failed'));
    }
  });
};
