import { Modal } from '@mantine/core';
import { QuotationResult } from '@pages/Client/PricingStrategy/GroupDisplacement/components/QuotationResult/QuotationResult';
import { useGetDetailResult } from '@pages/Client/PricingStrategy/GroupDisplacement/hooks/useGroupDisplacement';

interface DetailQuotationModalProps {
  isOpen: boolean;
  onClose: () => void;
  id: number | string | null;
}

const DetailQuotationModal = ({ isOpen, onClose, id }: DetailQuotationModalProps) => {
  const { detailResult, isLoading } = useGetDetailResult(id);
  return (
    <Modal
      opened={isOpen}
      onClose={() => {
        onClose();
      }}
      title={detailResult?.group_name}
      size="2xl"
      styles={{
        header: {
          alignItems: 'flex-end'
        },
        body: { padding: 0 }
      }}
    >
      <QuotationResult data={detailResult} isLoading={isLoading} onClose={onClose} mode="edit" />
    </Modal>
  );
};

export default DetailQuotationModal;
