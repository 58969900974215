import { setAuthToken } from '@common/api/axiosInstance';
import { useAuthStore, useHotelStore } from '@common/store/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDocumentTitle } from '@mantine/hooks';
import { PropertyHeader } from '@pages/Client/Account/Property/components/PropertyHeader';
import { useHotelAuth, useHotelUpdate } from '@pages/Client/Account/Property/hooks/useHotel';
import { useBookingCheckLoadingStore } from '@pages/Client/Account/Property/store/bookingCheckLoading';
import { useCheckBookingUrlStore } from '@pages/Client/Account/Property/store/bookingUrl';
import { useLoadingStore } from '@pages/Client/Account/Property/store/loading';
import { isBookingUrl } from '@common/utils/validateBookingUrl';
import { useEffect, useState } from 'react';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import * as z from 'zod';

interface PropertySetting {
  name: string;
  address: string;
  postcode: string;
  lat: string;
  lng: string;
  starts_monday: boolean;
  prefered_date_format: string;
  timezone: string;
  pms: number;
  is_send_email: boolean;
  room_apartment_space: number;
  country_id: number;
  currency_id: number;
  enc_hotel_id: string;
  property_type: number;
  business_leisure_ratio: string;
  event_types: number;
  event_radius: number;
  review: number;
  notes: string;
  booking_ref_url: string | null;
  token: string;
}

const schema = z.object({
  name: z.string().nonempty({ message: 'Please enter property name' }),
  address: z.string().nonempty({ message: 'Please enter property address' }),
  postcode: z.string().nullish(),
  lat: z.union([z.number(), z.string()]),
  lng: z.union([z.number(), z.string()]),
  starts_monday: z.boolean(),
  prefered_date_format: z.string().nonempty(),
  timezone: z.string().nonempty(),
  is_send_email: z.boolean(),
  room_apartment_space: z.number().nonnegative(),
  country_id: z.number().nonnegative(),
  currency_id: z.number().nonnegative(),
  property_type: z.string().or(z.number()).nullable(),
  business_leisure_ratio: z.string(),
  event_types: z.number().nonnegative(),
  event_radius: z.number().nonnegative(),
  review: z.number().nonnegative(),
  notes: z.string().nullish(),
  token: z.string().nonempty(),
  booking_ref_url: z
    .union([z.string(), z.null()])
    .transform((value) => value ?? '')
    .refine((value) => value === '' || isBookingUrl(value), {
      message: 'Invalid booking URL'
    })
});

export function PropertyLayout() {
  const { t } = useTranslation();
  useDocumentTitle(t('Property'));
  const { hotelAuthToken } = useHotelStore();
  const methods = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  const { isValidUrl } = useCheckBookingUrlStore();

  const { id: hotelId } = useParams();
  const { token: userToken } = useAuthStore();
  const { setLoading } = useLoadingStore();
  const { isCheckBookingLoadingState } = useBookingCheckLoadingStore();
  const [isSuccessRef, setIsSuccessRef] = useState<{ current: boolean }>({ current: false });
  const navigate = useNavigate();
  const {
    data: hotelData,
    isLoading,
    refetch: refetchHotelAuth
  } = useHotelAuth(userToken || '', hotelId || '');
  const {
    mutate: updateHotelProperty,
    isPending: updateHotelPropertyLoading,
    isSuccess: isUpdateHotelPropertySuccess
  } = useHotelUpdate(methods.getValues().token);

  const hotelDataParse = hotelData as PropertySetting;
  useEffect(() => {
    setLoading(isLoading);
    if (hotelData && !isLoading) {
      methods.reset({
        ...hotelData,
        property_type: hotelDataParse?.property_type === 0 ? '0' : hotelDataParse?.property_type
      });
    }
  }, [isLoading]);

  useEffect(() => {
    if (isUpdateHotelPropertySuccess) {
      hotelAuthToken && setAuthToken(hotelAuthToken);
      refetchHotelAuth();
    }
  }, [isUpdateHotelPropertySuccess]);

  const onUpdateHotel = async (data: any) => {
    if (data.booking_ref_url) {
      if (isValidUrl) {
        await updateHotelProperty(data);
      }
    } else {
      await updateHotelProperty(data);
    }
  };

  useEffect(() => {
    setIsSuccessRef({ current: isUpdateHotelPropertySuccess });
    if (isUpdateHotelPropertySuccess) {
      setTimeout(() => {
        setIsSuccessRef({ current: false });
      }, 2000);
    }
  }, [isUpdateHotelPropertySuccess]);

  const {
    handleSubmit,
    formState: { errors }
  } = methods;

  const PropertyTypeErrorKey = [
    'property_type',
    'business_leisure_ratio',
    'event_types',
    'booking_ref_url'
  ] as (keyof FieldErrors)[];

  const NameAndLocationErrorKey = [
    'name',
    'country_id',
    'currency_id',
    'pms',
    'postcode',
    'timezone',
    'starts_monday',
    'prefered_date_format',
    'room_apartment_space',
    'enc_hotel_id'
  ] as (keyof FieldErrors)[];

  type PropertyError = (typeof PropertyTypeErrorKey)[number];
  type NameAndLocationError = (typeof NameAndLocationErrorKey)[number];
  const hasError = (
    errors: FieldErrors,
    prop: PropertyError | NameAndLocationError
  ): prop is keyof FieldErrors => {
    return prop in errors;
  };
  const isNameAndLocationHasError = NameAndLocationErrorKey.some((prop) => hasError(errors, prop));

  const isPropertyTypeHasError = PropertyTypeErrorKey.some((prop) => hasError(errors, prop));

  useEffect(() => {
    if (errors && isPropertyTypeHasError) {
      navigate(`/client/${hotelId}/account/hotel-settings/property-type`);
    }
    if (errors && isNameAndLocationHasError) {
      navigate(`/client/${hotelId}/account/hotel-settings/name-and-location`);
    }
  }, [errors, isPropertyTypeHasError, isNameAndLocationHasError]);

  return (
    <FormProvider {...methods}>
      <div className="h-full w-full">
        <PropertyHeader
          isLoading={updateHotelPropertyLoading}
          disabled={isCheckBookingLoadingState}
          onClick={handleSubmit(onUpdateHotel)}
          isSuccess={isSuccessRef.current}
        />
        <div className="relative max-w-6xl">
          <Outlet />
        </div>
      </div>
    </FormProvider>
  );
}
