import {
  ParamsRoomType,
  ParamsRoomTypeSchema,
  UpdateRoomTypes
} from '@common/api/integration/roomTypesSchema';
import { Button } from '@common/components/atoms/Button';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Table } from '@common/components/molecules/Table';
import { pmsHasInvTypeCode } from '@common/constants/pmsList';
import { useModal } from '@common/hooks/useModal';
import { useUserRole } from '@common/hooks/useUserRole';
import { useViewStore } from '@common/store/view';
import { filterEmptyValues } from '@common/utils/filterEmptyValues';
import { zodResolver } from '@hookform/resolvers/zod';
import { ActionIcon, Center, Flex } from '@mantine/core';
import { AddNewRoomTypeModal } from '@pages/Client/Account/Integrations/components/room-types/AddNewRoomTypeModal';
import { DeleteRoomTypeModal } from '@pages/Client/Account/Integrations/components/room-types/DeleteRoomTypeModal';
import { UpdateRoomTypeModal } from '@pages/Client/Account/Integrations/components/room-types/UpdateRoomTypeModal';
import UploadRoomModal from '@pages/Client/Account/Integrations/components/UploadRoomModal';
import { usePmsRoomType } from '@pages/Client/Account/Integrations/hooks/usePmsRoomType';
import { useCreateRoomTypeStore } from '@pages/Client/Account/Integrations/store/createRoomType';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { PmsProvider, usePmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { SortingState, Updater } from '@tanstack/react-table';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Params } from 'react-router-dom';

export const RoomTypeTable = () => {
  const { hotelDetails } = useHotelDetails();
  const { isCreateLoading } = useCreateRoomTypeStore();
  const { hideMinMaxOccupancyOnRoomTypeTable } = usePmsProvider();
  const { isRpgAdmin } = useUserRole();
  const { isAdmin } = useViewStore();

  const { t } = useTranslation();
  const { isOpen: addOpen, openModal: addClick, closeModal: addClose } = useModal();
  const { isOpen: editOpen, openModal: editClick, closeModal: editClose } = useModal();
  const { isOpen: deleteOpen, openModal: deleteClick, closeModal: deleteClose } = useModal();
  const { isOpen: uploadOpen, openModal: uploadClick, closeModal: uploadClose } = useModal();

  const [selectedRoomType, setSelectedRoomType] = useState<UpdateRoomTypes>({
    id: 0,
    name: '',
    max_occupancy: 0,
    min_occupancy: 0,
    room_id: '',
    extra: '',
    default_occupancy: undefined
  });

  const [sorting, setSorting] = useState([{ id: 'id', desc: false }]);

  const defaultValues = {
    page: 1,
    ordering: undefined
  };

  const { watch, reset } = useForm<ParamsRoomType>({
    defaultValues,
    resolver: zodResolver(ParamsRoomTypeSchema)
  });

  const onResetAndSet = useCallback(
    (field: keyof Params, value: Params[typeof field]) => {
      reset(
        filterEmptyValues({
          ...defaultValues,
          [field]: value
        })
      );
    },
    [reset, filterEmptyValues, defaultValues]
  );

  const { pmsRoomTypeList, isLoading } = usePmsRoomType(
    filterEmptyValues({
      page: watch('page'),
      ordering: watch('ordering')
    })
  );

  const roomApartmentSpaceName = hotelDetails?.room_apartment_space_name;
  const pmsProviderId = hotelDetails?.pms_provider ? hotelDetails?.pms_provider : 0;

  const roomIdNameColumn = pmsHasInvTypeCode.includes(pmsProviderId)
    ? t(`${roomApartmentSpaceName} Type Code`)
    : t(`${roomApartmentSpaceName} ID`);

  useEffect(() => {
    if (deleteOpen) {
      editClose();
    }
  }, [deleteOpen]);

  const deleteColumn = {
    header: 'Actions',
    size: 50,
    accessorKey: 'id',
    cell: (row: any) => {
      return (
        <Center w="100%">
          <ActionIcon
            onClick={() => {
              setSelectedRoomType(row.row.original);
              deleteClick();
            }}
          >
            <Icon.Delete className="text-copyTextGrey" />
          </ActionIcon>
        </Center>
      );
    },
    meta: {
      showOnMobile: true
    }
  };

  const defaultOccupancy = {
    header: 'Default Occupancy',
    accessorKey: 'default_occupancy',
    cell: (row: any) => {
      return (
        <div className="flex items-center gap-3">
          <Typography element="span" color="copyTextGrey">
            {row?.getValue()}
          </Typography>
        </div>
      );
    },
    meta: {
      cellAlignment: 'right',
      showOnMobile: true
    }
  };

  const siteMinderNewColumn = [
    {
      header: 'Name',
      accessorKey: 'name',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Rate Type Code',
      accessorKey: 'extra',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    }
  ];

  const minMaxColumn = [
    {
      header: 'Min Occupancy',
      accessorKey: 'min_occupancy',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {(row?.getValue() as string) ?? '-'}
            </Typography>
          </div>
        );
      },
      meta: {
        cellAlignment: 'right',
        showOnMobile: true
      }
    },
    {
      header: 'Max Occupancy',
      accessorKey: 'max_occupancy',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {(row?.getValue() as string) ?? '-'}
            </Typography>
          </div>
        );
      },
      meta: {
        cellAlignment: 'right',
        showOnMobile: true
      }
    }
  ];

  const columns = [
    {
      header: `${roomIdNameColumn}`,
      accessorKey: 'room_id',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Name',
      accessorKey: 'name',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    ...(hideMinMaxOccupancyOnRoomTypeTable() || pmsProviderId === PmsProvider.GUESTLINE
      ? []
      : minMaxColumn),
    ...([PmsProvider.GUESTLINE, PmsProvider.PROTEL].includes(pmsProviderId)
      ? [defaultOccupancy]
      : []),
    ...(pmsProviderId === PmsProvider.PROTEL ||
    (pmsProviderId !== PmsProvider.CULTSWITCH && pmsProviderId !== PmsProvider.PROFITROOM)
      ? [deleteColumn]
      : [])
  ];

  const tableColumn = pmsProviderId === PmsProvider.SITEMINDER_NEW ? siteMinderNewColumn : columns;

  const ROWS_PER_PAGE = 10;

  const startIndex = pmsRoomTypeList?.metadata?.current
    ? (pmsRoomTypeList?.metadata?.current - 1) * ROWS_PER_PAGE + 1
    : 0;
  const endIndex =
    pmsRoomTypeList?.metadata?.current && pmsRoomTypeList?.metadata.count
      ? pmsRoomTypeList?.metadata.count
      : 0;

  const orderingLookup: Record<string, string> = {
    name: 'name',
    room_id: 'room_id',
    extra: 'extra',
    default_occupancy: 'default_occupancy',
    min_occupancy: 'min_occupancy',
    max_occupancy: 'max_occupancy'
  };

  const handleSortingChange = (updater: Updater<SortingState>) => {
    const newSorting = typeof updater === 'function' ? updater(sorting) : updater;
    const columnIndex = sorting.findIndex((s) => s.id === newSorting[0].id);

    if (columnIndex >= 0) {
      newSorting[0].desc = !sorting[columnIndex].desc;
    }

    const ordering = newSorting[0].desc
      ? `-${orderingLookup[newSorting[0].id]}`
      : orderingLookup[newSorting[0].id];

    onResetAndSet('ordering', ordering);
    setSorting(newSorting);
  };

  return (
    <div className="mt-8 max-w-4xl">
      <Table
        isFetching={isLoading}
        onClickRow={(row: any) => {
          setSelectedRoomType(row?.row.original);
          editClick();
        }}
        headerComponent={
          <div className="my-3 flex items-center justify-between">
            <Typography variant="h6" element="h6" color="darkGrey">
              {t(`${roomApartmentSpaceName} Types`)}
            </Typography>
          </div>
        }
        actionComponents={
          <Flex gap="xs">
            <Button isLoading={isCreateLoading} onClick={() => addClick()}>
              {t(`Add ${roomApartmentSpaceName} Type`)}
            </Button>
            {isRpgAdmin && isAdmin ? (
              <ActionIcon variant="filled" aria-label="Settings" onClick={() => uploadClick()}>
                <Icon.Publish className="h-5 w-5" />
              </ActionIcon>
            ) : null}
          </Flex>
        }
        skeletonCount={10}
        columns={tableColumn}
        data={pmsRoomTypeList ? pmsRoomTypeList.data : []}
        count={`${startIndex}-${endIndex}`}
        totalCount={pmsRoomTypeList?.metadata?.count}
        rowsPerPage={ROWS_PER_PAGE}
        onSortingChange={handleSortingChange}
        sorting={sorting}
        isHover
        isRowClickable
      />
      <AddNewRoomTypeModal isOpen={addOpen} onClose={addClose} />
      <DeleteRoomTypeModal
        selectedRoomType={selectedRoomType}
        isOpen={deleteOpen}
        onClose={deleteClose}
      />
      <UpdateRoomTypeModal
        selectedRoomType={selectedRoomType}
        isOpen={editOpen}
        onClose={editClose}
      />
      <UploadRoomModal isOpen={uploadOpen} onClose={uploadClose} />
    </div>
  );
};
