import { Typography } from '@common/components/foundations/Typography';
import { Header } from '@common/components/molecules/Header/Header';
import { Table } from '@common/components/molecules/Table';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import { Page } from '@common/components/organisms/Page';
import { browserTimezone } from '@common/utils/browserTimezone';
import { useDocumentTitle } from '@mantine/hooks';
import { useUsersLog } from '@pages/Client/Logs/UsersLog/hooks/useUsersLog';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import dayjs from 'dayjs';

export const UsersLog = () => {
  useDocumentTitle('Users Log');
  const { usersLog, isLoading } = useUsersLog();

  const { hotelDetails } = useHotelDetails();

  const columns = [
    {
      header: 'User Name',
      accessorKey: 'username',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Email',
      accessorKey: 'email',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Date Time',
      accessorKey: 'in_datetime',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <div className="flex flex-col">
                    <Typography element="span" variant="paragraph-3" color="copyTextGrey">
                      {dayjs(row?.getValue()).utc().format('YYYY-MM-DD HH:mm:ss [UTC]')}
                    </Typography>
                  </div>
                </TooltipTrigger>
                <TooltipContent side="bottom" sideOffset={10} className="w-full">
                  <table className="table-auto">
                    <tbody>
                      <tr>
                        <td className="pr-2">
                          <Typography element="span" variant="paragraph-3" color="white">
                            Hotel Time:
                          </Typography>
                        </td>
                        <td>
                          <Typography element="span" variant="paragraph-3" color="white">
                            {dayjs(row?.getValue())
                              .tz(hotelDetails?.timezone)
                              .format('YYYY-MM-DD HH:mm:ss')}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td className="pr-2">
                          <Typography element="span" variant="paragraph-3" color="white">
                            Browser Time:
                          </Typography>
                        </td>
                        <td>
                          <Typography element="span" variant="paragraph-3" color="white">
                            {dayjs(row?.getValue())
                              .tz(browserTimezone)
                              .format('YYYY-MM-DD HH:mm:ss')}
                          </Typography>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    }
  ];

  return (
    <Page header={<Header title="Users Log" />}>
      <Table
        skeletonCount={10}
        isFetching={isLoading}
        columns={columns}
        data={!isLoading ? usersLog.lists : []}
        isHover
      />
    </Page>
  );
};
