import { PricingSettings } from '@common/api/hotel/types';
import { Draft } from 'immer';
import { Engine, Rule } from 'json-rules-engine';
import { map, isNaN, isEmpty } from 'lodash-es';
import { ADJUSTMENT_DB } from '@pages/Client/Calendar/components/BulkEdit/types/adjustments';
import { Occupancy } from '@pages/Client/Calendar/components/BulkEdit/types/schema/occupancySchema';

/**
 * Rules for setting and deleting the occupancy_pricing key:
 *
 * Bulk edit only:
 * - Set Occupation Key:
 *   - Condition:
 *     - Bulk edit mode is enabled (isBulkEdit is true)
 *     - The item's value is not equal to its derivation
 *     - The item's value is not null
 *     - The item's value is not NaN (Not a Number)
 *   - Action:
 *     - Set the occupancy_pricing key (as specified by the `setOccupationKey` event)
 *
 * - Delete Occupation Key:
 *   - Condition:
 *     - Bulk edit mode is enabled (isBulkEdit is true)
 *     - The item's value equals its derivation
 *   - Action:
 *     - Delete the occupancy_pricing key (as specified by the `deleteOccupationKey` event)
 */

const actions = {
  setOccupationKey: 'setOccupationKey',
  deleteOccupationKey: 'deleteOccupationKey'
};

const setOccupationKey = new Rule({
  conditions: {
    all: [
      { fact: 'isBulkEdit', operator: 'equal', value: true },
      {
        fact: 'value',
        operator: 'notEqual',
        value: { fact: 'derivation', path: '$.derivation' }
      },
      { fact: 'value', operator: 'notEqual', value: null },
      { fact: 'value', operator: 'notEqual', value: undefined },
      { fact: 'value', operator: 'isNotNaNOperator', value: true }
    ]
  },
  event: { type: actions.setOccupationKey }
});

const deleteOccupationKey = new Rule({
  conditions: {
    all: [
      { fact: 'isBulkEdit', operator: 'equal', value: true },
      {
        fact: 'value',
        operator: 'equal',
        value: { fact: 'derivation', path: '$.derivation' }
      }
    ]
  },
  event: { type: actions.deleteOccupationKey }
});

export async function transformOccupancy(
  currentPricingDraft: Draft<PricingSettings>, // this is an immer draft
  formDataById: Occupancy[][],
  date: string
) {
  const engine = new Engine();
  engine.addOperator('isNotNaNOperator', (factValue) => !isNaN(factValue));
  engine.addRule(setOccupationKey);
  engine.addRule(deleteOccupationKey);

  engine.on('success', (event, almanac) => {
    (almanac.factValue('item') as Promise<Occupancy>).then((item: Occupancy) => {
      if (event.type === actions.setOccupationKey) {
        if (!currentPricingDraft.dates[date]) currentPricingDraft.dates[date] = {};
        if (!currentPricingDraft.dates[date][item.room])
          currentPricingDraft.dates[date][item.room] = {};

        currentPricingDraft.dates[date][item.room][ADJUSTMENT_DB.OCCUPANCY_KEY] = {
          ...currentPricingDraft.dates[date][item.room][ADJUSTMENT_DB.OCCUPANCY_KEY],
          [item.occupancy]: item.value as number
        };
      }
      if (event.type === actions.deleteOccupationKey) {
        delete currentPricingDraft.dates[date]?.[item.room]?.[ADJUSTMENT_DB.OCCUPANCY_KEY]?.[
          item.occupancy
        ];
      }
      if (isEmpty(currentPricingDraft.dates[date]?.[item.room]?.[ADJUSTMENT_DB.OCCUPANCY_KEY])) {
        delete currentPricingDraft.dates[date]?.[item.room]?.[ADJUSTMENT_DB.OCCUPANCY_KEY];
      }
    });
  });

  await Promise.all(
    map(formDataById, async (dataById) => {
      await Promise.all(
        map(dataById, async (item: Occupancy) => {
          await engine.run({ item, ...item });
        })
      );
    })
  );

  return;
}
