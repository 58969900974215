import { Input } from '@common/components/atoms/Input';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Header } from '@common/components/molecules/Header/Header';
import { Table } from '@common/components/molecules/Table';
import { Page } from '@common/components/organisms/Page';
import { useModal } from '@common/hooks/useModal';
import { useAuthStore } from '@common/store/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as z from 'zod';
import { AddNewAdminUserModal } from '@pages/Admin/RpgUsers/components/AddNewAdminUserModal';
import { DeleteAdminUserModal } from '@pages/Admin/RpgUsers/components/DeleteAdminUserModal';
import { UpdateAdminUserModal } from '@pages/Admin/RpgUsers/components/UpdateAdminUserModal';
import { useAdminUsersList } from '@pages/Admin/RpgUsers//hooks/useAdminUser';
import { useDeleteAdminUser } from '@pages/Admin/RpgUsers//hooks/useDeleteAdminUser';
import { useDocumentTitle } from '@mantine/hooks';
import { ActionIcon, Button, Badge } from '@mantine/core';

interface SelectedUser {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  is_sales: boolean;
  sub_account_type: number | null;
}

const schema = z.object({
  search: z.string().or(z.undefined()).optional()
});

export const RpgUsers = () => {
  useDocumentTitle('RPG Users');
  const { adminUserList, isLoading } = useAdminUsersList();
  const { deleteAdminUser } = useDeleteAdminUser();
  const { user: currentUser } = useAuthStore();
  const { isOpen: addOpen, openModal: addClick, closeModal: addClose } = useModal();
  const { isOpen: updateOpen, openModal: updateClick, closeModal: updateClose } = useModal();
  const { isOpen: deleteOpen, openModal: deleteClick, closeModal: deleteClose } = useModal();
  const [selectedUser, setSelectedUser] = useState<SelectedUser>();

  const defaultValues = {
    search: ''
  };

  const { watch, reset, control } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues
  });

  const users = adminUserList
    ?.sort((a, b) => a.first_name.localeCompare(b.first_name))
    .filter((user) => user.id !== currentUser?.id) as any;

  const searchUsers = (searchTerm: string): typeof users => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    if (searchTerm.length >= 3) {
      return users.filter(
        (user: any) =>
          user.email.toLowerCase().includes(lowerCaseSearchTerm) ||
          user.last_name.toLowerCase().includes(lowerCaseSearchTerm) ||
          user.first_name.toLowerCase().includes(lowerCaseSearchTerm)
      );
    } else {
      return users;
    }
  };

  useEffect(() => {
    if (deleteOpen) {
      updateClose();
    }
  }, [deleteOpen]);

  const columns = [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
        // cellAlignment: 'center'
      }
    },
    {
      header: 'Email',
      accessorKey: 'email',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'First Name',
      accessorKey: 'first_name',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Last Name',
      accessorKey: 'last_name',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Team',
      accessorKey: 'sub_account_type',
      cell: (row: any) => {
        return row?.getValue() === 3 || row?.getValue() === 1 ? (
          <Badge color={row?.getValue() === 3 ? 'green.9' : 'red.6'}>
            {row?.getValue() === 3 ? 'Support' : 'Sales'}
          </Badge>
        ) : (
          <span>-</span>
        );
      },
      meta: {
        showOnMobile: true
      }
    },

    {
      header: 'Delete',
      accessorKey: 'id_',
      cell: (row: any) => {
        return (
          <ActionIcon
            onClick={() => {
              setSelectedUser(row?.row?.original);
              deleteClick();
            }}
          >
            <Icon.Delete className="text-copyTextGrey" />
          </ActionIcon>
        );
      },
      meta: {
        showOnMobile: true
      }
    }
  ];

  const AdminUsersTableHeader = () => {
    return (
      <div className="flex w-full justify-between py-2">
        <Typography>Admin Users List</Typography>
      </div>
    );
  };

  return (
    <Page
      header={
        <Header
          title="Admin User List"
          actions={<Button onClick={addClick}>Add New Admin User</Button>}
        />
      }
    >
      <Table
        isHover
        isFetching={isLoading}
        headerComponent={<AdminUsersTableHeader />}
        rowsPerPage={20}
        skeletonCount={10}
        onClickRow={(row: any) => {
          setSelectedUser(row?.row?.original);
          updateClick();
        }}
        actionComponents={
          <Controller
            name="search"
            control={control}
            render={({ field: { value, name, onChange } }) => (
              <Input
                placeholder="Search user ..."
                background="grey"
                name={name}
                id={name}
                type="text"
                value={value}
                onChange={onChange}
                onClear={() => {
                  reset(defaultValues);
                }}
              />
            )}
          />
        }
        columns={columns}
        data={adminUserList ? searchUsers(watch('search') as string) : []}
      />
      <AddNewAdminUserModal onClose={addClose} isOpen={addOpen} />
      <DeleteAdminUserModal
        onClose={() => {
          setSelectedUser(undefined);
          deleteClose();
        }}
        isOpen={deleteOpen}
        user={`${selectedUser?.first_name} ${selectedUser?.last_name}`}
        onDelete={() => {
          if (selectedUser?.id) {
            deleteAdminUser(selectedUser?.id);
          }
        }}
      />
      <UpdateAdminUserModal selectedUser={selectedUser} isOpen={updateOpen} onClose={updateClose} />
    </Page>
  );
};
