import { PricingSettings } from '@common/api/hotel/types';
import { Draft } from 'immer';
import { Engine, Rule } from 'json-rules-engine';
import { map } from 'lodash-es';
import { ADJUSTMENT_DB } from '@pages/Client/Calendar/components/BulkEdit/types/adjustments';
import { Derivation } from '@pages/Client/Calendar/components/BulkEdit/types/schema/derivationSchema';

/**
 * Rules for setting and deleting the adjustment_to_reference_room key:
 *
 * Bulk edit only:
 * - Set Derivation Key:
 *   - Condition:
 *     - Bulk edit mode is enabled (isBulkEdit is true)
 *     - The value is not equal to the rounded base value
 *   - Action:
 *     - Set the adjustment_to_reference_room key (as specified by the `setDerivationKey` event)
 *
 * - Delete Derivation Key:
 *   - Condition:
 *     - Bulk edit mode is enabled (isBulkEdit is true)
 *     - The value is equal to the rounded base value
 *   - Action:
 *     - Delete the adjustment_to_reference_room key (as specified by the `deleteDerivationKey` event)
 */

const actions = {
  setDerivationKey: 'setDerivationKey',
  deleteDerivationKey: 'deleteDerivationKey'
};

const setDerivationKey = new Rule({
  conditions: {
    all: [
      { fact: 'isBulkEdit', operator: 'equal', value: true },
      { fact: 'value', operator: 'roundedNotEqual', value: { fact: 'baseValue' } }
    ]
  },
  event: { type: actions.setDerivationKey }
});

const deleteDerivationKey = new Rule({
  conditions: {
    all: [
      { fact: 'isBulkEdit', operator: 'equal', value: true },
      { fact: 'value', operator: 'roundedEqual', value: { fact: 'baseValue' } }
    ]
  },
  event: { type: actions.deleteDerivationKey }
});

export async function transformDerivation(
  currentPricingDraft: Draft<PricingSettings>, // this is an immer draft
  formDataById: Record<string, Derivation>,
  date: string
) {
  const engine = new Engine();
  engine.addOperator('roundedEqual', (f, j) => Math.round(Number(j)) === f);
  engine.addOperator('roundedNotEqual', (f, j) => Math.round(Number(j)) !== f);
  engine.addRule(setDerivationKey);
  engine.addRule(deleteDerivationKey);

  engine.on('success', (event, almanac) => {
    (almanac.factValue('id') as Promise<string>).then((id: string) => {
      if (event.type === actions.setDerivationKey) {
        if (!currentPricingDraft.dates[date]) {
          currentPricingDraft.dates[date] = {};
        }
        if (!currentPricingDraft.dates[date][id]) {
          currentPricingDraft.dates[date][id] = {};
        }

        const newValue = formDataById[id].value as number;

        if (!isNaN(newValue)) {
          currentPricingDraft.dates[date][id][ADJUSTMENT_DB.DERIVATION_KEY] = formDataById[id]
            .value as number;
        }
      }
      if (event.type === actions.deleteDerivationKey) {
        delete currentPricingDraft.dates[date]?.[id]?.[ADJUSTMENT_DB.DERIVATION_KEY];
      }
    });
  });

  await Promise.all(
    map(formDataById, async (item: Derivation) => {
      await engine.run(item);
    })
  );

  return;
}
