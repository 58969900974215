import {
  createTheme,
  CSSVariablesResolver,
  rem,
  Button,
  Textarea,
  Skeleton,
  SegmentedControl,
  Combobox,
  ComboboxOption,
  ActionIcon,
  Input,
  PasswordInput,
  NumberInput,
  ButtonCssVariables,
  PartialTransformVars,
  MultiSelect,
  Select,
  Drawer,
  Modal,
  CloseButton,
  Checkbox,
  Radio,
  Badge,
  Paper,
  TabsTab,
  Tooltip,
  VariantColorsResolver,
  defaultVariantColorsResolver,
  parseThemeColor,
  rgba,
  darken,
  PinInput,
  InputLabel,
  Text,
  InputDescription,
  List,
  ModalTitle,
  ModalBody,
  ModalContent,
  Loader,
  MantineLoaderComponent,
  ModalHeader,
  ModalCloseButton,
  Title,
  TableTh,
  TableTd,
  ThemeIcon,
  ListItem,
  MenuDropdown,
  Divider,
  Switch,
  BadgeCssVariables
} from '@mantine/core';
import { DateInput, DatePicker } from '@mantine/dates';
import classes from '@common/mantine/theme.module.css';
import { indigo, green, red, orange, gold, gray, lightGray } from '@common/mantine/colors';
import { LoadingCircle } from '@common/components/foundations/icons/icons/LoadingCircle';
import { IconCheck } from '@tabler/icons-react';

export const theme = createTheme({
  fontFamily: "'Inter', sans-serif",
  fontSizes: {
    xxs: rem(10)
  },
  headings: {
    fontFamily: 'PPNeueMontreal'
  },
  // Font smoothing is disabled because it causes issues with some fonts in the Tailwind config
  fontSmoothing: false,
  defaultRadius: 'md',
  focusClassName: classes.focus,
  radius: {
    xs: rem(4),
    sm: rem(8),
    md: rem(12),
    lg: rem(16),
    xl: rem(20)
  },
  lineHeights: {
    xxs: 'calc(12px * var(--mantine-scale))',
    xs: 'calc(16px * var(--mantine-scale))',
    sm: 'calc(20px * var(--mantine-scale))',
    md: 'calc(24px * var(--mantine-scale))',
    lg: 'calc(26px * var(--mantine-scale))',
    xl: 'calc(28px * var(--mantine-scale))'
  },
  black: '#2B2B2B',
  primaryColor: 'indigo',
  primaryShade: 4,
  colors: {
    indigo,
    orange,
    gold,
    gray,
    green,
    red,
    lightGray
  },
  autoContrast: true,
  cursorType: 'pointer',
  activeClassName: classes.active,
  components: {
    ActionIcon: ActionIcon.extend({
      defaultProps: {
        variant: 'filled',
        color: 'gray.1',
        c: 'gray.8',
        radius: 'xl',
        className: 'shadow-sm active:shadow-inner'
      }
    }),
    ThemeIcon: ThemeIcon.extend({
      defaultProps: {
        variant: 'filled',
        color: 'gray.1',
        c: 'dark',
        radius: 'xl'
      }
    }),
    Title: Title.extend({
      defaultProps: {
        fw: 500
      }
    }),
    Text: Text.extend({
      defaultProps: {
        c: 'gray.8'
      }
    }),
    InputLabel: InputLabel.extend({
      defaultProps: {
        c: 'gray.8',
        fw: 400
      }
    }),
    InputDescription: InputDescription.extend({
      defaultProps: {
        c: 'gray.8'
      }
    }),
    Input: Input.extend({
      defaultProps: {
        classNames: {
          input: classes.input
        },
        leftSectionProps: {
          className: classes.section
        },
        rightSectionProps: {
          className: classes.section
        }
      }
    }),
    PasswordInput: PasswordInput.extend({
      defaultProps: {
        classNames: {
          input: classes.input
        }
      }
    }),
    NumberInput: NumberInput.extend({
      defaultProps: {
        classNames: {
          input: classes.input,
          controls: classes.controls
        }
      }
    }),
    PinInput: PinInput.extend({
      defaultProps: {
        classNames: {
          input: classes.input
        }
      }
    }),
    TableTh: TableTh.extend({
      defaultProps: {
        fw: 500,
        c: 'gray.5',
        fz: 'xs'
      }
    }),
    TableTd: TableTd.extend({
      defaultProps: {
        fz: 'xs'
      }
    }),
    Loader: Loader.extend({
      defaultProps: {
        loaders: {
          ...Loader.defaultLoaders,
          ring: LoadingCircle as MantineLoaderComponent,
          success: IconCheck
        },
        h: 16,
        w: 16,
        c: 'inherit',
        type: 'ring'
      }
    }),
    Button: Button.extend({
      vars: () =>
        ({
          root: {
            '--button-height-xs': rem(24),
            '--button-height-sm': rem(32),
            '--button-height-md': rem(40),
            '--button-height-lg': rem(48),
            '--button-height-xl': rem(56),
            '--button-padding-x-xs': rem(8),
            '--button-padding-x-sm': rem(12),
            '--button-padding-x-md': rem(16),
            '--button-padding-x-lg': rem(24),
            '--button-padding-x-xl': rem(32),
            '--button-fz': rem(13)
          }
        }) as PartialTransformVars<ButtonCssVariables>,
      defaultProps: {
        radius: 'xl',
        classNames: {
          root: classes.button
        },
        fw: 'normal'
      }
    }),
    Textarea: Textarea.extend({
      defaultProps: {
        minRows: 3
      }
    }),
    SegmentedControl: SegmentedControl.extend({
      defaultProps: {
        radius: 'sm',
        withItemsBorders: false
      }
    }),
    Combobox: Combobox.extend({
      defaultProps: {
        shadow: 'md'
      }
    }),
    ComboboxOption: ComboboxOption.extend({
      defaultProps: {
        classNames: {
          option: classes.option
        }
      }
    }),
    MultiSelect: MultiSelect.extend({
      defaultProps: {
        classNames: {
          input: classes.input
        },
        styles: {
          pill: {
            background: 'var(--mantine-color-indigo-4)',
            color: 'white'
          }
        }
      }
    }),
    Select: Select.extend({
      defaultProps: {
        classNames: {
          input: classes.input
        }
      }
    }),
    CloseButton: CloseButton.extend({
      defaultProps: { radius: 'xl' }
    }),
    Paper: Paper.extend({
      defaultProps: {
        classNames: {
          root: classes.paper
        }
      }
    }),
    Drawer: Drawer.extend({
      defaultProps: {
        classNames: {
          header: classes.drawerHeader,
          content: classes.drawer
        },
        position: 'right',
        size: 'xl',
        closeButtonProps: {
          size: 'lg',
          className: classes.closeButton
        }
      }
    }),
    Modal: Modal.extend({
      defaultProps: {
        pos: 'relative',
        classNames: {
          header: classes.modalHeader
        },
        closeButtonProps: {
          size: 'sm',
          pos: 'absolute',
          top: 10,
          right: 10,
          className: classes.closeButton
        }
      }
    }),
    ModalHeader: ModalHeader.extend({
      defaultProps: {
        pos: 'relative',
        classNames: {
          header: classes.modalHeader
        }
      }
    }),
    ModalCloseButton: ModalCloseButton.extend({
      defaultProps: {
        size: 'sm',
        pos: 'absolute',
        top: 10,
        right: 10,
        className: classes.closeButton
      }
    }),
    Radio: Radio.extend({
      defaultProps: {
        variant: 'outline'
      }
    }),
    Checkbox: Checkbox.extend({
      defaultProps: {
        radius: 'xs',
        variant: 'filled',
        c: 'gray.8'
      }
    }),
    Switch: Switch.extend({
      defaultProps: {
        radius: 'xl'
      }
    }),
    TabsTab: TabsTab.extend({
      defaultProps: {
        classNames: {
          tab: classes.tab
        }
      }
    }),
    Badge: Badge.extend({
      vars: (_, props) =>
        ({
          root: props.variant === 'light' ? { '--badge-bd': '' } : {}
        }) as PartialTransformVars<BadgeCssVariables>,
      defaultProps: {
        radius: 'xs',
        size: 'sm',
        fz: rem(11),
        tt: 'capitalize',
        h: rem(24),
        fw: 500,
        className: 'w-fit flex-shrink-0'
      }
    }),
    Tooltip: Tooltip.extend({
      defaultProps: {
        radius: 'md',
        bg: 'dark.6',
        fz: 'xs',
        py: 1,
        transitionProps: {
          duration: 150,
          transition: 'pop'
        }
      }
    }),
    List: List.extend({
      defaultProps: {
        listStyleType: 'disc'
      }
    }),
    ListItem: ListItem.extend({
      defaultProps: {
        c: 'gray.8'
      }
    }),
    ModalContent: ModalContent.extend({
      defaultProps: {
        radius: 'xl'
      }
    }),
    ModalTitle: ModalTitle.extend({
      defaultProps: {
        fz: 'lg',
        ff: 'PPNeueMontreal',
        fw: 500
      }
    }),
    ModalBody: ModalBody.extend({
      defaultProps: {
        mih: 120
      }
    }),
    DatePicker: DatePicker.extend({
      defaultProps: {
        size: 'sm',
        classNames: {
          day: classes.datePickerDay,
          calendarHeaderLevel: classes.calendarHeaderLevel,
          calendarHeaderControl: classes.calendarHeaderControl,
          yearsListControl: classes.yearsListControl,
          monthsListControl: classes.monthListControl
        }
      }
    }),
    DateInput: DateInput.extend({
      defaultProps: {
        size: 'sm',
        c: 'gray.8',
        classNames: {
          day: classes.datePickerDay,
          calendarHeaderLevel: classes.calendarHeaderLevel,
          calendarHeaderControl: classes.calendarHeaderControl,
          yearsListControl: classes.yearsListControl,
          monthsListControl: classes.monthListControl
        }
      }
    }),
    MenuDropdown: MenuDropdown.extend({
      defaultProps: {
        className: 'border-none shadow-md'
      }
    }),
    Divider: Divider.extend({
      defaultProps: {
        color: 'gray.2'
      }
    }),
    Skeleton: Skeleton.extend({
      defaultProps: {
        className: 'after:bg-grey/30'
      }
    })
  },
  other: {
    appBackground: '#F3F5F5',
    brand: '#5B48EE'
  }
});

export const resolver: CSSVariablesResolver = (theme) => ({
  variables: {},
  light: {
    '--mantine-color-body': theme.other.appBackground
  },
  dark: {}
});

export const variantColorResolver: VariantColorsResolver = (input) => {
  const defaultResolvedColors = defaultVariantColorsResolver(input);
  const parsedColor = parseThemeColor({
    color: input.color || input.theme.primaryColor,
    theme: input.theme
  });

  if (parsedColor.isThemeColor && input.variant === 'outline') {
    return {
      ...defaultResolvedColors,
      color: 'var(--mantine-color-black)',
      hover: rgba('var(--mantine-color-black)', 0.05),
      border: `${rem(1)} solid var(--mantine-color-black)`
    };
  }

  // Completely override variant
  if (input.variant === 'light') {
    return {
      background: rgba(parsedColor.value, 0.1),
      hover: rgba(parsedColor.value, 0.15),
      border: `${rem(1)} solid ${parsedColor.value}`,
      color: darken(parsedColor.value, 0.1)
    };
  }

  // Add new variants support
  if (input.variant === 'danger') {
    return {
      background: 'var(--mantine-color-red-9)',
      hover: 'var(--mantine-color-red-8)',
      color: 'var(--mantine-color-white)',
      border: 'none'
    };
  }

  return defaultResolvedColors;
};
