import {
  AcademySignupPayload,
  AdminUserResponse,
  AssignedPayload,
  ChangePassword,
  ChangePasswordPayload,
  ClientAdminList,
  ClientManagerList,
  ClientRegisterPayload,
  ClientStaffList,
  CreateAdminUserPayload,
  DealOwner,
  DeletePartnerUserPayload,
  EditAdminUserPayload,
  HotelAccount,
  HotelUsers,
  InvitationUserDataPayload,
  InvitationUserResponse,
  InviteUser,
  OwnerDetail,
  OwnerUpdate,
  PartnerPayload,
  PartnerUserPayload,
  ResellerMember,
  ResellerMemberListResponse,
  ResellerResponse,
  Sales,
  SearchClientResponse,
  SupportTeamResponse,
  UpdatePartnerUserPayload,
  UpdateProfile,
  UpdateUserPayload,
  UpdateUserResponse,
  UTCTime
} from '@common/api/account/types';
import { ErrorResponse } from '@common/api/auth';
import { axiosInstance } from '@common/api/axiosInstance';
import { Reseller } from '@common/api/hotel/types';
import axios, { AxiosError } from 'axios';

export const getCurrentUtcTime = async () => {
  try {
    const { data } = await axiosInstance.get<UTCTime>('/account/utc-time/');
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getAccountHotel = async (id: number) => {
  try {
    const { data } = await axiosInstance.post<HotelAccount>(`/account/auth/hotel/${id}/`);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const hotelTracktime = async (payload: HotelAccount) => {
  try {
    const { data } = await axiosInstance.post<HotelAccount>('/account/hotel/tracktime/', payload);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

// CRUD-OPERATION ADMIN

export const assignAdmin = async (data: AssignedPayload, token: string) => {
  try {
    const { data: response } = await axiosInstance.post('/account/client/admin/assignment/', data, {
      headers: {
        Authorization: `Token ${token}` // Pass token directly in the headers
      }
    });
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const deleteAssignAdmin = async (assignId: number, token: string) => {
  try {
    const { data } = await axiosInstance.delete(`/account/client/admin/assignment/${assignId}/`, {
      headers: {
        Authorization: `Token ${token}` // Pass token directly in the headers
      }
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getClientAdminList = async () => {
  try {
    const { data } = await axiosInstance.get<ClientAdminList>('/account/client/admin/assignment/');
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const updateAdmin = async (id: number | undefined, data: UpdateUserPayload) => {
  try {
    const { data: response } = await axiosInstance.patch<UpdateUserResponse>(
      `/account/client/admin/update/${id}/`,
      data
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const deleteAdmin = async (id: number) => {
  try {
    const { data } = await axiosInstance.delete(`/account/client/admin/delete/${id}/`);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

// CRUD-OPERATION STAFF

export const assignStaff = async (data: AssignedPayload, token: string) => {
  try {
    const { data: response } = await axiosInstance.post('/account/client/staff/assignment/', data, {
      headers: {
        Authorization: `Token ${token}` // Pass token directly in the headers
      }
    });
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const deleteAssignStaff = async (assignId: number, token: string) => {
  try {
    const { data } = await axiosInstance.delete(`/account/client/staff/assignment/${assignId}/`, {
      headers: {
        Authorization: `Token ${token}` // Pass token directly in the headers
      }
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getClientStaffList = async () => {
  try {
    const { data } = await axiosInstance.get<ClientStaffList>('/account/client/staff/assignment/');
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const updateStaff = async (id: number | undefined, data: UpdateUserPayload) => {
  try {
    const { data: response } = await axiosInstance.patch<UpdateUserResponse>(
      `/account/client/staff/update/${id}/`,
      data
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const deleteStaff = async (id: number) => {
  try {
    const { data } = await axiosInstance.delete(`/account/client/staff/delete/${id}/`);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

// CRUD-OPERATION Manager

export const assignManager = async (data: AssignedPayload, token: string) => {
  try {
    const { data: response } = await axiosInstance.post(
      '/account/client/manager/assignment/',
      data,
      {
        headers: {
          Authorization: `Token ${token}` // Pass token directly in the headers
        }
      }
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const deleteAssignManager = async (assignId: number, token: string) => {
  try {
    const { data } = await axiosInstance.delete(`/account/client/manager/assignment/${assignId}/`, {
      headers: {
        Authorization: `Token ${token}` // Pass token directly in the headers
      }
    });

    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getClientManagerList = async () => {
  try {
    const { data } = await axiosInstance.get<ClientManagerList>(
      '/account/client/manager/assignment/'
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const updateManager = async (id: number | undefined, data: UpdateUserPayload) => {
  try {
    const { data: response } = await axiosInstance.patch<UpdateUserResponse>(
      `/account/client/manager/update/${id}/`,
      data
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const deleteManager = async (id: number) => {
  try {
    const { data } = await axiosInstance.delete(`/account/client/manager/delete/${id}/`);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

// Owner
export const getOwnerDetails = async () => {
  try {
    const { data } = await axiosInstance.get<OwnerDetail>('/account/owner/detail/');
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const updateOwner = async (data: OwnerUpdate) => {
  try {
    const { data: response } = await axiosInstance.patch<OwnerUpdate>(
      '/account/owner/update/',
      data
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<OwnerDetail>;
    }
    throw error as ErrorResponse;
  }
};

export const getPendingInvites = async () => {
  try {
    const { data } = await axiosInstance.get('/account/pending-invited-users-lists/');
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const inviteUser = async (user: InviteUser) => {
  try {
    const { data } = await axiosInstance.post('/account/invited-user-detail/', user);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getDealOwnerList = async () => {
  try {
    const { data } = await axiosInstance.post<DealOwner[]>(`/account/dealowner/list/`);

    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

// Profile

export const updateProfile = async (data: UpdateProfile) => {
  try {
    const response = await axiosInstance.patch('/account/update/', data);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const resetPassword = async (data: ChangePassword) => {
  try {
    const response = await axiosInstance.post('/account/password/reset/', data);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getResellerList = async () => {
  try {
    const { data } = await axiosInstance.get<Reseller[]>('/account/reseller/all/');
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
  }
};

export const getSalesList = async () => {
  try {
    const { data } = await axiosInstance.get<Sales[]>('/account/sales/list/');
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
  }
};

export const getResellerMember = async () => {
  try {
    const { data } = await axiosInstance.get<ResellerMember>('/account/reseller/member/');
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
  }
};

export const getUsersLog = async () => {
  try {
    const { data } = await axiosInstance.get('/account/hotel/gettracktime/');
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
  }
};

export const changePassword = async (payload: ChangePasswordPayload) => {
  try {
    const { data } = await axiosInstance.post('/account/admin/password/reset/', payload);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
  }
};

export const searchClient = async (search?: string) => {
  try {
    const response = await axiosInstance.get<SearchClientResponse>('/account/client/list/', {
      params: {
        search
      }
    });
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const createNewUser = async (payload: ClientRegisterPayload) => {
  try {
    const { data } = await axiosInstance.post('/account/client/register/', payload);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
  }
};

export const getAdminUserList = async () => {
  try {
    const { data } = await axiosInstance.get<AdminUserResponse[]>('/account/admin/list/');
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
  }
};

export const getDataInvitationUser = async (email_token: string) => {
  try {
    const { data } = await axiosInstance.get<InvitationUserResponse>(
      `/account/verify-invited-user/`,
      {
        params: {
          email_token
        }
      }
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
  }
};

export const createUserByInvitation = async (
  email_token: string,
  payload: InvitationUserDataPayload
) => {
  try {
    const { data } = await axiosInstance.post(`/account/create-invited-user/`, payload, {
      params: {
        email_token
      }
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
  }
};

export const getPartnerResellerList = async (page: number, search?: string) => {
  try {
    const { data } = await axiosInstance.get<ResellerResponse>(`/account/reseller/list/`, {
      params: {
        page,
        search
      }
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
  }
};

export const getPartnerUsersList = async (page: number, search?: string) => {
  try {
    const { data } = await axiosInstance.get<ResellerMemberListResponse>(
      `/account/reseller/member/list/`,
      {
        params: {
          page,
          search
        }
      }
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
  }
};

export const createAdminUser = async (payload: CreateAdminUserPayload) => {
  try {
    const { data } = await axiosInstance.post(`/account/admin/register/`, payload);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
  }
};

export const updateAdminUser = async (payload: EditAdminUserPayload, id: number) => {
  try {
    const { data } = await axiosInstance.patch(`/account/admin/update/${id}/`, payload);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
  }
};

export const deleteAdminUser = async (payload: number) => {
  try {
    const { data } = await axiosInstance.delete(`/account/admin/delete/${payload}/`);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
  }
};

export const createPartner = async (payload: PartnerPayload) => {
  try {
    const { data } = await axiosInstance.post(`/account/reseller/register/`, payload);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
  }
};

export const updatePartner = async (payload: PartnerPayload, id: number) => {
  try {
    const { data } = await axiosInstance.patch(`/account/reseller/update/${id}/`, payload);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
  }
};

export const deletePartner = async (payload: number) => {
  try {
    const { data } = await axiosInstance.delete(`/account/reseller/delete/${payload}/`);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
  }
};

export const createPartnerUser = async (payload: PartnerUserPayload) => {
  try {
    const { data } = await axiosInstance.post(`/account/reseller/member/`, payload);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
  }
};

export const updatePartnerUser = async (payload: UpdatePartnerUserPayload, id: number) => {
  try {
    const { data } = await axiosInstance.patch(`/account/reseller/member/update/${id}/`, payload);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
  }
};

export const deletePartnerUser = async (payload: DeletePartnerUserPayload) => {
  try {
    const { data } = await axiosInstance.patch(`/account/reseller/member/`, payload);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
  }
};

export const academySignup = async (payload: AcademySignupPayload) => {
  try {
    const { data } = await axiosInstance.post(`/hotel/academy/create/`, payload);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
  }
};

export const getResetPasswordSession = async (token: string | undefined) => {
  try {
    const { data } = await axiosInstance.get(`/account/password/recovery/session/?token=${token}`);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
  }
};

export const addResetPassword = async (payload: {
  token: string | null | undefined;
  new_password: string;
}) => {
  try {
    const { data } = await axiosInstance.post(`/account/password/recovery/session/`, payload);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
  }
};

export const getHotelUsers = async () => {
  try {
    const { data } = await axiosInstance.get<HotelUsers[]>('/account/client/hotel-users/');
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
  }
};

export const getUserDetail = async () => {
  try {
    const { data } = await axiosInstance.get('/account/users/me/');
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
  }
};

export const getSupportTeam = async (page: number) => {
  try {
    const response = await axiosInstance.get<SupportTeamResponse>(
      `/account/admin-users/support-team/?page=${page}`
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};
