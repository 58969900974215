import { AcademyLogo } from '@common/assets/images';
import { Button } from '@common/components/atoms/Button';
import { Checkbox, Divider } from '@mantine/core';
import { Input } from '@common/components/atoms/Input';
import { RadioGroup } from '@common/components/atoms/RadioGroup';
import { Typography } from '@common/components/foundations/Typography';
import { Icon } from '@common/components/foundations/icons';
import { Card } from '@common/components/molecules/Card';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import ReCAPTCHA from 'react-google-recaptcha';
import { getEnvVar } from '@common/utils/env';
import { AcademySignupPayload, AcademySignupResponse } from '@common/api/account/types';
import { AccountError } from '@common/api/auth';
import { academySignup } from '@common/api/account';
import { useWarnings } from '@common/store/warnings';
import { useMutation } from '@tanstack/react-query';

const validationSchema = z.object({
  firstName: z.string().nonempty(),
  lastName: z.string().nonempty(),
  email: z.string().email(),
  propertyName: z.string().nonempty(),
  pms: z.string().optional(),
  channelManager: z.string().optional(),
  background: z.string().nonempty(),
  preferredCourseLanguage: z.string().nonempty(),
  consent: z.boolean(),
  recaptcha: z.string().nonempty()
});

export const AcademySignup = () => {
  const { createWarning } = useWarnings();
  const { handleSubmit, control, formState } = useForm<z.infer<typeof validationSchema>>({
    resolver: zodResolver(validationSchema)
  });

  const onSubmit = async (data: z.infer<typeof validationSchema>) => {
    mutateAsync({
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      contact_company_name: data.propertyName,
      pms: data.pms ?? null,
      channel_manager: data.channelManager ?? null,
      background: data.background,
      language: data.preferredCourseLanguage,
      lead_source: 'RoomPriceGenie Website',
      captcha_token: data.recaptcha
    });
  };

  const { mutateAsync, isPending: isLoading } = useMutation<
    AcademySignupResponse,
    AccountError,
    AcademySignupPayload
  >({
    mutationFn: academySignup,
    onSuccess: () => {
      createWarning({
        message:
          'We have just created your RoomPriceGenie Academy account. You will will receive an email with your login details.',
        type: 'success',
        dismissLabel: 'Close',
        showIgnoreLabel: false
      })
        .then(() => {
          window.location.reload();
        })
        .catch(() => {
          window.location.reload();
        });
    },
    onError: () => {
      createWarning({
        message:
          "We're sorry, an error occured while creating your Academy account. Please contact our RoomPriceGenie support using the chat icon on the bottom right.",
        type: 'error',
        dismissLabel: 'Close',
        showIgnoreLabel: false
      })
        .then(() => {
          window.location.reload();
        })
        .catch(() => {
          window.location.reload();
        });
    }
  });

  return (
    <div>
      <header className="flex h-20 items-center justify-center p-2">
        <div className="h-auto max-w-sm">
          <img alt="RoomPriceGenie Academy Logo" src={AcademyLogo} />
        </div>
      </header>
      <main className="mx-auto grid w-full max-w-6xl grid-cols-2 gap-4 pb-10 pt-20">
        <div className="col-span-2 p-4 md:col-span-1">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card backgroundColor="white">
              <Typography
                element="h2"
                variant="h5"
                className="text-center font-medium"
                color="darkGrey"
              >
                Create Academy Account
              </Typography>
              <div className="mt-10 flex flex-col gap-4">
                <Controller
                  control={control}
                  name="firstName"
                  render={({ field, fieldState: { error, invalid } }) => (
                    <Input
                      {...field}
                      background="grey"
                      label="First Name *"
                      type="text"
                      showClearButton={false}
                      helperMessage={error?.message}
                      error={invalid}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="lastName"
                  render={({ field, fieldState: { error, invalid } }) => (
                    <Input
                      {...field}
                      background="grey"
                      label="Last Name *"
                      type="text"
                      showClearButton={false}
                      helperMessage={error?.message}
                      error={invalid}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="email"
                  render={({ field, fieldState: { error, invalid } }) => (
                    <Input
                      {...field}
                      background="grey"
                      label="Email *"
                      type="text"
                      showClearButton={false}
                      helperMessage={error?.message}
                      error={invalid}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="propertyName"
                  render={({ field, fieldState: { error, invalid } }) => (
                    <Input
                      {...field}
                      background="grey"
                      label="Property / Company Name *"
                      type="text"
                      showClearButton={false}
                      helperMessage={error?.message}
                      error={invalid}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="pms"
                  render={({ field }) => (
                    <Input
                      {...field}
                      background="grey"
                      label="PMS (if applicable)"
                      type="text"
                      showClearButton={false}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="channelManager"
                  render={({ field }) => (
                    <Input
                      {...field}
                      background="grey"
                      label="Channel Manager (if applicable)"
                      type="text"
                      showClearButton={false}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="background"
                  render={({ field }) => (
                    <div className="flex flex-col gap-2">
                      <label className="block text-meta-1 text-grey">
                        Preferred Course Language *
                      </label>
                      <RadioGroup
                        {...field}
                        options={[
                          {
                            label: 'Hotel, Serviced Apartments, Camping/RV, B&B',
                            value: 'Hotelier'
                          },
                          {
                            label: 'Consultancy',
                            value: 'Consultant'
                          },
                          {
                            label: 'Academic Institution',
                            value: 'Academic Institution'
                          },
                          {
                            label: 'Other',
                            value: 'Other'
                          }
                        ]}
                      />
                    </div>
                  )}
                />

                <Controller
                  control={control}
                  name="preferredCourseLanguage"
                  defaultValue="en"
                  render={({ field }) => (
                    <div className="flex flex-col gap-2">
                      <label className="block text-meta-1 text-grey">
                        Preferred Course Language *
                      </label>
                      <RadioGroup
                        {...field}
                        options={[
                          {
                            label: 'English',
                            value: 'en'
                          },
                          {
                            label: 'German',
                            value: 'de'
                          }
                        ]}
                      />
                    </div>
                  )}
                />

                <Controller
                  control={control}
                  name="consent"
                  render={({ field: { value, name, onChange } }) => (
                    <Checkbox
                      label={
                        <>
                          I consent to allow RoomPriceGenie to store and process the personal
                          information submitted above to provide you the content requested. I accept
                          the{' '}
                          <a
                            href="https://roompricegenie.com/terms-and-conditions/"
                            target="_blank"
                            className="text-indigo"
                            rel="noreferrer"
                          >
                            terms and conditions
                          </a>{' '}
                          and{' '}
                          <a
                            href="https://roompricegenie.com/privacy-policy/"
                            target="_blank"
                            rel="noreferrer"
                            className="text-indigo"
                          >
                            privacy policy
                          </a>
                          .*
                        </>
                      }
                      checked={value}
                      onChange={onChange}
                      name={name}
                    />
                  )}
                />
              </div>
              <div className="mt-4">
                <Controller
                  control={control}
                  name="recaptcha"
                  render={({ field: { onChange } }) => (
                    <ReCAPTCHA sitekey={getEnvVar('VITE_GOOGLERECAPTCHAKEY')} onChange={onChange} />
                  )}
                />
              </div>
              <div className="mt-10">
                <Button type="submit" isLoading={isLoading} disabled={!formState.isValid}>
                  Create Account
                </Button>
              </div>
            </Card>
          </form>
        </div>
        <div className="col-span-2 mt-5 p-4 md:col-span-1">
          <div className="flex flex-col gap-4">
            <Typography element="h2" variant="h5" className="font-medium" color="darkGrey">
              Achieve your goals with
              <br />
              RoomPriceGenie Academy
            </Typography>
            <div>
              <Typography element="p">
                Start our Hotel Digitalization Learning Track today
              </Typography>
              <ul className="mt-4 flex flex-col gap-2">
                <li className="flex items-start">
                  <Icon.CheckList className="mr-2 mt-1 h-4 w-4 shrink-0 rounded-full bg-indigo text-white" />
                  <Typography>
                    Full access to courses about Revenue Management, Hotel Marketing. Cloud
                    Technology and more
                  </Typography>
                </li>
                <li className="flex items-start">
                  <Icon.CheckList className="mr-2 mt-1 h-4 w-4 shrink-0 rounded-full bg-indigo text-white" />
                  <Typography>Course Certificates</Typography>
                </li>
                <li className="flex items-start">
                  <Icon.CheckList className="mr-2 mt-1 h-4 w-4 shrink-0 rounded-full bg-indigo text-white" />
                  <Typography>
                    Take your business to the next level by implementing the suggestions from the
                    Check List
                  </Typography>
                </li>
                <li className="flex items-start">
                  <Icon.CheckList className="mr-2 mt-1 h-4 w-4 shrink-0 rounded-full bg-indigo text-white" />
                  <Typography>
                    Get started with the first steps of manual Revenue Management today and see the
                    effects yourself
                  </Typography>
                </li>
              </ul>
            </div>
          </div>
          <Divider my={40} />
          <div className="flex flex-col gap-4">
            <Typography element="h2" variant="h5" className="font-medium" color="darkGrey">
              You are already a RoomPriceGenie client?
            </Typography>
            <Typography element="p">
              Click below to sign in with your RoomPriceGenie Account
            </Typography>
            <div>
              <a
                href="https://academy.roompricegenie.com/login/index.php?roompricegenie_client"
                target="_blank"
                rel="noreferrer"
              >
                <Button type="button">Sign in with RoomPriceGenie</Button>
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
