import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface AuthStore {
  ssoToken: string | null;
  setSsoToken: (token: string | null) => void;
  isTriggerFromIntegration: boolean;
  setIsTriggerFromIntegration: (value: boolean) => void;
}

export const useGetSSOToken = create<AuthStore>()(
  persist(
    (set) => ({
      ssoToken: null,
      setSsoToken: (token) => set({ ssoToken: token }),
      isTriggerFromIntegration: false,
      setIsTriggerFromIntegration: (value) => set({ isTriggerFromIntegration: value })
    }),
    {
      name: 'sso-state',
      partialize: (state) => ({ isTriggerFromIntegration: state.isTriggerFromIntegration }),
      getStorage: () => localStorage
    }
  )
);
