import { useQuotationsListStore } from '@common/store/group-displacement-list-store';
import { Input, Stack, Text } from '@mantine/core';
import { isNil } from 'lodash-es';
import { useTranslation } from 'react-i18next';

export function FilterByName() {
  const { t } = useTranslation();
  const paramKey = 'name';
  const { params, setParams, setFilters } = useQuotationsListStore();

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFilters({
      [paramKey]: isNil(event.target.value) ? undefined : event.target.value
    });
    setParams({ [paramKey]: isNil(event.target.value) ? undefined : event.target.value });
  }

  return (
    <Stack gap="0">
      <Text size="sm">{t('Group Name')}</Text>
      <Input
        variant="filled"
        placeholder={String(t('All names'))}
        value={params[paramKey] ?? ''}
        onChange={handleChange}
      />
    </Stack>
  );
}
