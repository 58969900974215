import {
  createRatePlans,
  deleteRatePlans,
  getRatePlans,
  updateRatePlans
} from '@common/api/integration/internal';
import {
  CreateRatePlans,
  ParamsRatePlan,
  RatePlansResponse,
  UpdateRatePlans
} from '@common/api/integration/ratePlansSchema';
import { useNotificationsStore } from '@common/store/notifications';
import { RoomsQueryKeys } from '@common/types/query-keys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const usePmsRoomRate = ({ ordering, page }: ParamsRatePlan) => {
  const query = useQuery({
    queryKey: [RoomsQueryKeys.PMS_ROOM_RATE_LIST, ordering, page],
    queryFn: () => getRatePlans({ ordering, page })
  });

  return {
    pmsRoomRateList: query.data as RatePlansResponse,
    isLoading: query.isLoading,
    error: query.error
  };
};

export const useCreatePmsRoomRate = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();
  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (payload: CreateRatePlans) => {
      return createRatePlans(payload);
    },
    onSuccess: () => {
      addNotification('success', 'Create Room Rate Successfully');
      queryClient.invalidateQueries({ queryKey: [RoomsQueryKeys.PMS_ROOM_RATE_LIST] });
    },
    onError: () => {
      addNotification('fail', 'Failed to Create Room Rate');
    }
  });

  return {
    createRoomRate: mutate,
    isLoading,
    isSuccess
  };
};

export const useUpdatePmsRoomRate = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();
  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (payload: UpdateRatePlans) => {
      return updateRatePlans(payload);
    },
    onSuccess: () => {
      addNotification('success', 'Room Rate Updated Successfully');
      queryClient.invalidateQueries({ queryKey: [RoomsQueryKeys.PMS_ROOM_RATE_LIST] });
    },
    onError: () => {
      addNotification('fail', 'Failed to Update Room Rate');
    }
  });

  return {
    updateRoomRate: mutate,
    isLoading,
    isSuccess
  };
};

export const useDeletePmsRoomRate = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();
  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (id: number) => {
      return deleteRatePlans(id);
    },
    onSuccess: () => {
      addNotification('success', 'Delete  Room Rate Successfully');
      queryClient.invalidateQueries({ queryKey: [RoomsQueryKeys.PMS_ROOM_RATE_LIST] });
    },
    onError: () => {
      addNotification('fail', 'Failed to Delete Room Rate');
    }
  });

  return {
    deleteRoomRate: mutate,
    isLoading,
    isSuccess
  };
};
