import { Header } from '@common/components/molecules/Header/Header';
import { Page } from '@common/components/organisms/Page';
import { Feature, useFeaturesStore } from '@common/store/features';
import { Button, SegmentedControl } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useGetHotelPmsList } from '@pages/Client/Features/hooks/useGetHotelPmsList';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { usePmsProvider } from '@pages/Client/hooks/usePmsProvider';
import SetTypeofMinstayModal from '@pages/Client/PricingStrategy/MinStay/components/SetTypeofMinstayModal';
import { useMinStayBanner } from '@pages/Client/PricingStrategy/MinStay/hooks/useMinstayBanner';
import useSaveStayTypeStore from '@pages/Client/PricingStrategy/MinStay/store/changeTypeStore';
import { useFormSubmitStore } from '@pages/Client/PricingStrategy/MinStay/store/formSubmitStore';
import { isEqual } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

export const MinStay = () => {
  const { t } = useTranslation();
  const { hotelDetails } = useHotelDetails();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const triggerSubmit = useFormSubmitStore((state) => state.triggerSubmit);
  const isSaving = useFormSubmitStore((state) => state.isSaving);
  const { features, hasMinStayFeature } = useFeaturesStore();
  const { hasMinStayMultipleRates } = usePmsProvider();
  const [opened, { open, close }] = useDisclosure(false);
  const { isSupportArivalAndStayThrough } = usePmsProvider();
  const [activeTabs, setActiveTabs] = useState<'stay_through' | 'stay_arrival'>('stay_through');
  const { isSave, isSaveLoading, setIsSave } = useSaveStayTypeStore();

  const {
    pmsList,
    query: { isFetching: isPmsListLoading }
  } = useGetHotelPmsList();

  const connectedPms = pmsList?.find((pms) => pms.primary);

  useEffect(() => {
    if (connectedPms?.configurations?.min_stay_type) {
      setActiveTabs(connectedPms?.configurations?.min_stay_type);
    }
  }, [connectedPms]);

  useEffect(() => {
    if (!opened && !isSave) {
      setActiveTabs(connectedPms?.configurations?.min_stay_type);
    }
  }, [opened, isSave]);

  useEffect(() => {
    if (!hotelDetails) return;
    if (
      features?.includes(Feature.LimitedMinimumStayRestrictions) &&
      !features?.includes(Feature.MinimumStayRestrictions) &&
      pathname.includes('removal')
    ) {
      navigate(`/client/${hotelDetails.id}/min-stay/orphan-gap-correction`, { replace: true });
    }
  }, [features, hotelDetails, pathname, navigate]);

  // Get the banner descriptions
  useMinStayBanner();

  const subMenuLink = [
    ...(features?.includes(Feature.MinimumStayRestrictions)
      ? [
          {
            id: 'automatic-removal',
            label: t('Automatic Removal'),
            to: 'removal'
          }
        ]
      : []),
    ...(hasMinStayFeature()
      ? [
          {
            id: 'orphan-gap-correction',
            label: t('Orphan Gap Correction'),
            to: 'orphan-gap-correction'
          }
        ]
      : []),
    ...(hasMinStayMultipleRates() && features?.includes(Feature.LimitedMinimumStayRestrictions)
      ? [
          {
            id: 'multiple-rate-plans',
            label: t('Restrictions on Rates'),
            to: 'multiple-rate-plans'
          }
        ]
      : [])
  ];

  return (
    <Page
      header={
        <Header
          title={t('Minimum Stay Restrictions')}
          titleAdditionalInfo={
            isSupportArivalAndStayThrough() ? (
              <SegmentedControl
                data={[
                  { label: t('Stay Through'), value: 'stay_through' },
                  { label: t('Arrival'), value: 'stay_arrival' }
                ]}
                disabled={isPmsListLoading || isSaveLoading}
                value={activeTabs || 'stay_through'}
                onChange={(value: string) => {
                  if (value === 'stay_through' || value === 'stay_arrival') {
                    if (!isEqual(value, connectedPms?.configurations?.min_stay_type)) {
                      setIsSave(false);
                    }
                    setActiveTabs(value);
                    open();
                  }
                }}
              />
            ) : null
          }
          actions={
            <Button
              disabled={pathname.includes('removal')}
              onClick={() => triggerSubmit()}
              loading={isSaving}
            >
              {t('Save')}
            </Button>
          }
          tabs={subMenuLink}
        />
      }
    >
      <SetTypeofMinstayModal onClose={close} isOpen={opened} mode={activeTabs} />
      <Outlet />
    </Page>
  );
};
