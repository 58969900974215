import { Badge } from '@mantine/core';
import { Button } from '@common/components/atoms/Button';
import { Icon } from '@common/components/foundations/icons';
import { Header } from '@common/components/molecules/Header/Header';
import { Page } from '@common/components/organisms/Page';
import { useAuthStore, useHotelStore, useLogout } from '@common/store/auth';
import { useFeaturesStore } from '@common/store/features';
import { useViewStore } from '@common/store/view';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { subscriptionStatus } from '@common/config/app-config';
import { setAuthToken } from '@common/api/axiosInstance';
import { useGetHotelUsers } from '@pages/Client/hooks/useGetHotelUsers';

export function AccountLayout() {
  const location = useLocation();
  const { t } = useTranslation();
  const logoutUser = useLogout();
  const { hotelDetails: hotel } = useHotelDetails();
  const { hotelAuthToken } = useHotelStore();
  const { features } = useFeaturesStore();
  const { view, isReadOnly } = useViewStore();
  const subdomain = window.location.hostname.split('.')[0];
  const isIframe = subdomain.includes('iframe');

  const { hotelUsers } = useGetHotelUsers();
  const { user: currentUser } = useAuthStore();
  const isCurrentUserStaff =
    hotelUsers?.find((user) => user.id === currentUser?.id)?.role === 'staff';

  useEffect(() => {
    return () => {
      hotelAuthToken && setAuthToken(hotelAuthToken);
    };
  }, [location]);

  const isProBadge = hotel?.state === 0;
  const isProDashboardEnabled = features?.includes(25);
  const isUnsubscribe = hotel?.state === 7;
  const isFreeTrialExpired = hotel?.state === 3;
  const isSubscriptionPaused = hotel?.state === 9;
  const isSuspended = hotel?.state === 9;
  const isCanceled = hotel?.state === 2;

  const subMenuLink = [
    ...(isIframe
      ? []
      : [
          {
            id: 'profile-personal-information',
            label: t('Profile'),
            to: `profile-settings`,
            icon: Icon.AccountCircle
          }
        ]),
    ...(isReadOnly
      ? []
      : [
          {
            id: 'property-name-and-location',
            label: t('Property'),
            to: `hotel-settings`,
            icon: Icon.HomeVs
          },
          {
            id: 'subscription',
            label: t('Subscription'),
            to: `payment-settings`,
            icon: Icon.Verified
          },
          ...(isCurrentUserStaff
            ? []
            : [
                {
                  id: 'user-settings',
                  label: t('Users'),
                  to: `user-settings`,
                  icon: Icon.GroupEqual
                }
              ]),
          {
            id: 'schedule-reports',
            label: t('Scheduled Reports'),
            to: `schedule-reports`,
            badge: isProBadge && isProDashboardEnabled && <Badge color={'yellow.6'}>PRO</Badge>,
            icon: Icon.Timer40s
          },
          {
            id: 'integration',
            label: t('Integration'),
            to: `integrations`,
            icon: Icon.SwapHorizontal
          }
        ])
  ];

  const isHideMenu =
    isUnsubscribe || isFreeTrialExpired || isSubscriptionPaused || isSuspended || isCanceled;

  const tabs = isProDashboardEnabled
    ? (isUnsubscribe && view === 'client') || (isHideMenu && view === 'client')
      ? subMenuLink.filter(
          (item) => item?.id === 'profile-personal-information' || item?.id === 'subscription'
        )
      : subMenuLink
    : (isUnsubscribe && view === 'client') || (isHideMenu && view === 'client')
      ? subMenuLink.filter(
          (item) => item?.id === 'profile-personal-information' || item?.id === 'subscription'
        )
      : subMenuLink.filter((item) => item?.id !== 'schedule-reports');

  const getHotelName = () => {
    if (hotel) {
      return import.meta.env.DEV
        ? `${hotel.name} - [(${hotel.state})${subscriptionStatus[hotel?.state]?.label}]`
        : hotel?.name;
    } else {
      return 'Loading...';
    }
  };

  const HeaderAccount = () => {
    return (
      <Header
        title={getHotelName()}
        tabs={tabs}
        additionalInfo={
          <Button type="button" icon intent="text" onClick={logoutUser}>
            <Icon.SignOut className="h-5 w-5" />
            {t('Logout')}
          </Button>
        }
      />
    );
  };

  return (
    <Page header={<HeaderAccount />}>
      <Outlet />
    </Page>
  );
}
